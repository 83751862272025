import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { serviceProvider as API } from "../../../API/api";
import { sortByDate, sortByName } from "../../../lib/utils";

// Component
import { Spinner, Toast } from "flowbite-react";
import ConfirmationModal from "./elements/ConfirmationModal";
import DocsModal from "./elements/DocsModal";
import GenericAlert from "../../elements/GenericAlert";
import ImgModal from "./elements/ImgModal";
import MatchCard from "./elements/MatchCard";
import RefundModal from "./elements/RefundModal";
import ReportModal from "./elements/ReportModal";
import ResultsModal from "./elements/ResultsModal";
import UserModal from "./elements/UserModal";
import WelcomeUser from "./elements/WelcomeUser";

function UserMain() {
    const { _id: committeeId } = useSelector(state => state.committee);
    const { _id: seasonId } = useSelector(state => state.season);
    const user = useSelector(state => state.user);
    const [action, setAction] = useState('');
    const [alert, setAlert] = useState({ type: '', label: '' });
    const [assignment, setAssignment] = useState({});
    const [carCheckbox, setCarCheckbox] = useState(false);
    const [docTypes, setDocTypes] = useState([]);
    const [imgInfo, setImgInfo] = useState({ scope: '', src: '' });
    const [loading, setLoading] = useState(true);
    const [nextAssignments, setNextAssignments] = useState([]);
    const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [openDocsModal, setOpenDocsModal] = useState(false);
    const [openImgModal, setOpenImgModal] = useState(false);
    const [openRefundModal, setOpenRefundModal] = useState(false);
    const [openReportModal, setOpenReportModal] = useState(false);
    const [openResultsModal, setOpenResultsModal] = useState(false);
    const [previousAssignments, setPreviousAssignments] = useState([]);
    const [refund, setRefund] = useState({ allowance: 0, subtotal: 0, distance: 0, car: 0, carplate: '', departureArrival: '', departureTime: '', meal: 0, travel: 0, expenses: 0, returnArrival: '', returnTime: '', start_time: '', edited_distance: false, end_time: '', notes: '' });
    const [report, setReport] = useState([]);
    const [reportAction] = useState('edit');
    const [resultContext] = useState('referee');
    const [roles, setRoles] = useState([]);
    const [toasts, setToasts] = useState([]);
    const [totalAssignments, setTotalAssignments] = useState([]);

    // Assignments
    useEffect(() => {
        if (user?.isreferee && committeeId && seasonId) {
            setLoading(true)
            API.get(`assignments?committee=${committeeId}&season=${seasonId}&referee=${user._id}`, true)
                .then(res => {
                    if (res.success) {
                        setTotalAssignments(res.assignments);
                    } else {
                        setAlert({ type: 'failure', label: res.msg || 'Qualcosa è andato storto...' });
                    }
                    setLoading(false);
                })
        }
        if (!user?.isreferee) {
            setLoading(false);
        }
    }, [committeeId, seasonId, user]);

    useEffect(() => {
        setNextAssignments(sortByDate(totalAssignments
            .filter(a => new Date(a.match.date) > new Date() && a.status > 1), 'match.date'));
        setPreviousAssignments(sortByDate(totalAssignments
            .filter(a => new Date() > new Date(a.match.date) && (new Date() - new Date(a.match.date)) < 3 * 24 * 60 * 60 * 1000 && a.status === 3), 'match.date'));
    }, [totalAssignments]);

    // Roles
    useEffect(() => {
        if (committeeId) {
            API.get(`refroles?committee=${committeeId}`)
                .then(res => {
                    if (res.success) {
                        setRoles(sortByName(res.refroles, 'title'));
                    } else {
                        setAlert({ type: 'failure', label: res.msg || 'Qualcosa è andato storto...' });
                    }
                })
                .catch(err => console.error(err));
        }
    }, [committeeId])

    // DocTypes
    useEffect(() => {
        if (committeeId) {
            API.get(`doctypes?committee=${committeeId}`)
                .then(res => {
                    if (res.success) {
                        setDocTypes(sortByName(res.doctypes, 'name'));
                    } else {
                        setAlert({ type: 'failure', label: res.msg || 'Qualcosa è andato storto...' });
                    }
                })
                .catch(err => console.error(err));
        }
    }, [committeeId])

    return (
        <>
            <WelcomeUser />
            {alert?.type && <GenericAlert type={alert.type}>{alert.label}</GenericAlert>}
            {loading &&
            <div className="bg-white p-4">
                <Spinner color="pink" size="xl" />
            </div>
            }
            {toasts.length > 0 &&
                <div className="flex flex-row flex-wrap p-2 gap-4">
                    {
                        toasts.map((t, i) =>
                            <Toast key={`toast_${i}`} className="outline outline-1 outline-red-200 shadow">
                                <div className="inline-flex h-8 w-8 shrink-0 items-center justify-center rounded-lg bg-red-100 text-red-500 dark:bg-red-800 dark:text-red-200">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                    </svg>
                                </div>
                                <div className="ml-3 text-sm font-normal">
                                    {t}
                                </div>
                                <Toast.Toggle />
                            </Toast>
                        )
                    }
                </div>
            }
            {nextAssignments.length > 0 &&
                <section className="bg-white p-2 rounded shadow">
                    <h5 className="w-1/2 lg:w-1/3 xl:w-1/5 mx-auto rounded-lg text-xl text-center font-bold tracking-wide uppercase text-gray-800 dark:text-white">
                        Le tue prossime gare
                    </h5>
                    {
                        nextAssignments.map((a, i) =>
                            <MatchCard
                                key={a._id}
                                assignment={a}
                                index={i}
                                roles={roles}
                                setAction={setAction}
                                setAssignment={setAssignment}
                                setOpenModal={setOpenModal}
                                setOpenResultsModal={setOpenResultsModal}
                            />
                        )
                    }
                </section>
            }
            {nextAssignments.length < 1 && !loading && user?.isreferee &&
            <section className="bg-white p-2 rounded shadow">
                <h5 className="w-1/2 lg:w-1/3 xl:w-1/5 mx-auto rounded-lg text-sm text-center font-bold tracking-wide uppercase text-gray-800 dark:text-white">
                    Al momento non ci sono prossime gare designate
                </h5>
            </section>
            }
            {previousAssignments.length > 0 &&
                <section className="bg-white p-2 rounded shadow">
                    <h5 className="w-1/2 lg:w-1/3 xl:w-1/5 mx-auto rounded-lg text-xl text-center font-bold tracking-wide uppercase text-gray-800 dark:text-white">
                        Gare recenti
                    </h5>
                    {
                        previousAssignments.map((a, i) =>
                            <MatchCard
                                key={a._id}
                                assignment={a}
                                index={i}
                                roles={roles}
                                setAction={setAction}
                                setAssignment={setAssignment}
                                setCarCheckbox={setCarCheckbox}
                                setOpenConfirmationModal={setOpenConfirmationModal}
                                setOpenDocsModal={setOpenDocsModal}
                                setOpenModal={setOpenModal}
                                setOpenRefundModal={setOpenRefundModal}
                                setOpenReportModal={setOpenReportModal}
                                setOpenResultsModal={setOpenResultsModal}
                                setRefund={setRefund}
                                setReport={setReport}
                            />
                        )
                    }
                </section>
            }
            {previousAssignments.length < 1 && !loading && user?.isreferee &&
            <section className="bg-white mt-4 p-2 rounded shadow">
                <h5 className="w-1/2 lg:w-1/3 xl:w-1/5 mx-auto rounded-lg text-sm text-center font-bold tracking-wide uppercase text-gray-800 dark:text-white">
                    Non hai gare recenti negli ultimi 3 giorni
                </h5>
            </section>
            }
            <UserModal
                action={action}
                assignment={assignment}
                openModal={openModal}
                setAlert={setAlert}
                setAssignment={setAssignment}
                setToasts={setToasts}
                setTotalAssignments={setTotalAssignments}
                setOpenModal={setOpenModal}
            />
            <ResultsModal
                assignment={assignment}
                match={assignment.match}
                openModal={openResultsModal}
                resultContext={resultContext}
                setAlert={setAlert}
                setOpenModal={setOpenResultsModal}
                setTotalAssignments={setTotalAssignments}
            />
            <ReportModal
                assignment={assignment}
                openModal={openReportModal}
                report={report}
                reportAction={reportAction}
                roles={roles}
                setAlert={setAlert}
                setOpenModal={setOpenReportModal}
                setReport={setReport}
                setTotalAssignments={setTotalAssignments}
            />
            <DocsModal
                assignment={assignment}
                docTypes={docTypes}
                openModal={openDocsModal}
                setAssignment={setAssignment}
                setImgInfo={setImgInfo}
                setOpenImgModal={setOpenImgModal}
                setOpenModal={setOpenDocsModal}
                setTotalAssignments={setTotalAssignments}
            />
            <ImgModal
                imgInfo={imgInfo}
                openModal={openImgModal}
                setOpenModal={setOpenImgModal}
            />
            <RefundModal
                assignment={assignment}
                car={carCheckbox}
                openModal={openRefundModal}
                refund={refund}
                roles={roles}
                setAlert={setAlert}
                setCar={setCarCheckbox}
                setOpenModal={setOpenRefundModal}
                setRefund={setRefund}
                setTotalAssignments={setTotalAssignments}
            />
            <ConfirmationModal
                assignment={assignment}
                openModal={openConfirmationModal}
                setAlert={setAlert}
                setAssignment={setAssignment}
                setOpenModal={setOpenConfirmationModal}
                setTotalAssignments={setTotalAssignments}
            />
        </>
    )
}

export default UserMain;