import { useCallback, useState } from "react";
import { useSelector } from "react-redux";
import { formatDateTime, genGroupPdf, genGroupXlsx, genTeamPdf, readablePartials } from "../../../../lib/utils";

// Components
import { Button, Select, Table, Tabs, Tooltip } from "flowbite-react";

// Icons
import { ReactComponent as WhistleIcon } from '../../../../images/icons/whistle.svg';


const MatchRow = ({ match, showReferees, sport }) => {
    const referees = useCallback(() =>
        match.assignments
            .filter(a => a.status > 2)
            .map(a => `${a.role}: ${a.referee.lastname} ${a.referee.firstname}`)
        , [match]
    )

    return (
        <Table.Row className="text-xs sm:text-sm bg-white dark:border-gray-700 dark:bg-gray-800">
            <Table.Cell className="hidden md:inline-grid whitespace-nowrap font-medium text-gray-900 dark:text-white">
                {match.num}
            </Table.Cell>
            <Table.Cell className="text-ellipsis">
                {match.postponed ? 'Rinviata' : formatDateTime(match.date)}
            </Table.Cell>
            <Table.Cell className="hidden md:inline-grid">
                <Tooltip content={`${match.field.city} - ${match.field.address}`}>
                    {match.field.name}
                </Tooltip>
            </Table.Cell>
            <Table.Cell className="text-ellipsis">
                {match.teamA.name}{' '}
                <span className="inline-grid md:hidden">{'- '}{match.teamB.name}</span>
            </Table.Cell>
            <Table.Cell className="hidden md:inline-grid">
                {match.teamB.name}
            </Table.Cell>
            <Table.Cell>
                {match.result.a}-{match.result.b}
            </Table.Cell>
            {sport === 'Pallavolo' &&
                <Table.Cell>
                    {readablePartials(match.partials)}
                </Table.Cell>
            }
            <Table.Cell className={`hidden ${showReferees && 'md:inline-grid'}`}>
                {referees().length > 0 &&
                    <Tooltip content={referees().map((a, i) => <p key={`prev_${i}`}>{a}</p>)}>
                        <div className="bg-gray-100 hover:bg-gray-200 p-1.5 rounded border border-gray-300">
                            <WhistleIcon className="w-6 h-6" />
                        </div>
                    </Tooltip>
                }
            </Table.Cell>
        </Table.Row>
    )
}

const RoundCalendar = ({ round, sport }) => {
    const { showReferees } = useSelector(state => state.committee);

    return (
        <Table hoverable={true}>
            <Table.Head>
                <Table.HeadCell className="hidden md:inline-grid">
                    #
                </Table.HeadCell>
                <Table.HeadCell>
                    Data
                </Table.HeadCell>
                <Table.HeadCell className="hidden md:inline-grid">
                    Campo di gioco
                </Table.HeadCell>
                <Table.HeadCell>
                    <span className="inline-grid md:hidden">Squadre</span>
                    <span className="md:inline-grid hidden">Squadra 1</span>
                </Table.HeadCell>
                <Table.HeadCell className="hidden md:inline-grid">
                    Squadra 2
                </Table.HeadCell>
                <Table.HeadCell>
                    <span className="inline-grid md:hidden">Ris.</span>
                    <span className="md:inline-grid hidden">Risultato</span>
                </Table.HeadCell>
                {sport === 'Pallavolo' &&
                    <Table.HeadCell>
                        Parziali
                    </Table.HeadCell>
                }
                <Table.HeadCell className={`hidden ${showReferees && 'md:inline-grid'}`}>
                    UDG
                </Table.HeadCell>
            </Table.Head>
            <Table.Body className="divide-y">
                {
                    round.matches.map(match =>
                        <MatchRow
                            key={match._id}
                            match={match}
                            showReferees={showReferees}
                            sport={sport}
                        />
                    )
                }
            </Table.Body>
        </Table>
    )
}

const GroupCalendar = ({ group, teams, sport, title }) => {
    const [teamSelect, setTeamSelect] = useState('all');

    const createPdf = () => {
        teamSelect === 'all'
            ? genGroupPdf(title, group.group_name, teams, group.rounds)
            : genTeamPdf(title, group.group_name, teamSelect, group.rounds, teams);
    }

    const createXls = () => {
        const matchRows = teamSelect === 'all'
            ? group.rounds.reduce((prev, r) =>
                [
                    ...prev,
                    ...r.matches.map(m => ({
                        'Giornata': r.round,
                        'Num': m.num,
                        'Data': m.postponed ? 'da stabilire' : formatDateTime(m.date),
                        'Campo': m.field.name,
                        'Indirizzo': `${m.field.city} - ${m.field.address}`,
                        'Squadra 1': m.teamA.name,
                        'Squadra 2': m.teamB.name
                    }))
                ], [])
            : group.rounds.reduce((prev, r) =>
                [
                    ...prev,
                    ...r.matches
                        .filter(m => m.teamA._id === teamSelect || m.teamB._id === teamSelect)
                        .map(m => ({
                            'Giornata': r.round,
                            'Num': m.num,
                            'Data': m.postponed ? 'da stabilire' : formatDateTime(m.date),
                            'Campo': m.field.name,
                            'Indirizzo': `${m.field.city} - ${m.field.address}`,
                            'Squadra 1': m.teamA.name,
                            'Squadra 2': m.teamB.name
                        }))
                ], [])
            ;
        const teamRows = teams.map(t => (
            {
                'Squadra': t.name,
                'Referente': t.referent,
                'Telefono': t.referent_phone,
                'E-mail': t.referent_email
            }
        ));
        genGroupXlsx(title, matchRows, teamRows);
    }

    return (
        <div className="text-left p-2 bg-white rounded shadow-lg">
            <p className="font-semibold text-base md:text-xl">
                Girone{' '}
                <span className="uppercase font-extrabold tracking-wide text-blue-600">{group.group_name}</span>
            </p>
            <Tabs.Group aria-label="Tab Gironi" style="underline">
                {group.rounds.map(round =>
                    <Tabs.Item key={round._id} title={`Giornata ${round.round}`}>
                        <RoundCalendar round={round} sport={sport} />
                    </Tabs.Item>
                )}
            </Tabs.Group>
            <div className="flex flex-row gap-2 items-center">
                <div>
                    <Select
                        id="teamselect"
                        sizing="sm"
                        value={teamSelect}
                        onChange={(e) => setTeamSelect(e.currentTarget.value)}
                    >
                        <option value="all">Tutte</option>
                        {
                            teams.map(t =>
                                <option key={t._id} value={t._id}>{t.name}</option>
                            )
                        }
                    </Select>
                </div>
                <div>
                    <Button
                        gradientDuoTone="cyanToBlue"
                        onClick={createPdf}
                        size="xs"
                    >
                        Esporta Pdf
                    </Button>
                </div>
                <div>
                    <Button
                        gradientDuoTone="greenToBlue"
                        onClick={createXls}
                        size="xs"
                    >
                        Esporta Xls
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default GroupCalendar;