import { formatDate } from "../../../../../lib/utils";

// Components
import { Table } from "flowbite-react";
import DeleteButton from "../../../../buttons/DeleteButton";
import EditButton from "../../../../buttons/EditButton";
import GenericTitle from "../../../../elements/GenericTitle";


const PenaltyRow = ({ penalty, setAction, setOpenModal, setPenalty }) => {

    const handleEdit = () => {
        setAction('edit');
        setPenalty(penalty);
        setOpenModal(true);
    }

    const handleDelete = () => {
        setAction('delete');
        setPenalty(penalty);
        setOpenModal(true);
    }

    return (
        <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
            <Table.Cell>
                {formatDate(penalty.date)}
            </Table.Cell>
            <Table.Cell>
                {penalty.team?.name}
            </Table.Cell>
            <Table.Cell>
                {penalty.value}
            </Table.Cell>
            <Table.Cell className="text-right">
                <EditButton onClick={handleEdit} />
                <DeleteButton onClick={handleDelete} />
            </Table.Cell>
        </Table.Row>
    )
}

const PenaltyTable = ({ penalties, setAction, setOpenModal, setPenalty }) => {

    return (
        <Table hoverable={true}>
            <Table.Head>
                <Table.HeadCell>
                    Data
                </Table.HeadCell>
                <Table.HeadCell>
                    Squadra
                </Table.HeadCell>
                <Table.HeadCell>
                    Penalità
                </Table.HeadCell>
                <Table.HeadCell>
                    <span className="sr-only">
                        Edit
                    </span>
                </Table.HeadCell>
            </Table.Head>
            <Table.Body className="divide-y">
                {
                    penalties?.map(p =>
                        <PenaltyRow
                            key={p._id}
                            penalty={p}
                            setAction={setAction}
                            setOpenModal={setOpenModal}
                            setPenalty={setPenalty}
                        />
                    )
                }
            </Table.Body>
        </Table>
    )
}

function Penalties({ championship, setAction, setOpenModal, setPenalty }) {

    return (
        <div className="mb-3">
            <GenericTitle title="Penalità" size="lg" />
            <PenaltyTable
                penalties={championship.penalties}
                setAction={setAction}
                setOpenModal={setOpenModal}
                setPenalty={setPenalty}
            />
        </div>
    )
}

export default Penalties;