import { useEffect, useState } from "react";
import { serviceProvider as API } from "../../../../../API/api";
import { volleyball_formulas } from "../../../../../lib/configs";

// Components
import { Button, Select } from "flowbite-react";
import FormLabel from "../../../../elements/FormLabel";
import GenericAlert from "../../../../elements/GenericAlert";
import GenericModal from "../../../../elements/GenericModal";


const RetireForm = ({ championship, setAlert, setChampionship, setGroups, setModalAlert, setOpenModal, teams }) => {
    const [action, setAction] = useState('');
    const [team, setTeam] = useState('');

    const onSubmit = async (e) => {
        e.preventDefault();
        let updChamp = { ...championship };
        const matches = [];
        // Se i match includono la squadra con l'id team, saranno incluse nell'array
        updChamp.calendar.forEach(g =>
            g.rounds.forEach(r =>
                r.matches.forEach(m => {
                    if (m.teamA._id === team || m.teamB._id === team) {
                        matches.push(m)
                    }
                })
            )
        );
        const deleteTeam = teams.find(t => t._id === team);
        switch (action) {
            case 'complete':
                matches.forEach(async match => {
                    // Elimino le designazioni
                    match.assignments.forEach(async a => {
                        await API.delete(`assignments/${a._id}`, true).catch(err => console.error(err));
                    });
                    match.documents.forEach(async d => {
                        const query = `ref=match&refId=${match._id}&filename=${d.filename}`;
                        await API.delete(`files?${query}`, true).catch(err => console.error(err));
                    });
                    await API.delete(`matches/${match._id}`, true).catch(err => console.error(err));
                });
                // Filtro dal calendario i match che implicano quella squadra
                updChamp.calendar = updChamp.calendar.map(group => ({
                    ...group,
                    rounds: group.rounds.map(round => ({
                        ...round,
                        matches: round.matches.filter(m => m.teamA._id !== team && m.teamB._id !== team)
                    }))
                }));
                // Filtro dalla composizione dei gironi quel team
                updChamp.teams = updChamp.teams.map(group => ({
                    ...group,
                    teams: group.teams
                        .filter(t => t._id.toString() !== team)
                        .map(t => t._id)
                }))
                if (deleteTeam.championships.length > 1) {
                    // La squadra non va eliminata, ma aggiornata
                    const updBody = {
                        championships: deleteTeam.championships.filter(c => c._id !== updChamp._id)
                    }
                    await API.update(`teams/${deleteTeam._id}`, JSON.stringify(updBody), true).catch(err => console.error(err));
                } else {
                    // La squadra va eliminata
                    await API.delete(`teams/${deleteTeam._id}`, true).catch(err => console.error(err));
                }
                API.update(`championships/${updChamp._id}`, JSON.stringify(updChamp), true)
                    .then(res => {
                        if (res.success) {
                            setChampionship(res.championship);
                            setGroups(res.championship.teams);
                            setAlert({ type: 'success', label: `La squadra "${deleteTeam.name}" è stata eliminata` });
                            setOpenModal(false);
                        } else {
                            setModalAlert({ type: 'failure', label: res.msg || 'Qualcosa è andato storto...' });
                        }
                    })
                    .catch(err => console.error(err));
                break;
            case 'partial':
                //  Questa funzione va modificata'
                /*matches.forEach(async match => {
                    console.log(volleyball_formulas[championship.formula])
                     const updBody = {
                        result: { a: 0, b: 0 },
                        partials: []
                    }
                    if ((match.result.a + match.result.b) === 0) {
                        if (match.teamB._id === team) {
                            updBody.result.a = 3;
                            updBody.partials = [{ a: 25, b: 0 }, { a: 25, b: 0 }, { a: 25, b: 0 }];
                        }
                        if (match.teamA._id === team) {
                            updBody.result.b = 3;
                            updBody.partials = [{ a: 0, b: 25 }, { a: 0, b: 25 }, { a: 0, b: 25 }];
                        }
                        await API.update(`matches/${match._id}`, JSON.stringify(updBody), true).catch(err => console.error(err));
                    } 
                });*/
                /* API.get(`championships/${championship._id}`)
                    .then(res => {
                        if (res.success) {
                            setChampionship(res.championship);
                            setAlert({ type: 'success', label: 'Le modifiche sono state effettuate' });
                            setOpenModal(false);
                        } else {
                            setModalAlert({ type: 'failure', label: res.msg || 'Qualcosa è andato storto...' });
                        }
                    })
                    .catch(err => console.error(err)); */
                break;
            default:
        }
    }

    return (
        <form className="flex flex-col gap-4" onSubmit={onSubmit}>
            <div>
                <div className="mb-2 block">
                    <FormLabel htmlFor="team" label="Seleziona la squadra da ritirare" />
                </div>
                <Select
                    id="team"
                    required={true}
                    value={team}
                    onChange={(e) => setTeam(e.currentTarget.value)}
                >
                    <option value="">-</option>
                    {
                        teams.map(t =>
                            <option
                                key={t._id}
                                value={t._id}
                            >
                                {t.name}
                            </option>
                        )
                    }
                </Select>
            </div>
            <div>
                <div className="mb-2 block">
                    <FormLabel htmlFor="action" label="Modalità" />
                </div>
                <Select
                    id="action"
                    required={true}
                    value={action}
                    onChange={(e) => setAction(e.currentTarget.value)}
                >
                    <option value="">-</option>
                    <option value="complete">
                        Elimina completamente la squadra
                    </option>
                    <option value="partial">
                        La squadra perde le restanti gare
                    </option>
                </Select>
            </div>
            {
                action &&
                <GenericAlert type="warning">
                    {
                        action === "complete" &&
                        `Tutte le gare associate a quella squadra saranno eliminate, le avversarie saranno considerate a riposo.
                    La squadra sarà eliminata anche dal campionato e dall'elenco squadre (se non associata anche ad altri campionati).
                    Eventuali designazioni e documenti di gara saranno eliminati. Si desidera procedere?`
                    }
                    {
                        action === "partial" &&
                        `Le gare giocate dalla squadra (in cui è stato impostato un risultato) rimarranno invariate.
                    Tutte le restanti gare (o quelle con un risultato non impostato) verranno considerate vinte
                    dalla squadra avversaria col punteggio più favorevole. Si desidera procedere?`
                    }
                </GenericAlert>
            }
            <div className="flex flex-row gap-2">
                <Button
                    type="submit"
                    color="failure"
                >
                    Sì, procedi
                </Button>
                <Button
                    color="light"
                    onClick={() => setOpenModal(false)}
                >
                    No, annulla
                </Button>
            </div>
        </form>
    )
}

function RetireModal({ championship, openModal, setAlert, setChampionship, setGroups, setOpenModal, teams }) {
    const [modalAlert, setModalAlert] = useState({ type: '', label: '' });

    // Azzera l'alert del modal ogni volta che viene aperto
    useEffect(() => {
        openModal === true && setModalAlert({ type: '', label: '' });
    }, [openModal]);

    return (
        <GenericModal openModal={openModal} setOpenModal={setOpenModal} size="xl" title='Ritiro squadra'>
            {modalAlert?.type && <GenericAlert type={modalAlert.type}>{modalAlert.label}</GenericAlert>}
            <RetireForm
                championship={championship}
                setAlert={setAlert}
                setChampionship={setChampionship}
                setGroups={setGroups}
                setModalAlert={setModalAlert}
                setOpenModal={setOpenModal}
                teams={teams}
            />
        </GenericModal>
    )
}

export default RetireModal;