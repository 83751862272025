function GenericAlert({ type, children, divclass = '', hideicon=false }) {
    const classes = {
        info: 'flex p-4 mb-4 text-sm text-blue-700 bg-blue-100 rounded-lg dark:bg-blue-200 dark:text-blue-800 items-center',
        failure: 'flex p-4 mb-4 text-sm text-red-700 bg-red-100 rounded-lg dark:bg-red-200 dark:text-red-800 items-center',
        success: 'flex p-4 mb-4 text-sm text-green-700 bg-green-100 rounded-lg dark:bg-green-200 dark:text-green-800 items-center',
        warning: 'flex p-4 mb-4 text-sm text-yellow-700 bg-yellow-100 rounded-lg dark:bg-yellow-200 dark:text-yellow-800 items-center'
    }

    return (
        <div className={classes[type]} role="alert">
            <svg aria-hidden="true" className={`flex-shrink-0 inline w-5 h-5 mr-3 ${hideicon && 'hidden'}`} fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd">
                </path>
            </svg>
            <span className="sr-only">Info</span>
            <div className={divclass}>
                {children}
            </div>
        </div>
    )
}

export default GenericAlert;