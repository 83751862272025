import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { abbrText, formatDateTime, setAssignmentMail } from "../../../../lib/utils";
import { serviceProvider as API } from "../../../../API/api";

// Components
import { Button, Tooltip } from "flowbite-react";
import GenericAlert from "../../../elements/GenericAlert";
import GenericModal from "../../../elements/GenericModal";
import SubmitButton from "../../../buttons/SubmitButton";


function NotifyModal({ action, assignment, assignments, match, openModal, setAlert, setAssignments, setMatches, setOpenModal, setViewAssignments, viewAssignments }) {
    const { title: committee } = useSelector(state => state.committee);
    const [isLoading, setIsLoading] = useState(false);
    const [modalAlert, setModalAlert] = useState({ type: '', label: '' });
    const [startIndex, setStartIndex] = useState(0);

    const addNav = () => {
        setViewAssignments(assignments.slice(startIndex + 10, startIndex + 20));
        setStartIndex(prevState => prevState + 10);
    }

    const subNav = () => {
        setViewAssignments(assignments.slice(startIndex - 10, startIndex));
        setStartIndex(prevState => prevState - 10);
    }

    const orderyByName = () => {
        const orderingAss = assignments
            .sort((a, b) => {
                if (`${a.referee.lastname} ${a.referee.firstname}`.toUpperCase() < `${b.referee.lastname} ${b.referee.firstname}`.toUpperCase()) return -1;
                if (`${a.referee.lastname} ${a.referee.firstname}`.toUpperCase() > `${b.referee.lastname} ${b.referee.firstname}`.toUpperCase()) return 1;
                return 0;
            })
        setAssignments(orderingAss)
        setViewAssignments(orderingAss.slice(startIndex, startIndex + 10));
    }

    const orderyByDate = () => {
        const orderingAss = assignments
            .sort((a, b) => new Date(a.match.date) - new Date(b.match.date))
        setAssignments(orderingAss);
        setViewAssignments(orderingAss.slice(startIndex, startIndex + 10));
    }

    const onSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        switch (action) {
            case 'sendOne':
                // {...assignment, match: match }
                // Need to append match infos to the assignment for the function to work properly
                const mailBody = setAssignmentMail(match, { ...assignment, match: match }, committee);
                API.insert('emails', JSON.stringify(mailBody), true)
                    .then(res => {
                        if (res.success) {
                            API.update(`assignments/${assignment._id}`, JSON.stringify({ status: 2 }), true)
                                .then(res => {
                                    if (res.success) {
                                        // Aggiorno lo state principale
                                        setMatches(prevState => {
                                            const array = [...prevState];
                                            const matchIndex = prevState.findIndex(m => m._id === match._id);
                                            array[matchIndex].assignments = [
                                                ...array[matchIndex].assignments.filter(a => a._id !== res.assignment._id),
                                                res.assignment
                                            ];
                                            return array;
                                        })
                                        setAlert({ type: 'success', label: 'Designazione inviata' });
                                        setOpenModal(false);
                                        setIsLoading(false);
                                    } else {
                                        setModalAlert({ type: 'failure', label: res.msg || 'Qualcosa è andato storto...' });
                                    }
                                })
                                .catch(err => console.error(err));
                        } else {
                            setModalAlert({ type: 'failure', label: res.msg || 'Qualcosa è andato storto...' });
                        }
                    })
                    .catch(err => console.error(err));
                break;
            case 'sendMany':
                const info = await Promise.all(assignments.map(async a => {
                    // Ciclo per ogni designazione proposta
                    const mailBody = setAssignmentMail(a.match, a, committee);
                    // Invio la mail
                    const result = await API.insert('emails', JSON.stringify(mailBody), true).catch(err => console.error(err));
                    if (result.success) {
                        // In caso di successo, aggiorna la designazione a "Notificata"
                        const assignmentResult = await API.update(`assignments/${a._id}`, JSON.stringify({ status: 2 }), true).catch(err => console.error(err));
                        if (assignmentResult.success) {
                            // Aggiorno lo state principale
                            setMatches(prevState => {
                                const array = [...prevState];
                                const matchIndex = prevState.findIndex(m => m._id === a.match._id);
                                array[matchIndex].assignments = [
                                    ...array[matchIndex].assignments.filter(a => a._id !== assignmentResult.assignment._id),
                                    assignmentResult.assignment
                                ];
                                return array;
                            });
                            setIsLoading(false);
                        }
                    }
                    return result;
                }))
                // Verifico che tutte le notifiche siano state inviate
                const results = info.every(i => i.success === true);
                if (results === true) {
                    setAlert({ type: 'success', label: 'Le designazioni sono state inviate' });
                    setOpenModal(false);
                } else {
                    setModalAlert({ type: 'failure', label: 'Qualcosa è andato storto, riprova più tardi' });
                }
                break;
            default:
        }
    }

    // Azzera l'alert del modal ogni volta che viene aperto
    useEffect(() => {
        openModal === true && setModalAlert({ type: '', label: '' });
    }, [openModal]);

    return (
        <GenericModal openModal={openModal} setOpenModal={setOpenModal} size={action === 'sendOne' ? 'lg' : '5xl'} title={action === 'sendOne' ? 'Invia Designazione' : 'Invia Designazioni'}>
            {modalAlert?.type && <GenericAlert type={modalAlert.type}>{modalAlert.label}</GenericAlert>}
            {
                action === 'sendOne' &&
                <ul className="space-y-1 list-none list-inside p-1 cursor-default border bg-gray-50 shadow-lg border-red-400 rounded-lg text-center text-gray-700 dark:text-gray-400">
                    <li>
                        <span className="font-semibold text-sm uppercase">{match.championship?.name}</span> <span className="font-semibold">({match.teamA?.name} - {match.teamB?.name})</span>
                    </li>
                    <li>
                        <span className="font-semibold">Data:</span> <span className="capitalize">{formatDateTime(match.date, true)}</span>
                    </li>
                    <li>
                        <span className="font-semibold">Campo:</span> {match.field?.city} - {match.field?.name}
                    </li>
                    <li>
                        <span className="font-semibold">Arbitro:</span> {assignment.referee?.lastname} {assignment.referee?.firstname}
                    </li>
                    <li>
                        <span className="font-semibold">Ruolo:</span> {assignment.role}
                    </li>
                </ul>
            }
            {
                action === 'sendMany' &&
                <>
                    <p className="text-center">Stai inviando le seguenti designazioni:</p>
                    <ul className="space-y-1 list-none list-inside p-1 cursor-default rounded-lg text-center text-gray-700 dark:text-gray-400">
                        {
                            viewAssignments.map((a, i) =>
                                <li className={`grid grid-cols-2 sm:grid-cols-4 lg:grid-cols-5 border gap-1 items-center justify-between rounded-lg ${i % 2 === 0 ? 'bg-pink-100 hover:bg-pink-200 border-pink-300' : 'bg-purple-100 hover:bg-purple-200 border-purple-300'}`} key={a._id}>
                                    <span>{formatDateTime(a.match.date)}</span>
                                    {<span className="font-semibold">{a.referee.lastname} {a.referee.firstname[0]}.</span>}
                                    <Tooltip content={`Gara #${a.match.num}: ${a.match.teamA.name} / ${a.match.teamB.name}`}>
                                        <span>
                                            Gara #{a.match.num}: {abbrText(a.match.teamA.name, 8)} / {abbrText(a.match.teamB.name, 8)}
                                        </span>
                                    </Tooltip>
                                    <Tooltip content={a.match.championship.name}>
                                        <span className="hidden lg:block">
                                            {abbrText(a.match.championship.name, 8)}
                                        </span>
                                    </Tooltip>
                                    <span>{a.role}</span>
                                </li>
                            )
                        }
                    </ul>
                    <div className="flex flex-row gap-4 justify-between">
                        {assignments.length > 10 &&
                            <div className="flex flex-row gap-4">
                                <div>
                                    <Button
                                        onClick={subNav}
                                        outline
                                        disabled={startIndex < 1}
                                        size="xs"
                                    >
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="m18.75 4.5-7.5 7.5 7.5 7.5m-6-15L5.25 12l7.5 7.5" />
                                        </svg>
                                    </Button>
                                </div>
                                <div>
                                    <Button
                                        onClick={addNav}
                                        outline
                                        disabled={assignments.length < startIndex + 10}
                                        size="xs"
                                    >
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="m5.25 4.5 7.5 7.5-7.5 7.5m6-15 7.5 7.5-7.5 7.5" />
                                        </svg>
                                    </Button>
                                </div>
                            </div>
                        }
                        <div className="flex flex-row gap-4">
                            <div>
                                <Button
                                    onClick={orderyByName}
                                    outline
                                    size="xs"
                                >
                                    Ordina per nome
                                </Button>
                            </div>
                            <div>
                                <Button
                                    onClick={orderyByDate}
                                    outline
                                    size="xs"
                                >
                                    Ordina per data
                                </Button>
                            </div>
                        </div>
                    </div>
                </>
            }
            <form onSubmit={onSubmit}>
                <div className="text-center mb-2 font-semibold text-sm uppercase tracking-wide">{action === 'sendOne' ? 'Inviare questa designazione?' : 'Confermi l\'invio?'}</div>
                <div className="flex flex-row gap-2">
                    <SubmitButton isLoading={isLoading} />
                    <Button
                        color="light"
                        size="sm"
                        onClick={() => setOpenModal(false)}
                    >
                        Annulla
                    </Button>
                </div>
            </form>
        </GenericModal>
    )
}

export default NotifyModal;