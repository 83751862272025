const mainConfigs = 
{
    PROTOCOL: 'https',
    PATH: 'localhost:3001/api/v1',
    REACT_ENV: 'production',
    REACT_APP_API_URL: 'www.csisport.it/api/v1'
}

export default mainConfigs;


/* PROTOCOL: 'https',
    PATH: 'localhost:3001/api/v1',
    REACT_ENV: 'production',
    REACT_APP_API_URL: 'www.csisport.it/api/v1' */