import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { serviceProvider as API } from "../../../API/api";
import { sortByName } from "../../../lib/utils";

// Components
import { Table, Tooltip } from "flowbite-react";
import GenericAlert from "../../elements/GenericAlert";
import LoadingRow from "../../elements/LoadingRow";
import NoResultsRow from "../../elements/NoResultsRow";


const TableRow = ({ referee }) => {
    return (
        <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
            <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                {referee.lastname} {referee.firstname}
            </Table.Cell>
            <Table.Cell>
                {
                    referee.sports.map((s, i) =>
                        <p key={`sports_${i}`}>
                            {s}
                        </p>
                    )
                }
            </Table.Cell>
            <Table.Cell>
                <Tooltip content={referee.address}>
                    {referee.city}
                </Tooltip>
            </Table.Cell>
            <Table.Cell>
                {referee.email}
            </Table.Cell>
            <Table.Cell>
                {referee.phone}
            </Table.Cell>
        </Table.Row>
    )
}

const MainTable = ({ loading, referees }) => {
    return (
        <Table hoverable={true}>
            <Table.Head>
                <Table.HeadCell>
                    Nome
                </Table.HeadCell>
                <Table.HeadCell>
                    Sport
                </Table.HeadCell>
                <Table.HeadCell>
                    Residenza
                </Table.HeadCell>
                <Table.HeadCell>
                    E-Mail
                </Table.HeadCell>
                <Table.HeadCell>
                    Telefono
                </Table.HeadCell>
            </Table.Head>
            <Table.Body className="divide-y">
                {loading && <LoadingRow colspan={5} />}
                {!loading && referees.length < 1 && <NoResultsRow colspan={5} />}
                {
                    referees.map(r =>
                        <TableRow
                            key={r._id}
                            referee={r}
                        />
                    )
                }
            </Table.Body>
        </Table>
    )
}

function RefereesReport() {
    const { _id: committeeId } = useSelector(state => state.committee);
    const [alert, setAlert] = useState({ type: '', label: '' });
    const [loading, setLoading] = useState(true);
    const [referees, setReferees] = useState([]);

    useEffect(() => {
        if (committeeId) {
            API.get(`users?committees=${committeeId}&isreferee=true`, true)
                .then(res => {
                    if (res.success) {
                        setReferees(sortByName(res.users, 'lastname'));
                    } else {
                        setAlert({ type: 'failure', label: res.msg || 'Qualcosa è andato storto, si prega di riprovare più tardi' });
                    }
                    setLoading(false);
                })
                .catch(err => console.error(err))
        }
    }, [committeeId])

    return (
        <div className="flex flex-col gap-3">
            {alert?.type && <GenericAlert type={alert.type}>{alert.label}</GenericAlert>}
            <MainTable
                loading={loading}
                referees={referees}
            />
        </div>
    )
}

export default RefereesReport;