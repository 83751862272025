import { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { serviceProvider as API } from "../../../API/api";
import { formatDateTime, sortByDate } from "../../../lib/utils";
import mainConfigs from '../../../configs/main';

// Components
import { Button, Label, Table, Textarea, TextInput } from "flowbite-react";
import DeleteButton from "../../buttons/DeleteButton";
import EditButton from "../../buttons/EditButton";
import GenericAlert from "../../elements/GenericAlert";
import GenericModal from "../../elements/GenericModal";
import LoadingRow from "../../elements/LoadingRow";
import NoResultsRow from "../../elements/NoResultsRow";
import SubmitButton from "../../buttons/SubmitButton";


const MainRow = ({ bulletin, setAction, setBulletin, setOpenModal }) => {
    const { _id, committee, season, description, filename, title, type, inserted_at, updated_at } = bulletin;

    const editBulletin = {
        _id: _id,
        committee: committee,
        season: season,
        description: description || '',
        filename: filename,
        title: title || '',
        type: type || 'bulletin',
        inserted_at: inserted_at,
        updated_at: updated_at
    }

    const handleEdit = () => {
        setAction('edit');
        setBulletin(editBulletin);
        setOpenModal(true)
    }

    const handleDelete = () => {
        setAction('delete');
        setBulletin(editBulletin);
        setOpenModal(true)
    }

    return (
        <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
            <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                {title}
            </Table.Cell>
            <Table.Cell>
                {formatDateTime(inserted_at)}
            </Table.Cell>
            <Table.Cell>
                <EditButton onClick={handleEdit} />
                <DeleteButton onClick={handleDelete} />
            </Table.Cell>
        </Table.Row>
    )
}

const MainTable = ({ bulletins, loading, setAction, setBulletin, setOpenModal }) => {

    return (
        <Table hoverable={true}>
            <Table.Head>
                <Table.HeadCell>
                    Titolo
                </Table.HeadCell>
                <Table.HeadCell>
                    Inserito il
                </Table.HeadCell>
                <Table.HeadCell>
                    <span className="sr-only">
                        Edit
                    </span>
                </Table.HeadCell>
            </Table.Head>
            <Table.Body className="divide-y">
                {loading && <LoadingRow colspan={3} />}
                {!loading && bulletins.length < 1 && <NoResultsRow colspan={3} />}
                {
                    bulletins.map(b =>
                        <MainRow
                            key={b._id}
                            bulletin={b}
                            setAction={setAction}
                            setBulletin={setBulletin}
                            setOpenModal={setOpenModal}
                        />
                    )
                }
            </Table.Body>
        </Table>
    )
}

const MainForm = ({ action, bulletin, setAlert, setBulletin, setBulletins, setModalAlert, setOpenModal }) => {
    const { PATH, PROTOCOL, REACT_APP_API_URL, REACT_ENV } = mainConfigs;
    const { file_prefix } = useSelector(state => state.committee);
    const fileUpload = useRef(null);
    const docHref = useCallback(() => REACT_ENV === 'development' 
        ? `${PROTOCOL}://${PATH}/files?ref=bulletin&refId=${bulletin._id}&filename=${bulletin.filename}`
        : `${PROTOCOL}://${REACT_APP_API_URL}/files?ref=bulletin&refId=${bulletin._id}&filename=${bulletin.filename}`
        , [bulletin, PATH, PROTOCOL, REACT_APP_API_URL, REACT_ENV]);
    const [isLoading, setIsLoading] = useState(false);

    const handleInput = (e) => {
        const { id, value } = e.currentTarget;
        setBulletin(prevState => ({
            ...prevState,
            [id]: value
        }));
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        setIsLoading(true);
        switch (action) {
            case 'new':
                const formData = new FormData();
                const updated_at = new Date().toISOString();
                const inserted_at = updated_at;
                formData.append('inserted_at', inserted_at);
                formData.append('updated_at', updated_at);
                formData.append('ref', 'bulletin');
                formData.append('committee_prefix', file_prefix);
                formData.append('file', fileUpload.current.files[0]);
                API.insert('files', formData, true, true)
                    .then(res => {
                        if (res.success) {
                            const body = {
                                ...bulletin,
                                filename: res.file.filename,
                                inserted_at: inserted_at,
                                updated_at: updated_at
                            }
                            API.insert(`documents`, JSON.stringify(body), true)
                                .then(res => {
                                    if (res.success) {
                                        setBulletins(prevState => sortByDate([...prevState, res.document], 'inserted_at'));
                                        setAlert({ type: 'success', label: 'Il comunicato è stato inserito' });
                                        setOpenModal(false);
                                    } else {
                                        setModalAlert({ type: 'failure', label: res.msg || 'Qualcosa è andato storto...' });
                                    }
                                    setIsLoading(false);
                                })
                        } else {
                            setModalAlert({ type: 'failure', label: res.msg || 'Qualcosa è andato storto...' });
                        }
                    })
                    .catch(err => console.error(err));
                break;
            case 'edit':
                API.update(`documents/${bulletin._id}`, JSON.stringify({ ...bulletin, updated_at: new Date().toISOString() }), true)
                    .then(res => {
                        if (res.success) {
                            setBulletins(prevState => sortByDate([
                                ...prevState.filter(b => b._id !== bulletin._id),
                                res.document
                            ], 'inserted_at'));
                            setAlert({ type: 'success', label: 'Il comunicato è stato modificato' });
                            setOpenModal(false);
                        } else {
                            setModalAlert({ type: 'failure', label: res.msg || 'Qualcosa è andato storto...' });
                        }
                        setIsLoading(false);
                    })
                    .catch(err => console.error(err));
                break;
            case 'delete':
                API.delete(`files?ref=bulletin&refId=${bulletin._id}&filename=${bulletin.filename}`, true)
                    .then(res => {
                        if (res.success) {
                            API.delete(`documents/${bulletin._id}`, true)
                                .then(res => {
                                    if (res.success) {
                                        setBulletins(prevState => prevState.filter(b => b._id !== bulletin._id));
                                        setAlert({ type: 'success', label: 'Il comunicato è stato eliminato' });
                                        setOpenModal(false);
                                    } else {
                                        setModalAlert({ type: 'failure', label: res.msg || 'Qualcosa è andato storto...' });
                                    }
                                })
                        } else {
                            setModalAlert({ type: 'failure', label: 'Impossibile eliminare' });
                        }
                        setIsLoading(false);
                    })
                break;
            default:
        }
    }

    return (
        <form className="flex flex-col gap-4" onSubmit={handleSubmit}>
            {action === 'new' &&
                <div>
                    <input
                        className="block w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 cursor-pointer dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                        aria-describedby="file_upload_help"
                        id="file"
                        type="file"
                        accept=".pdf"
                        ref={fileUpload}
                        required={true}
                    />
                    <div>
                        <div
                            className="mt-1 text-sm text-gray-500 dark:text-gray-300"
                            id="file_upload_help"
                        >
                            Seleziona il documento
                        </div>

                    </div>
                </div>
            }
            {
                action !== 'new' &&
                <div>
                    <div>
                        <Label>
                            File inserito
                        </Label>
                    </div>
                    <a
                        href={docHref()}
                        target="_blank"
                        rel="noreferrer noopener"
                        download={bulletin.filename}
                    >
                        {bulletin.filename}
                    </a>
                </div>
            }
            <div>
                <div>
                    <Label htmlFor="title">
                        Titolo
                    </Label>
                </div>
                <TextInput
                    id="title"
                    value={bulletin.title}
                    onChange={handleInput}
                    required={true}
                    disabled={action === 'delete' ? true : false}
                />
            </div>
            <div>
                <div>
                    <Label htmlFor="description">
                        Descrizione
                    </Label>
                </div>
                <Textarea
                    id="description"
                    value={bulletin.description}
                    onChange={handleInput}
                    required={true}
                    disabled={action === 'delete' ? true : false}
                />
            </div>
            {
                action === 'delete' &&
                <GenericAlert type="warning" divclass="text-lg mx-auto" hideicon="true">Desideri eliminare questo documento?</GenericAlert>
            }
            <div>
                <SubmitButton isLoading={isLoading} />
            </div>
        </form>
    )
}

const MainModal = ({ action, bulletin, openModal, setAlert, setBulletin, setBulletins, setOpenModal }) => {
    const [modalAlert, setModalAlert] = useState({ type: '', label: '' });

    // Azzera l'alert del modal ogni volta che viene aperto
    useEffect(() => {
        openModal === true && setModalAlert({ type: '', label: '' });
    }, [openModal]);

    return (
        <GenericModal
            openModal={openModal}
            setOpenModal={setOpenModal}
            title={`${action === 'new' ? 'Nuovo' : action === 'edit' ? 'Modifica' : 'Elimina'} Comunicato`}
        >
            {modalAlert?.type && <GenericAlert type={modalAlert.type}>{modalAlert.label}</GenericAlert>}
            <MainForm
                action={action}
                bulletin={bulletin}
                setAlert={setAlert}
                setBulletin={setBulletin}
                setBulletins={setBulletins}
                setModalAlert={setModalAlert}
                setOpenModal={setOpenModal}
            />
        </GenericModal>
    )
}

function Bulletins() {
    const { _id: committeeId } = useSelector(state => state.committee);
    const { _id: seasonId } = useSelector(state => state.season);
    const [action, setAction] = useState('new');
    const [alert, setAlert] = useState({ type: '', label: '' });
    const [bulletin, setBulletin] = useState({ type: 'bulletin', filename: '', title: '', description: '', committee: '', season: '' });
    const [bulletins, setBulletins] = useState([]);
    const [loading, setLoading] = useState(true);
    const [openModal, setOpenModal] = useState(false);

    const onClick = () => {
        setBulletin({
            type: 'bulletin',
            filename: '',
            title: '',
            description: '',
            committee: committeeId,
            season: seasonId
        });
        setAction('new');
        setOpenModal(true);
    }

    useEffect(() => {
        if (committeeId && seasonId) {
            API.get(`documents?type=bulletin&committee=${committeeId}&season=${seasonId}`)
                .then(res => {
                    if (res.success) {
                        setBulletins(sortByDate(res.documents, 'inserted_at'));
                    } else {
                        setAlert({ type: 'failure', label: res.msg || 'Qualcosa è andato storto, riprova più tardi' });
                    }
                    setLoading(false);
                })
                .catch(err => console.error(err));
        }
    }, [committeeId, seasonId])

    return (
        <div className="flex flex-col gap-3">
            {alert?.type && <GenericAlert type={alert.type}>{alert.label}</GenericAlert>}
            <MainTable
                bulletins={bulletins}
                loading={loading}
                setAction={setAction}
                setBulletin={setBulletin}
                setOpenModal={setOpenModal}
            />
            <div className="w-fit">
                <Button
                    onClick={onClick}
                    size="sm"
                >
                    Nuovo Comunicato
                </Button>
            </div>
            <MainModal
                action={action}
                bulletin={bulletin}
                openModal={openModal}
                setAlert={setAlert}
                setBulletin={setBulletin}
                setBulletins={setBulletins}
                setOpenModal={setOpenModal}
            />
        </div>
    )
}

export default Bulletins;