import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { serviceProvider as API } from "../../../API/api";
import { checkPassword, sortByName } from "../../../lib/utils";
import { assign as assignUser } from "../../../features/user/userSlice";

// Components
import { Button, Label, Select, Spinner, TextInput } from "flowbite-react";
import GenericAlert from "../../elements/GenericAlert";


const PasswordForm = ({ setAlert, user }) => {
    const [password, setPassword] = useState('');
    const [verifyPassword, setVerifyPassword] = useState('');
    const dispatch = useDispatch();

    const onSubmit = (e) => {
        e.preventDefault();
        if (password !== verifyPassword) {
            setAlert({ type: 'failure', label: 'Le password non coincidono' });
            return;
        } else {
            setAlert({ type: '', label: '' });
        }
        if (!checkPassword(password)) {
            setAlert({ type: 'failure', label: 'Non hai rispettato i requisiti minimi della password' })
            return;
        } else {
            setAlert({ type: '', label: '' });
        }
        API.update(`users/${user._id}`, JSON.stringify({ password: password }), true)
            .then(res => {
                if (res.success) {
                    dispatch(assignUser(res.user));
                    setAlert({ type: 'success', label: 'Le modifiche sono state salvate' });
                } else {
                    setAlert({ type: 'failure', label: res.msg || 'Qualcosa è andato storto...' });
                }
            })
            .catch(err => console.error(err));
    }

    return (
        <form onSubmit={onSubmit}>
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 px-16 lg:px-20 xl:px-48 2xl:px-80 mb-4">
                <div>
                    <div className="mb-2 block">
                        <Label
                            htmlFor="password"
                            value="Password"
                        />
                    </div>
                    <TextInput
                        id="password"
                        type="password"
                        value={password}
                        helperText="Minimo 6 caratteri, almeno un numero e una maiuscola"
                        onChange={e => setPassword(e.currentTarget.value)}
                    />
                </div>
                <div>
                    <div className="mb-2 block">
                        <Label
                            htmlFor="verifyPassword"
                            value="Riscrivi la password per conferma"
                        />
                    </div>
                    <TextInput
                        id="verifyPassword"
                        type="password"
                        value={verifyPassword}
                        onChange={e => setVerifyPassword(e.currentTarget.value)}
                    />
                </div>
            </div>
            <div className="w-48 mx-auto">
                <Button type="submit">
                    Modifica la password
                </Button>
            </div>
        </form>
    )
}

const MainForm = ({ locations, setAlert, setUser, user }) => {
    const [cities, setCities] = useState([]);
    const dispatch = useDispatch();

    const onChange = (e) => {
        const { id, value } = e.currentTarget;
        setUser(prevState => ({
            ...prevState,
            [id]: value
        }));
    }

    const onSubmit = (e) => {
        e.preventDefault();
        API.update(`users/${user._id}`, JSON.stringify(user), true)
            .then(res => {
                if (res.success) {
                    dispatch(assignUser(res.user));
                    setAlert({ type: 'success', label: 'Le modifiche sono state salvate' });
                } else {
                    setAlert({ type: 'failure', label: res.msg || 'Qualcosa è andato storto...' });
                }
            })
            .catch(err => console.error(err));
    }

    useEffect(() => {
        // Ogni volta che viene impostata una provincia, aggiorna l'array dei comuni
        if (user.province) {
            const loc = locations.find(loc => loc.provincia === user.province);
            setCities(loc?.comuni);
        } else {
            setCities([]);
        }
    }, [locations, user.province])

    return (
        <form onSubmit={onSubmit}>
            <div className="grid grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 gap-4 px-8 lg:px-16 xl:px-36 mb-4">
                <div>
                    <div className="mb-2 block">
                        <Label
                            htmlFor="firstname"
                            value="Nome"
                        />
                    </div>
                    <TextInput
                        id="firstname"
                        type="text"
                        value={user.firstname}
                        onChange={onChange}
                    />
                </div>
                <div>
                    <div className="mb-2 block">
                        <Label
                            htmlFor="lastname"
                            value="Cognome"
                        />
                    </div>
                    <TextInput
                        id="lastname"
                        type="text"
                        value={user.lastname}
                        onChange={onChange}
                    />
                </div>
                <div>
                    <div className="mb-2 block">
                        <Label
                            htmlFor="email"
                            value="Indirizzo e-mail"
                        />
                    </div>
                    <TextInput
                        id="email"
                        type="text"
                        value={user.email}
                        onChange={onChange}
                    />
                </div>
                <div>
                    <div className="mb-2 block">
                        <Label
                            htmlFor="phone"
                            value="Telefono"
                        />
                    </div>
                    <TextInput
                        id="phone"
                        type="text"
                        value={user.phone}
                        onChange={onChange}
                    />
                </div>
                <div>
                    <div className="mb-2 block">
                        <Label
                            htmlFor="codfisc"
                            value="Codice Fiscale"
                        />
                    </div>
                    <TextInput
                        id="codfisc"
                        type="text"
                        value={user.codfisc}
                        onChange={onChange}
                    />
                </div>
                <div>
                    <div className="mb-2 block">
                        <Label
                            htmlFor="iban"
                            value="Coordinate IBAN"
                        />
                    </div>
                    <TextInput
                        id="iban"
                        type="text"
                        value={user.iban}
                        onChange={onChange}
                    />
                </div>
                <div>
                    <div className="mb-2 block">
                        <Label
                            htmlFor="distance_range"
                            value="Raggio d'impiego (km)"
                        />
                    </div>
                    <TextInput
                        id="distance_range"
                        type="number"
                        min={0}
                        helperText="Lascia 0 per non impostare limiti"
                        value={user.distance_range}
                        onChange={onChange}
                    />
                </div>
                <div>
                    <div className="mb-2 block">
                        <Label
                            htmlFor="province"
                            value="Provincia"
                        />
                    </div>
                    <Select
                        id="province"
                        required={true}
                        onChange={onChange}
                        value={user.province}
                    >
                        <option>-</option>
                        {
                            locations.map(location =>
                                <option
                                    key={location._id}
                                    value={location.provincia}
                                >
                                    {location.provincia}
                                </option>
                            )
                        }
                    </Select>
                </div>
                <div>
                    <div className="mb-2 block">
                        <Label
                            htmlFor="city"
                            value="Comune di residenza"
                        />
                    </div>
                    <Select
                        id="city"
                        required={true}
                        onChange={onChange}
                        value={user.city}
                    >
                        <option>-</option>
                        {
                            cities?.map((city, i) =>
                                <option
                                    key={`city_${i}`}
                                    value={city}
                                >
                                    {city}
                                </option>
                            )
                        }
                    </Select>
                </div>
                <div>
                    <div className="mb-2 block">
                        <Label
                            htmlFor="address"
                            value="Indirizzo"
                        />
                    </div>
                    <TextInput
                        id="address"
                        type="text"
                        value={user.address}
                        onChange={onChange}
                    />
                </div>
            </div>
            <div className="w-48 mx-auto">
                <Button type="submit">
                    Salva
                </Button>
            </div>
        </form>
    )
}

function EditProfile() {
    const { _id: userId } = useSelector(state => state.user);
    const [alert, setAlert] = useState({ type: '', label: '' });
    const [loading, setLoading] = useState(true);
    const [locations, setLocations] = useState([]);
    const [pwAlert, setPwAlert] = useState({ type: '', label: '' });
    const [userForm, setUserForm] = useState({});

    useEffect(() => {
        if (userId) {
            API.get(`users/${userId}`, true)
                .then(res => {
                    if (res.success) {
                        setUserForm({
                            _id: res.user._id,
                            firstname: res.user.firstname || '',
                            lastname: res.user.lastname || '',
                            email: res.user.email || '',
                            phone: res.user.phone || '',
                            codfisc: res.user.codfisc || '',
                            iban: res.user.iban || '',
                            address: res.user.address || '',
                            province: res.user.province || '',
                            city: res.user.city || '',
                            distance_range: res.user.distance_range || 0
                        });
                    } else {
                        setAlert({ type: 'failure', label: res.msg || 'Qualcosa è andato storto...' });
                    }
                    setLoading(false);
                })
                .catch(err => console.error(err));
        }
    }, [userId])

    useEffect(() => {
        API.get('locations')
            .then(res => {
                if (res.success) {
                    setLocations(sortByName(res.locations, 'provincia'));
                } else {
                    setLocations([]);
                    setAlert({ type: 'failure', label: res.msg });
                }
            })
            .catch(err => console.error(err))
    }, [setLocations, setAlert])

    return (
        <>
            <div className="flex flex-col gap-3 bg-white rounded-lg shadow px-2 py-4 mb-8">
                {alert?.type && <GenericAlert type={alert.type}>{alert.label}</GenericAlert>}
                {
                    loading &&
                    <Spinner
                        aria-label="Loading spinner"
                        color="success"
                        size="xl"
                    />
                }

                {
                    !loading && userForm._id &&
                    <>
                        <span className="uppercase text-lg font-bold tracking-wide">
                            Modifica profilo
                        </span>
                        <MainForm
                            locations={locations}
                            setAlert={setAlert}
                            setUser={setUserForm}
                            user={userForm}
                        />
                    </>
                }
            </div>
            {
                !loading && userForm._id &&
                <div className="flex flex-col gap-3 bg-white rounded-lg shadow px-2 py-4">
                    {pwAlert?.type && <GenericAlert type={pwAlert.type}>{pwAlert.label}</GenericAlert>}
                    <span className="uppercase text-lg font-bold tracking-wide">
                        Modifica password
                    </span>
                    <PasswordForm
                        setAlert={setPwAlert}
                        user={userForm}
                    />
                </div>
            }
        </>
    )
}

export default EditProfile;
