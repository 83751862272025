import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { serviceProvider as API } from "../../../API/api";
import { formatDate, formatDateTime, sortByDate } from "../../../lib/utils";
import "flatpickr/dist/themes/material_blue.css";
import { Italian } from 'flatpickr/dist/l10n/it';

// Components
import { Button, Table, TextInput } from "flowbite-react";
import DeleteButton from "../../buttons/DeleteButton";
import EditButton from "../../buttons/EditButton";
import Flatpickr from "react-flatpickr";
import FormLabel from "../../elements/FormLabel";
import GenericAlert from "../../elements/GenericAlert";
import GenericModal from "../../elements/GenericModal";
import LoadingRow from "../../elements/LoadingRow";
import NoResultsRow from "../../elements/NoResultsRow";
import SubmitButton from "../../buttons/SubmitButton";


const TableRow = ({ daysToEditUnavailaibility, setAction, setOpenModal, setUnavailability, unavailability }) => {
    const { _id, startDate, endDate, inserted_at, updated_at, reason } = unavailability;

    const rowUnavailability = {
        _id: _id,
        startDate: startDate || '',
        endDate: endDate || '',
        inserted_at: inserted_at || '',
        updated_at: updated_at || '',
        reason: reason || ''
    }

    const handleEdit = () => {
        setAction('edit');
        setUnavailability(rowUnavailability);
        setOpenModal(true);
    }

    const handleDelete = () => {
        setAction('delete');
        setUnavailability(rowUnavailability);
        setOpenModal(true);
    }

    return (
        <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
            <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                {formatDate(unavailability.startDate)}
            </Table.Cell>
            <Table.Cell>
                {formatDate(unavailability.endDate)}
            </Table.Cell>
            <Table.Cell className="hidden lg:table-cell">
                {formatDateTime(unavailability.inserted_at)}
            </Table.Cell>
            <Table.Cell>
                {unavailability.reason}
            </Table.Cell>
            <Table.Cell>
                {((new Date(unavailability.startDate) - new Date()) / 1000 / 60 / 60 / 24) > daysToEditUnavailaibility &&
                    <>
                        <EditButton onClick={handleEdit} />
                        <DeleteButton onClick={handleDelete} />
                    </>
                }
            </Table.Cell>
        </Table.Row>
    )
}

const MainTable = ({ daysToEditUnavailaibility, loading, setAlert, setAction, setOpenModal, setUnavailability, unavailabilities }) => {
    const [noResults, setNoResults] = useState(false);

    useEffect(() => {
        if (unavailabilities.length) {
            setNoResults(false);
        } else {
            setNoResults(true);
        }
    }, [unavailabilities])

    return (
        <Table hoverable={true}>
            <Table.Head>
                <Table.HeadCell>
                    Dal giorno
                </Table.HeadCell>
                <Table.HeadCell>
                    Al giorno
                </Table.HeadCell>
                <Table.HeadCell className="hidden lg:table-cell">
                    Inserita il
                </Table.HeadCell>
                <Table.HeadCell>
                    Motivazione
                </Table.HeadCell>
                <Table.HeadCell>
                    <span className="sr-only">
                        Edit
                    </span>
                </Table.HeadCell>
            </Table.Head>
            <Table.Body className="divide-y">
                {loading && <LoadingRow colspan={5} />}
                {(!loading && noResults) && <NoResultsRow colspan={5} />}
                {
                    unavailabilities.map((u, i) => <TableRow key={i} daysToEditUnavailaibility={daysToEditUnavailaibility} setAction={setAction} setOpenModal={setOpenModal} setUnavailability={setUnavailability} unavailability={u} />)
                }
            </Table.Body>
        </Table>
    )
}

const MainForm = ({ action, daysToInsertUnavailaibility, fp, setAlert, setModalAlert, setOpenModal, setUnavailabilities, setUnavailability, unavailabilities, unavailability }) => {
    const { _id: userId } = useSelector(state => state.user);

    const handleDates = (date) => {
        setUnavailability(prevState => {
            const result = { ...prevState };
            if (date.length > 0) result.startDate = date[0].toISOString();
            if (date.length > 1) {
                const endDate = new Date(date[1]);
                endDate.setHours('23')
                endDate.setMinutes('59');
                result.endDate = endDate.toISOString();
            }
            return result;
        })
    }

    const resetDate = () => {
        setUnavailability(prevState => ({
            ...prevState,
            startDate: '',
            endDate: ''
        }));
        if (fp?.current?.flatpickr) {
            fp.current.flatpickr.clear();
        }
    }

    const handleInput = (e) => {
        const { id, value } = e.currentTarget;
        setUnavailability(prevState => ({
            ...prevState,
            [id]: value
        }));
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        const currentDate = new Date().toISOString();
        const body = { ...unavailability };
        const updUnavails = [...unavailabilities];
        if (!body.startDate || !body.endDate) {
            setModalAlert({ type: "warning", label: "Verifica le date inserite" });
            return;
        }
        switch (action) {
            case 'new':
                body.inserted_at = currentDate;
                body.updated_at = currentDate;
                updUnavails.push(body);
                API.update(`users/${userId}`, JSON.stringify({ unavailabilities: updUnavails }), true)
                    .then(res => {
                        if (res.success) {
                            setUnavailabilities(sortByDate(res.user.unavailabilities, 'startDate'));
                            setAlert({ type: 'success', label: 'L\'indisponibilità è stata inserita' });
                            setOpenModal(false);
                        } else {
                            setModalAlert({ type: 'failure', label: res.msg || 'Qualcosa è andato storto...' });
                        }
                    })
                    .catch(err => console.error(err));
                break;
            case 'edit':
                body.updated_at = currentDate;
                const index = updUnavails.findIndex(u => u._id === body._id);
                updUnavails[index] = body;
                API.update(`users/${userId}`, JSON.stringify({ unavailabilities: updUnavails }), true)
                    .then(res => {
                        if (res.success) {
                            setUnavailabilities(sortByDate(res.user.unavailabilities, 'startDate'));
                            setAlert({ type: 'success', label: 'L\'indisponibilità è stata modificata' });
                            setOpenModal(false);
                        } else {
                            setModalAlert({ type: 'failure', label: res.msg || 'Qualcosa è andato storto...' });
                        }
                    })
                    .catch(err => console.error(err));
                break;
            case 'delete':
                API.update(`users/${userId}`, JSON.stringify({ unavailabilities: updUnavails.filter(u => u._id !== body._id) }), true)
                    .then(res => {
                        if (res.success) {
                            setUnavailabilities(sortByDate(res.user.unavailabilities, 'startDate'));
                            setAlert({ type: 'success', label: 'L\'indisponibilità è stata eliminata' });
                            setOpenModal(false);
                        } else {
                            setModalAlert({ type: 'failure', label: res.msg || 'Qualcosa è andato storto...' });
                        }
                    })
                    .catch(err => console.error(err));
                break;
            default:
        }
    }

    return (
        <form className="flex flex-col gap-4" onSubmit={handleSubmit}>
            <div>
                <div className="mb-2 block">
                    <FormLabel label="Date da/a (per singole date cliccare due volte sullo stesso giorno)" />
                </div>
                <div className="flex flex-row gap-2">
                    <Flatpickr
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        ref={fp}
                        value={unavailability.startDate
                            ? unavailability.startDate && unavailability.endDate
                                ? [new Date(unavailability.startDate), new Date(unavailability.endDate)]
                                : [new Date(unavailability.startDate)]
                            : []}
                        options={{
                            mode: "range",
                            dateFormat: "d-m-Y",
                            altFormat: "Y-m-d",
                            minDate: new Date().setDate(new Date().getDate() + daysToInsertUnavailaibility),
                            locale: Italian
                        }}
                        placeholder="Da / a"
                        onChange={handleDates}
                        required={true}
                        disabled={action === 'delete' ? true : false}
                    />
                    <div className="w-fit">
                        <Button
                            color="light"
                            onClick={resetDate}
                            disabled={action === 'delete' ? true : false}
                        >
                            Reset
                        </Button>
                    </div>
                </div>
            </div>
            <div>
                <div className="mb-2 block">
                    <FormLabel htmlFor="reason" label="Motivazione" />
                </div>
                <TextInput
                    id="reason"
                    type="text"
                    required={true}
                    value={unavailability.reason}
                    onChange={handleInput}
                    disabled={action === 'delete' ? true : false}
                />
            </div>
            {
                action === 'delete' &&
                <GenericAlert type="warning" divclass="text-lg mx-auto" hideicon="true">Desideri eliminare questa indisponibilità?</GenericAlert>
            }
            <div className="text-center">
                <SubmitButton />
            </div>
        </form>
    )
}

const MainModal = ({ action, daysToInsertUnavailaibility, fp, openModal, setAlert, setUnavailabilities, setUnavailability, setOpenModal, unavailabilities, unavailability }) => {
    const [modalAlert, setModalAlert] = useState({ type: '', label: '' });

    // Azzera l'alert del modal ogni volta che viene aperto
    useEffect(() => {
        openModal === true && setModalAlert({ type: '', label: '' });
    }, [openModal]);

    return (
        <GenericModal openModal={openModal} setOpenModal={setOpenModal} title={`${action === 'new' ? 'Nuova' : action === 'edit' ? 'Modifica' : 'Elimina'} Indisponibilità`}>
            {modalAlert?.type && <GenericAlert type={modalAlert.type}>{modalAlert.label}</GenericAlert>}
            <MainForm
                action={action}
                daysToInsertUnavailaibility={daysToInsertUnavailaibility}
                fp={fp}
                setAlert={setAlert}
                setModalAlert={setModalAlert}
                setOpenModal={setOpenModal}
                setUnavailabilities={setUnavailabilities}
                setUnavailability={setUnavailability}
                unavailabilities={unavailabilities}
                unavailability={unavailability}
            />
        </GenericModal>
    )
}

function Unavailabilities() {
    const { daysToInsertUnavailaibility, daysToEditUnavailaibility } = useSelector(state => state.committee);
    const { startDate: startSeason, endDate: endSeason } = useSelector(state => state.season);
    const { _id: userId } = useSelector(state => state.user);
    const fp = useRef(null);
    const [action, setAction] = useState('new');
    const [alert, setAlert] = useState({ type: '', label: '' });
    const [loading, setLoading] = useState(true);
    const [unavailabilities, setUnavailabilities] = useState([]);
    const [unavailability, setUnavailability] = useState({
        startDate: '',
        endDate: '',
        inserted_at: '',
        updated_at: '',
        reason: ''
    });
    const [openModal, setOpenModal] = useState(false);

    const openNewModal = () => {
        if (fp?.current?.flatpickr) {
            fp.current.flatpickr.clear();
        }
        setUnavailability({
            startDate: '',
            endDate: '',
            inserted_at: '',
            updated_at: '',
            reason: ''
        });
        setAction('new');
        setOpenModal(true);
    }

    useEffect(() => {
        if (startSeason && userId) {
            API.get(`users/${userId}`, true)
                .then(res => {
                    if (res.success) {
                        const filtered = res.user.unavailabilities.filter(u =>
                            (new Date(u.startDate) >= new Date(startSeason)) && (new Date(endSeason) >= new Date(u.endDate))
                        );
                        setUnavailabilities(sortByDate(filtered, 'startDate'));
                        setLoading(false);
                    } else {
                        setUnavailabilities([]);
                        setLoading(false);
                        setAlert({ type: 'warning', label: 'Nessuna indisponibilità trovata' });
                    }
                })
        }
    }, [endSeason, startSeason, userId])

    return (
        <div className="flex flex-col gap-3">
            {alert?.type && <GenericAlert type={alert.type}>{alert.label}</GenericAlert>}
            <MainTable
                daysToEditUnavailaibility={daysToEditUnavailaibility}
                loading={loading}
                setAlert={setAlert}
                setAction={setAction}
                setOpenModal={setOpenModal}
                setUnavailability={setUnavailability}
                unavailabilities={unavailabilities}
            />
            <div className="w-fit">
                <Button size="sm" onClick={openNewModal}>
                    Nuova Indisponibilità
                </Button>
            </div>
            <MainModal
                action={action}
                daysToInsertUnavailaibility={daysToInsertUnavailaibility}
                fp={fp}
                openModal={openModal}
                setAlert={setAlert}
                setUnavailabilities={setUnavailabilities}
                setUnavailability={setUnavailability}
                setOpenModal={setOpenModal}
                unavailabilities={unavailabilities}
                unavailability={unavailability}
            />
        </div>
    )
}

export default Unavailabilities;