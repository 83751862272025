import { useCallback } from "react";
import { serviceProvider as API } from "../../../../API/api";
import { formatDateTime, readablePartials } from "../../../../lib/utils";

// Components
import { Button, Tooltip } from "flowbite-react";
import GenericCard from "../../../elements/GenericCard";


const MatchCard = ({ match, setAction, setAlert, setImgInfo, setMatch, setOpenJudgeModal, setOpenImgModal, setOpenReportModal, setOpenResultsModal }) => {
    const noDocs = useCallback(() => match.documents.length < 1 ? true : false, [match]);
    const noReport = useCallback(() => match.report?.length < 1 ? true : false, [match]);
    const noResults = useCallback(() => !match.hasOwnProperty('result_updated_at'), [match]);
    const matchClass = useCallback(() => {
        if (match.status === 3) {
            return 'bg-emerald-100 hover:bg-emerald-50';
        }
        if (match.referee_confirmation) {
            return 'bg-amber-100 hover:bg-amber-50';
        }
        if (match.status < 3 && (!noReport() || !noResults())) {
            return 'bg-sky-100 hover:bg-sky-50';
        }
        return 'bg-gray-100 hover:bg-gray-50';
    }, [match, noReport, noResults])

    const showDocument = (e) => {
        const docId = e.currentTarget.dataset.id;
        const { filename } = match.documents.find(d => d._id === docId);
        const queryParams = `ref=match&refId=${match._id}&filename=${filename}`;
        API.getResource(`files?${queryParams}`, true)
            .then(res => {
                if (res.ok) {
                    return res.blob();
                } else {
                    setAlert({ type: 'failure', label: 'Impossibile visualizzare questo file' });
                }
            })
            .then(blob => {
                const objectURL = URL.createObjectURL(blob);
                setImgInfo({ scope: 'docsmodal', src: objectURL, blob: blob, filename: filename });
                setOpenImgModal(true);
            })
            .catch(err => console.error(err));
    }

    const showReport = () => {
        setMatch(match);
        setOpenReportModal(true);
    }

    const editResults = () => {
        setMatch(match);
        setOpenResultsModal(true);
    }

    const cancelConfirmation = () => {
        setAction('cancel_confirmation');
        setMatch(match);
        setOpenJudgeModal(true);
    }

    const confirmOmologation = () => {
        setAction('confirm_omologation');
        setMatch(match);
        setOpenJudgeModal(true);
    }

    const cancelOmologation = () => {
        setAction('cancel_omologation');
        setMatch(match);
        setOpenJudgeModal(true);
    }

    return (
        <GenericCard colorClasses={matchClass()}>
            <h5 className="w-1/2 lg:w-1/3 xl:w-1/5 mx-auto rounded-lg text-md text-center font-bold tracking-wide uppercase text-gray-700 dark:text-white">
                {match.championship.name}
            </h5>
            <div className="flex flex-row gap-2">
                <div className="basis-1/2 my-auto">
                    <ul className="space-y-1 max-w-md list-none list-inside text-gray-700 dark:text-gray-400">
                        <li>
                            <span className="font-semibold text-sm uppercase">{match.teamA.name} - {match.teamB.name}</span>
                        </li>
                        <li>
                            <span className="font-semibold">Data:</span> <span className="capitalize">{formatDateTime(match.date, true)}</span>
                        </li>
                        <li>
                            <span className="font-semibold">Gara #:</span> {match.num}
                        </li>
                        <li>
                            <span className="font-semibold">Campo:</span> {match.field.city} - {match.field.name}
                        </li>
                    </ul>
                </div>
                <div className="basis-1/2 my-auto">
                    <ul className="space-y-1 max-w-md list-none list-inside text-gray-700 dark:text-gray-400">
                        <li>
                            <span className="font-semibold">Arbitri: </span>
                            {
                                match.assignments.filter(a => a.status === 3)
                                    .map(a => `${a.referee.lastname} ${a.referee.firstname}`)
                                    .join(', ')
                            }
                        </li>
                        <li className="flex flex-row gap-2 items-center">
                            <span className="font-semibold">Risultato Inserito: </span>
                            {noResults() &&
                                <>
                                    <span className="italic">Non inserito</span>
                                    <div className="w-fit">
                                        <Button size="xs" onClick={editResults}>
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
                                            </svg>
                                        </Button>
                                    </div>
                                </>}
                            {!noResults() &&
                                <>
                                    <Tooltip content={`Inserito il ${formatDateTime(match.result_updated_at)} da ${match.result_updated_by?.lastname || 'Null'} ${match.result_updated_by?.firstname || 'Null'}`}>
                                        <span className="cursor-default">
                                            {match.result.a} - {match.result.b}
                                            {' '}({readablePartials(match.partials)})
                                        </span>
                                    </Tooltip>
                                    <div className="w-fit">
                                        <Button size="xs" onClick={editResults}>
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
                                            </svg>
                                        </Button>
                                    </div>
                                </>
                            }
                        </li>
                        <li className="flex flex-row gap-2 items-center">
                            <span className="font-semibold">Rapporto Gara: </span>
                            {noReport() && <span className="italic">Non compilato</span>}
                            {!noReport() &&
                                <Tooltip content={`Inserito il ${formatDateTime(match.report_updated_at)} da ${match.report_updated_by?.lastname} ${match.report_updated_by?.firstname}`}>
                                    <Button
                                        size="xs"
                                        onClick={showReport}
                                    >
                                        Visualizza
                                    </Button>
                                </Tooltip>
                            }
                        </li>
                        <li className="flex flex-row flex-wrap gap-2 items-center">
                            <span className="font-semibold">Documenti: </span>
                            {noDocs() && <span className="italic">Nessuno</span>}
                            {!noDocs() &&
                                match.documents.map(d =>
                                    <Tooltip key={d._id} content={`Inserito il ${formatDateTime(d.updated_at)} da ${d.updated_by.lastname} ${d.updated_by.firstname}`}>
                                        <Button
                                            size="xs"
                                            data-id={d._id}
                                            onClick={showDocument}
                                        >
                                            {d.doctype}
                                        </Button>
                                    </Tooltip>
                                )
                            }
                        </li>
                    </ul>
                </div>
            </div>
            <div className="flex flex-row gap-2">
                <div className="flex flex-row gap-2 mt-1 mx-auto">
                    {match.referee_confirmation &&
                        <div>
                            <Button
                                size="sm"
                                color="light"
                                onClick={cancelConfirmation}
                            >
                                Annulla finalizzazione
                            </Button>
                        </div>
                    }
                    {!noResults() &&
                        <div>
                            <Button
                                size="sm"
                                color={match.status > 2 ? 'warning' : 'success'}
                                onClick={match.status > 2 ? cancelOmologation : confirmOmologation}
                            >
                                {match.status > 2 ? 'Annulla Omologazione' : 'Omologa'}
                            </Button>
                        </div>
                    }
                </div>
            </div>

        </GenericCard>
    )
}

export default MatchCard;