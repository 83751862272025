import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { serviceProvider as API } from "../../../API/api";
import { compareTime, sortByName } from "../../../lib/utils";
import { daysOTW } from "../../../lib/configs";

// Components
import { Label, Select, Table } from "flowbite-react";
import GenericAlert from "../../elements/GenericAlert";
import LoadingRow from "../../elements/LoadingRow";
import NoResultsRow from "../../elements/NoResultsRow";


const MainTable = ({ day, loading, referee, referees }) => {
    const refAvailability = useCallback((r) => ({
        background: compareTime(r.availability[day].startTime, r.availability[day].endTime).className,
        text: `dalle ${r.availability[day].startTime} alle ${r.availability[day].endTime}`
    }), [day]);
    const dayAvailability = useCallback((d) => ({
        background: compareTime(referee.availability[d].startTime, referee.availability[d].endTime).className,
        text: `dalle ${referee.availability[d].startTime} alle ${referee.availability[d].endTime}`
    }), [referee])

    return (
        <Table hoverable={true}>
            <Table.Head>
                <Table.HeadCell>
                    {day > -1 ? 'Giorno' : 'Nome'}
                </Table.HeadCell>
                <Table.HeadCell>
                    Orario
                </Table.HeadCell>
            </Table.Head>
            <Table.Body className="divide-y">
                {loading && <LoadingRow colspan={2} />}
                {!loading && referees.length < 1 && <NoResultsRow colspan={2} />}
                {referees.length > 0 && !loading && day < 0 && !referee?._id && <NoResultsRow colspan={2} />}
                {
                    day > -1 && referees.map(r =>
                        <Table.Row key={r._id} className="bg-white dark:border-gray-700 dark:bg-gray-800">
                            <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                                {r.lastname} {r.firstname}
                            </Table.Cell>
                            <Table.Cell>
                                <p className={`${refAvailability(r).background} font-semibold w-fit p-2 rounded-lg cursor-default`}>
                                    {refAvailability(r).text}
                                </p>
                            </Table.Cell>
                        </Table.Row>
                    )
                }
                {
                    referee?._id &&
                    daysOTW.map((d, i) =>
                        <Table.Row key={`day_${i}`} className="bg-white dark:border-gray-700 dark:bg-gray-800">
                            <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                                {d.day}
                            </Table.Cell>
                            <Table.Cell>
                                <p className={`${dayAvailability(d.value).background} font-semibold w-fit p-2 rounded-lg cursor-default`}>
                                    {dayAvailability(d.value).text}
                                </p>
                            </Table.Cell>
                        </Table.Row>
                    )
                }
            </Table.Body>
        </Table>
    )
}

const Filters = ({ day, referee, referees, setDay, setReferee }) => {

    const handleDay = e => {
        const { value } = e.currentTarget;
        setDay(Number(value))
        setReferee({});
    }

    const handleRef = e => {
        setReferee(referees.find(r => r._id === e.currentTarget.value) || '');
        setDay(-1);
    }

    return (
        <div className="flex flex-row gap-4 items-center">
            <div className="w-40">
                <div>
                    <Label
                        htmlFor="referee"
                        value="Filtra per arbitro"
                    />
                </div>
                <Select
                    id="refereee"
                    value={referee._id || ''}
                    onChange={handleRef}
                >
                    <option value="">-</option>
                    {
                        referees.map((r, i) =>
                            <option key={`refsel_${i}`} value={r._id}>
                                {r.lastname} {r.firstname}
                            </option>
                        )
                    }
                </Select>
            </div>
            <div className="w-40">
                <div>
                    <Label
                        htmlFor="day"
                        value="Filtra per giorno"
                    />
                </div>
                <Select
                    id="day"
                    value={day}
                    onChange={handleDay}
                >
                    <option value="-1">-</option>
                    {
                        daysOTW.map((d, i) =>
                            <option key={`d_${i}`} value={d.value}>
                                {d.day}
                            </option>
                        )
                    }
                </Select>
            </div>
        </div>
    )
}

function AvailReport() {
    const { _id: committeeId } = useSelector(state => state.committee);
    const [alert, setAlert] = useState({ type: '', label: '' });
    const [day, setDay] = useState(-1);
    const [loading, setLoading] = useState(true);
    const [referee, setReferee] = useState({});
    const [referees, setReferees] = useState([]);

    useEffect(() => {
        if (committeeId) {
            API.get(`users?isreferee=true&committees=${committeeId}`, true)
                .then(res => {
                    if (res.success) {
                        setReferees(sortByName(res.users, 'lastname'));
                    } else {
                        setAlert({ type: 'failure', alert: res.msg || 'Qualcosa è andato storto, riprova più tardi' });
                    }
                    setLoading(false);
                })
                .catch(err => console.error(err))
        }
    }, [committeeId])

    return (
        <div className="flex flex-col gap-3">
            <Filters
                day={day}
                referee={referee}
                referees={referees}
                setDay={setDay}
                setReferee={setReferee}
            />
            {alert?.type && <GenericAlert type={alert.type}>{alert.label}</GenericAlert>}
            <MainTable
                day={day}
                loading={loading}
                referee={referee}
                referees={referees}
            />
        </div>
    )
}

export default AvailReport;