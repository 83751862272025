import { useCallback } from "react";
import mainConfigs from "../../../configs/main";

// Components
import { Carousel } from "flowbite-react"

function Bulletins({ bulletins }) {
    const { PATH, PROTOCOL, REACT_APP_API_URL, REACT_ENV } = mainConfigs;
    const docHref = useCallback((bulletin) => REACT_ENV === 'development' 
        ? `${PROTOCOL}://${PATH}/files?ref=bulletin&refId=${bulletin._id}&filename=${bulletin.filename}`
        : `${PROTOCOL}://${REACT_APP_API_URL}/files?ref=bulletin&refId=${bulletin._id}&filename=${bulletin.filename}`
        , [PATH, PROTOCOL, REACT_APP_API_URL, REACT_ENV]);

    return (
        <div className="h-56">
            <Carousel
                slide={false}
                leftControl={
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="text-gray-700 w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
                    </svg>
                }
                rightControl={
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="text-gray-700 w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                    </svg>
                }
            >
                {bulletins?.map(b =>
                    <div
                        key={b._id}
                        className="flex flex-col h-full items-center cursor-default justify-center bg-gray-50 dark:bg-gray-700 dark:text-white"
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
                        </svg>
                        <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                            {b.title}
                        </h5>
                        <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">
                            {b.description}
                        </p>
                        <a
                            href={docHref(b)}
                            target="_blank"
                            rel="noreferrer noopener"
                            download={b.filename}
                            className="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                        >
                            Apri il documento
                            <svg aria-hidden="true" className="w-4 h-4 ml-2 -mr-1" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                        </a>
                    </div>
                )}
            </Carousel>
        </div>
    )
}

export default Bulletins;