import { useCallback, useEffect, useState } from "react";
import { serviceProvider as API } from "../../../../API/api";

// Components
import { Button } from "flowbite-react";
import GenericAlert from "../../../elements/GenericAlert";
import GenericModal from "../../../elements/GenericModal";

// Icons
const HiOutlineExclamationCircle = () =>
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-14 h-14 mx-auto text-gray-400 dark:text-gray-200">
        <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z" />
    </svg>


function JudgeModal({ action, match, openModal, setAlert, setOpenModal, setMatches }) {
    const actionInfo = useCallback(() => {
        switch (action) {
            case 'cancel_omologation':
                return {
                    btn_color: 'failure',
                    btn_label: 'Sì, annulla',
                    text: 'Confermi di annullare l\'omologazione di questa gara?',
                    title: 'Annulla omologazione'
                };
            case 'cancel_confirmation':
                return {
                    btn_color: 'failure',
                    btn_label: 'Sì, annulla',
                    text: 'Confermi di annullare la finalizzazione di questa gara?',
                    title: 'Annulla finalizzazione'
                };
            default:
                return {
                    btn_color: 'success',
                    btn_label: 'Sì, omologa',
                    text: 'Confermi di voler omologare questa gara?',
                    title: 'Omologa gara'
                };
        }
    }, [action]);
    const [modalAlert, setModalAlert] = useState({ type: '', label: '' });

    const confirmOperation = () => {
        switch (action) {
            case 'cancel_omologation':
                API.update(`matches/${match._id}`, JSON.stringify({ status: 2 }), true)
                    .then(res => {
                        if (res.success) {
                            setMatches(prevState => [
                                ...prevState.filter(m => m._id !== match._id),
                                res.match
                            ]);
                            setAlert({ type: 'success', label: 'Omologazione annullata' });
                            setOpenModal(false);
                        } else {
                            setModalAlert({ type: 'failure', label: res.msg || 'Qualcosa è andato storto...' });
                        }
                    })
                    .catch(err => console.error(err));
                break;
            case 'cancel_confirmation':
                API.update(`matches/${match._id}`, JSON.stringify({ referee_confirmation: false }), true)
                    .then(res => {
                        if (res.success) {
                            setMatches(prevState => [
                                ...prevState.filter(m => m._id !== match._id),
                                res.match
                            ]);
                            setAlert({ type: 'success', label: 'Finalizzazione annullata' });
                            setOpenModal(false);
                        } else {
                            setModalAlert({ type: 'failure', label: res.msg || 'Qualcosa è andato storto...' });
                        }
                    })
                    .catch(err => console.error(err));
                break;
            default:
                API.update(`matches/${match._id}`, JSON.stringify({ status: 3 }), true)
                    .then(res => {
                        if (res.success) {
                            setMatches(prevState => [
                                ...prevState.filter(m => m._id !== match._id),
                                res.match
                            ]);
                            setAlert({ type: 'success', label: 'Gara omologata con successo' });
                            setOpenModal(false);
                        } else {
                            setModalAlert({ type: 'failure', label: res.msg || 'Qualcosa è andato storto...' });
                        }
                    })
                    .catch(err => console.error(err));
        }
    }

    // Azzera l'alert del modal ogni volta che viene aperto
    useEffect(() => {
        openModal === true && setModalAlert({ type: '', label: '' });
        document.getElementById('confirm_btn')?.focus();
    }, [openModal]);

    return (
        <GenericModal openModal={openModal} setOpenModal={setOpenModal} title={actionInfo().title}>
            {modalAlert?.type && <GenericAlert type={modalAlert.type}>{modalAlert.label}</GenericAlert>}
            <div className="text-center">
                <HiOutlineExclamationCircle />
                <h3 className="mb-5 text-lg font-semibold text-gray-700 dark:text-gray-400">
                    {actionInfo().text}
                </h3>
                <div className="flex justify-center gap-4">
                    <Button
                        id="confirm_btn"
                        color={actionInfo().btn_color}
                        onClick={confirmOperation}
                    >
                        {actionInfo().btn_label}
                    </Button>
                    <Button
                        color="light"
                        onClick={() => setOpenModal(false)}
                    >
                        No, annulla
                    </Button>
                </div>
            </div>
        </GenericModal>
    )
}

export default JudgeModal;