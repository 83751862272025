import { useEffect, useState } from "react";
import { serviceProvider as API } from "../../../../../API/api";
import { sortByName } from "../../../../../lib/utils";
import "flatpickr/dist/themes/material_blue.css";

// Components
import { Select, TextInput } from "flowbite-react";
import FormLabel from "../../../../elements/FormLabel";
import GenericAlert from "../../../../elements/GenericAlert";
import GenericModal from "../../../../elements/GenericModal";
import SubmitButton from "../../../../buttons/SubmitButton";

const GroupsForm = ({ action, championship, group, notAssignedTeams, setAlert, setChampionship, setGroup, setGroups, setModalAlert, setNotAssignedTeams, setOpenModal, teams }) => {

    const handleInput = (e) => {
        const { id, selectedOptions, value } = e.currentTarget;
        setGroup(prevState => ({
            ...prevState,
            [id]: id === 'teams'
                ? Array.from(selectedOptions).map(opt => opt.value)
                : value
        }));
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        // In tutti e 3 i casi, si tratta di un'azione di PATCH in cui rimpiazzo teams
        const updateBody = action === 'new'
            ? {
                teams: [
                    ...championship.teams.map(group => ({
                        group_name: group.group_name,
                        teams: group.teams.reduce((prev, curr) => [...prev, curr._id], [])
                    })),
                    group
                ]
            }
            : action === 'delete'
                ? {
                    teams: championship.teams.filter(teamGroup => teamGroup._id !== group._id)
                        .map(teamGroup => ({
                            group_name: teamGroup.group_name,
                            teams: teamGroup.teams.map(team => team._id)
                        }))
                }
                : {
                    teams: [
                        ...championship.teams.filter(teamGroup => teamGroup._id !== group._id)
                            .map(teamGroup => ({
                                group_name: teamGroup.group_name,
                                teams: teamGroup.teams.map(team => team._id)
                            })),
                        { group_name: group.group_name, teams: group.teams }
                    ]
                }
        switch (action) {

            case 'new':
                API.update(`championships/${championship._id}`, JSON.stringify(updateBody), true)
                    .then(res => {
                        if (res.success) {
                            setChampionship(res.championship);
                            setGroups(res.championship.teams);
                            setNotAssignedTeams(prevState => prevState.filter(team => !group.teams.includes(team._id)));
                            setAlert({ type: 'success', label: 'Il girone è stato creato' });
                            setOpenModal(false);
                        } else {
                            setModalAlert({ type: 'failure', label: res.msg || 'Qualcosa è andato storto...' })
                        }
                    })
                    .catch(err => console.error(err));
                break;
            case 'edit':
                API.update(`championships/${championship._id}`, JSON.stringify(updateBody), true)
                    .then(res => {
                        if (res.success) {
                            setChampionship(res.championship);
                            setGroups(res.championship.teams);
                            setAlert({ type: 'success', label: 'Il girone è stato modificato correttamente' });
                            const champTeams = res.championship.teams.length
                                ? res.championship.teams
                                    .map(group => group.teams.map(team => team._id))
                                    .reduce((prev, curr) => [...prev, ...curr])
                                : [];
                            setNotAssignedTeams(sortByName(teams.filter(team => !champTeams.includes(team._id)), 'name'));
                            setOpenModal(false);
                        } else {
                            setModalAlert({ type: 'failure', label: res.msg || 'Qualcosa è andato storto...' });
                        }
                    })
                    .catch(err => console.error(err));
                break;
            case 'delete':
                API.update(`championships/${championship._id}`, JSON.stringify(updateBody), true)
                    .then(res => {
                        if (res.success) {
                            setChampionship(res.championship);
                            setGroups(res.championship.teams);
                            setAlert({ type: 'success', label: 'Il girone è stato eliminato' });
                            setOpenModal(false);
                            const champTeams = res.championship.teams.length
                                ? res.championship.teams
                                    .map(group => group.teams.map(team => team._id))
                                    .reduce((prev, curr) => [...prev, ...curr])
                                : [];
                            setNotAssignedTeams(sortByName(teams.filter(team => !champTeams.includes(team._id)), 'name'));
                        } else {
                            setModalAlert({ type: 'failure', label: res.msg || 'Qualcosa è andato storto...' });
                        }
                    })
                    .catch(err => console.error(err));
                break;
            default:
        }
    }

    return (
        <form className="flex flex-col gap-4" onSubmit={handleSubmit}>
            <div>
                <div className="mb-2 block">
                    <FormLabel htmlFor="group_name" label="Nome" />
                </div>
                <TextInput
                    id="group_name"
                    type="text"
                    required={true}
                    value={group.group_name}
                    onChange={handleInput}
                    disabled={action === 'delete' ? true : false}
                />
            </div>
            <div>
                <div className="mb-2 block">
                    <FormLabel htmlFor="teams" label="Squadre" />
                </div>
                <Select
                    id="teams"
                    multiple
                    required={true}
                    onChange={handleInput}
                    value={group.teams}
                    disabled={action === 'delete' ? true : false}
                >
                    {
                        (action === 'new' || action === 'delete') &&
                        notAssignedTeams.map(team =>
                            <option key={team._id} value={team._id}>
                                {team.name}
                            </option>
                        )
                    }
                    {
                        action === 'edit' && (
                            championship.teams.find(groupTeam => groupTeam._id === group._id)?.teams
                                .concat(notAssignedTeams)
                                .map(team =>
                                    <option key={team._id} value={team._id}>
                                        {team.name}
                                    </option>
                                )
                        )
                    }
                </Select>
            </div>
            {
                action === 'delete' &&
                <GenericAlert type="warning" divclass="text-lg mx-auto" hideicon="true">Desideri eliminare questo girone?</GenericAlert>
            }
            <div className="text-center">
                <SubmitButton />
            </div>
        </form>
    )
}

const GroupsModal = ({ action, championship, group, notAssignedTeams, openModal, setAlert, setChampionship, setGroup, setGroups, setNotAssignedTeams, setOpenModal, teams }) => {
    const [modalAlert, setModalAlert] = useState({ type: '', label: '' });

    // Azzera l'alert del modal ogni volta che viene aperto
    useEffect(() => {
        openModal === true && setModalAlert({ type: '', label: '' });
    }, [openModal]);

    return (
        <GenericModal openModal={openModal} setOpenModal={setOpenModal} title={`${action === 'new' ? 'Nuovo' : action === 'edit' ? 'Modifica' : 'Elimina'} Girone`}>
            {modalAlert?.type && <GenericAlert type={modalAlert.type}>{modalAlert.label}</GenericAlert>}
            <GroupsForm
                action={action}
                championship={championship}
                group={group}
                notAssignedTeams={notAssignedTeams}
                setAlert={setAlert}
                setChampionship={setChampionship}
                setGroup={setGroup}
                setGroups={setGroups}
                setModalAlert={setModalAlert}
                setNotAssignedTeams={setNotAssignedTeams}
                setOpenModal={setOpenModal}
                teams={teams}
            />
        </GenericModal>
    )
}

export default GroupsModal;