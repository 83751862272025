import { useState } from "react";
import { serviceProvider as API } from "../../../../API/api";

// Components
import { Button, Modal } from "flowbite-react";
import GenericAlert from "../../../elements/GenericAlert";

// Icons
const HiOutlineExclamationCircle = () =>
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-14 h-14 mx-auto text-gray-400 dark:text-gray-200">
        <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z" />
    </svg>



function ConfirmationModal({assignment, openModal, setAlert, setAssignment, setOpenModal, setTotalAssignments}) {
    const [modalAlert, setModalAlert] = useState({ type: '', label: '' });

    const handleSubmit = () => {
        const updBody = {
            referee_confirmation: true,
            referee_confirmation_at: new Date().toISOString()
        }
        API.update(`matches/${assignment.match._id}`, JSON.stringify(updBody), true)
            .then(res => {
                if (res.success) {
                    setAssignment(prevState => ({
                        ...prevState,
                        match: res.match
                    }));
                    setTotalAssignments(prevState => [
                        ...prevState.filter(a => a._id !== assignment._id),
                        {
                            ...assignment,
                            match: res.match
                        }
                    ]);
                    setAlert({ type: 'success', label: 'La gara è stata finalizzata' });
                    setOpenModal(false);
                } else {
                    setModalAlert({ type: 'failure', label: res.msg || 'Impossibile completare l\'operazione' });
                }
            })
    }

    return (
        <Modal
            show={openModal}
            size="md"
            popup={true}
            onClose={() => setOpenModal(false)}
        >
            <Modal.Header />
            <Modal.Body>
                {modalAlert?.type && <GenericAlert type={modalAlert.type}>{modalAlert.label}</GenericAlert>}
                <div className="text-center">
                    <HiOutlineExclamationCircle />
                    <h3 className="mb-5 text-lg font-bold text-gray-500 dark:text-gray-400">
                        Confermi di voler finalizzare questa gara?
                    </h3>
                    <h4 className="mb-5 text-md font-normal text-gray-500 dark:text-gray-400">
                        Successivamente, non potrai più modificare risultato, rapporto gara e documenti
                    </h4>
                    <div className="flex justify-center gap-4">
                        <Button
                            color="failure"
                            onClick={handleSubmit}
                        >
                            Sì, finalizza
                        </Button>
                        <Button
                            color="light"
                            onClick={() => setOpenModal(false)}
                        >
                            No, annulla
                        </Button>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default ConfirmationModal;