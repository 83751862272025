import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { serviceProvider as API } from "../../../API/api";
import { formToIsoString, isoToForm, newDate, sortByDate, sortByName } from "../../../lib/utils";

// Components
import { Button, Select, Spinner, TextInput } from "flowbite-react";
import EditAssignmentModal from "./elements/EditAssignmentModal";
import FormLabel from "../../elements/FormLabel";
import GenericAlert from "../../elements/GenericAlert";
import MatchCard from "./elements/MatchCard";
import NewAssignmentModal from "./elements/NewAssignmentModal";
import NotifyModal from "./elements/NotifyModal";


const FiltersBar = ({ championships, filters, setFilters }) => {

    const handleInput = (e) => {
        const { id, value } = e.currentTarget;
        switch (id) {
            case "days":
                setFilters(prevState => ({ ...prevState, days: value, endDate: '' }));
                break;
            case "startDate":
                setFilters(prevState => ({ ...prevState, startDate: formToIsoString(`${value}T00:00`) }));
                break;
            case "endDate":
                setFilters(prevState => ({ ...prevState, days: '', endDate: formToIsoString(`${value}T23:59`) }));
                break;
            default:
                setFilters(prevState => ({ ...prevState, [id]: value }));
        }
    }

    const resetFilters = () => {
        setFilters({ days: '10', startDate: newDate(), endDate: '', championship: '', sport: '' });
    }

    return (
        <div className="flex flex-row flex-wrap gap-8 items-end">
            <div>
                <div className="mb-2 block">
                    <FormLabel htmlFor="days" label="Giorni successivi" />
                </div>
                <Select
                    id="days"
                    value={filters.days}
                    onChange={handleInput}
                >
                    <option value="">-</option>
                    <option value="3">3</option>
                    <option value="5">5</option>
                    <option value="7">7</option>
                    <option value="10">10</option>
                    <option value="14">14</option>
                    <option value="30">30</option>
                </Select>
            </div>
            <div>
                <div className="mb-2 block">
                    <FormLabel label="Da / A" />
                </div>
                <div className="flex flex-row gap-2">
                    <TextInput
                        id="startDate"
                        type="date"
                        value={isoToForm(filters.startDate)}
                        onChange={handleInput}
                    />
                    <TextInput
                        id="endDate"
                        type="date"
                        value={filters.endDate && isoToForm(filters.endDate)}
                        onChange={handleInput}
                    />
                </div>
            </div>
            <div>
                <div className="mb-2 block">
                    <FormLabel htmlFor="championship" label="Campionato" />
                </div>
                <Select
                    id="championship"
                    value={filters.championship}
                    onChange={handleInput}
                >
                    <option value=''>-</option>
                    {
                        championships.map(c => <option key={c._id} value={c._id}>{c.name}</option>)
                    }
                </Select>
            </div>
            <div>
                <div className="mb-2 block">
                    <FormLabel htmlFor="sport" label="Sport" />
                </div>
                <Select
                    id="sport"
                    className="w-32"
                    value={filters.sport}
                    onChange={handleInput}
                >
                    <option value=''>-</option>
                    <option value="Pallavolo">Pallavolo</option>
                    <option value="Calcio">Calcio</option>
                </Select>
            </div>
            <div>
                <Button
                    color="dark"
                    size="sm"
                    className="mb-1"
                    onClick={resetFilters}
                >Reset Filtri</Button>
            </div>
        </div>
    )
}



function Assignments() {
    const { _id: committeeId } = useSelector(state => state.committee);
    const { _id: seasonId } = useSelector(state => state.season);
    const [action, setAction] = useState('new');
    const [alert, setAlert] = useState({ type: '', label: '' });
    const [assignment, setAssignment] = useState({});
    const [assignments, setAssignments] = useState({});
    const [championships, setChampionships] = useState([]);
    const [filteredMatches, setFilteredMatches] = useState([]);
    const [filters, setFilters] = useState({ days: '10', startDate: newDate(), endDate: '', championship: '', sport: '' });
    const [loading, setLoading] = useState(true);
    const [match, setMatch] = useState({});
    const [matches, setMatches] = useState([]);
    const [proposedAssignments, setProposedAssignments] = useState([]);
    const [referees, setReferees] = useState([]);
    const [refList, setRefList] = useState({});
    const [roles, setRoles] = useState([]);
    const [openEditModal, setOpenEditModal] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [openNotifyModal, setOpenNotifyModal] = useState(false);
    const [totalAssignments, setTotalAssignments] = useState([]);
    const [viewAssignments, setViewAssignments] = useState([]);

    const sendAssignments = () => {
        const array = assignments
            .filter(a => a.status === 1)
            .map(a => ({
                ...a,
                match: matches.find(m => a.match._id === m._id)
            }))
            .sort((a, b) => new Date(a.match.date) - new Date(b.match.date))
        setProposedAssignments(array);
        setViewAssignments(array.slice(0, 10));
        setAction('sendMany');
        setOpenNotifyModal(true);
    }

    // Championships & Matches
    useEffect(() => {
        if (committeeId && seasonId) {
            setLoading(true);
            API.get(`championships?committee=${committeeId}&season=${seasonId}&isDesignable=true&page=assignments`)
                .then(res => {
                    if (res.success) {
                        setChampionships(sortByName(res.championships, 'name'));
                        // Creo un array con tutte le gare dei campionati
                        const matches = [];
                        res.championships.forEach(c =>
                            c.calendar.forEach(g =>
                                g.rounds.forEach(r =>
                                    r.matches.forEach(m => {
                                        matches.push(m)
                                    })
                                )
                            )
                        )
                        setMatches(matches);
                    } else {
                        setAlert({ type: 'failure', label: res.msg || 'Qualcosa è andato storto...' });
                    }
                    setLoading(false);
                })
                .catch(err => console.error(err));
        }
    }, [committeeId, seasonId])

    // Roles
    useEffect(() => {
        if (committeeId) {
            API.get(`refroles?committee=${committeeId}`)
                .then(res => {
                    if (res.success) {
                        setRoles(sortByName(res.refroles, 'title'));
                    } else {
                        setAlert({ type: 'failure', label: res.msg || 'Qualcosa è andato storto...' });
                    }
                })
                .catch(err => console.error(err));
        }
    }, [committeeId])

    // Total Assignments
    useEffect(() => {
        if (matches.length > 0) {
            setTotalAssignments(matches.reduce((prev, match) => [...prev, ...match.assignments], []));
        }
    }, [matches])

    // Filtered Matches
    useEffect(() => {
        if (matches.length > 0) {
            let array = [...matches];
            // Filtro per sport
            if (filters.sport) {
                array = array.filter(m => m.championship.sport === filters.sport);
            }
            // Filtro per campionato
            if (filters.championship) {
                array = array.filter(m => m.championship._id === filters.championship);
            }
            // Filtro per giorni
            if (filters.days) {
                array = array.filter(m => {
                    const distance = filters.days * 24 * 60 * 60 * 1000;
                    return (new Date(m.date) - new Date()) <= distance;
                })
            }
            // Filtra per giorno di inizio
            if (filters.startDate) {
                array = array.filter(m => new Date(m.date) >= new Date(filters.startDate))
            }
            // Filtra per giorno di fine
            if (filters.endDate) {
                array = array.filter(m => new Date(m.date) <= new Date(filters.endDate))
            }
            setAssignments(array.reduce((prev, match) => [...prev, ...match.assignments], []));
            setFilteredMatches(sortByDate(sortByName(array, 'teamA.name'), 'date'));
        }
    }, [filters, matches])

    // Referees
    useEffect(() => {
        if (committeeId) {
            API.get(`users?isreferee=true&committees=${committeeId}`, true)
                .then(res => {
                    if (res.success) {
                        setReferees(res.users)
                    } else {
                        setAlert({ type: 'failure', label: res.msg || 'Qualcosa è andato storto...' });
                    }
                })
                .catch(err => console.error(err))
        }
    }, [committeeId])

    return (
        <div className="flex flex-col gap-3">
            <FiltersBar championships={championships} filters={filters} setFilters={setFilters} />
            <div className="w-fit">
                <Button
                    gradientDuoTone="purpleToBlue"
                    onClick={sendAssignments}
                >
                    Invia le designazioni proposte
                </Button>
            </div>
            {alert?.type && <GenericAlert type={alert.type}>{alert.label}</GenericAlert>}
            {loading &&
                <div className="bg-white p-4">
                    <Spinner color="purple" size="xl" />
                </div>
            }
            <div className="flex flex-col gap-2 text-left">
                {
                    filteredMatches.map((m, i) =>
                        <MatchCard
                            key={m._id}
                            assignments={assignments}
                            index={i}
                            match={m}
                            referees={referees}
                            roles={roles}
                            setAction={setAction}
                            setAssignment={setAssignment}
                            setMatch={setMatch}
                            setOpenEditModal={setOpenEditModal}
                            setOpenModal={setOpenModal}
                            setOpenNotifyModal={setOpenNotifyModal}
                            setRefList={setRefList}
                        />)
                }
            </div>
            <NewAssignmentModal
                assignment={assignment}
                assignments={totalAssignments}
                match={match}
                openModal={openModal}
                refList={refList}
                setAlert={setAlert}
                setMatches={setMatches}
                setOpenModal={setOpenModal}
            />
            <EditAssignmentModal
                action={action}
                assignment={assignment}
                match={match}
                matches={matches}
                openModal={openEditModal}
                setAlert={setAlert}
                setAssignment={setAssignment}
                setMatches={setMatches}
                setOpenModal={setOpenEditModal}
            />
            <NotifyModal
                action={action}
                assignment={assignment}
                assignments={proposedAssignments}
                match={match}
                openModal={openNotifyModal}
                setAlert={setAlert}
                setMatches={setMatches}
                setOpenModal={setOpenNotifyModal}
                setAssignments={setAssignments}
                setViewAssignments={setViewAssignments}
                viewAssignments={viewAssignments}
            />
        </div>
    )
}

export default Assignments;