import { useState } from "react";
import { useSelector } from "react-redux";
import { serviceProvider as API } from "../../../API/api";
import { formatDate, formToIsoString, isoToForm, textAllowance, whitespaces } from "../../../lib/utils";

// Components
import FormLabel from "../../elements/FormLabel";
import SendRasModal from "./elements/SendRasModal";
import { Alert, Button, Select, Spinner, TextInput } from "flowbite-react";

const ExportIcon = () => <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
    <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m6.75 12-3-3m0 0-3 3m3-3v6m-1.5-15H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z" />
</svg>

const InfoIcon = () => <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
    <path strokeLinecap="round" strokeLinejoin="round" d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z" />
</svg>

const CheckIcon = () => <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
    <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
</svg>

const XIcon = () => <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
    <path strokeLinecap="round" strokeLinejoin="round" d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
</svg>


const FiltersBar = ({ downloadLink, filters, findAssignments, isLoading, setDownloadLink, setErrors, setFilters }) => {

    const handleInput = (e) => {
        const { id, value } = e.currentTarget;
        setFilters(prevState => (
            {
                ...prevState,
                [id]: id === 'sport'
                    ? value
                    : id === 'endDate'
                        ? formToIsoString(`${value}T23:59`)
                        : formToIsoString(`${value}T00:00`)
            }
        ));
    }

    const resetFilters = () => {
        // Evito memory leak
        if (downloadLink !== '') window.URL.revokeObjectURL(downloadLink);
        setDownloadLink('');
        setFilters({ startDate: '', endDate: '', sport: '' });
        setErrors([]);
    }

    return (
        <div className="flex flex-row flex-wrap gap-8 items-end">
            <div>
                <div className="mb-2 block">
                    <FormLabel label="Da / A" />
                </div>
                <div className="flex flex-row gap-2">
                    <TextInput
                        id="startDate"
                        type="date"
                        value={isoToForm(filters.startDate)}
                        onChange={handleInput}
                        disabled={isLoading}
                    />
                    <TextInput
                        id="endDate"
                        type="date"
                        value={filters.endDate && isoToForm(filters.endDate)}
                        onChange={handleInput}
                        disabled={isLoading}
                    />
                </div>
            </div>
            <div>
                <div className="mb-2 block">
                    <FormLabel htmlFor="sport" label="Sport" />
                </div>
                <Select
                    id="sport"
                    value={filters.sport}
                    onChange={handleInput}
                    disabled={isLoading}
                >
                    <option value=''>Tutti</option>
                    <option value="Pallavolo">Pallavolo</option>
                    <option value="Calcio">Calcio</option>
                </Select>
            </div>
            <div>
                <Button
                    size="sm"
                    className="mb-1"
                    onClick={findAssignments}
                    disabled={filters.startDate && filters.endDate ? false : true}
                >
                    Cerca
                </Button>
            </div>
            <div>
                <Button
                    color="dark"
                    size="sm"
                    className="mb-1"
                    onClick={resetFilters}
                >
                    Reset Filtri
                </Button>
            </div>
        </div>
    )
}


function ExportAssignments() {
    const { _id: committeeId, csiid } = useSelector(state => state.committee);
    const { _id: seasonId, season_code } = useSelector(state => state.season);
    const [action, setAction] = useState('');
    const [assignments, setAssignments] = useState([]);
    const [downloadLink, setDownloadLink] = useState('');
    const [errors, setErrors] = useState([])
    const [filters, setFilters] = useState({ startDate: '', endDate: '', sport: '' });
    const [isLoading, setIsLoading] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [viewAssignments, setViewAssignments] = useState([]);


    const findAssignments = () => {

        const makeTextArray = (assignments, setErrors) => {
            const array = assignments.map(a => {
                if (!csiid)
                    setErrors(prevState => [...prevState, 'Codice Comitato CSI non impostato']);
                const codfisc = a.referee.codfisc?.trim().toUpperCase() || 'XXXXXXXXXXXXXXXX';
                if (!a.referee.codfisc)
                    setErrors(prevState => [...prevState, `Codice fiscale non impostato per ${a.referee.lastname} ${a.referee.firstname}`]);
                const allowance = textAllowance(a.refund.allowance)
                if (!a.refund.hasOwnProperty('subtotal'))
                    setErrors(prevState => [...prevState, `Rimborso non inserito dall'arbitro ${a.referee.lastname} ${a.referee.firstname}`]);
                const dayOfTheMatch = formatDate(a.match.date);
                const rascode = a.match.championship.rascode?.trim().toUpperCase() || 'NORASCODE';
                if (!a.match.championship.rascode)
                    setErrors(prevState => [...prevState, `Gara del ${dayOfTheMatch} di ${a.referee.lastname} ${a.referee.firstname}: Codice campionato RAS non impostato`]);
                const workdays = '01';
                if (a.sent_to_ras)
                    setErrors(prevState => [...prevState, `Gara del ${dayOfTheMatch} di ${a.referee.lastname} ${a.referee.firstname}: Gara segnata come già inviata al RAS`]);
                return `${csiid || 'NOCSIID'}${season_code}${codfisc}${allowance}${whitespaces(6)}${dayOfTheMatch}${rascode}${whitespaces(20 - rascode.length)}${whitespaces(24)}${workdays}`;
            });
            return array;
        }

        const makeDownloadLink = (textAssignments) => {
            // Creo il file
            const data = new Blob([textAssignments.join('\n')], { type: 'text/plain' });

            // Evito memory leak
            if (downloadLink !== '') window.URL.revokeObjectURL(downloadLink);
            // Creo il link
            setDownloadLink(window.URL.createObjectURL(data));
        }

        if (filters.startDate && filters.endDate) {
            setIsLoading(true);
            setErrors([]);
            // Evito memory leak
            if (downloadLink !== '') window.URL.revokeObjectURL(downloadLink);
            setDownloadLink('');
            API.get(`championships?committee=${committeeId}&season=${seasonId}`)
                .then(res => {
                    const championships = res.championships;
                    // Creo un array con tutte le gare dei campionati
                    const totalMatches = [];
                    res.championships.forEach(c =>
                        c.calendar.forEach(g =>
                            g.rounds.forEach(r =>
                                r.matches.forEach(m => {
                                    totalMatches.push(m)
                                })
                            )
                        )
                    )
                    /* APPLICO I FILTRI */
                    let matches = [...totalMatches];
                    // Filtra per sport
                    if (filters.sport) {
                        matches = matches.filter(m => m.championship.sport === filters.sport);
                    }
                    // Filtra per giorno di inizio
                    if (filters.startDate) {
                        matches = matches.filter(m => new Date(m.date) >= new Date(filters.startDate))
                    }
                    // Filtra per giorno di fine
                    if (filters.endDate) {
                        matches = matches.filter(m => new Date(m.date) <= new Date(filters.endDate))
                    }
                    const totalAssignments = matches.length > 0
                        ? matches.reduce((prev, match) => [...prev, ...match.assignments], [])
                        : []
                    let assignments = totalAssignments
                        // filtro per designazioni accettate
                        .filter(a => a.status >= 2)
                        .sort((a, b) => new Date(a.match.date) - new Date(b.match.date))
                        // Richiesta del comitato di CARPI
                        .filter(a => a.referee.firstname !== 'REGGIO EMILIA' && a.referee.lastname !== 'Arbitro CSI Modena')
                        // Se manca il rimborso, verifico la diaria dai campionati o la imposto a 0
                        .map(a => {
                            if (!a.hasOwnProperty('refund')) {
                                a.refund = {
                                    allowance: championships
                                        .find(c => c._id === a.match.championship?._id).allowances
                                        .find(all => all.role === a.role)?.amount || 0
                                }
                            }
                            return a;
                        })
                    setAssignments(assignments);
                    const textAssignments = makeTextArray(assignments, setErrors);
                    if (textAssignments.length > 0) makeDownloadLink(textAssignments);
                    if (textAssignments.length < 1)
                        setErrors(prevState => [...prevState, 'Non sono state trovate designazioni coi dati impostati']);
                    setIsLoading(false);
                })
                .catch(err => console.error(err))
        }
    }

    const handleConfirm = () => {
        setAction('confirm');
        setViewAssignments(assignments.slice(0, 10));
        setOpenModal(true);
    }

    const handleCancel = () => {
        setAction('cancel');
        setViewAssignments(assignments.slice(0, 10));
        setOpenModal(true);
    }


    return (
        <div className="flex flex-col gap-3">
            <FiltersBar
                downloadLink={downloadLink}
                filters={filters}
                findAssignments={findAssignments}
                isLoading={isLoading}
                setDownloadLink={setDownloadLink}
                setErrors={setErrors}
                setFilters={setFilters}
            />
            {isLoading &&
                <Alert
                    color='info'
                >
                    <Spinner color='warning' size="lg" />{' '}
                    <span className="text-md">Carico le designazioni...</span>
                </Alert>
            }
            <div className="flex flex-row gap-2">
                <div className="w-fit">
                    <a
                        download="designazioni_compensi.txt"
                        href={downloadLink}
                    >
                        <Button
                            disabled={downloadLink ? false : true}
                        >
                            <ExportIcon />
                            Esporta Designazioni
                        </Button>
                    </a>
                </div>
                <div className="w-fit">
                    <Button
                        outline={true}
                        color="success"
                        disabled={downloadLink ? false : true}
                        onClick={handleConfirm}
                    >
                        <CheckIcon />
                        Segna come inviati
                    </Button>
                </div>
                <div className="w-fit">
                    <Button
                        outline={true}
                        color="warning"
                        disabled={downloadLink ? false : true}
                        onClick={handleCancel}
                    >
                        <XIcon />
                        Segna come non inviati
                    </Button>
                </div>
            </div>
            {
                errors.length > 0 &&
                <Alert
                    color='warning'

                >
                    <InfoIcon /><br />
                    {errors.map((e, i) =>
                        <p className="text-left" key={`e_${i}`}>
                            {e}
                        </p>
                    )}
                </Alert>
            }
            <SendRasModal
                action={action}
                assignments={assignments}
                openModal={openModal}
                setOpenModal={setOpenModal}
                setViewAssignments={setViewAssignments}
                viewAssignments={viewAssignments}
            />
        </div>
    )
}

export default ExportAssignments;