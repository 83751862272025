import { Spinner, Table } from "flowbite-react";

function LoadingRow({ colspan }) {

    return (
        <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
            <Table.Cell colSpan={colspan} className="text-center whitespace-nowrap font-medium text-gray-900 dark:text-white">
                <Spinner />
            </Table.Cell>
        </Table.Row>
    )
}

export default LoadingRow;