import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { serviceProvider as API } from "../../../../API/api";
import { formatDateTime } from "../../../../lib/utils";

// Components
import { Checkbox, Textarea, TextInput, Tooltip } from "flowbite-react";
import FormLabel from "../../../elements/FormLabel";
import GenericAlert from "../../../elements/GenericAlert";
import GenericModal from "../../../elements/GenericModal";
import SubmitButton from "../../../buttons/SubmitButton";


const MainForm = ({ assignment, car, refund, setAlert, setCar, setModalAlert, setOpenModal, setRefund, setTotalAssignments }) => {
    const { km_refund } = useSelector(state => state.committee);

    const handleInput = (e) => {
        const { id, value } = e.currentTarget;
        const numbers = ['meal', 'travel', 'expenses']
        const updRefund = {
            ...refund,
            [id]: numbers.includes(id) ? Number(value) : value
        }
        const { car, meal, travel, expenses, allowance } = updRefund;
        setRefund({
            ...updRefund,
            subtotal: car + meal + travel + expenses + allowance
        });
    }

    const handleDistance = (e) => {
        const distance = Number(e.currentTarget.value);
        const updRefund = {
            ...refund,
            distance: distance,
            car: distance * (km_refund * 100) / 100
        }
        const { car, meal, travel, expenses, allowance } = updRefund;
        setRefund({
            ...updRefund,
            edited_distance: true,
            subtotal: car + meal + travel + expenses + allowance
        });
    }

    const handleCheckbox = (e) => {
        const { checked } = e.currentTarget;
        const updRefund = {
            ...refund,
            car: checked ? (refund.distance * (km_refund * 100) / 100) : 0
        }
        setCar(checked);
        const { car, meal, travel, expenses, allowance } = updRefund;
        setRefund({
            ...updRefund,
            subtotal: car + meal + travel + expenses + allowance
        });
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        const updBody = {
            refund: {
                ...refund,
                updated_at: new Date().toISOString()
            }
        }
        API.update(`assignments/${assignment._id}`, JSON.stringify(updBody), true)
            .then(res => {
                if (res.success) {
                    setTotalAssignments(prevState => [
                        ...prevState.filter(a => a._id !== assignment._id),
                        res.assignment
                    ]);
                    setAlert({ type: 'success', label: 'Il rimborso è stato inserito' });
                    setOpenModal(false);
                } else {
                    setModalAlert({ type: 'failure', label: res.msg || 'Qualcosa è andato storto...' });
                }
            })
            .catch(err => console.error(err));
    }

    return (
        <form className="flex flex-col gap-2" onSubmit={handleSubmit}>
            <div className="grid grid-cols-4 gap-2 items-center justify-center">
                <div className="text-right">
                    <FormLabel htmlFor="allowance" label="Rimborso" />
                </div>
                <TextInput
                    id="allowance"
                    type="number"
                    min="0"
                    required={true}
                    value={`${refund.allowance}` || 0}
                    addon="€"
                    disabled={true}
                />
                <div className="text-right">
                    <FormLabel htmlFor="start_time" label="Ora di inizio gara" />
                </div>
                <TextInput
                    id="start_time"
                    type="time"
                    required={true}
                    value={refund.start_time || ''}
                    onChange={handleInput}
                    disabled={refund?.verified}
                />
                <div className="text-right">
                    <FormLabel htmlFor="end_time" label="Ora di fine gara" />
                </div>
                <TextInput
                    id="end_time"
                    type="time"
                    required={true}
                    value={refund.end_time || ''}
                    min={refund.start_time || '00:00'}
                    onChange={handleInput}
                    disabled={refund?.verified}
                />
                <div className="text-right">
                    <FormLabel htmlFor="travel" label="Spese di viaggio (pedaggi, mezzi pubblici)" />
                </div>
                <TextInput
                    id="travel"
                    type="number"
                    min="0"
                    step="0.1"
                    value={refund.travel || 0}
                    addon="€"
                    onChange={handleInput}
                    disabled={refund?.verified}
                />
                <div className="text-right">
                    <FormLabel htmlFor="meal" label="Pasti" />
                </div>
                <TextInput
                    id="meal"
                    type="number"
                    min="0"
                    value={refund.meal || 0}
                    addon="€"
                    onChange={handleInput}
                    disabled={refund?.verified}
                />
                <div className="text-right">
                    <FormLabel htmlFor="expenses" label="Altre spese" />
                </div>
                <TextInput
                    id="expenses"
                    type="number"
                    min="0"
                    value={refund.expenses || 0}
                    addon="€"
                    onChange={handleInput}
                    disabled={refund?.verified}
                />
                <div className="text-right">
                    <FormLabel htmlFor="carCheck" label="Auto propria?" />
                </div>
                <Checkbox id="carCheck" checked={car} onChange={handleCheckbox} disabled={refund?.verified} />
                {car &&
                    <>
                        <div className="text-right">
                            <FormLabel htmlFor="distance" label="Distanza calcolata" />
                        </div>
                        <Tooltip content="Modifica questo valore solo consapevolmente">
                            <TextInput
                                id="distance"
                                type="number"
                                min="0"
                                addon="km"
                                onChange={handleDistance}
                                value={refund.distance || 0}
                                disabled={refund?.verified}
                            />
                        </Tooltip>
                    </>
                }
                {!car && <><div></div><div></div></>}
                <div className="text-right">
                    <FormLabel htmlFor="carplate" label="Targa Auto Utilizzata" />
                </div>
                <TextInput
                    id="carplate"
                    type="text"
                    onChange={handleInput}
                    value={refund.carplate || ''}
                    disabled={refund?.verified}
                />
                {car &&
                    <>
                        <div className="text-right">
                            <FormLabel htmlFor="car" label="Rimborso km" />
                        </div>
                        <TextInput
                            id="car"
                            type="number"
                            min="0"
                            value={refund.car || 0}
                            addon="€"
                            disabled={true}
                        />
                        <div></div><div></div>
                    </>
                }
                <hr className="my-2 h-px col-span-4 bg-gray-200 border-0 dark:bg-gray-700" />
                <div className="col-span-4 text-sm text-center uppercase font-semibold tracking-wider">
                    <h3>Dati sulla trasferta</h3>
                </div>
                <div className="text-right">
                    <FormLabel htmlFor="departureTime" label="Orario di Partenza (andata)" />
                </div>
                <TextInput
                    id="departureTime"
                    type="time"
                    onChange={handleInput}
                    value={refund.departureTime || ''}
                    disabled={refund?.verified}
                />
                <div className="text-right">
                    <FormLabel htmlFor="departureArrival" label="Orario di Arrivo (andata)" />
                </div>
                <TextInput
                    id="departureArrival"
                    type="time"
                    onChange={handleInput}
                    value={refund.departureArrival || ''}
                    disabled={refund?.verified}
                />
                <div className="text-right">
                    <FormLabel htmlFor="returnTime" label="Orario di Partenza (ritorno)" />
                </div>
                <TextInput
                    id="returnTime"
                    type="time"
                    onChange={handleInput}
                    value={refund.returnTime || ''}
                    disabled={refund?.verified}
                />
                <div className="text-right">
                    <FormLabel htmlFor="returnArrival" label="Orario di Arrivo (ritorno)" />
                </div>
                <TextInput
                    id="returnArrival"
                    type="time"
                    onChange={handleInput}
                    value={refund.returnArrival || ''}
                    disabled={refund?.verified}
                />
                <hr className="my-2 h-px col-span-4 bg-gray-200 border-0 dark:bg-gray-700" />
                <div className="col-span-4 grid grid-cols-2 gap-2 items-end">
                    <div className="col-span-2 text-left">
                        <FormLabel htmlFor="notes" label="Note Aggiuntive" />
                    </div>
                    <div className="col-span-2">
                        <Textarea
                            id="notes"
                            type="text"
                            value={refund.notes || ''}
                            onChange={handleInput}
                            disabled={refund?.verified}
                        />
                    </div>
                    <div className="text-right">
                        <label htmlFor="subtotal" className="block mb-2 text-sm uppercase font-medium text-gray-900 dark:text-gray-300">Totale Rimborsabile</label>
                    </div>
                    <TextInput
                        id="subtotal"
                        type="number"
                        min="0"
                        value={refund.subtotal || 0}
                        addon="€"
                        disabled={true}
                    />
                </div>
            </div>
            <div>
                <SubmitButton
                    disabled={refund.verified}
                />
            </div>
        </form>
    )
}

const RoleRow = ({ match, role }) => {
    const [roleAssignment, setRoleAssignment] = useState([]);

    useEffect(() => {
        setRoleAssignment(match.assignments?.filter(a =>
            (a.role === role.title && a.status > 2)));
    }, [match.assignments, role.title])


    return (
        roleAssignment?.length > 0 &&
        <li>
            <span className="font-semibold">{role.title}:</span>
            {roleAssignment
                .map(a => ` ${a.referee.lastname} ${a.referee.firstname}`)
            }
        </li>
    )
}

const ModalHeader = ({ match, roles }) => {
    return (
        <ul className="space-y-1 list-none list-inside p-2 mb-4 cursor-default border bg-gray-50 shadow-lg border-red-400 rounded-lg text-center text-gray-700 dark:text-gray-400">
            <li>
                <span className="font-semibold text-sm uppercase">{match?.championship.name}</span>
            </li>
            <li>
                <span className="font-semibold">{match?.teamA.name} - {match?.teamB.name}</span>
            </li>
            <hr className="my-8 h-px bg-gray-300 border-0 dark:bg-gray-700" />
            <div className="flex flex-row gap-4 items-center">
                <div className="text-right basis-1/2">
                    <li>
                        <span className="font-semibold">Data:</span> <span className="capitalize">{formatDateTime(match?.date, true)}</span>
                    </li>
                    <li>
                        <span className="font-semibold">Campo:</span> {match?.field.name}
                    </li>
                    <li>
                        <span className="font-semibold">Località:</span> {match?.field.city} ({match?.field.province})
                    </li>
                    <li>
                        <span className="font-semibold">Indirizzo:</span> {match?.field.address}
                    </li>
                </div>
                <div className="text-left basis-1/2">
                    {
                        roles
                            .filter(r => r.sport === match?.championship.sport)
                            .map(r =>
                                <RoleRow
                                    key={r._id}
                                    match={match}
                                    role={r}
                                />
                            )
                    }
                </div>
            </div>
        </ul>
    )
}

function RefundModal({ assignment, car, openModal, refund, roles, setAlert, setOpenModal, setCar, setRefund, setTotalAssignments }) {
    const [modalAlert, setModalAlert] = useState({ type: '', label: '' });

    // Azzera l'alert del modal ogni volta che viene aperto
    useEffect(() => {
        openModal === true && setModalAlert({ type: '', label: '' });
    }, [openModal]);

    return (
        <GenericModal openModal={openModal} setOpenModal={setOpenModal} title='Compila il rimborso' size="2xl">
            {modalAlert?.type && <GenericAlert type={modalAlert.type}>{modalAlert.label}</GenericAlert>}
            <ModalHeader
                match={assignment.match}
                roles={roles}
            />
            <MainForm
                assignment={assignment}
                car={car}
                refund={refund}
                setAlert={setAlert}
                setCar={setCar}
                setModalAlert={setModalAlert}
                setOpenModal={setOpenModal}
                setRefund={setRefund}
                setTotalAssignments={setTotalAssignments}
            />
        </GenericModal>
    )
}

export default RefundModal;