import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { serviceProvider as API } from "../../../API/api";
import { formatDateTime, formToIsoString, sortByDate, sortByName } from "../../../lib/utils";

// Components
import { Button, Select } from "flowbite-react";
import FormLabel from "../../elements/FormLabel";
import GenericAlert from "../../elements/GenericAlert";
import GenericCard from "../../elements/GenericCard";


const MatchCard = ({ index, match }) => {
    const { urlcode: committeeUrlcode } = useSelector(state => state.committee);
    const path = `/${committeeUrlcode}/riservata`;

    return (
        <GenericCard colorClasses={index % 2 === 0 ? 'bg-green-50 hover:bg-green-100' : 'bg-blue-50 hover:bg-blue-100'}>
            <h5 className="w-1/2 lg:w-1/3 xl:w-1/5 mx-auto rounded-lg text-md text-center font-bold tracking-wide uppercase text-gray-700 dark:text-white">
                {match.championship.name}
            </h5>
            <div className="flex flex-row gap-2">
                <div className="basis-1/2 my-auto">
                    <ul className="space-y-1 max-w-md list-none list-inside text-gray-700 dark:text-gray-400">
                        <li>
                            <span className="font-semibold text-sm uppercase">{match.teamA.name} - {match.teamB.name}</span>
                        </li>
                        <li>
                            <span className="font-semibold">Data Originale:</span>
                            <span className="capitalize"> {formatDateTime(match.date, true)}</span>
                        </li>
                        <li>
                            <span className="font-semibold">Stato attuale:</span>
                            <span className="bg-yellow-100 text-yellow-800 text-sm font-medium mx-2 px-2.5 py-0.5 rounded dark:bg-yellow-200 dark:text-yellow-900 uppercase">Rinviata</span>
                        </li>
                        <li>
                            <span className="font-semibold">Gara #:</span> {match.num}
                        </li>
                        <li>
                            <span className="font-semibold">Campo:</span> {match.field.city} - {match.field.name}
                        </li>
                    </ul>
                </div>
                <div className="basis-1/2 my-auto">
                    <div className="w-fit mx-auto">
                        <Link to={`${path}/campionati/${match.championship._id}`}>
                            <button
                                type="button"
                                className="focus:outline-none text-white bg-blue-400 hover:bg-blue-500 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg px-2.5 py-2.5 mr-2 mb-2 dark:focus:ring-blue-900"
                            >
                                <div className="flex flex-row items-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
                                    </svg>
                                    <span>{' '}Vai al Campionato</span>
                                </div>
                            </button>
                        </Link>
                    </div>
                </div>
            </div>
        </GenericCard>
    )
}

const FiltersBar = ({ championships, filters, setFilters }) => {

    const handleInput = (e) => {
        const { id, value } = e.currentTarget;
        switch (id) {
            case "days":
                setFilters(prevState => ({ ...prevState, days: value, endDate: '' }));
                break;
            case "startDate":
                setFilters(prevState => ({ ...prevState, startDate: formToIsoString(`${value}T00:00`) }));
                break;
            case "endDate":
                setFilters(prevState => ({ ...prevState, days: '', endDate: formToIsoString(`${value}T23:59`) }));
                break;
            default:
                setFilters(prevState => ({ ...prevState, [id]: value }));
        }
    }

    const resetFilters = () => {
        setFilters({ championship: '', sport: '' });
    }

    return (
        <div className="flex flex-row flex-wrap gap-8 items-end">
            <div>
                <div className="mb-2 block">
                    <FormLabel htmlFor="championship" label="Campionato" />
                </div>
                <Select
                    id="championship"
                    value={filters.championship}
                    onChange={handleInput}
                >
                    <option value=''>-</option>
                    {
                        championships.map(c => <option key={c._id} value={c._id}>{c.name}</option>)
                    }
                </Select>
            </div>
            <div>
                <div className="mb-2 block">
                    <FormLabel htmlFor="sport" label="Sport" />
                </div>
                <Select
                    id="sport"
                    className="w-32"
                    value={filters.sport}
                    onChange={handleInput}
                >
                    <option value=''>-</option>
                    <option value="Pallavolo">Pallavolo</option>
                    <option value="Calcio">Calcio</option>
                </Select>
            </div>
            <div>
                <Button
                    color="dark"
                    size="sm"
                    className="mb-1"
                    onClick={resetFilters}
                >Reset Filtri</Button>
            </div>
        </div>
    )
}

function PostponedMatches() {
    const { _id: committeeId } = useSelector(state => state.committee);
    const { _id: seasonId } = useSelector(state => state.season);
    const [alert, setAlert] = useState({ type: '', label: '' });
    const [championships, setChampionships] = useState([]);
    const [filteredMatches, setFilteredMatches] = useState([]);
    const [filters, setFilters] = useState({ championship: '', sport: '' });
    const [matches, setMatches] = useState([]);

    // Championships & Matches
    useEffect(() => {
        if (committeeId && seasonId) {
            API.get(`championships?committee=${committeeId}&season=${seasonId}&isDesignable=true`)
                .then(res => {
                    if (res.success) {
                        setChampionships(sortByName(res.championships, 'name'));
                        // Creo un array con tutte le gare dei campionati
                        const matches = [];
                        res.championships.forEach(c =>
                            c.calendar.forEach(g =>
                                g.rounds.forEach(r =>
                                    r.matches.forEach(m => {
                                        matches.push(m)
                                    })
                                )
                            )
                        )
                        setMatches(matches);
                    } else {
                        setAlert({ type: 'failure', label: res.msg || 'Qualcosa è andato storto...' });
                    }
                })
                .catch(err => console.error(err));
        }
    }, [committeeId, seasonId])

    // Filtered Matches
    useEffect(() => {
        if (matches.length > 0) {
            let array = [...matches].filter(m => m.postponed);
            // Filtro per sport
            if (filters.sport) {
                array = array.filter(m => m.championship.sport === filters.sport);
            }
            // Filtro per campionato
            if (filters.championship) {
                array = array.filter(m => m.championship._id === filters.championship);
            }
            setFilteredMatches(sortByDate(sortByName(array, 'teamA.name'), 'date'));
        }
    }, [filters, matches])

    return (
        <div className="flex flex-col gap-3">
            <FiltersBar championships={championships} filters={filters} setFilters={setFilters} />
            {alert?.type && <GenericAlert type={alert.type}>{alert.label}</GenericAlert>}
            <div className="flex flex-col gap-2 text-left">
                {
                    filteredMatches.map((m, i) =>
                        <MatchCard
                            key={m._id}
                            index={i}
                            match={m}
                        />)
                }
            </div>
        </div>
    )
}

export default PostponedMatches;