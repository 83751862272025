import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { serviceProvider as API } from "../../../../API/api";
import { formatDateTime, isSameDay } from "../../../../lib/utils";

// Components
import { Button, Tooltip } from "flowbite-react";
import GenericCard from "../../../elements/GenericCard";


const RoleRow = ({ assignments, match, referees, role, setAction, setAssignment, setMatch, setOpenEditModal, setOpenModal, setOpenNotifyModal, setRefList }) => {
    const roleAssignments = match.assignments.filter(a => a.role === role.title && a.status > 0);
    const statusClasses = [
        'text-gray-600 bg-red-400 hover:bg-red-300',
        'text-gray-100 bg-blue-500 hover:bg-blue-400',
        'text-gray-600 bg-yellow-300 hover:bg-yellow-200',
        'text-white bg-green-600 hover:bg-green-500'
    ];

    const assignReferee = async () => {
        setMatch(match);
        const matchDate = new Date(match.date);
        const queryParams = `provinces=${match.field.province}&locations=${match.field.city}`;
        const api = await API.get(`distances?${queryParams}`).catch(err => console.error(err));
        const distances = api.success ? api.distances : [];
        const refList = referees
            .filter(ref => ref.sports.includes(match.championship.sport))
            .map(ref => {
                if (match.field.province === ref.province && match.field.city === ref.city) {
                    return { ...ref, distance: 0 };
                }
                const distance = distances.find(d => d.provinces.includes(ref.province) && d.locations.includes(ref.city));
                if (distance) return { ...ref, distance: distance.distance }
                else return { ...ref, distance: 999 }
            });
        const filterAssignments = assignments.filter(a => isSameDay(a.match.date, match.date));
        const usedReferees = filterAssignments.map(a => a.referee._id);
        const assignedReferees = [];
        const freeReferees = [];
        refList.forEach(r => {
            if (usedReferees.includes(r._id)) {
                assignedReferees.push(r);
            } else {
                freeReferees.push(r);
            }
        });
        const array = [];
        const availableRefs = [];
        const outOfTimeRefs = [];
        const unavailableRefs = [];
        // Cerco gli arbitri che hanno messo indisponibilità e creo anche un array con quelli rimanenti
        freeReferees.forEach(r => {
            if (r.unavailabilities.some(u => new Date(u.startDate) <= matchDate && matchDate <= new Date(u.endDate))) {
                unavailableRefs.push(r)
            } else {
                array.push(r);
            }
        });
        const [matchHours, matchMinutes] = [matchDate.getHours(), matchDate.getMinutes()];
        array.forEach(r => {
            const day = matchDate.getDay();
            const { startTime, endTime } = r.availability[day];
            const [startHours, startMinutes] = startTime.split(':');
            const [endHours, endMinutes] = endTime.split(':');
            const startDate = new Date(1997, 9, 6, Number(startHours), Number(startMinutes));
            const matchTime = new Date(1997, 9, 6, matchHours, matchMinutes);
            const endDate = new Date(1997, 9, 6, Number(endHours), Number(endMinutes));
            // Comparo le date per verificare la disponibilità
            if (startDate <= matchTime && matchTime <= endDate) {
                availableRefs.push(r);
            } else {
                outOfTimeRefs.push(r);
            }
        });
        setRefList({
            availables: availableRefs.sort((a, b) => a.distance - b.distance),
            assignedReferees: assignedReferees,
            timeUnavailables: outOfTimeRefs.sort((a, b) => a.distance - b.distance),
            unavailables: unavailableRefs.sort((a, b) => a.distance - b.distance),
        });
        setAssignment({ match: match, role: role.title });
        setOpenModal(true);
    }

    const handleEdit = (e) => {
        setAction('edit');
        setAssignment(assignments.find(a => a._id === e.currentTarget.id));
        setMatch(match);
        setOpenEditModal(true);
    }

    const handleDelete = (e) => {
        setAction('delete');
        setAssignment(assignments.find(a => a._id === e.currentTarget.dataset.id));
        setMatch(match);
        setOpenEditModal(true);
    }

    const handleNotify = (e) => {
        setAction('sendOne');
        setAssignment(assignments.find(a => a._id === e.currentTarget.dataset.id));
        setMatch(match);
        setOpenNotifyModal(true);
    }

    return (
        <li className="flex flex-row gap-2 items-center">
            <span className="font-semibold">{role.title}:</span>
            {roleAssignments
                .map(a =>
                    <div key={a._id} className="flex flex-row gap-1 items-center">
                        <span
                            id={a._id}
                            className={`p-1.5 rounded uppercase font-semibold text-sm cursor-pointer ${statusClasses[a.status]}`}
                            onClick={handleEdit}
                        >
                            {a.referee.lastname} {a.referee.firstname}
                        </span>
                        <div>
                            <Tooltip content="Elimina designazione">
                                <Button
                                    color="failure"
                                    size="xs"
                                    data-id={a._id}
                                    onClick={handleDelete}
                                >
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className='w-5 h-5'>
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                                    </svg>
                                </Button>
                            </Tooltip>
                        </div>
                        {
                            a.status === 1 &&
                            <div>
                                <Tooltip content="Invia designazione">
                                    <Button
                                        color="purple"
                                        size="xs"
                                        data-id={a._id}
                                        onClick={handleNotify}
                                    >
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75" />
                                        </svg>
                                    </Button>
                                </Tooltip>
                            </div>
                        }
                    </div>
                )
            }
            {roleAssignments.length < 1 &&
                <div className="w-fit">
                    <Button pill={true} size="xs" onClick={assignReferee}>
                        Imposta
                    </Button>
                </div>
            }
        </li>
    )
}

const MatchCard = ({ assignments, index, match, referees, roles, setAction, setAssignment, setMatch, setOpenEditModal, setOpenModal, setOpenNotifyModal, setRefList }) => {
    const rejectedAssignments = match.assignments.filter(a => a.status < 1);
    const { urlcode: committeeUrlcode } = useSelector(state => state.committee);

    const handleEdit = (e) => {
        setAction('edit');
        setAssignment(assignments.find(a => a._id === e.currentTarget.dataset.id));
        setMatch(match);
        setOpenEditModal(true);
    }

    return (
        <GenericCard colorClasses={index % 2 === 0 ? 'bg-green-50 hover:bg-green-100' : 'bg-blue-50 hover:bg-blue-100'}>
            <h5 className="w-1/2 lg:w-1/3 xl:w-1/5 mx-auto rounded-lg text-md text-center font-bold tracking-wide uppercase text-gray-700 dark:text-white">
                <Link to={`/${committeeUrlcode}/campionati/${match.championship.sport?.toLowerCase()}/${match.championship.urlcode}`} target="_blank">
                    {match.championship.name}
                </Link>
            </h5>
            <div className="flex flex-row gap-2">
                <div className="basis-1/2 my-auto">
                    <ul className="space-y-1 max-w-md list-none list-inside text-gray-700 dark:text-gray-400">
                        <li>
                            <span className="font-semibold text-sm uppercase">{match.teamA.name} - {match.teamB.name}</span>
                        </li>
                        <li>
                            <span className="font-semibold">Data:</span>
                            {
                                match.postponed &&
                                <span className="bg-yellow-100 text-yellow-800 text-sm font-medium mx-2 px-2.5 py-0.5 rounded dark:bg-yellow-200 dark:text-yellow-900 uppercase">Rinviata</span>
                            }
                            {
                                !match.postponed &&
                                <span className="capitalize">{formatDateTime(match.date, true)}</span>
                            }
                        </li>
                        <li>
                            <span className="font-semibold">Gara #:</span> {match.num}
                        </li>
                        <li>
                            <span className="font-semibold">Campo:</span> {match.field.city} - {match.field.name}
                        </li>
                    </ul>
                </div>
                <div className="basis-1/2 my-auto">
                    <ul className="space-y-1 max-w-md list-none list-inside text-gray-700 dark:text-gray-400">
                        {
                            roles
                                .filter(r => r.sport === match.championship.sport)
                                .map(r =>
                                    <RoleRow
                                        key={r._id}
                                        assignments={assignments}
                                        match={match}
                                        referees={referees}
                                        role={r}
                                        setAction={setAction}
                                        setAssignment={setAssignment}
                                        setMatch={setMatch}
                                        setOpenEditModal={setOpenEditModal}
                                        setOpenModal={setOpenModal}
                                        setOpenNotifyModal={setOpenNotifyModal}
                                        setRefList={setRefList}
                                    />
                                )
                        }
                        {
                            rejectedAssignments.length > 0 &&
                            <li className="flex flex-row gap-2 items-center">
                                <span className="font-semibold">Rifiuti:</span>
                                {rejectedAssignments
                                    .map(a =>
                                        <Tooltip key={a._id} content={a.reason || 'n/a'}>
                                            <span
                                                className="p-1.5 rounded uppercase font-semibold text-xs cursor-pointer text-white bg-red-900 hover:bg-red-800"
                                                data-id={a._id}
                                                onClick={handleEdit}
                                            >
                                                {a.referee.lastname} {a.referee.firstname}
                                            </span>
                                        </Tooltip>
                                    )
                                }
                            </li>
                        }
                    </ul>
                </div>
            </div>
        </GenericCard>
    )
}

export default MatchCard;