import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { serviceProvider as API } from "../../../../API/api";
import { abbrText, formatDateTime, sortByDate, timePassed } from "../../../../lib/utils";

// Components
import GenericAlert from "../../../elements/GenericAlert";
import GenericModal from "../../../elements/GenericModal";

const RefereeRow = ({ assignment, assignments, color, match, referee, setAlert, setMatches, setModalAlert, setOpenModal }) => {
    const { _id: committeeId } = useSelector(state => state.committee);
    const { _id: seasonId } = useSelector(state => state.season);
    const [loading, setLoading] = useState(false);
    const [previousAssignment, setPreviousAssignment] = useState({});
    const totalAssignments = useCallback(() => assignments.filter(a => a.referee?._id === referee?._id && a.status > 1).length, [assignments, referee]);

    const styles = {
        'blue': 'bg-blue-50 hover:bg-blue-100',
        'green': 'bg-green-50 hover:bg-green-100',
        'red': 'bg-red-50 hover:bg-red-100',
        'yellow': 'bg-amber-50 hover:bg-amber-100',
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        // updated_by must be a string
        const body = {
            ...assignment,
            match: {
                ...assignment.match,
                documents: assignment.match.documents?.map(d => ({
                    ...d,
                    updated_by: d.updated_by._id
                }))
            },
            referee: referee,
            status: 1,
            season: seasonId,
            committee: committeeId,
            inserted_at: new Date().toISOString()
        };
        API.insert('assignments', JSON.stringify(body), true)
            .then(res => {
                if (res.success) {
                    const updMatch = { assignments: [...match.assignments.map(a => ({_id: a._id})), {_id: res.assignment._id}] };
                    API.update(`matches/${match._id}`, JSON.stringify(updMatch), true)
                        .then(res => {
                            if (res.success) {
                                // Elimino il match precedente e lo sostituisco con quello aggiornato
                                setMatches(prevState => [
                                    ...prevState.filter(m => m._id !== res.match._id),
                                    res.match
                                ]);
                                setAlert({ type: 'success', label: 'La designazione è stata inserita' });
                                setOpenModal(false);
                            } else {
                                setModalAlert({ type: 'failure', label: res.msg || 'Qualcosa è andato storto...' });
                            }
                            setLoading(false);
                        })
                } else {
                    setModalAlert({ type: 'failure', label: res.msg || 'Qualcosa è andato storto...' });
                    setLoading(false)
                }
            })
            .catch(err => console.error(err))
    }

    useEffect(() => {
        if (assignments?.length) {
            const findAssignments = sortByDate(assignments.filter(a => a.referee._id === referee._id
                && a.status > 0
                && (
                    (a.match.teamA?._id === assignment.match.teamA?._id || a.match.teamA?._id === assignment.match.teamB?._id
                        || a.match.teamB?._id === assignment.match.teamA?._id || a.match.teamB?._id === assignment.match.teamB?._id)
                )), 'match.date');
            setPreviousAssignment(findAssignments[findAssignments.length - 1]);
        }
    }, [assignment.match, assignments, referee._id]);

    return (
        <button
            className={`${styles[color]} rounded-md dark:border-gray-700 dark:bg-gray-800 w-full px-2 py-3 ${loading && 'cursor-wait'}`}
            onClick={handleSubmit}
            disabled={loading}
        >
            <div className="grid grid-cols-4 gap-2 border-1 border-gray-800">
                <div className="col-span-2 text-sm text-left font-semibold">
                    {referee.lastname} {abbrText(referee.firstname, 8)} ({totalAssignments()})
                </div>
                <div>
                    {previousAssignment?._id &&
                        <span className="text-xs">{timePassed(assignment.match?.date, previousAssignment.match?.date)}</span>
                    }
                </div>
                <div className="text-sm text-right">
                    ({referee.distance !== 999 ? referee.distance : 'n/a'} km)
                </div>
            </div>
        </button>
    )
}

const RefereeList = ({ assignment, assignments, color, match, refList, setAlert, setMatches, setModalAlert, setOpenModal }) => {

    return (
        <div>
            {refList?.map(r =>
                <RefereeRow
                    key={r._id}
                    assignment={assignment}
                    assignments={assignments}
                    color={color}
                    match={match}
                    referee={r}
                    setAlert={setAlert}
                    setMatches={setMatches}
                    setModalAlert={setModalAlert}
                    setOpenModal={setOpenModal}
                />
            )}
        </div>
    )
}

const NewAssignmentModal = ({ assignment, assignments, match, openModal, refList, setAlert, setMatches, setOpenModal }) => {
    const [modalAlert, setModalAlert] = useState({ type: '', label: '' });

    // Azzera l'alert del modal ogni volta che viene aperto
    useEffect(() => {
        openModal === true && setModalAlert({ type: '', label: '' });
    }, [openModal]);

    return (
        <GenericModal openModal={openModal} setOpenModal={setOpenModal} title={'Nuova Designazione'}>
            {modalAlert?.type && <GenericAlert type={modalAlert.type}>{modalAlert.label}</GenericAlert>}
            <ul className="space-y-1 max-w-md list-none list-inside p-1 cursor-default border bg-gray-50 shadow-lg border-red-400 rounded-lg text-center text-gray-700 dark:text-gray-400">
                <li>
                    <span className="font-semibold text-sm uppercase">{match.championship?.name}</span> <span className="font-semibold">({match.teamA?.name} - {match.teamB?.name})</span>
                </li>
                <li>
                    <span className="font-semibold">Data:</span> <span className="capitalize">{formatDateTime(match.date, true)}</span>
                </li>
                <li>
                    <span className="font-semibold">Campo:</span> {match.field?.city} - {match.field?.name}
                </li>
            </ul>
            <div className="max-h-96 overflow-auto">
                {refList.availables?.length > 0 &&
                    <>
                        <h5 className="font-semibold text-sm text-center uppercase">Arbitri Disponibili</h5>
                        <RefereeList
                            assignment={assignment}
                            assignments={assignments}
                            color="green"
                            match={match}
                            refList={refList.availables}
                            setAlert={setAlert}
                            setMatches={setMatches}
                            setModalAlert={setModalAlert}
                            setOpenModal={setOpenModal}
                        />
                    </>
                }
                {refList.timeUnavailables?.length > 0 &&
                    <>
                        <h5 className="font-semibold text-sm text-center uppercase">Non Disponibili (orario)</h5>
                        <RefereeList
                            assignment={assignment}
                            assignments={assignments}
                            color="yellow"
                            match={match}
                            refList={refList.timeUnavailables}
                            setAlert={setAlert}
                            setMatches={setMatches}
                            setModalAlert={setModalAlert}
                            setOpenModal={setOpenModal}
                        />
                    </>
                }
                {refList.unavailables?.length > 0 &&
                    <>
                        <h5 className="font-semibold text-sm text-center uppercase">Indisponibili</h5>
                        <RefereeList
                            assignment={assignment}
                            assignments={assignments}
                            color="red"
                            match={match}
                            refList={refList.unavailables}
                            setAlert={setAlert}
                            setMatches={setMatches}
                            setModalAlert={setModalAlert}
                            setOpenModal={setOpenModal}
                        />
                    </>
                }
                {refList.assignedReferees?.length > 0 &&
                    <>
                        <h5 className="font-semibold text-sm text-center uppercase">Designati</h5>
                        <RefereeList
                            assignment={assignment}
                            assignments={assignments}
                            color="blue"
                            match={match}
                            refList={refList.assignedReferees}
                            setAlert={setAlert}
                            setMatches={setMatches}
                            setModalAlert={setModalAlert}
                            setOpenModal={setOpenModal}
                        />
                    </>
                }
            </div>
        </GenericModal>
    )
}

export default NewAssignmentModal;