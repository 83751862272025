import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { serviceProvider as API } from "../../../API/api";
import { sortByDate, sortByName } from "../../../lib/utils";

// Component
import ConfirmationModal from "../home/elements/ConfirmationModal";
import DocsModal from "../home/elements/DocsModal";
import GenericAlert from "../../elements/GenericAlert";
import ImgModal from "../home/elements/ImgModal";
import MatchCard from "../home/elements/MatchCard";
import RefundModal from "../home/elements/RefundModal";
import ReportModal from "../home/elements/ReportModal";
import ResultsModal from "../home/elements/ResultsModal";


function UserMain() {
    const { _id: committeeId } = useSelector(state => state.committee);
    const { _id: seasonId } = useSelector(state => state.season);
    const user = useSelector(state => state.user);
    const [alert, setAlert] = useState({ type: '', label: '' });
    const [assignment, setAssignment] = useState({});
    const [carCheckbox, setCarCheckbox] = useState(false);
    const [docTypes, setDocTypes] = useState([]);
    const [imgInfo, setImgInfo] = useState({ scope: '', src: '' });
    const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
    const [openDocsModal, setOpenDocsModal] = useState(false);
    const [openImgModal, setOpenImgModal] = useState(false);
    const [openRefundModal, setOpenRefundModal] = useState(false);
    const [openReportModal, setOpenReportModal] = useState(false);
    const [openResultsModal, setOpenResultsModal] = useState(false);
    const [previousAssignments, setPreviousAssignments] = useState([]);
    const [refund, setRefund] = useState({ allowance: 0, subtotal: 0, distance: 0, car: 0, meal: 0, travel: 0, expenses: 0, start_time: '', end_time: '', notes: '' });
    const [report, setReport] = useState([]);
    const [reportAction] = useState('edit');
    const [resultContext] = useState('referee');
    const [roles, setRoles] = useState([]);
    const [totalAssignments, setTotalAssignments] = useState([]);

    // Assignments
    useEffect(() => {
        if (user?.isreferee && committeeId && seasonId) {
            API.get(`assignments?committee=${committeeId}&season=${seasonId}&referee=${user._id}`, true)
                .then(res => {
                    if (res.success) {
                        // Filtro le gare future
                        setTotalAssignments(res.assignments.filter(a => new Date(a.match.date) < new Date()));
                    } else {
                        setAlert({ type: 'failure', label: res.msg || 'Qualcosa è andato storto...' });
                    }
                })
        }
    }, [committeeId, seasonId, user]);

    useEffect(() => {
        setPreviousAssignments(sortByDate(totalAssignments, 'match.date'));
    }, [totalAssignments]);

    // Roles
    useEffect(() => {
        if (committeeId) {
            API.get(`refroles?committee=${committeeId}`)
                .then(res => {
                    if (res.success) {
                        setRoles(sortByName(res.refroles, 'title'));
                    } else {
                        setAlert({ type: 'failure', label: res.msg || 'Qualcosa è andato storto...' });
                    }
                })
                .catch(err => console.error(err));
        }
    }, [committeeId])

    // DocTypes
    useEffect(() => {
        if (committeeId) {
            API.get(`doctypes?committee=${committeeId}`)
                .then(res => {
                    if (res.success) {
                        setDocTypes(sortByName(res.doctypes, 'name'));
                    } else {
                        setAlert({ type: 'failure', label: res.msg || 'Qualcosa è andato storto...' });
                    }
                })
                .catch(err => console.error(err));
        }
    }, [committeeId])

    return (
        <>
            {alert?.type && <GenericAlert type={alert.type}>{alert.label}</GenericAlert>}
            {previousAssignments && previousAssignments.filter(a => a.status > 1).length > 0 &&
                <section className="bg-white p-2 rounded shadow">
                    <h5 className="w-1/2 lg:w-1/3 xl:w-1/5 mx-auto rounded-lg text-xl text-center font-bold tracking-wide uppercase text-gray-800 dark:text-white">
                        Gare precedenti: {previousAssignments.filter(a => a.status > 1).length}
                    </h5>
                    {
                        previousAssignments
                            .filter(a => a.status > 1)
                            .map((a, i) =>
                                <MatchCard
                                    key={a._id}
                                    assignment={a}
                                    index={i}
                                    roles={roles}
                                    setAssignment={setAssignment}
                                    setCarCheckbox={setCarCheckbox}
                                    setOpenConfirmationModal={setOpenConfirmationModal}
                                    setOpenDocsModal={setOpenDocsModal}
                                    setOpenRefundModal={setOpenRefundModal}
                                    setOpenReportModal={setOpenReportModal}
                                    setOpenResultsModal={setOpenResultsModal}
                                    setRefund={setRefund}
                                    setReport={setReport}
                                />
                            )
                    }
                </section>
            }
            {
                previousAssignments
                    .filter(a => a.status === 0).length > 0 &&
                <section className="bg-white p-2 rounded shadow">
                    <h5 className="w-1/2 lg:w-1/3 xl:w-1/5 mx-auto rounded-lg text-xl text-center font-bold tracking-wide uppercase text-gray-800 dark:text-white">
                        Gare rifiutate: {previousAssignments.filter(a => a.status === 0).length}
                    </h5>
                    {
                        previousAssignments
                            .filter(a => a.status === 0)
                            .map((a, i) =>
                                <MatchCard
                                    key={a._id}
                                    assignment={a}
                                    index={i}
                                    rejected={true}
                                    roles={roles}
                                    setAssignment={setAssignment}
                                    setCarCheckbox={setCarCheckbox}
                                    setOpenConfirmationModal={setOpenConfirmationModal}
                                    setOpenDocsModal={setOpenDocsModal}
                                    setOpenRefundModal={setOpenRefundModal}
                                    setOpenReportModal={setOpenReportModal}
                                    setOpenResultsModal={setOpenResultsModal}
                                    setRefund={setRefund}
                                    setReport={setReport}
                                />
                            )
                    }
                </section>

            }
            <ResultsModal
                assignment={assignment}
                match={assignment.match}
                openModal={openResultsModal}
                resultContext={resultContext}
                setAlert={setAlert}
                setOpenModal={setOpenResultsModal}
                setTotalAssignments={setTotalAssignments}
            />
            <ReportModal
                assignment={assignment}
                openModal={openReportModal}
                report={report}
                reportAction={reportAction}
                roles={roles}
                setAlert={setAlert}
                setOpenModal={setOpenReportModal}
                setReport={setReport}
                setTotalAssignments={setTotalAssignments}
            />
            <DocsModal
                assignment={assignment}
                docTypes={docTypes}
                openModal={openDocsModal}
                setAssignment={setAssignment}
                setImgInfo={setImgInfo}
                setOpenImgModal={setOpenImgModal}
                setOpenModal={setOpenDocsModal}
                setTotalAssignments={setTotalAssignments}
            />
            <ImgModal
                imgInfo={imgInfo}
                openModal={openImgModal}
                setOpenModal={setOpenImgModal}
            />
            <RefundModal
                assignment={assignment}
                car={carCheckbox}
                openModal={openRefundModal}
                refund={refund}
                roles={roles}
                setAlert={setAlert}
                setCar={setCarCheckbox}
                setOpenModal={setOpenRefundModal}
                setRefund={setRefund}
                setTotalAssignments={setTotalAssignments}
            />
            <ConfirmationModal
                assignment={assignment}
                openModal={openConfirmationModal}
                setAlert={setAlert}
                setAssignment={setAssignment}
                setOpenModal={setOpenConfirmationModal}
                setTotalAssignments={setTotalAssignments}
            />
        </>
    )
}

export default UserMain;