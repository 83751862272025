import { useMemo } from "react";

// Images
import imgBasket1 from "../../../images/home/basketball.jpg";
import imgBasket2 from "../../../images/home/basketball_2.jpg";
import imgBasket3 from "../../../images/home/basketball_3.jpg";
import imgBeach1 from "../../../images/home/beachvolley.jpg";
import imgBeach2 from "../../../images/home/beachvolley_2.jpg";
import imgBeach3 from "../../../images/home/beachvolley_3.jpg";
import imgMedals from "../../../images/home/medals.jpg";
import imgSoccer1 from "../../../images/home/soccer.jpg";
import imgSoccer2 from "../../../images/home/soccer_2.jpg";
import imgVolley1 from "../../../images/home/volleyball.jpg";
import imgVolley2 from "../../../images/home/volleyball_2.jpg";
import imgVolley3 from "../../../images/home/volleyball_3.jpg";
import imgVolley4 from "../../../images/home/volleyball_4.jpg";


function Header() {
    const imgArray = useMemo(() => [imgBasket1, imgBasket2, imgBasket3, imgBeach1, imgBeach2, imgBeach3, imgMedals, imgSoccer1, imgSoccer2, imgVolley1, imgVolley2, imgVolley3, imgVolley4], []);
    const randomImage = useMemo(() => Math.floor(Math.random() * imgArray.length), [imgArray]);


    return (
        <section className="px-2 py-24 bg-white md:px-0 shadow-lg">
            <div className="container items-center max-w-6xl px-8 mx-auto xl:px-5">
                <div className="flex flex-wrap items-center sm:-mx-3">
                    <div className="w-full md:w-1/2 md:px-3">
                        <div className="w-full pb-6 space-y-6 sm:max-w-md lg:max-w-lg md:space-y-4 lg:space-y-8 xl:space-y-9 sm:pr-5 lg:pr-0 md:pb-0">
                            <h1 className="text-4xl font-extrabold tracking-tight text-gray-900 sm:text-5xl md:text-4xl lg:text-5xl xl:text-6xl">
                                <span className="block xl:inline font-extrabold text-transparent bg-clip-text bg-gradient-to-br from-blue-800 to-blue-600">Campionati </span>
                                <span className="block xl:inline font-extrabold text-transparent bg-clip-text bg-gradient-to-br from-blue-600 to-orange-600" data-primary="blue-600">e Classifiche</span>
                            </h1>
                            <p className="mx-auto text-base text-gray-500 sm:max-w-md lg:text-xl md:max-w-3xl">
                                Qui puoi consultare gli ultimi risultati, le classifiche, i comunicati e i calendari della stagione corrente!
                            </p>
                        </div>
                    </div>
                    <div className="w-full md:w-1/2">
                        <div className="w-full h-auto overflow-hidden rounded-md shadow-xl sm:rounded-xl" data-rounded="rounded-xl" data-rounded-max="rounded-full">
                            <img src={imgArray[randomImage]} alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Header;