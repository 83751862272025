import { Spinner } from "flowbite-react";

function SubmitButton({ isLoading = false, disabled = false }) {
    return (
        <button
            type="submit"
            className={`py-2 px-3 w-full text-sm font-medium text-center text-white rounded-lg ${isLoading || disabled ? 'bg-blue-400 dark:bg-blue-500 cursor-not-allowed' : 'bg-blue-700 dark:bg-blue-600 hover:bg-blue-800 dark:hover:bg-blue-700'} focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800`}
            disabled={(isLoading || disabled) && true}
        >
            <div className="mr-3">
                { !isLoading && 'Invia' }
                { isLoading &&
                    <>
                        <Spinner
                            size="sm"
                            light={true}
                            className="mr-2 mb-1"
                        />

                        Loading ...
                    </>
                }
            </div>
        </button>
    )
}

export default SubmitButton;