import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { serviceProvider as API } from "../../../API/api";
import { sortByName } from "../../../lib/utils";

// Components
import { Button, Checkbox, Select, Table, TextInput, Tooltip } from "flowbite-react";
import DeleteButton from "../../buttons/DeleteButton";
import EditButton from "../../buttons/EditButton";
import FormLabel from "../../elements/FormLabel";
import GenericAlert from "../../elements/GenericAlert";
import GenericModal from "../../elements/GenericModal";
import LoadingRow from "../../elements/LoadingRow";
import NoResultsRow from "../../elements/NoResultsRow";
import SubmitButton from "../../buttons/SubmitButton";

// Icons
const InsertResultsIcon = ({ status = false }) =>
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={`w-6 h-6 ${status ? 'text-green-700' : 'text-red-700'}`}>
        <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m3.75 9v6m3-3H9m1.5-12H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
    </svg>

const InsertReportIcon = ({ status = false }) =>
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={`w-6 h-6 ${status ? 'text-green-700' : 'text-red-700'}`}>
        <path strokeLinecap="round" strokeLinejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
    </svg>


const InsertDocsIcon = ({ status = false }) =>
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={`w-6 h-6 ${status ? 'text-green-700' : 'text-red-700'}`}>
        <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m.75 12l3 3m0 0l3-3m-3 3v-6m-1.5-9H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
    </svg>

const InsertConfirmationIcon = ({ status = false }) =>
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={`w-6 h-6 ${status ? 'text-green-700' : 'text-red-700'}`}>
        <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 01-1.043 3.296 3.745 3.745 0 01-3.296 1.043A3.745 3.745 0 0112 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 01-3.296-1.043 3.745 3.745 0 01-1.043-3.296A3.745 3.745 0 013 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 011.043-3.296 3.746 3.746 0 013.296-1.043A3.746 3.746 0 0112 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 013.296 1.043 3.746 3.746 0 011.043 3.296A3.745 3.745 0 0121 12z" />
    </svg>


const ShowInReportIcon = ({ status = false }) =>
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={`w-6 h-6 ${status ? 'text-green-700' : 'text-red-700'}`}>
        <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
    </svg>

const ShowInHomeIcon = ({ status = false }) =>
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={`w-6 h-6 ${status ? 'text-green-700' : 'text-red-700'}`}>
        <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25" />
    </svg>

const TableRow = ({ refRole, setAction, setRefRole, setOpenModal }) => {
    const { _id, title, insert_results, insert_report, insert_docs, insert_confirmation, show_in_report, show_in_home, sport, committee } = refRole;

    const rowRefRole = {
        _id: _id,
        title: title || '',
        insert_results: insert_results || false,
        insert_report: insert_report || false,
        insert_docs: insert_docs || false,
        insert_confirmation: insert_confirmation || false,
        show_in_report: show_in_report || false,
        show_in_home: show_in_home || false,
        sport: sport || '',
        committee: committee
    }

    const handleEdit = () => {
        setAction('edit');
        setRefRole(rowRefRole);
        setOpenModal(true);
    }

    const handleDelete = () => {
        setAction('delete');
        setRefRole(rowRefRole);
        setOpenModal(true);
    }

    return (
        <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
            <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                {sport}
            </Table.Cell>
            <Table.Cell>
                {title}
            </Table.Cell>
            <Table.Cell>
                <div className="flex flex-row">
                    <Tooltip content={`${insert_results ? 'Può' : 'Non può'} inserire risultati`}>
                        <InsertResultsIcon status={insert_results} />
                    </Tooltip>
                    <Tooltip content={`${insert_report ? 'Può' : 'Non può'} inserire il rapporto gara`}>
                        <InsertReportIcon status={insert_report} />
                    </Tooltip>
                    <Tooltip content={`${insert_docs ? 'Può' : 'Non può'} caricare documenti`}>
                        <InsertDocsIcon status={insert_docs} />
                    </Tooltip>
                    <Tooltip content={`${insert_confirmation ? 'Può' : 'Non può'} finalizzare la gara`}>
                        <InsertConfirmationIcon status={insert_confirmation} />
                    </Tooltip>
                    <Tooltip content={`${show_in_report ? 'Può' : 'Non può'} apparire nei rapporti gara`}>
                        <ShowInReportIcon status={show_in_report} />
                    </Tooltip>
                    <Tooltip content={`${show_in_home ? 'Può' : 'Non può'} apparire in home page`}>
                        <ShowInHomeIcon status={show_in_home} />
                    </Tooltip>
                </div>
            </Table.Cell>
            <Table.Cell>
                <EditButton onClick={handleEdit} />
                <DeleteButton onClick={handleDelete} />
            </Table.Cell>
        </Table.Row>
    )
}

const RefRolesTable = ({ committee, refRoles, setAlert, setAction, setRefRole, setRefRoles, setOpenModal }) => {
    const [loading, setLoading] = useState(true);
    const [noResults, setNoResults] = useState(false);

    useEffect(() => {
        API.get(`refroles?committee=${committee}`)
            .then(res => {
                if (res.success) {
                    setRefRoles(sortByName(sortByName(res.refroles, 'title'), 'sport'));
                    setAlert({ type: '', label: '' });
                    setNoResults(false);
                    setLoading(false);
                } else {
                    setRefRoles([]);
                    setAlert({ type: 'failure', label: res.msg });
                    setLoading(false);
                }
            })
            .catch(err => console.error(err))
    }, [committee, setRefRoles, setAlert])

    useEffect(() => {
        if (refRoles.length) {
            setNoResults(false);
            setLoading(false);
        } else {
            setNoResults(true);
            setLoading(false);
        }
    }, [refRoles])

    return (
        <Table hoverable={true}>
            <Table.Head>
                <Table.HeadCell>
                    Sport
                </Table.HeadCell>
                <Table.HeadCell>
                    Titolo
                </Table.HeadCell>
                <Table.HeadCell>
                    Permessi
                </Table.HeadCell>
                <Table.HeadCell>
                    <span className="sr-only">
                        Edit
                    </span>
                </Table.HeadCell>
            </Table.Head>
            <Table.Body className="divide-y">
                {loading && <LoadingRow colspan={3} />}
                {(!loading && noResults) && <NoResultsRow colspan={3} />}
                {
                    refRoles.map((refRole, i) => <TableRow key={i} refRole={refRole} setAction={setAction} setRefRole={setRefRole} setOpenModal={setOpenModal} />)
                }
            </Table.Body>
        </Table>
    )
}

const RefRoleForm = ({ action, refRole, setAlert, setRefRole, setRefRoles, setModalAlert, setOpenModal }) => {

    const handleInput = (e) => {
        const { id, value } = e.currentTarget;
        setRefRole(prevState => ({
            ...prevState,
            [id]: value
        }));
    }

    const handleCheckbox = (e) => {
        const { id } = e.currentTarget;
        setRefRole(prevState => {
            return ({
                ...prevState,
                [id]: !prevState[id]
            })
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        switch (action) {
            case 'new':
                API.insert('refroles', JSON.stringify(refRole), true)
                    .then(res => {
                        if (res.success) {
                            setRefRoles(prevState => sortByName(sortByName([...prevState, res.refrole], 'title'), 'sport'));
                            setAlert({ type: 'success', label: 'Il ruolo è stato creato' });
                            setOpenModal(false);
                        } else {
                            setModalAlert({ type: 'failure', label: res.msg || 'Qualcosa è andato storto...' });
                        }
                    })
                    .catch(err => console.error(err));
                break;
            case 'edit':
                API.update(`refroles/${refRole._id}`, JSON.stringify(refRole), true)
                    .then(res => {
                        if (res.success) {
                            setRefRoles(prevState => {
                                const index = prevState.findIndex(el => el._id === refRole._id);
                                prevState[index] = refRole;
                                return sortByName(sortByName(prevState, 'title'), 'sport')
                            });
                            setAlert({ type: 'success', label: 'Il ruolo è stato modificato correttamente' });
                            setOpenModal(false);
                        } else {
                            setModalAlert({ type: 'failure', label: res.msg || 'Qualcosa è andato storto...' });
                        }
                    })
                    .catch(err => console.error(err));
                break;
            case 'delete':
                API.delete(`refroles/${refRole._id}`, true)
                    .then(res => {
                        if (res.success) {
                            setRefRoles(prevState => prevState.filter(el => el._id !== refRole._id));
                            setAlert({ type: 'success', label: 'Il ruolo è stato eliminato' });
                            setOpenModal(false);
                        } else {
                            setModalAlert({ type: 'failure', label: res.msg || 'Qualcosa è andato storto...' });
                        }
                    })
                    .catch(err => console.error(err));
                break;
            default:
        }
    }

    return (
        <form className="flex flex-col gap-4" onSubmit={handleSubmit}>
            <div>
                <div className="mb-2 block">
                    <FormLabel htmlFor="title" label="Titolo" />
                </div>
                <TextInput
                    id="title"
                    type="text"
                    required={true}
                    value={refRole.title}
                    onChange={handleInput}
                    disabled={action === 'delete' ? true : false}
                />
            </div>
            <div>
                <div className="mb-2 block">
                    <FormLabel htmlFor="sport" label="Sport" />
                </div>
                <Select
                    id="sport"
                    required={true}
                    onChange={handleInput}
                    value={refRole.sport}
                    disabled={action === 'delete' ? true : false}
                >
                    <option>-</option>
                    <option value="Pallavolo">Pallavolo</option>
                    <option value="Calcio">Calcio</option>
                </Select>
            </div>
            <div className="flex flex-row gap-2">
                <Checkbox
                    id="insert_results"
                    checked={refRole.insert_results}
                    onChange={handleCheckbox}
                    disabled={action === 'delete' ? true : false}
                />
                <FormLabel htmlFor="insert_results" label="Può inserire risultati" />
            </div>
            <div className="flex flex-row gap-2">
                <Checkbox
                    id="insert_report"
                    checked={refRole.insert_report}
                    onChange={handleCheckbox}
                    disabled={action === 'delete' ? true : false}
                />
                <FormLabel htmlFor="insert_report" label="Può inserire il rapporto gara" />
            </div>
            <div className="flex flex-row gap-2">
                <Checkbox
                    id="insert_docs"
                    checked={refRole.insert_docs}
                    onChange={handleCheckbox}
                    disabled={action === 'delete' ? true : false}
                />
                <FormLabel htmlFor="insert_docs" label="Può caricare documenti" />
            </div>
            <div className="flex flex-row gap-2">
                <Checkbox
                    id="insert_confirmation"
                    checked={refRole.insert_confirmation}
                    onChange={handleCheckbox}
                    disabled={action === 'delete' ? true : false}
                />
                <FormLabel htmlFor="insert_confirmation" label="Può finalizzare la gara" />
            </div>
            <div className="flex flex-row gap-2">
                <Checkbox
                    id="show_in_report"
                    checked={refRole.show_in_report}
                    onChange={handleCheckbox}
                    disabled={action === 'delete' ? true : false}
                />
                <FormLabel htmlFor="show_in_report" label="Appare nei rapporti gara" />
            </div>
            <div className="flex flex-row gap-2">
                <Checkbox
                    id="show_in_home"
                    checked={refRole.show_in_home}
                    onChange={handleCheckbox}
                    disabled={action === 'delete' ? true : false}
                />
                <FormLabel htmlFor="show_in_home" label="Appare in home page" />
            </div>
            {
                action === 'delete' &&
                <GenericAlert type="warning" divclass="text-lg mx-auto" hideicon="true">Desideri eliminare questo ruolo?</GenericAlert>
            }
            <div className="text-center">
                <SubmitButton />
            </div>
        </form>
    )
}

const RefRolesModal = ({ action, refRole, openModal, setAlert, setRefRole, setRefRoles, setOpenModal }) => {
    const [modalAlert, setModalAlert] = useState({ type: '', label: '' });

    // Azzera l'alert del modal ogni volta che viene aperto
    useEffect(() => {
        openModal === true && setModalAlert({ type: '', label: '' });
    }, [openModal]);

    return (
        <GenericModal openModal={openModal} setOpenModal={setOpenModal} title={`${action === 'new' ? 'Nuovo' : action === 'edit' ? 'Modifica' : 'Elimina'} Ruolo Arbitri`}>
            {modalAlert?.type && <GenericAlert type={modalAlert.type}>{modalAlert.label}</GenericAlert>}
            <RefRoleForm action={action} refRole={refRole} setAlert={setAlert} setRefRole={setRefRole} setRefRoles={setRefRoles} setModalAlert={setModalAlert} setOpenModal={setOpenModal} />
        </GenericModal>
    )
}

function RefRoles() {
    const { _id: committeeId } = useSelector(state => state.committee);
    const [action, setAction] = useState('new');
    const [alert, setAlert] = useState({ type: '', label: '' });
    const [refRoles, setRefRoles] = useState([]);
    const [refRole, setRefRole] = useState({ title: '', insert_results: false, insert_report: false, insert_docs: false, insert_confirmation: false, show_in_report: false, show_in_home: false, sport: '', committee: committeeId });
    const [openModal, setOpenModal] = useState(false);

    const openNewModal = () => {
        setRefRole({ title: '', insert_results: false, insert_report: false, insert_docs: false, insert_confirmation: false, show_in_report: false, show_in_home: false, sport: '', committee: committeeId });
        setAction('new');
        setOpenModal(true);
    }

    return (
        <div className="flex flex-col gap-3">
            {alert?.type && <GenericAlert type={alert.type}>{alert.label}</GenericAlert>}
            <RefRolesTable committee={committeeId} refRoles={refRoles} setAlert={setAlert} setAction={setAction} setRefRole={setRefRole} setRefRoles={setRefRoles} setOpenModal={setOpenModal} />
            <div className="w-fit">
                <Button size="sm" onClick={openNewModal}>
                    Nuovo Ruolo
                </Button>
            </div>
            <RefRolesModal action={action} refRole={refRole} openModal={openModal} setAlert={setAlert} setRefRole={setRefRole} setRefRoles={setRefRoles} setOpenModal={setOpenModal} />
        </div>
    )
}

export default RefRoles;