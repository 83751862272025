import { Modal } from "flowbite-react";

function GenericModal({ openModal, setOpenModal, title, size="lg", children }) {

    return (
        <Modal
            show={openModal}
            size={size}
            popup={true}
            onClose={() => setOpenModal(false)}
        >
            <Modal.Header>
                <div className="ml-4 my-2">
                    {title}
                </div>
            </Modal.Header>
            <Modal.Body>
                <div className="space-y-6 px-6 pb-4 sm:pb-6 lg:px-8 xl:pb-8">
                    {children}
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default GenericModal;