import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { serviceProvider as API } from "../../../API/api";

// Components
import GenericAlert from "../../elements/GenericAlert";
import GroupCalendar from "./elements/GroupCalendar";
import Rankings from "./elements/Rankings";
import { Spinner } from "flowbite-react";

// Images
import imgSoccer1 from "../../../images/home/soccer.jpg";
import imgSoccer2 from "../../../images/home/soccer_2.jpg";
import imgVolley1 from "../../../images/home/volleyball.jpg";
import imgVolley2 from "../../../images/home/volleyball_2.jpg";
import imgVolley3 from "../../../images/home/volleyball_3.jpg";
import imgVolley4 from "../../../images/home/volleyball_4.jpg";


const Title = ({ championship }) => {
    const imgArray = useMemo(() => ({
        'Calcio': [imgSoccer1, imgSoccer2],
        'Pallavolo': [imgVolley1, imgVolley2, imgVolley3, imgVolley4]
    }), []);
    const randomImage = useMemo(() => Math.floor(Math.random() * imgArray[championship.sport]?.length), [imgArray, championship]);

    return (
        <section className="px-2 py-12 md:py-18 bg-white md:px-0 shadow-lg">
            <div className="container items-center max-w-6xl px-8 mx-auto xl:px-5">
                <div className="flex flex-wrap items-center sm:-mx-3">
                    <div className="w-full md:w-1/2 hidden md:inline-block">
                        <div className="w-full h-auto overflow-hidden rounded-md shadow-xl sm:rounded-xl" data-rounded="rounded-xl" data-rounded-max="rounded-full">
                            <img
                                src={championship.sport ? imgArray[championship.sport][randomImage] : ''}
                                alt=""
                            />
                        </div>
                    </div>
                    <div className="w-full md:w-1/2 md:px-3">
                        <div className="w-full pb-6 space-y-6 sm:max-w-md lg:max-w-lg md:space-y-4 lg:space-y-8 xl:space-y-9 sm:pr-5 lg:pr-0 md:pb-0">
                            <h1 className="text-4xl font-extrabold tracking-tight text-gray-900 sm:text-5xl md:text-4xl lg:text-5xl xl:text-6xl">
                                <span className="block xl:inline font-extrabold text-transparent bg-clip-text bg-gradient-to-br from-blue-600 to-orange-600" data-primary="blue-600">{championship.name}</span>
                            </h1>
                            <p className="mx-auto text-base text-gray-500 sm:max-w-md lg:text-xl md:max-w-3xl">
                                Consulta classifica e calendario del campionato o utilizza le funzioni di esportazione
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

function ChampionshipMain() {
    const { sport: sportCode, champUrlCode } = useParams();
    const { _id: committeeId } = useSelector(state => state.committee);
    const { _id: seasonId } = useSelector(state => state.season);
    const [alert, setAlert] = useState({ type: '', label: '' });
    const [championship, setChampionship] = useState({});
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (committeeId && seasonId) {
            setLoading(true);
            const sport = `${sportCode.charAt(0).toUpperCase()}${sportCode.slice(1)}`;
            const paramString = `committee=${committeeId}&season=${seasonId}&sport=${sport}&urlcode=${champUrlCode}&page=public-championship`;
            API.get(`championships?${paramString}`)
                .then(res => {
                    if (res.success) {
                        setChampionship(res.championships[0]);
                    } else {
                        setAlert({ type: 'warning', label: res.msg || 'Riprova più tardi' });
                    }
                    setLoading(false);
                })
                .catch(err => console.error(err))
        }
    }, [champUrlCode, committeeId, seasonId, sportCode]);

    return (
        <div className="flex flex-col gap-3 cursor-default">
            {alert?.type && <GenericAlert type={alert.type}>{alert.label}</GenericAlert>}
            {!loading && <Title championship={championship} />}
            {loading &&
                <div className="bg-white p-4">
                    <Spinner color="pink" size="xl" />
                </div>
            }
            {championship.calendar?.length > 0 &&
                <>
                    {championship.sport === 'Pallavolo' &&
                        <>
                            <p className="font-extrabold p-1 underline underline-offset-4 decoration-blue-600 text-xl uppercase tracking-wide">
                                Classifiche
                            </p>
                            <Rankings championship={championship} />
                            <p className="font-extrabold p-1 underline underline-offset-4 decoration-orange-600 text-xl uppercase tracking-wide">
                                Calendari
                            </p>
                        </>
                    }
                    {championship.calendar.map(group =>
                        <GroupCalendar
                            key={group._id}
                            group={group}
                            teams={championship.teams.filter(g => g.group_name === group.group_name)[0].teams}
                            sport={championship.sport}
                            title={championship.name}
                        />
                    )}
                </>
            }
        </div>
    )
}

export default ChampionshipMain;