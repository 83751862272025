import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { serviceProvider as API } from "../../../API/api";
import { sortByName } from "../../../lib/utils";
import { daysOTW } from "../../../lib/configs";

// Components
import { Button, Select, Table, TextInput, Tooltip } from "flowbite-react";
import DeleteButton from "../../buttons/DeleteButton";
import EditButton from "../../buttons/EditButton";
import FormLabel from "../../elements/FormLabel";
import GenericAlert from "../../elements/GenericAlert";
import GenericModal from "../../elements/GenericModal";
import LoadingRow from "../../elements/LoadingRow";
import NoResultsRow from "../../elements/NoResultsRow";
import SubmitButton from "../../buttons/SubmitButton";


const TableRow = ({ championships, team, setAction, setTeam, setOpenModal }) => {
    const { _id, name, club, championships: teamChampionships, committee, game_day, alt_day, game_time, alt_time, game_field, referent, referent_email, referent_phone, season } = team;
    const { _id: committeeId } = useSelector(state => state.committee);
    const { _id: currentSeason } = useSelector(state => state.season);
    const assignedToGroup = useCallback(() => championships.some(c => c.teams.some(g => g.teams.some(t => t._id === team._id))), [championships, team]);

    const rowTeam = {
        _id: _id,
        name: name || '',
        club: club._id || '',
        championships: teamChampionships || [],
        committee: committee || committeeId,
        game_day: game_day || 0,
        alt_day: alt_day || 0,
        game_time: game_time || '12:00',
        alt_time: alt_time || '10:00',
        game_field: game_field._id || '',
        referent: referent || '',
        referent_email: referent_email || '',
        referent_phone: referent_phone || '',
        season: season || currentSeason
    }

    const handleEdit = () => {
        setAction('edit');
        setTeam(rowTeam);
        setOpenModal(true);
    }

    const handleDelete = () => {
        setAction('delete');
        setTeam(rowTeam);
        setOpenModal(true);
    }

    return (
        <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
            <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                {team.club.name}
            </Table.Cell>
            <Table.Cell>
                {team.name}
            </Table.Cell>
            <Table.Cell>
                {
                    <ul>
                        {
                            team.championships.map(championship => <li key={championship._id}>{championship.name}</li>)
                        }
                    </ul>
                }
            </Table.Cell>
            <Table.Cell className="flex flex-row">
                <Tooltip content="Modifica la squadra">
                    <EditButton onClick={handleEdit} />
                </Tooltip>
                <Tooltip content={assignedToGroup() ? 'La squadra è assegnata a un girone' : 'Elimina la squadra'}>
                    <DeleteButton
                        onClick={handleDelete}
                        {...assignedToGroup() && { disabled: true }}
                    />
                </Tooltip>
            </Table.Cell>
        </Table.Row>
    )
}

const MainTable = ({ championships, loading, noResults, teams, setAction, setTeam, setOpenModal }) => {

    return (
        <Table hoverable={true}>
            <Table.Head>
                <Table.HeadCell>
                    Società
                </Table.HeadCell>
                <Table.HeadCell>
                    Nome Squadra
                </Table.HeadCell>
                <Table.HeadCell>
                    Campionati
                </Table.HeadCell>
                <Table.HeadCell>
                    <span className="sr-only">
                        Edit
                    </span>
                </Table.HeadCell>
            </Table.Head>
            <Table.Body className="divide-y">
                {loading && <LoadingRow colspan={4} />}
                {(!loading && noResults) && <NoResultsRow colspan={4} />}
                {
                    teams.map((team, i) => <TableRow key={i} championships={championships} team={team} setAction={setAction} setTeam={setTeam} setOpenModal={setOpenModal} />)
                }
            </Table.Body>
        </Table>
    )
}

const MainForm = ({ action, championships, clubs, fields, team, setAlert, setTeam, setTeams, setModalAlert, setOpenModal }) => {

    const handleInput = (e) => {
        const { id, selectedOptions, value } = e.currentTarget;
        setTeam(prevState => ({
            ...prevState,
            [id]: selectedOptions && id === 'championships'
                ? Array.from(selectedOptions).map(opt => championships.find(c => c._id === opt.value))
                : value
        }));
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        switch (action) {
            case 'new':
                API.insert('teams', JSON.stringify(team), true)
                    .then(res => {
                        if (res.success) {
                            setTeams(prevState => sortByName([...prevState, res.team], 'name'));
                            setAlert({ type: 'success', label: 'La squadra è stata inserita' });
                            setOpenModal(false);
                        } else {
                            setModalAlert({ type: 'failure', label: res.msg || 'Qualcosa è andato storto...' });
                        }
                    })
                    .catch(err => console.error(err));
                break;
            case 'edit':
                API.update(`teams/${team._id}`, JSON.stringify(team), true)
                    .then(res => {
                        if (res.success) {
                            setTeams(prevState => {
                                const index = prevState.findIndex(el => el._id === team._id);
                                prevState[index] = res.team;
                                return sortByName(sortByName(prevState, 'name'), 'club.name');
                            });
                            setAlert({ type: 'success', label: 'La squadra è stata modificata correttamente' });
                            setOpenModal(false);
                        } else {
                            setModalAlert({ type: 'failure', label: res.msg || 'Qualcosa è andato storto...' });
                        }
                    })
                    .catch(err => console.error(err));
                break;
            case 'delete':
                API.delete(`teams/${team._id}`, true)
                    .then(res => {
                        if (res.success) {
                            setTeams(prevState => prevState.filter(el => el._id !== team._id));
                            setAlert({ type: 'success', label: 'La squadra è stata eliminata' });
                            setOpenModal(false);
                        } else {
                            setModalAlert({ type: 'failure', label: res.msg || 'Qualcosa è andato storto...' });
                        }
                    })
                    .catch(err => console.error(err));
                break;
            default:
        }
    }

    return (
        <form className="flex flex-col gap-4" onSubmit={handleSubmit}>
            <div className="flex flex-row gap-2">
                <div className="basis-1/2">
                    <div className="mb-2 block">
                        <FormLabel htmlFor="name" label="Nome" />
                    </div>
                    <TextInput
                        id="name"
                        type="text"
                        required={true}
                        value={team.name}
                        onChange={handleInput}
                        disabled={action === 'delete' ? true : false}
                    />
                </div>
                <div className="basis-1/2">
                    <div className="mb-2 block">
                        <FormLabel htmlFor="club" label="Società" />
                    </div>
                    <Select
                        id="club"
                        required={true}
                        onChange={handleInput}
                        value={team.club}
                        disabled={action === 'delete' ? true : false}
                    >
                        <option>-</option>
                        {
                            clubs.map(club =>
                                <option
                                    key={club._id}
                                    value={club._id}
                                >
                                    {club.name}
                                </option>
                            )
                        }
                    </Select>
                </div>
            </div>
            <div>
                <div className="mb-2 block">
                    <FormLabel htmlFor="championships" label="Campionato" />
                </div>
                <Select
                    id="championships"
                    multiple
                    required={true}
                    onChange={handleInput}
                    value={team.championships.map(c => c._id)}
                    disabled={action === 'delete' ? true : false}
                >
                    {
                        championships.map(championship =>
                            <option
                                key={championship._id}
                                value={championship._id}
                            >
                                {championship.name}
                            </option>
                        )
                    }
                </Select>
            </div>
            <div className="flex flex-row gap-2">
                <div className="basis-1/2">
                    <div className="mb-2 block">
                        <FormLabel htmlFor="game_day" label="Giorno di gara" />
                    </div>
                    <Select
                        id="game_day"
                        required={true}
                        onChange={handleInput}
                        value={team.game_day}
                        disabled={action === 'delete' ? true : false}
                    >
                        {
                            daysOTW.map(dayOTW =>
                                <option
                                    key={`dayOTW_${dayOTW.value}`}
                                    value={dayOTW.value}
                                >
                                    {dayOTW.day}
                                </option>
                            )
                        }
                    </Select>
                </div>
                <div className="basis-1/2">
                    <div className="mb-2 block">
                        <FormLabel htmlFor="game_time" label="Ora di gara" />
                    </div>
                    <TextInput
                        id="game_time"
                        type="time"
                        required={true}
                        value={team.game_time}
                        onChange={handleInput}
                        disabled={action === 'delete' ? true : false}
                    />
                </div>
            </div>
            <div className="flex flex-row gap-2">
                <div className="basis-1/2">
                    <div className="mb-2 block">
                        <FormLabel htmlFor="alt_day" label="Giorno alternativo" />
                    </div>
                    <Select
                        id="alt_day"
                        required={true}
                        onChange={handleInput}
                        value={team.alt_day}
                        disabled={action === 'delete' ? true : false}
                    >
                        {
                            daysOTW.map(dayOTW =>
                                <option
                                    key={`dayOTW_${dayOTW.value}`}
                                    value={dayOTW.value}
                                >
                                    {dayOTW.day}
                                </option>
                            )
                        }
                    </Select>
                </div>
                <div className="basis-1/2">
                    <div className="mb-2 block">
                        <FormLabel htmlFor="alt_time" label="Ora di gara" />
                    </div>
                    <TextInput
                        id="alt_time"
                        type="time"
                        required={true}
                        value={team.alt_time}
                        onChange={handleInput}
                        disabled={action === 'delete' ? true : false}
                    />
                </div>
            </div>
            <div className="flex flex-row gap-2">
                <div className="basis-1/2">
                    <div className="mb-2 block">
                        <FormLabel htmlFor="game_field" label="Campo di gioco" />
                    </div>
                    <Select
                        id="game_field"
                        required={true}
                        onChange={handleInput}
                        value={team.game_field}
                        disabled={action === 'delete' ? true : false}
                    >
                        <option value="">-</option>
                        {
                            fields.map(field =>
                                <option
                                    key={field._id}
                                    value={field._id}
                                >
                                    {`${field.city} - ${field.name}`}
                                </option>
                            )
                        }
                    </Select>
                </div>
                <div className="basis-1/2">
                    <div className="mb-2 block">
                        <FormLabel htmlFor="referent" label="Referente squadra" />
                    </div>
                    <TextInput
                        id="referent"
                        type="text"
                        value={team.referent}
                        onChange={handleInput}
                        placeholder="Nome"
                        disabled={action === 'delete' ? true : false}
                    />
                </div>
            </div>
            <div className="flex flex-row gap-2">
                <div className="basis-1/2">
                    <div className="mb-2 block">
                        <FormLabel htmlFor="referent_phone" label="Telefono referente" />
                    </div>
                    <TextInput
                        id="referent_phone"
                        type="text"
                        value={team.referent_phone}
                        onChange={handleInput}
                        disabled={action === 'delete' ? true : false}
                    />
                </div>
                <div className="basis-1/2">
                    <div className="mb-2 block">
                        <FormLabel htmlFor="referent_email" label="Email referente" />
                    </div>
                    <TextInput
                        id="referent_email"
                        type="email"
                        value={team.referent_email}
                        onChange={handleInput}
                        disabled={action === 'delete' ? true : false}
                    />
                </div>
            </div>
            {
                action === 'delete' &&
                <GenericAlert type="warning" divclass="text-lg mx-auto" hideicon="true">Desideri eliminare questa squadra?</GenericAlert>
            }
            <div className="text-center">
                <SubmitButton />
            </div>
        </form>
    )
}

const MainModal = ({ action, championships, clubs, fields, team, openModal, setAlert, setTeam, setTeams, setOpenModal }) => {
    const [modalAlert, setModalAlert] = useState({ type: '', label: '' });

    // Azzera l'alert del modal ogni volta che viene aperto
    useEffect(() => {
        openModal === true && setModalAlert({ type: '', label: '' });
    }, [openModal]);

    return (
        <GenericModal
            openModal={openModal}
            setOpenModal={setOpenModal}
            size="2xl"
            title={`${action === 'new' ? 'Nuova' : action === 'edit' ? 'Modifica' : 'Elimina'} Squadra`}
        >
            {modalAlert?.type && <GenericAlert type={modalAlert.type}>{modalAlert.label}</GenericAlert>}
            <MainForm action={action} championships={championships} clubs={clubs} fields={fields} team={team} setAlert={setAlert} setTeam={setTeam} setTeams={setTeams} setModalAlert={setModalAlert} setOpenModal={setOpenModal} />
        </GenericModal>
    )
}

function Teams() {
    const { _id: currentSeason } = useSelector(state => state.season);
    const { _id: committeeId } = useSelector(state => state.committee);
    const [action, setAction] = useState('new');
    const [alert, setAlert] = useState({ type: '', label: '' });
    const [championships, setChampionships] = useState([]);
    const [clubs, setClubs] = useState([]);
    const [fields, setFields] = useState([]);
    const [loading, setLoading] = useState(true);
    const [noResults, setNoResults] = useState(false);
    const [teams, setTeams] = useState([]);
    const [team, setTeam] = useState({ name: '', club: '', championships: [], committee: committeeId, game_day: 0, alt_day: 0, game_time: '12:00', alt_time: '10:00', game_field: '', referent: '', referent_email: '', referent_phone: '', season: currentSeason });
    const [openModal, setOpenModal] = useState(false);

    const openCommModal = () => {
        setTeam({ name: '', club: '', championships: [], committee: committeeId, game_day: 0, alt_day: 0, game_time: '12:00', alt_time: '10:00', game_field: '', referent: '', referent_phone: '', season: currentSeason });
        setAction('new');
        setOpenModal(true);
    }

    useEffect(() => {
        if (currentSeason && committeeId) {
            API.get(`championships?season=${currentSeason}&committee=${committeeId}`)
                .then(res => {
                    if (res.success) {
                        setChampionships(sortByName(res.championships, 'name'));
                        setAlert({ type: '', label: '' });
                    } else {
                        setChampionships([]);
                        setAlert({ type: 'warning', label: 'Nessun campionato trovato' })
                    }
                })
        }
    }, [committeeId, currentSeason])

    useEffect(() => {
        if (committeeId) {
            API.get(`clubs?committees=${committeeId}`)
                .then(res => {
                    if (res.success) {
                        setClubs(sortByName(res.clubs, 'name'));
                        setAlert({ type: '', label: '' });
                    } else {
                        setClubs([]);
                        setAlert({ type: 'warning', label: 'Nessuna società trovata' });
                    }
                })
        }
    }, [committeeId])

    useEffect(() => {
        if (committeeId) {
            API.get(`fields?committee=${committeeId}`)
                .then(res => {
                    if (res.success) {
                        setFields(sortByName(sortByName(res.fields, 'name'), 'city'));
                        setAlert({ type: '', label: '' });
                    } else {
                        setFields([]);
                        setAlert({ type: 'warning', label: 'Nessun campo trovato' });
                    }
                })
        }
    }, [committeeId])

    useEffect(() => {
        if (committeeId) {
            API.get(`teams?season=${currentSeason}&committee=${committeeId}`)
                .then(res => {
                    if (res.success) {
                        setTeams(sortByName(sortByName(res.teams, 'name'), 'club.name'));
                        setAlert({ type: '', label: '' });
                        setNoResults(false);
                    } else {
                        setTeams([]);
                        setAlert({ type: 'failure', label: res.msg });
                    }
                    setLoading(false);
                })
                .catch(err => console.error(err))
        }
    }, [championships, committeeId, currentSeason])

    return (
        <div className="flex flex-col gap-3">
            {alert?.type && <GenericAlert type={alert.type}>{alert.label}</GenericAlert>}
            <MainTable
                championships={championships}
                loading={loading}
                noResults={noResults}
                teams={teams}
                setAction={setAction}
                setTeam={setTeam}
                setOpenModal={setOpenModal}
            />
            <div className="w-fit">
                <Button size="sm" onClick={openCommModal}>
                    Nuova Squadra
                </Button>
            </div>
            <MainModal
                action={action}
                championships={championships}
                clubs={clubs}
                fields={fields}
                team={team}
                openModal={openModal}
                setAlert={setAlert}
                setTeam={setTeam}
                setTeams={setTeams}
                setOpenModal={setOpenModal}
            />
        </div>
    )
}

export default Teams;