import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    _id: undefined,
    name: '',
    startDate: '',
    endDate: ''
};

export const seasonSlice = createSlice({
    name: 'season',
    initialState,
    reducers: {
        assign: (state, action) => action.payload
    }
});

export const { assign } = seasonSlice.actions;

export default seasonSlice.reducer;