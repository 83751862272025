import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Outlet, Routes, Route } from "react-router-dom";
import './index.css';
import reportWebVitals from './reportWebVitals';
import { store } from './store';
import { Provider } from 'react-redux';

// Auth Component
import RequireRole from './components/auth/RequireRole';

// Components
import App from './App';
import Assignments from './components/restricted/assignments/Assignments';
import Availability from './components/restricted/areaudg/Availability';
import AvailReport from './components/restricted/assignments/AvailReport';
import Bulletins from './components/restricted/matches/Bulletins';
import Championship from './components/restricted/matches/championships/Championship';
import ChampionshipMain from './components/public/championship/ChampionshipMain';
import Championships from './components/restricted/matches/Championships';
import Clubs from './components/restricted/matches/Clubs';
import Committees from './components/restricted/superadmin/Committees';
import Distances from './components/restricted/configurations/Distances';
import DocTypes from './components/restricted/configurations/DocTypes';
import EditProfile from './components/restricted/user/EditProfile';
import ExportAssignments from './components/restricted/admin/ExportAssignments';
import Fields from './components/restricted/matches/Fields';
import FieldsMain from './components/public/index/fields/FieldsMain';
import Home from './components/public/index/Home';
import Index from './components/public/Index';
import OmologMain from './components/restricted/judges/OmologMain';
import Overlapses from './components/restricted/matches/Overlapses';
import Payments from './components/restricted/admin/Payments';
import PostponedMatches from './components/restricted/matches/PostponedMatches';
import PreviousMatches from './components/restricted/areaudg/PreviousMatches';
import RefereesReport from './components/restricted/assignments/RefereesReport';
import RefRoles from './components/restricted/configurations/RefRoles';
import Refunds from './components/restricted/admin/Refunds';
import ReportModel from './components/restricted/superadmin/reportmodel/ReportModel';
import ReportModels from './components/restricted/superadmin/ReportModels';
import Teams from './components/restricted/matches/Teams';
import UserHome from './components/restricted/UserHome';
import Users from './components/restricted/admin/Users';
import Seasons from './components/restricted/superadmin/Seasons';
import Unavailabilities from './components/restricted/areaudg/Unavailabilities';
import UserMain from './components/restricted/home/UserMain';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  //<React.StrictMode>

  <Provider store={store}>
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Index />} />
        <Route path='/:committeeUrlCode' element={<App />}>
          <Route index element={<Home />} />
          <Route path='campionati/:sport/:champUrlCode' element={<ChampionshipMain />} />
          <Route path='campi-di-gioco' element={<FieldsMain />} />
          <Route path='riservata' element={<RequireRole role={5}><UserHome /></RequireRole>}>
            <Route index element={<UserMain />} />
            { /* SUPER ADMIN */}
            <Route path='comitati' element={<RequireRole role={0}><Committees /></RequireRole>} />
            <Route path='stagioni' element={<RequireRole role={0}><Seasons /></RequireRole>} />
            <Route path='modelli-rapporto-gara' element={<RequireRole role={0}><Outlet /></RequireRole>}>
              <Route index element={<ReportModels />} />
              <Route path=':modelId' element={<ReportModel />} />
            </Route>
            { /* CONFIGURAZIONI */}
            <Route path='tipi-di-documenti' element={<RequireRole role={1}><DocTypes /></RequireRole>} />
            <Route path='ruoli-arbitri' element={<RequireRole role={1}><RefRoles /></RequireRole>} />
            <Route path='distanze-km' element={<RequireRole role={1}><Distances /></RequireRole>} />
            { /* ADMIN */}
            <Route path='utenti' element={<RequireRole role={1}><Users /></RequireRole>} />
            <Route path='rimborsi' element={<RequireRole role={1}><Refunds /></RequireRole>} />
            <Route path='pagamenti' element={<RequireRole role={1}><Payments /></RequireRole>} />
            <Route path='esporta-designazioni' element={<RequireRole role={1}><ExportAssignments /></RequireRole>} />
            { /* COMMISSIONE GARE */}
            <Route path='campi-di-gioco' element={<RequireRole role={2}><Fields /></RequireRole>} />
            <Route path='societa' element={<RequireRole role={2}><Clubs /></RequireRole>} />
            <Route path='campionati' element={<RequireRole role={2}><Outlet /></RequireRole>}>
              <Route index element={<Championships />} />
              <Route path=':champId' element={<Championship />} />
            </Route>
            <Route path='squadre' element={<RequireRole role={2}><Teams /></RequireRole>} />
            <Route path='sovrapposizioni' element={<RequireRole role={2}><Overlapses /></RequireRole>} />
            <Route path='gare-rinviate' element={<RequireRole role={2}><PostponedMatches /></RequireRole>} />
            <Route path='comunicati' element={<RequireRole role={2}><Bulletins /></RequireRole>} />
            { /* COMMISSIONE GIUDICANTE */}
            <Route path='omologazione-gare' element={<RequireRole role={3}><OmologMain /></RequireRole>} />
            { /* COMMISSIONE DESIGNANTE */}
            <Route path='designazioni' element={<RequireRole role={4} exclusive={true}><Assignments /></RequireRole>} />
            <Route path='report-arbitri' element={<RequireRole role={4}><RefereesReport /></RequireRole>} />
            <Route path='report-disponibilita' element={<RequireRole role={4}><AvailReport /></RequireRole>} />
            { /* AREA UDG */}
            <Route path='disponibilita' element={<Availability />} />
            <Route path='gare-precedenti' element={<PreviousMatches />} />
            <Route path='indisponibilita' element={<Unavailabilities />} />
            { /* AREA UTENTE */ }
            <Route path='modifica-profilo' element={<EditProfile />} />
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  </Provider>

  //</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
