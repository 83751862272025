import { useCallback, useEffect, useState } from "react";
import { serviceProvider as API } from "../../../../../API/api";
import { abbrText, formatDateTime } from "../../../../../lib/utils";

// Components
import { Button, Table } from "flowbite-react";
import GenericAlert from "../../../../elements/GenericAlert";
import GenericModal from "../../../../elements/GenericModal";


const InvertionModal = ({ championship, invert, openModal, pointers, setAlert, setChampionship, setOpenModal }) => {
    const hasResults = useCallback(() => invert.originalMatches.some(m => (m.result?.a + m.result?.b) > 0), [invert.originalMatches]);
    const [modalAlert, setModalAlert] = useState({ type: '', label: '' });

    // Azzera l'alert del modal ogni volta che viene aperto
    useEffect(() => {
        openModal === true && setModalAlert({ type: '', label: '' });
    }, [openModal]);

    const onClick = () => {
        invert.newMatches?.forEach(m => {
            // Cerco l'indice della giornata
            const roundIndex = championship.calendar[pointers.groupIndex].rounds
                .findIndex(r => r.matches
                    .some(match => match._id === m._id));
            const updBody = {
                date: m.date,
                teamA: m.teamA,
                teamB: m.teamB,
                field: m.field._id
            }
            API.update(`matches/${m._id}`, JSON.stringify(updBody), true)
                .then(res => {
                    if (res.success) {
                        setChampionship(prevState => {
                            const index = prevState.calendar[pointers.groupIndex].rounds[roundIndex].matches
                                .findIndex(el => el._id === m._id);
                            prevState.calendar[pointers.groupIndex].rounds[roundIndex].matches[index] = res.match;
                            return prevState;
                        });
                        setAlert({ type: 'success', label: 'L\'inversione è stata effettuata correttamente' });
                        setOpenModal(false);
                    } else {
                        setModalAlert({ type: 'failure', label: res.msg || 'Qualcosa è andato storto...' });
                    }
                })
                .catch(err => console.error(err));
        })
    }

    return (
        <GenericModal
            openModal={openModal}
            setOpenModal={setOpenModal}
            size="3xl"
            title="Inverti Gare"
        >
            {modalAlert?.type && <GenericAlert type={modalAlert.type}>{modalAlert.label}</GenericAlert>}
            {
                invert.originalMatches?.length < 2 &&
                <GenericAlert type="warning" divclass="text-lg mx-auto" hideicon="true">Non è stata trovata una gara di ritorno.</GenericAlert>
            }
            {
                invert.originalMatches?.length > 1 && hasResults() &&
                <GenericAlert type="warning" divclass="text-lg mx-auto" hideicon="true">Le gare hanno dei risultati impostati.</GenericAlert>
            }
            {
                invert.originalMatches?.length > 1 && !hasResults() &&
                <>
                    <div>
                        <p className="uppercase text-sm font-bold text-center">Le seguenti gare:</p>
                        <div className="p-2 bg-amber-300 rounded-lg shadow">
                            <Table striped={true}>
                                <Table.Head>
                                    <Table.HeadCell>
                                        #
                                    </Table.HeadCell>
                                    <Table.HeadCell>
                                        Data
                                    </Table.HeadCell>
                                    <Table.HeadCell>
                                        Squadre
                                    </Table.HeadCell>
                                    <Table.HeadCell>
                                        Campo
                                    </Table.HeadCell>
                                </Table.Head>
                                <Table.Body className="divide-y">
                                    {
                                        invert.originalMatches?.map(m =>
                                            <Table.Row key={`om_${m._id}`} className="bg-white dark:border-gray-700 dark:bg-gray-800">
                                                <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                                                    {m.num}
                                                </Table.Cell>
                                                <Table.Cell>
                                                    <span className="hidden lg:block">{formatDateTime(m.date, true)}</span>
                                                    <span className="lg:hidden">{formatDateTime(m.date)}</span>
                                                </Table.Cell>
                                                <Table.Cell>
                                                    {m.teamA.name} - {m.teamB.name}
                                                </Table.Cell>
                                                <Table.Cell>
                                                    <span className="hidden lg:block">{m.field.city} - {m.field.name}</span>
                                                    <span className="lg:hidden">{abbrText(m.field.city, 8)} - {abbrText(m.field.name, 18)}</span>
                                                </Table.Cell>
                                            </Table.Row>
                                        )
                                    }
                                </Table.Body>
                            </Table>
                        </div>
                    </div>
                    <div>
                        <p className="uppercase text-sm font-bold text-center">Saranno modificate come segue:</p>
                        <div className="p-2 bg-green-300 rounded-lg shadow">
                            <Table striped={true}>
                                <Table.Head>
                                    <Table.HeadCell>
                                        #
                                    </Table.HeadCell>
                                    <Table.HeadCell>
                                        Data
                                    </Table.HeadCell>
                                    <Table.HeadCell>
                                        Squadre
                                    </Table.HeadCell>
                                    <Table.HeadCell>
                                        Campo
                                    </Table.HeadCell>
                                </Table.Head>
                                <Table.Body className="divide-y">
                                    {
                                        invert.newMatches?.map(m =>
                                            <Table.Row key={`nm_${m._id}`} className="bg-white dark:border-gray-700 dark:bg-gray-800">
                                                <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                                                    {m.num}
                                                </Table.Cell>
                                                <Table.Cell>
                                                    <span className="hidden lg:block">{formatDateTime(m.date, true)}</span>
                                                    <span className="lg:hidden">{formatDateTime(m.date)}</span>
                                                </Table.Cell>
                                                <Table.Cell>
                                                    {m.teamA.name} - {m.teamB.name}
                                                </Table.Cell>
                                                <Table.Cell>
                                                    <span className="hidden lg:block">{m.field.city} - {m.field.name}</span>
                                                    <span className="lg:hidden">{abbrText(m.field.city, 8)} - {abbrText(m.field.name, 18)}</span>
                                                </Table.Cell>
                                            </Table.Row>
                                        )
                                    }
                                </Table.Body>
                            </Table>
                        </div>
                    </div>
                </>
            }
            <div className="flex justify-center gap-4">
                {
                    invert.originalMatches?.length > 1 && !hasResults() &&
                    <Button
                        color="purple"
                        onClick={onClick}
                    >
                        Sì, conferma
                    </Button>}
                <Button
                    color="dark"
                    onClick={() => setOpenModal(false)}
                >
                    {invert.originalMatches?.length > 1 && !hasResults() ? 'No, annulla' : 'Torna indietro'}
                </Button>
            </div>
        </GenericModal>
    )
}

export default InvertionModal;