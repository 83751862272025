import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { serviceProvider as API } from "../../../../API/api";
import { formatDateTime } from "../../../../lib/utils";

// Components
import { Select, TextInput } from "flowbite-react";
import FormLabel from "../../../elements/FormLabel";
import GenericAlert from "../../../elements/GenericAlert";
import GenericModal from "../../../elements/GenericModal";
import SubmitButton from "../../../buttons/SubmitButton";


const MainForm = ({ action, assignment, checkPresent, match, setAlert, setAssignment, setMatches, setModalAlert, setOpenModal }) => {
    const [loading, setLoading] = useState(false);
    const user = useSelector(state => state.user);

    const handleSelect = (e) => {
        const { value } = e.currentTarget;
        setAssignment(prevState => ({
            ...prevState,
            status: Number(value),
            reason: Number(value) > 0
                ? ''
                : prevState.reason
        }));
    }

    const handleInput = (e) => {
        const { id, value } = e.currentTarget;
        setAssignment(prevState => ({
            ...prevState,
            [id]: value
        }));
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        switch (action) {
            case 'edit':
                const updateBody = {
                    ...assignment,
                    updated_at: new Date().toISOString(),
                    updated_by: { _id: user._id, lastname: user.lastname, firstname: user.firstname }
                }
                API.update(`assignments/${assignment._id}`, JSON.stringify(updateBody), true)
                    .then(res => {
                        if (res.success) {
                            setMatches(prevState => {
                                const array = [...prevState];
                                const matchIndex = prevState.findIndex(m => m._id === match._id);
                                array[matchIndex].assignments = [
                                    ...array[matchIndex].assignments.filter(a => a._id !== res.assignment._id), 
                                    res.assignment
                                ];
                                return array;
                            });
                            setAlert({ type: 'success', label: 'La designazione è stata modificata' });
                            setOpenModal(false);
                        } else {
                            setModalAlert({ type: 'failure', label: res.msg || 'Qualcosa è andato storto...' });
                        }
                        setLoading(false);
                    })
                    .catch(err => console.error(err));
                break;
            case 'delete':
                const updMatch = {
                    assignments: match.assignments.filter(a => a._id !== assignment._id).map(a => ({_id: a._id}))
                }
                API.update(`matches/${match._id}`, JSON.stringify(updMatch), true)
                    .then(res => {
                        if (res.success) {
                            setMatches(prevState => {
                                const array = [...prevState];
                                const matchIndex = prevState.findIndex(m => m._id === match._id);
                                array[matchIndex].assignments = array[matchIndex].assignments.filter(a => a._id !== assignment._id);
                                return array;
                            });
                            API.delete(`assignments/${assignment._id}`, true)
                                .then(res => {
                                    if (res.success) {
                                        setAlert({ type: 'success', label: 'La designazione è stata modificata' });
                                        setOpenModal(false);
                                    } else {
                                        setModalAlert({ type: 'failure', label: res.msg || 'Qualcosa è andato storto...' });
                                    }
                                })
                                setLoading(false);
                        } else {
                            setModalAlert({ type: 'failure', label: res.msg || 'Qualcosa è andato storto...' });
                            setLoading(false);
                        }
                    })
                    .catch(err => console.error(err));
                break;
            default:
        }
    }

    return (
        <form className="flex flex-col gap-4" onSubmit={handleSubmit}>
            <div>
                <div className="mb-2 block">
                    <FormLabel htmlFor="status" label="Modifica status" />
                </div>
                <Select
                    id="status"
                    required={true}
                    value={assignment.status}
                    onChange={handleSelect}
                    disabled={action === 'delete' || checkPresent ? true : false}
                >
                    <option value={1}>Proposta</option>
                    <option value={2}>Notificata</option>
                    <option value={3}>Accettata</option>
                    <option value={0}>Rifiutata</option>
                </Select>
            </div>
            {assignment.status === 0 &&
                <div>
                    <div className="mb-2 block">
                        <FormLabel htmlFor="reason" label="Motivazione rifiuto" />
                    </div>
                    <TextInput
                        id="reason"
                        type="text"
                        value={assignment.reason}
                        onChange={handleInput}
                        disabled={action === 'delete' || checkPresent ? true : false}
                    />
                </div>
            }
            {
                action === 'delete' &&
                <GenericAlert type="warning" divclass="text-lg mx-auto" hideicon="true">Desideri eliminare questa designazione?</GenericAlert>
            }
            <div className="text-center">
                <SubmitButton disabled={loading ? true : checkPresent} />
            </div>
        </form>
    )
}

function EditAssignmentModal({ action, assignment, match, openModal, setAlert, setAssignment, setMatches, setOpenModal }) {
    const [checkPresent, setCheckPresent] = useState(false);
    const [modalAlert, setModalAlert] = useState({ type: '', label: '' });

    // Azzera l'alert del modal ogni volta che viene aperto
    useEffect(() => {
        openModal === true && setModalAlert({ type: '', label: '' });
    }, [openModal]);

    useEffect(() => {
        if (match.assignments?.length > 0) {
            const findAss = match.assignments.find(a => a.role === assignment.role && a.status > 0);
            if (findAss && findAss._id !== assignment._id) {
                setCheckPresent(true);
                setModalAlert({ type: 'warning', label: 'Prima di modificare questa designazione devi eliminare quella attiva' });
            } else {
                setCheckPresent(false);
            }
        } else {
            setCheckPresent(false);
        }
    }, [assignment, match.assignments]);

    return (
        <GenericModal openModal={openModal} setOpenModal={setOpenModal} title={`${action === 'edit' ? 'Modifica' : 'Elimina'} Designazione`}>
            {modalAlert?.type && <GenericAlert type={modalAlert.type}>{modalAlert.label}</GenericAlert>}
            <ul className="space-y-1 max-w-md list-none list-inside p-1 cursor-default border bg-gray-50 shadow-lg border-red-400 rounded-lg text-center text-gray-700 dark:text-gray-400">
                <li>
                    <span className="font-semibold text-sm uppercase">{match.championship?.name}</span> <span className="font-semibold">({match.teamA?.name} - {match.teamB?.name})</span>
                </li>
                <li>
                    <span className="font-semibold">Data:</span> <span className="capitalize">{formatDateTime(match.date, true)}</span>
                </li>
                <li>
                    <span className="font-semibold">Campo:</span> {match.field?.city} - {match.field?.name}
                </li>
            </ul>
            <ul className="space-y-1 max-w-md list-none list-inside p-1 cursor-default border bg-gray-50 shadow-lg border-amber-400 rounded-lg text-center text-gray-700 dark:text-gray-400">
                <li>
                    <span className="font-semibold">{assignment.role}:</span> {assignment.referee?.lastname} {assignment.referee?.firstname}
                </li>
                <li>
                    <span className="font-semibold">Designato/a il:</span> {formatDateTime(assignment.inserted_at)}
                </li>
                {
                    assignment.updated_at &&
                    <>
                        <li>
                            <span className="font-semibold">Ultimo aggiornamento:</span> {formatDateTime(assignment.updated_at)}
                        </li>
                        <li>
                            <span className="font-semibold">Autore aggiornamento:</span> {assignment.updated_by.lastname} {assignment.updated_by.firstname}
                        </li>
                    </>
                }
            </ul>
            <MainForm
                action={action}
                assignment={assignment}
                checkPresent={checkPresent}
                match={match}
                setAlert={setAlert}
                setAssignment={setAssignment}
                setMatches={setMatches}
                setModalAlert={setModalAlert}
                setOpenModal={setOpenModal}
            />
        </GenericModal>
    )
}

export default EditAssignmentModal;