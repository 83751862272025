import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { serviceProvider as API } from "../../../API/api";
import { formToIsoString, isoToForm, newDate, sortByDate, sortByName } from "../../../lib/utils";

// Components
import { Button, Select, Spinner, TextInput } from "flowbite-react";
import FormLabel from "../../elements/FormLabel";
import GenericAlert from "../../elements/GenericAlert";
import ImgModal from "../home/elements/ImgModal";
import JudgeModal from "./elements/JudgeModal";
import ReportModal from "../home/elements/ReportModal";
import ResultsModal from "../home/elements/ResultsModal";
import MatchCard from "./elements/MatchCard";


const FiltersBar = ({ championships, filters, setFilters }) => {

    const handleInput = (e) => {
        const { id, value } = e.currentTarget;
        switch (id) {
            case "days":
                setFilters(prevState => ({ ...prevState, days: value, startDate: '', endDate: '' }));
                break;
            case "startDate":
                setFilters(prevState => ({ ...prevState, days: '', startDate: formToIsoString(`${value}T00:00`) }));
                break;
            case "endDate":
                setFilters(prevState => ({ ...prevState, days: '', endDate: formToIsoString(`${value}T23:59`) }));
                break;
            default:
                setFilters(prevState => ({ ...prevState, [id]: value }));
        }
    }

    const resetFilters = () => {
        setFilters({ days: '10', startDate: '', endDate: newDate(), championship: '', sport: '' });
    }

    return (
        <div className="flex flex-row flex-wrap gap-8 items-end">
            <div>
                <div className="mb-2 block">
                    <FormLabel htmlFor="days" label="Giorni trascorsi" />
                </div>
                <Select
                    id="days"
                    value={filters.days}
                    onChange={handleInput}
                >
                    <option value="">-</option>
                    <option value="3">3</option>
                    <option value="5">5</option>
                    <option value="7">7</option>
                    <option value="10">10</option>
                    <option value="14">14</option>
                    <option value="30">30</option>
                </Select>
            </div>
            <div>
                <div className="mb-2 block">
                    <FormLabel label="Da / A" />
                </div>
                <div className="flex flex-row gap-2">
                    <TextInput
                        id="startDate"
                        type="date"
                        value={filters.startDate && isoToForm(filters.startDate)}
                        onChange={handleInput}
                    />
                    <TextInput
                        id="endDate"
                        type="date"
                        value={isoToForm(filters.endDate)}
                        onChange={handleInput}
                    />
                </div>
            </div>
            <div>
                <div className="mb-2 block">
                    <FormLabel htmlFor="championship" label="Campionato" />
                </div>
                <Select
                    id="championship"
                    value={filters.championship}
                    onChange={handleInput}
                >
                    <option value=''>-</option>
                    {
                        championships.map(c => <option key={c._id} value={c._id}>{c.name}</option>)
                    }
                </Select>
            </div>
            <div>
                <div className="mb-2 block">
                    <FormLabel htmlFor="sport" label="Sport" />
                </div>
                <Select
                    id="sport"
                    className="w-32"
                    value={filters.sport}
                    onChange={handleInput}
                >
                    <option value=''>-</option>
                    <option value="Pallavolo">Pallavolo</option>
                    <option value="Calcio">Calcio</option>
                </Select>
            </div>
            <div>
                <Button
                    color="dark"
                    size="sm"
                    className="mb-1"
                    onClick={resetFilters}
                >Reset Filtri</Button>
            </div>
        </div>
    )
}

const Legenda = () => {
    return (
        <div className="mx-auto border rounded-lg bg-slate-500 p-2">
            <h4 className="font-semibold uppercase text-sm text-gray-100 text-center mb-2">Legenda colori</h4>
            <div className="flex flex-row gap-2">
                <p className="px-2 py-1.5 text-xs uppercase font-semibold text-gray-800 bg-gray-100 border border-gray-200 rounded-lg dark:text-gray-100 dark:border-gray-500">
                    Non compilata
                </p>
                <p className="px-2 py-1.5 text-xs uppercase font-semibold text-gray-800 bg-sky-200 border border-gray-200 rounded-lg dark:text-gray-100 dark:border-gray-500">
                    In compilazione
                </p>
                <p className="px-2 py-1.5 text-xs uppercase font-semibold text-gray-800 bg-amber-200 border border-gray-200 rounded-lg dark:text-gray-100 dark:border-gray-500">
                    Finalizzata
                </p>
                <p className="px-2 py-1.5 text-xs uppercase font-semibold text-gray-800 bg-emerald-200 border border-gray-200 rounded-lg dark:text-gray-100 dark:border-gray-500">
                    Omologata
                </p>
            </div>
        </div>
    )
}

function OmologMain() {
    const { _id: committeeId } = useSelector(state => state.committee);
    const { _id: seasonId } = useSelector(state => state.season);
    const [action, setAction] = useState('');
    const [alert, setAlert] = useState({ type: '', label: '' });
    const [championships, setChampionships] = useState([]);
    const [filteredMatches, setFilteredMatches] = useState([]);
    const [filters, setFilters] = useState({ days: '10', startDate: '', endDate: newDate(), championship: '', sport: '' });
    const [imgInfo, setImgInfo] = useState({ scope: '', src: '' });
    const [loading, setLoading] = useState(true);
    const [openJudgeModal, setOpenJudgeModal] = useState(false);
    const [match, setMatch] = useState({});
    const [matches, setMatches] = useState([]);
    const [openImgModal, setOpenImgModal] = useState(false);
    const [openReportModal, setOpenReportModal] = useState(false);
    const [openResultsModal, setOpenResultsModal] = useState(false);
    const [reportAction] = useState('show');
    const [resultContext] = useState('judge');
    const [roles, setRoles] = useState([]);

    // Championships & Matches
    useEffect(() => {
        if (committeeId && seasonId) {
            setLoading(true);
            API.get(`championships?committee=${committeeId}&season=${seasonId}&page=omolog`)
                .then(res => {
                    if (res.success) {
                        setChampionships(sortByName(res.championships, 'name'));
                        // Creo un array con tutte le gare dei campionati
                        const matches = [];
                        res.championships.forEach(c =>
                            c.calendar.forEach(g =>
                                g.rounds.forEach(r =>
                                    r.matches.forEach(m => {
                                        matches.push(m)
                                    })
                                )
                            )
                        )
                        setMatches(matches.filter(m => !m.postponed));
                    } else {
                        setAlert({ type: 'failure', label: res.msg || 'Qualcosa è andato storto...' });
                    }
                    setLoading(false);
                })
                .catch(err => console.error(err));
        }
    }, [committeeId, seasonId])

    // Roles
    useEffect(() => {
        if (committeeId) {
            API.get(`refroles?committee=${committeeId}`)
                .then(res => {
                    if (res.success) {
                        setRoles(sortByName(res.refroles, 'title'));
                    } else {
                        setAlert({ type: 'failure', label: res.msg || 'Qualcosa è andato storto...' });
                    }
                })
                .catch(err => console.error(err));
        }
    }, [committeeId])

    // Filtered Matches
    useEffect(() => {
        if (matches.length > 0) {
            let array = [...matches];
            // Filtro per sport
            if (filters.sport) {
                array = array.filter(m => m.championship.sport === filters.sport);
            }
            // Filtro per campionato
            if (filters.championship) {
                array = array.filter(m => m.championship._id === filters.championship);
            }
            // Filtro per giorni
            if (filters.days) {
                array = array.filter(m => {
                    const distance = filters.days * 24 * 60 * 60 * 1000;
                    return new Date() > new Date(m.date) && (new Date() - new Date(m.date)) < distance;
                })
            }
            // Filtra per giorno di inizio
            if (filters.startDate) {
                array = array.filter(m => new Date(m.date) >= new Date(filters.startDate))
            }
            // Filtra per giorno di fine
            if (filters.endDate) {
                array = array.filter(m => new Date(m.date) <= new Date(filters.endDate))
            }
            setFilteredMatches(sortByDate(sortByName(array, 'teamA.name'), 'date'));
        }
    }, [filters, matches])

    return (
        <div className="flex flex-col gap-3">
            {alert?.type && <GenericAlert type={alert.type}>{alert.label}</GenericAlert>}
            <div className="flex flex-row flex-wrap gap-4 items-center">
                <FiltersBar
                    championships={championships}
                    filters={filters}
                    setFilters={setFilters}
                />
                <Legenda />
            </div>
            {loading &&
                <div className="bg-white p-4">
                    <Spinner color="purple" size="xl" />
                </div>
            }
            <div className="flex flex-col gap-2 text-left">
                {
                    filteredMatches.map((m, i) =>
                        <MatchCard
                            key={m._id}
                            match={m}
                            setAction={setAction}
                            setAlert={setAlert}
                            setImgInfo={setImgInfo}
                            setMatch={setMatch}
                            setOpenJudgeModal={setOpenJudgeModal}
                            setOpenImgModal={setOpenImgModal}
                            setOpenReportModal={setOpenReportModal}
                            setOpenResultsModal={setOpenResultsModal}
                        />)
                }
            </div>
            <ImgModal
                imgInfo={imgInfo}
                openModal={openImgModal}
                setOpenModal={setOpenImgModal}
            />
            <ReportModal
                openModal={openReportModal}
                match={match}
                reportAction={reportAction}
                roles={roles}
                setOpenModal={setOpenReportModal}
            />
            <ResultsModal
                match={match}
                openModal={openResultsModal}
                resultContext={resultContext}
                setAlert={setAlert}
                setMatches={setMatches}
                setOpenModal={setOpenResultsModal}
            />
            <JudgeModal
                action={action}
                match={match}
                openModal={openJudgeModal}
                setAlert={setAlert}
                setMatches={setMatches}
                setOpenModal={setOpenJudgeModal}
            />
        </div>
    )
}

export default OmologMain;