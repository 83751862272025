import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

// Components
import { Spinner } from "flowbite-react";

function RequireRole({ children, role, exclusive = false }) {
    const navigate = useNavigate();
    const committee = useSelector(state => state.committee);
    const user = useSelector(state => state.user);
    const [loading, setLoading] = useState(true);


    useEffect(() => {
        if (committee._id && user._id) {
            const condition = exclusive
                ? Number(user.role) === Number(role) || Number(user.role) <= 1
                : Number(user.role) <= Number(role)
            if (condition) {
                setLoading(false);
            } else {
                navigate(`/${committee.urlcode}`)
            }
        }
    }, [committee, exclusive, navigate, role, user])

    return (
        <>
            {loading &&
                <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                    <div className="relative p-20 bg-white rounded-lg shadow-lg">
                        <Spinner size="xl" color="failure" aria-label="Loading..." />
                    </div>
                </div>
            }
            { !loading && children }
        </>
    )

}

export default RequireRole;