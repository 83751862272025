import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { assign as assignUser } from "../../features/user/userSlice";
import { authProvider as AUTH, serviceProvider as API } from "../../API/api";

// Components
import { Button, Label, TextInput } from "flowbite-react";
import GenericAlert from "./GenericAlert";
import GenericModal from "./GenericModal";


function LoginModal({ open, setOpen }) {
    const [action, setAction] = useState('login');
    const [alert, setAlert] = useState({ type: '', label: '' });
    const [form, setForm] = useState({ email: '', password: '' });
    const [hidePassword, setHidePassword] = useState(true);
    const { urlcode } = useSelector(state => state.committee);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleChange = (e) => {
        const { id, value } = e.currentTarget;
        setForm(prevState => ({
            ...prevState,
            [id]: value
        }));
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        switch (action) {
            case 'pwRecovery':
                API.insert(`emails/password-recovery`, JSON.stringify({ email: form.email }))
                    .then(res => {
                        if (res.success) {
                            setAlert({ type: 'success', label: res.msg });
                            setAction('login');
                        } else {
                            setAlert({ type: 'failure', label: res.msg || 'Qualcosa è andato storto, riprova più tardi' });
                        }
                    })
                    .catch(err => console.error(err));
                break;
            default:
                AUTH.signin(JSON.stringify(form))
                    .then(res => {
                        if (res.success) {
                            dispatch(assignUser(res.user));
                            setAlert({ type: '', label: '' });
                            setOpen(false);
                            navigate(`/${urlcode}/riservata`);
                        } else {
                            setAlert({ type: 'failure', label: res.msg || 'Qualcosa è andato storto' });
                        };
                    })
                    .catch(err => console.error(err))
        }
    }

    return (
        <GenericModal openModal={open} setOpenModal={setOpen} title={action === 'login' ? 'Effettua il Login' : 'Password dimenticata'}>
            <form className="flex flex-col gap-2" onSubmit={handleSubmit}>
                {alert?.type && <GenericAlert type={alert.type}>{alert.label}</GenericAlert>}
                <div>
                    <div className="mb-2 block">
                        <Label htmlFor="email">
                            {action === 'login' ? 'E-Mail' : 'Inserisci l\'e-mail associata al tuo account'}
                        </Label>
                    </div>
                    <TextInput
                        id="email"
                        type="email"
                        required={true}
                        value={form.email}
                        onChange={handleChange}
                    />
                </div>
                {
                    action === 'login' &&
                    <div>
                        <div className="mb-2 block">
                            <Label htmlFor="password">
                                Password
                            </Label>
                        </div>
                        <TextInput
                            id="password"
                            type={hidePassword ? 'password' : 'text'}
                            required={true}
                            value={form.password}
                            onChange={handleChange}
                        />
                        <div onClick={() => setHidePassword(!hidePassword)}>
                            {hidePassword ?
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88" />
                                </svg>
                                :
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" />
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                </svg>

                            }
                        </div>
                    </div>
                }
                <div className="flex justify-between">
                    <span
                        className="text-sm cursor-pointer text-blue-700 hover:underline dark:text-blue-500"
                        onClick={() => setAction(prevState => prevState === 'login' ? 'pwRecovery' : 'login')}
                    >
                        {action === 'login' ? 'Password Dimenticata?' : 'Torna al Login'}
                    </span>
                </div>
                <div className="w-full">
                    <Button type="submit">
                        {action === 'login' ? 'Effettua il Login' : 'Invia'}
                    </Button>
                </div>
            </form>
        </GenericModal>
    )
}

export default LoginModal;