import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    _id: '',
    username: '',
    role: 6,
    firstname: '',
    lastname: '',
    email: '',
    province: '',
    city: '',
    isreferee: false,
    committees: []
}

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        assign: (state, action) => action.payload
    }
});

export const { assign } = userSlice.actions;
export default userSlice.reducer;