import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useOutletContext } from "react-router-dom";
import { serviceProvider as API } from "../../../API/api";
import { sortByDate } from "../../../lib/utils";

// Components
import { Spinner } from "flowbite-react";
import Bulletins from "./Bulletins";
import GenericTitle from "../../elements/GenericTitle";
import Header from "./Header";
import NextMatches from "./NextMatches";
import PreviousMatches from "./PreviousMatches";

function Home() {
    const [loadingMatches, setLoadingMatches] = useState(true);
    const [matches, setMatches] = useState([]);
    const { _id: committeeId } = useSelector(state => state.committee);
    const { _id: seasonId } = useSelector(state => state.season);
    const [bulletins] = useOutletContext();
    const nextMatches = useCallback(() => {
        const currentDate = new Date();
        const endDate = new Date(new Date().setDate(currentDate.getDate() + 15));
        return matches.filter(m => new Date(m.date) >= currentDate && new Date(m.date) < endDate)
    }, [matches]);
    const previousMatches = useCallback(() => {
        const currentDate = new Date();
        const startDate = new Date(new Date().setDate(currentDate.getDate() - 15));
        return matches
            .filter(m => new Date(m.date) >= startDate && new Date(m.date) < currentDate)
            .sort((a, b) => new Date(b.date) - new Date(a.date))
    }, [matches]);

    useEffect(() => {
        if (committeeId && seasonId) {
            setLoadingMatches(true);
            API.get(`championships?committee=${committeeId}&season=${seasonId}&isPublic=true&page=home`)
                .then(res => {
                    if (res.success) {
                        // Creo un array con tutte le gare dei campionati
                        const matches = [];
                        res.championships.forEach(c =>
                            c.calendar.forEach(g =>
                                g.rounds.forEach(r =>
                                    r.matches.forEach(m => {
                                        matches.push(m)
                                    })
                                )
                            )
                        )
                        setMatches(sortByDate(matches.filter(m => !m.postponed), 'date'));
                        setLoadingMatches(false);
                    }
                })
                .catch(err => console.error(err));
        }
    }, [committeeId, seasonId])


    return (
        <>
            <Header />
            {bulletins?.length > 0 &&
                <>
                    <GenericTitle title="Comunicati Ufficiali" size="2xl" />
                    <Bulletins bulletins={bulletins} />
                </>
            }
            {loadingMatches &&
                <div className="bg-white p-4">
                    <Spinner size="xl" color="purple" />
                </div>
            }
            {nextMatches()?.length > 0 &&
                <>
                    <GenericTitle title="Prossime Gare" size="2xl" />
                    <NextMatches nextMatches={nextMatches()} />
                </>
            }
            {previousMatches()?.length > 0 &&
                <>
                    <GenericTitle title="Risultati recenti" size="2xl" />
                    <PreviousMatches previousMatches={previousMatches()} />
                </>
            }
        </>
    )
}

export default Home;