import { useCallback, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { serviceProvider as API } from "../../../../API/api";
import { sortByName } from "../../../../lib/utils";
import "flatpickr/dist/themes/material_blue.css";

// Components
import { Button, Spinner, Table } from "flowbite-react";
import Calendar from "./Calendar";
import CalendarModal from "./elements/CalendarModal";
import DeleteButton from "../../../buttons/DeleteButton";
import EditButton from "../../../buttons/EditButton";
import GenericAlert from "../../../elements/GenericAlert";
import GenericTitle from "../../../elements/GenericTitle";
import GroupsModal from "./elements/GroupsModal";
import LoadingRow from "../../../elements/LoadingRow";
import NoResultsRow from "../../../elements/NoResultsRow";
import Penalties from "./elements/Penalties";
import PenaltyModal from "./elements/PenaltyModal";
import RetireModal from "./elements/RetireModal";


const TableRow = ({ championship, group, setAction, setGroup, setOpenModal }) => {
    const { _id, group_name, teams } = group;

    const rowGroup = {
        _id: _id,
        group_name: group_name || '',
        teams: teams.map(team => team._id) || []
    }

    const handleEdit = () => {
        setAction('edit');
        setGroup(rowGroup);
        setOpenModal(true);
    }

    const handleDelete = () => {
        setAction('delete');
        setGroup(rowGroup);
        setOpenModal(true);
    }

    return (
        <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
            <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                {group_name}
            </Table.Cell>
            <Table.Cell>
                {
                    teams.length &&
                    <ul>
                        {
                            teams.map(team =>
                                <li key={team._id}>{team.name}</li>
                            )
                        }
                    </ul>
                }
            </Table.Cell>
            <Table.Cell>
                <EditButton onClick={handleEdit} />
                {!championship.calendar?.length &&
                    <DeleteButton onClick={handleDelete} />
                }
            </Table.Cell>
        </Table.Row>
    )
}

const GroupsTable = ({ championship, groups, notAssignedTeams, setAction, setAlert, setGroup, setOpenModal, teams }) => {
    const [loading, setLoading] = useState(true);
    const [noResults, setNoResults] = useState(false);

    useEffect(() => {
        if (groups.length) {
            setNoResults(false);
            setLoading(false);
        } else {
            setNoResults(true);
            setLoading(false);
        }
    }, [groups, setAlert])

    return (
        <Table hoverable={true}>
            <Table.Head>
                <Table.HeadCell>
                    Nome Girone
                </Table.HeadCell>
                <Table.HeadCell>
                    Squadre
                </Table.HeadCell>
                <Table.HeadCell>
                    <span className="sr-only">
                        Edit
                    </span>
                </Table.HeadCell>
            </Table.Head>
            <Table.Body className="divide-y">
                {loading && <LoadingRow colspan={3} />}
                {(!loading && noResults && !teams.length) && <NoResultsRow colspan={3} content="Non ci sono squadre associate a questo campionato" />}
                {
                    groups.map(group =>
                        <TableRow
                            key={group._id}
                            championship={championship}
                            group={group}
                            setAction={setAction}
                            setGroup={setGroup}
                            setOpenModal={setOpenModal}
                        />
                    )
                }
                {
                    notAssignedTeams.length > 0 &&
                    <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                        <Table.Cell className="whitespace-nowrap italic text-gray-900 dark:text-white">
                            Squadre non assegnate
                        </Table.Cell>
                        <Table.Cell colSpan={2}>
                            {
                                <ul>
                                    {
                                        notAssignedTeams.map(team => <li key={`team_${team._id}`}>{team.name}</li>)
                                    }
                                </ul>
                            }
                        </Table.Cell>
                    </Table.Row>
                }
            </Table.Body>
        </Table>
    )
}



function Championship() {
    const { champId } = useParams();
    const [action, setAction] = useState('new');
    const [actionMatch, setActionMatch] = useState('new');
    const [actionPenalty, setActionPenalty] = useState('new');
    const [alert, setAlert] = useState({ type: '', label: '' });
    const [calendarInfo, setCalendarInfo] = useState({ phases: 'andata', startingNumber: 1, startingDate: '', datesToSkip: [] });
    const [groups, setGroups] = useState([]);
    const [group, setGroup] = useState({ group_name: '', teams: [] });
    const [championship, setChampionship] = useState({});
    const [loading, setLoading] = useState(true);
    const [match, setMatch] = useState({ championship: champId, date: '', field: '', noteams: false, noteams_title: '', num: 1, postponed: false, status: 0, teamA: {}, teamB: {} });
    const [notAssignedTeams, setNotAssignedTeams] = useState([]);
    const [openCalModal, setOpenCalModal] = useState(false);
    const [openMatchModal, setOpenMatchModal] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [openPenaltyModal, setOpenPenaltyModal] = useState(false);
    const [openRetireModal, setOpenRetireModal] = useState(false);
    const [penalty, setPenalty] = useState({ date: '', notes: '', team: { _id: '' }, value: -1 });
    const [teams, setTeams] = useState([]);
    const getTeams = useCallback(() =>
        championship.teams?.reduce((prev, g) => [...prev, ...g.teams], [])
        , [championship]);
    const fp = useRef(null);

    const openNewModal = () => {
        setGroup({ group_name: '', teams: [] });
        setAction('new');
        setOpenModal(true);
    }

    const openNewMatchModal = () => {
        setActionMatch('new');
        setMatch({
            championship: champId,
            season: championship.season,
            date: '',
            field: '',
            noteams: false,
            noteams_title: '',
            num: 1,
            postponed: false,
            status: 0,
            teamA: '',
            teamB: ''
        })
        setOpenMatchModal(true);
    }

    const openCalendarModal = () => {
        if (fp?.current?.flatpickr) {
            fp.current.flatpickr.clear();
        }
        setCalendarInfo({ phases: 'ritorno', startingNumber: 1, startingDate: '', datesToSkip: [] });
        setAction(championship.calendar.length > 0 ? 'deleteCalendar' : 'newCalendar');
        setOpenCalModal(true);
    }

    const openPenModal = () => {
        setActionPenalty('new');
        setPenalty({ date: '', notes: '', team: { _id: '' }, value: -1 });
        setOpenPenaltyModal(true);
    }

    useEffect(() => {
        if (champId) {
            setLoading(true);
            API.get(`championships/${champId}`)
                .then(res => {
                    if (res.success) {
                        setChampionship(res.championship);
                        setGroups(res.championship.teams || []);
                    } else {
                        setChampionship({});
                        setGroups([]);
                        setAlert({ type: 'failure', label: res.msg || 'Qualcosa è andato storto...' });
                    }
                    setLoading(false);
                })
        }
    }, [champId])

    useEffect(() => {
        if (champId) {
            API.get(`teams?championships=${champId}`)
                .then(res => {
                    if (res.success) {
                        setTeams(sortByName(res.teams, 'name'));
                        const champTeams = championship.teams?.length
                            ? championship.teams
                                .map(group => group.teams.map(team => team._id))
                                .reduce((prev, curr) => [...prev, ...curr])
                            : [];
                        setNotAssignedTeams(sortByName(res.teams.filter(team => !champTeams.includes(team._id)), 'name'));
                    } else {
                        setTeams([]);
                        setAlert({ type: 'warning', label: 'Non sono state trovate squadre associate a questo campionato' });
                    }
                })
        }
    }, [champId, championship])

    return (
        <div className="flex flex-col gap-3">
            <GenericTitle title={championship.name} size="xl" />
            {alert?.type && <GenericAlert type={alert.type}>{alert.label}</GenericAlert>}
            {loading &&
                <div className="bg-white p-4">
                    <Spinner color="purple" size="xl" />
                </div>
            }
            {!loading &&
                <>
                    <GroupsTable
                        championship={championship}
                        groups={groups}
                        notAssignedTeams={notAssignedTeams}
                        setAction={setAction}
                        setAlert={setAlert}
                        setGroup={setGroup}
                        setOpenModal={setOpenModal}
                        teams={teams}
                    />
                    <div className="flex flex-row gap-2 w-fit items-center">
                        <div>
                            <Button
                                size="sm"
                                onClick={openNewModal}
                                disabled={notAssignedTeams.length ? false : true}
                            >
                                Nuovo Girone
                            </Button>
                        </div>
                        {championship.teams?.length > 0 &&
                            <div>
                                <Button
                                    size="sm"
                                    onClick={openCalendarModal}
                                    disabled={notAssignedTeams.length ? true : false}
                                    {...championship.calendar.length > 0 ? { color: 'failure' } : {}}
                                >
                                    {`${championship.calendar.length > 0 ? 'Elimina' : 'Nuovo'} Calendario`}
                                </Button>
                            </div>
                        }
                        {notAssignedTeams.length === 0 &&
                            <div>
                                <Button
                                    size="sm"
                                    onClick={openNewMatchModal}
                                >
                                    Gara Manuale
                                </Button>
                            </div>
                        }
                        {championship.teams?.length > 0 &&
                            <div>
                                <Button
                                    color="failure"
                                    size="sm"
                                    onClick={() => setOpenRetireModal(true)}
                                >
                                    Ritiro squadra
                                </Button>
                            </div>
                        }
                        {championship.teams?.length > 0 &&
                            <div>
                                <Button
                                    color="failure"
                                    size="sm"
                                    onClick={openPenModal}
                                >
                                    Nuova Penalità
                                </Button>
                            </div>
                        }
                    </div>
                </>
            }
            {championship.penalties?.length > 0 &&
                <Penalties
                    championship={championship}
                    setAction={setActionPenalty}
                    setOpenModal={setOpenPenaltyModal}
                    setPenalty={setPenalty}
                />
            }
            <Calendar
                action={actionMatch}
                championship={championship}
                match={match}
                openModal={openMatchModal}
                setAction={setActionMatch}
                setAlert={setAlert}
                setChampionship={setChampionship}
                setMatch={setMatch}
                setOpenModal={setOpenMatchModal}
            />
            <GroupsModal
                action={action}
                championship={championship}
                group={group}
                notAssignedTeams={notAssignedTeams}
                openModal={openModal}
                setAlert={setAlert}
                setChampionship={setChampionship}
                setGroup={setGroup}
                setGroups={setGroups}
                setNotAssignedTeams={setNotAssignedTeams}
                setOpenModal={setOpenModal}
                teams={teams}
            />
            <CalendarModal
                action={action}
                calendarInfo={calendarInfo}
                championship={championship}
                fp={fp}
                openCalModal={openCalModal}
                setAlert={setAlert}
                setCalendarInfo={setCalendarInfo}
                setChampionship={setChampionship}
                setOpenCalModal={setOpenCalModal}
            />
            <PenaltyModal
                action={actionPenalty}
                championship={championship}
                openModal={openPenaltyModal}
                penalty={penalty}
                setAlert={setAlert}
                setChampionship={setChampionship}
                setOpenModal={setOpenPenaltyModal}
                setPenalty={setPenalty}
                teams={getTeams()}
            />
            <RetireModal
                championship={championship}
                openModal={openRetireModal}
                setAlert={setAlert}
                setChampionship={setChampionship}
                setGroups={setGroups}
                setOpenModal={setOpenRetireModal}
                teams={teams}
            />
        </div>
    )
}

export default Championship;