import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { assign as assignSeason } from "../../features/season/seasonSlice";
import { assign as assignUser } from "../../features/user/userSlice";
import { removeToken } from "../../lib/storageUtils";

// Components
import { Avatar, Button, Dropdown, Navbar, Select } from "flowbite-react";

// Images
import csiLogo from '../../images/csi-logo.png';

function Nav({ championships, seasons, setOpenLogin }) {
    const sports = useCallback(() => {
        let array = [];
        championships.forEach(c => {
            if (!array.includes(c.sport)) array.push(c.sport);
        });
        return array;
    }, [championships])
    const committee = useSelector(state => state.committee);
    const season = useSelector(state => state.season);
    const user = useSelector(state => state.user);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [selectedSeason, setSelectedSeason] = useState(season);
    const path = useCallback(() => `/${committee.urlcode}/campionati`, [committee.urlcode]);

    const signout = () => {
        removeToken();
        dispatch(assignUser({}));
        navigate(`/${committee.urlcode}`);
    }

    const changeSeason = (e) => {
        setSelectedSeason(seasons.find(s => s._id.toString() === e.currentTarget.value));
        localStorage.setItem('season', JSON.stringify(seasons.find(s => s._id.toString() === e.currentTarget.value)))
        dispatch(assignSeason(seasons.find(s => s._id.toString() === e.currentTarget.value)));
    }

    // Se è il primo accesso, non ci sarà un valore in localstorage, lo aggiorno per visualizzare correttamente il select
    useEffect(() => {
        setSelectedSeason(season);
    }, [season])

    return (
        <section className="bg-gradient-to-r from-orange-300 to-orange-100 shadow-lg">
            <Navbar
                fluid={true}
                rounded={true}

            >
                <Navbar.Brand href={`/${committee.urlcode}`}>
                    <img
                        src={csiLogo}
                        className="mr-3 h-6 sm:h-9"
                        alt="Flowbite Logo"
                    />
                    <span className="self-center whitespace-nowrap uppercase text-xl font-semibold dark:text-white">
                        {committee.name}
                    </span>
                </Navbar.Brand>
                <div className="flex md:order-2">
                    {/* <div className="mr-3 w-44 hidden sm:inline">
                        <Select
                            id="seasons_selector"
                            onChange={changeSeason}
                            value={selectedSeason?._id}
                        >
                            {seasons?.map(s =>
                                <option
                                    key={s._id}
                                    value={s._id}
                                >
                                    {`Stagione ${s.name}`}
                                </option>
                            )}
                        </Select>
                    </div> */}
                    {user._id &&
                        <Dropdown
                            arrowIcon={false}
                            inline={true}
                            label={
                                <>
                                    <div className="space-y-1 font-medium dark:text-white text-right hidden lg:inline-flex">
                                        <div className="text-sm text-gray-500 dark:text-gray-400 mr-1">
                                            {user.firstname}
                                        </div>
                                    </div>
                                    <Avatar alt="User settings" rounded={true}>
                                    </Avatar>
                                </>
                            }
                        >
                            <div className="bg-white">
                                <Dropdown.Header>
                                    <span className="block text-sm">
                                        {`${user.firstname} ${user.lastname}`}
                                    </span>
                                    <span className="block truncate text-sm font-medium">
                                        {user.email}
                                    </span>
                                </Dropdown.Header>
                                <Dropdown.Item>
                                    {/* <ul> needed to prevent <li> being nested in a <li> */}
                                    <ul>
                                        <Navbar.Link
                                            href={`/${committee.urlcode}/riservata`}
                                        >
                                            Dashboard
                                        </Navbar.Link>
                                    </ul>
                                </Dropdown.Item>
                                <Dropdown.Item>
                                    <ul>
                                        <Navbar.Link
                                            href={`/${committee.urlcode}/riservata/modifica-profilo`}
                                        >
                                            Modifica profilo
                                        </Navbar.Link>
                                    </ul>
                                </Dropdown.Item>
                                <Dropdown.Divider />
                                <Dropdown.Item onClick={signout}>
                                    Log out
                                </Dropdown.Item>
                            </div>
                        </Dropdown>
                    }
                    {
                        !user._id &&
                        <div className="flex flex-wrap items-center gap-2">
                            <Button
                                pill="true"
                                onClick={() => setOpenLogin(true)}
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z" />
                                </svg>
                                Login
                            </Button>
                        </div>
                    }
                    <Navbar.Toggle />
                </div>
                <Navbar.Collapse>
                    <Navbar.Link
                        href={`/${committee.urlcode}`}
                        active={true}
                    >
                        Home
                    </Navbar.Link>
                    {championships.length > 0 &&
                        <div className="flex place-content-center">
                            <Dropdown
                                inline={true}
                                label="Campionati"
                            >
                                <div className="bg-white">
                                    {sports().length < 2 &&
                                        championships.map(c =>
                                            <Dropdown.Item key={c._id}>
                                                <ul>
                                                    <Navbar.Link href={`${path()}/${c.sport.toLowerCase()}/${c.urlcode}`}>
                                                        {c.name}
                                                    </Navbar.Link>
                                                </ul>
                                            </Dropdown.Item>
                                        )
                                    }
                                    {sports().length > 1 &&
                                        sports().map(sport =>
                                            <div key={sport}>
                                                <Dropdown.Header>
                                                    <span className="uppercase text-xs tracking-widest">
                                                        {sport}
                                                    </span>
                                                </Dropdown.Header>
                                                {
                                                    championships
                                                        .filter(c => c.sport === sport)
                                                        .map(c =>
                                                            <Dropdown.Item key={c._id}>
                                                                <ul>
                                                                    <Navbar.Link href={`${path()}/${c.sport.toLowerCase()}/${c.urlcode}`}>
                                                                        {c.name}
                                                                    </Navbar.Link>
                                                                </ul>
                                                            </Dropdown.Item>
                                                        )
                                                }
                                            </div>
                                        )
                                    }
                                </div>
                            </Dropdown>
                        </div>}
                    <Navbar.Link href={`/${committee.urlcode}/campi-di-gioco`}>
                        Campi di gioco
                    </Navbar.Link>
                </Navbar.Collapse>
            </Navbar>
        </section>
    )
}

export default Nav;