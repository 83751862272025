import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { serviceProvider as API } from "../../../../API/api";
import { formatDateTime, genGroupPdf, genGroupXlsx, invertMatch, readablePartials, sortByName } from "../../../../lib/utils";

// Components
import { Button, Table, Tabs, Tooltip } from "flowbite-react";
import DeleteButton from "../../../buttons/DeleteButton";
import EditButton from "../../../buttons/EditButton";
import InvertionModal from "./elements/InvertionModal";
import MatchModal from "./elements/MatchModal";


const TableRow = ({ groupIndex, groupName, matchDetails, roundIndex, roundMatches, setAction, setGroupName, setInvert, setMatch, setOpenInvertModal, setOpenModal, setPointers }) => {
    const { _id, date, field, noteams, noteams_title, num, postponed, status, teamA, teamB } = matchDetails;

    const rowMatch = {
        _id: _id || '',
        date: date || '',
        field: field._id || '',
        noteams: noteams || false,
        noteams_title: noteams_title || '',
        num: num || 1,
        postponed: postponed || false,
        status: status || 0,
        teamA: teamA || '',
        teamB: teamB || '',
    }

    const handleEdit = () => {
        setAction('edit');
        setGroupName(groupName);
        setMatch(rowMatch);
        setPointers({ groupIndex, roundIndex });
        setOpenModal(true);
    }

    const handleDelete = () => {
        setAction('delete');
        setGroupName(groupName);
        setMatch(rowMatch);
        setPointers({ groupIndex, roundIndex });
        setOpenModal(true);
    }

    const handleInvertion = () => {
        const returnMatch = roundMatches.find(m => m.teamA._id === rowMatch.teamB._id && m.teamB._id === rowMatch.teamA._id);
        if (returnMatch?._id) {
            const originalMatches = [
                {
                    ...rowMatch,
                    field: field
                },
                returnMatch
            ];
            const newMatches = [invertMatch(originalMatches[0]), invertMatch(originalMatches[1])];
            setInvert({ originalMatches, newMatches });

        } else {
            setInvert({ originalMatches: [rowMatch], newMatches: [] });
        }
        setPointers({ groupIndex, roundIndex });
        setOpenInvertModal(true);
    }

    return (
        <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
            <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                {matchDetails.num}
            </Table.Cell>
            <Table.Cell>
                {postponed &&
                    <span
                        className="bg-yellow-100 text-yellow-800 text-sm font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-yellow-200 dark:text-yellow-900 uppercase"
                    >
                        Rinviata
                    </span>
                }
                {!postponed && formatDateTime(matchDetails.date)}
            </Table.Cell>
            <Table.Cell>
                {matchDetails.teamA.name}
            </Table.Cell>
            <Table.Cell>
                {matchDetails.teamB.name}
            </Table.Cell>
            <Table.Cell className="hidden lg:table-cell">
                {
                    (matchDetails.result.a + matchDetails.result.b) > 0 &&
                    <Tooltip content={readablePartials(matchDetails.partials)}>
                        {`${matchDetails.result.a} - ${matchDetails.result.b}`}
                    </Tooltip>
                }
                {
                    (matchDetails.result.a + matchDetails.result.b) === 0 &&
                    `${matchDetails.result.a} - ${matchDetails.result.b}`
                }
            </Table.Cell>
            <Table.Cell className="hidden lg:table-cell">
                {`${matchDetails.field.city} - ${matchDetails.field.name}`}
            </Table.Cell>
            <Table.Cell className="text-right">
                <EditButton onClick={handleEdit} />
                {
                    !matchDetails.noteams &&
                    <button
                        type="button"
                        className="focus:outline-none text-white bg-purple-700 hover:bg-purple-800 focus:ring-4 focus:ring-purple-300 font-medium rounded-lg text-sm px-2.5 py-2.5 mr-2 mb-2 dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-900"
                        onClick={handleInvertion}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 12c0-1.232-.046-2.453-.138-3.662a4.006 4.006 0 00-3.7-3.7 48.678 48.678 0 00-7.324 0 4.006 4.006 0 00-3.7 3.7c-.017.22-.032.441-.046.662M19.5 12l3-3m-3 3l-3-3m-12 3c0 1.232.046 2.453.138 3.662a4.006 4.006 0 003.7 3.7 48.656 48.656 0 007.324 0 4.006 4.006 0 003.7-3.7c.017-.22.032-.441.046-.662M4.5 12l3 3m-3-3l-3 3" />
                        </svg>
                    </button>
                }
                <DeleteButton onClick={handleDelete} />
            </Table.Cell>
        </Table.Row>
    )
}

const MatchesTable = ({ groupIndex, groupName, matches, roundIndex, roundMatches, setAction, setGroupName, setInvert, setMatch, setOpenInvertModal, setOpenModal, setPointers }) => {

    return (
        <Table hoverable={true}>
            <Table.Head>
                <Table.HeadCell>
                    #
                </Table.HeadCell>
                <Table.HeadCell>
                    Data
                </Table.HeadCell>
                <Table.HeadCell>
                    Squadra 1
                </Table.HeadCell>
                <Table.HeadCell>
                    Squadra 2
                </Table.HeadCell>
                <Table.HeadCell className="hidden lg:table-cell">
                    Risultato
                </Table.HeadCell>
                <Table.HeadCell className="hidden lg:table-cell">
                    Campo
                </Table.HeadCell>
                <Table.HeadCell>
                    <span className="sr-only">
                        Edit
                    </span>
                </Table.HeadCell>
            </Table.Head>
            <Table.Body className="divide-y">
                {
                    matches.map(matchDetails =>
                        <TableRow
                            key={matchDetails._id}
                            groupIndex={groupIndex}
                            groupName={groupName}
                            matchDetails={matchDetails}
                            roundIndex={roundIndex}
                            roundMatches={roundMatches}
                            setAction={setAction}
                            setGroupName={setGroupName}
                            setInvert={setInvert}
                            setMatch={setMatch}
                            setOpenInvertModal={setOpenInvertModal}
                            setOpenModal={setOpenModal}
                            setPointers={setPointers}
                        />
                    )
                }
            </Table.Body>
        </Table>
    )
}

const Round = ({ championship, groupIndex, groupName, round, roundIndex, roundMatches, setAction, setGroupName, setInvert, setMatch, setOpenInvertModal, setOpenModal, setPointers }) => {
    const teamsA = round.matches.map(m => m.teamA._id);
    const teamsB = round.matches.map(m => m.teamB._id);
    const { teams } = championship.teams.filter(g => g.group_name === groupName)[0];
    const restTeams = teams.filter(team => ![...teamsA, ...teamsB].includes(team._id));
    const restTeamsString = restTeams.reduce((prev, t) => `${prev} ${prev && '-'} ${t.name}`, '');

    return (
        <div className="mb-3">
            <p className={`mt-1 text-xl font-light italic text-gray-900 sm:text-2xl sm:tracking-tight lg:text-lg`}>
                Giornata {round.round}
            </p>
            <MatchesTable
                groupIndex={groupIndex}
                groupName={groupName}
                matches={round.matches}
                roundIndex={roundIndex}
                roundMatches={roundMatches}
                setAction={setAction}
                setGroupName={setGroupName}
                setInvert={setInvert}
                setMatch={setMatch}
                setOpenInvertModal={setOpenInvertModal}
                setOpenModal={setOpenModal}
                setPointers={setPointers}
            />
            {restTeams.length > 0 &&
                <div className="text-left">
                    <span className="bg-indigo-100 text-indigo-800 text-sm font-medium mt-2 mr-2 px-2.5 py-0.5 rounded dark:bg-indigo-200 dark:text-indigo-900">
                        <span className="font-extrabold">Squadre a riposo:</span>
                        {' '}
                        {restTeamsString}
                    </span>
                </div>
            }
        </div>

    )
}

const Group = ({ championship, group, groupIndex, setAction, setGroupName, setInvert, setMatch, setOpenInvertModal, setOpenModal, setPointers }) => {
    const groupmatches = useCallback(() => group.rounds.reduce((prev, curr) => [...prev, ...curr.matches], []), [group.rounds]);

    const createPdf = () => {
        const { teams } = championship.teams.filter(g => g.group_name === group.group_name)[0];
        genGroupPdf(championship.name, group.group_name, teams, group.rounds)
    }

    const createXls = () => {
        const matchRows = group.rounds.reduce((prev, r) =>
            [
                ...prev,
                ...r.matches.map(m => ({
                    'Giornata': r.round,
                    'Num': m.num,
                    'Data': m.postponed ? 'da stabilire' : formatDateTime(m.date),
                    'Campo': m.field.name,
                    'Indirizzo': `${m.field.city} - ${m.field.address}`,
                    'Squadra 1': m.teamA.name,
                    'Squadra 2': m.teamB.name
                }))
            ], []
        );
        const { teams } = championship.teams.filter(g => g.group_name === group.group_name)[0];
        const teamRows = teams.map(t => (
            {
                'Squadra': t.name,
                'Referente': t.referent,
                'Telefono': t.referent_phone,
                'E-mail': t.referent_email
            }
        ));
        genGroupXlsx(championship.name, matchRows, teamRows);
    }

    return (
        <>
            <div className="flex flex-row gap-2">
                <div>
                    <Button
                        gradientDuoTone="cyanToBlue"
                        onClick={createPdf}
                        size="xs"
                    >
                        Esporta Pdf
                    </Button>
                </div>
                <div>
                    <Button
                        gradientDuoTone="greenToBlue"
                        onClick={createXls}
                        size="xs"
                    >
                        Esporta Xls
                    </Button>
                </div>
            </div>
            <p className={`mt-1 text-2xl font-extrabold text-gray-900 sm:text-3xl sm:tracking-tight lg:text-xl`}>
                Girone: {group.group_name}
            </p>
            {group.rounds.map((round, i) =>
                <Round
                    key={round._id}
                    championship={championship}
                    groupIndex={groupIndex}
                    groupName={group.group_name}
                    round={round}
                    roundIndex={i}
                    roundMatches={groupmatches()}
                    setAction={setAction}
                    setInvert={setInvert}
                    setGroupName={setGroupName}
                    setMatch={setMatch}
                    setOpenInvertModal={setOpenInvertModal}
                    setOpenModal={setOpenModal}
                    setPointers={setPointers}
                />)
            }
        </>
    )
}

function Calendar({ action, championship, match, openModal, setAction, setAlert, setChampionship, setMatch, setOpenModal }) {
    const { _id: committeeId } = useSelector(state => state.committee);
    const [fields, setFields] = useState([]);
    const [groupName, setGroupName] = useState('');
    const [invert, setInvert] = useState({ originalMatches: [], newMatches: [] });
    const [openInvertModal, setOpenInvertModal] = useState(false);
    const [pointers, setPointers] = useState({});


    useEffect(() => {
        API.get(`fields?committee=${committeeId}`)
            .then(res => {
                if (res.success) {
                    setFields(sortByName(sortByName(sortByName(res.fields, 'name'), 'city'), 'province'));
                } else {
                    setFields([]);
                }
            })
            .catch(err => console.error(err));
    }, [committeeId])

    return (
        <>
            {championship.calendar?.length > 0 &&
                <Tabs.Group aria-label="Tab Girone" style="underline">
                    {championship.calendar.map((group, i) =>
                        <Tabs.Item key={group._id} title={group.group_name}>
                            <Group
                                championship={championship}
                                group={group}
                                groupIndex={i}
                                setAction={setAction}
                                setGroupName={setGroupName}
                                setInvert={setInvert}
                                setMatch={setMatch}
                                setOpenInvertModal={setOpenInvertModal}
                                setOpenModal={setOpenModal}
                                setPointers={setPointers}
                            />
                        </Tabs.Item>
                    )}
                </Tabs.Group>
            }
            <MatchModal
                action={action}
                championship={championship}
                fields={fields}
                groupName={groupName}
                match={match}
                openModal={openModal}
                pointers={pointers}
                setAlert={setAlert}
                setChampionship={setChampionship}
                setGroupName={setGroupName}
                setMatch={setMatch}
                setOpenModal={setOpenModal}
            />
            <InvertionModal
                championship={championship}
                invert={invert}
                openModal={openInvertModal}
                pointers={pointers}
                setAlert={setAlert}
                setChampionship={setChampionship}
                setOpenModal={setOpenInvertModal}
            />
        </>
    )
}

export default Calendar;