import { useSelector } from "react-redux";

// Components
import { Footer } from "flowbite-react";

function MainFooter() {
    const { title } = useSelector(state => state.committee);

    return (
        <Footer container={true}>
            <span className="text-left text-xs">{title} © 2022 by Erik Iudica. Tutti i diritti sono riservati.</span>
        </Footer>
    )
}

export default MainFooter;