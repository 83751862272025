import { useEffect, useState } from "react";
import { serviceProvider as API } from "../../../API/api";
import { sortByName } from "../../../lib/utils";

// Components
import { Checkbox, Button, Table, TextInput, Tooltip } from "flowbite-react";
import DeleteButton from "../../buttons/DeleteButton";
import EditButton from "../../buttons/EditButton";
import FormLabel from "../../elements/FormLabel";
import GenericAlert from "../../elements/GenericAlert";
import GenericModal from "../../elements/GenericModal";
import LoadingRow from "../../elements/LoadingRow";
import NoResultsRow from "../../elements/NoResultsRow";
import SubmitButton from "../../buttons/SubmitButton";

const TableRow = ({ committee, setAction, setCommittee, setOpenModal }) => {
    const { _id, csiid, name, urlcode, title, km_refund, email, file_prefix, omolog_email, daysToDeleteAssignment, daysToInsertUnavailaibility, daysToEditUnavailaibility, daysToReject, showReferees, rankingIfConfirmed, soc_email, vbl_email, receipt_name } = committee;

    const rowCommittee = {
        _id: _id,
        csiid: csiid || '',
        name: name || '',
        urlcode: urlcode || '',
        title: title || '',
        km_refund: km_refund || 0,
        email: email || '',
        file_prefix: file_prefix || '',
        omolog_email: omolog_email || '',
        daysToInsertUnavailaibility: daysToInsertUnavailaibility || 0,
        daysToEditUnavailaibility: daysToEditUnavailaibility || 0,
        daysToReject: daysToReject || 0,
        daysToDeleteAssignment: daysToDeleteAssignment || 0,
        showReferees: showReferees,
        rankingIfConfirmed: rankingIfConfirmed,
        soc_email: soc_email || '',
        vbl_email: vbl_email || '',
        receipt_name: receipt_name || ''
    }

    const handleEdit = () => {
        setAction('edit');
        setCommittee(rowCommittee);
        setOpenModal(true);
    }

    const handleDelete = () => {
        setAction('delete');
        setCommittee(rowCommittee);
        setOpenModal(true);
    }

    return (
        <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
            <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                {name}
            </Table.Cell>
            <Table.Cell>
                {urlcode}
            </Table.Cell>
            <Table.Cell>
                {title}
            </Table.Cell>
            <Table.Cell>
                <EditButton onClick={handleEdit} />
                <DeleteButton onClick={handleDelete} />
            </Table.Cell>
        </Table.Row>
    )
}

const CommitteeTable = ({ committees, setAlert, setAction, setCommittee, setCommittees, setOpenModal }) => {
    const [loading, setLoading] = useState(true);
    const [noResults, setNoResults] = useState(false);

    useEffect(() => {
        API.get('committees')
            .then(res => {
                if (res.success) {
                    setCommittees(sortByName(res.committees, 'name'));
                    setAlert({ type: '', label: '' });
                    setNoResults(false);
                    setLoading(false);
                } else {
                    setCommittees([]);
                    setAlert({ type: 'failure', label: res.msg });
                    setLoading(false);
                }
            })
            .catch(err => console.error(err))
    }, [setCommittees, setAlert])

    useEffect(() => {
        if (committees.length) {
            setNoResults(false);
            setLoading(false);
        } else {
            setNoResults(true);
            setLoading(false);
        }
    }, [committees])

    return (
        <Table hoverable={true}>
            <Table.Head>
                <Table.HeadCell>
                    Nome
                </Table.HeadCell>
                <Table.HeadCell>
                    Urlcode
                </Table.HeadCell>
                <Table.HeadCell>
                    Titolo
                </Table.HeadCell>
                <Table.HeadCell>
                    <span className="sr-only">
                        Edit
                    </span>
                </Table.HeadCell>
            </Table.Head>
            <Table.Body className="divide-y">
                {loading && <LoadingRow colspan={4} />}
                {(!loading && noResults) && <NoResultsRow colspan={4} />}
                {
                    committees.map((committee, i) => <TableRow key={i} committee={committee} setAction={setAction} setCommittee={setCommittee} setOpenModal={setOpenModal} />)
                }
            </Table.Body>
        </Table>
    )
}

const CommitteeForm = ({ action, committee, setAlert, setCommittee, setCommittees, setModalAlert, setOpenModal }) => {

    const handleInput = (e) => {
        const { id, type, value } = e.currentTarget;
        setCommittee(prevState => ({
            ...prevState,
            [id]: type === 'checkbox'
                ? e.target.checked
                : value
        }));
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        switch (action) {
            case 'new':
                API.insert('committees', JSON.stringify(committee), true)
                    .then(res => {
                        if (res.success) {
                            setCommittees(prevState => sortByName([...prevState, res.committee], 'name'));
                            setAlert({ type: 'success', label: 'Il comitato è stato inserito' });
                            setOpenModal(false);
                        } else {
                            setModalAlert({ type: 'failure', label: res.msg || 'Qualcosa è andato storto...' });
                        }
                    })
                    .catch(err => console.error(err));
                break;
            case 'edit':
                API.update(`committees/${committee._id}`, JSON.stringify(committee), true)
                    .then(res => {
                        if (res.success) {
                            setCommittees(prevState => {
                                const index = prevState.findIndex(el => el._id === committee._id);
                                prevState[index] = committee;
                                return sortByName(prevState, 'name')
                            });
                            setAlert({ type: 'success', label: 'Il comitato è stato modificato correttamente' });
                            setOpenModal(false);
                        } else {
                            setModalAlert({ type: 'failure', label: res.msg || 'Qualcosa è andato storto...' });
                        }
                    })
                    .catch(err => console.error(err));
                break;
            case 'delete':
                API.delete(`committees/${committee._id}`, true)
                    .then(res => {
                        if (res.success) {
                            setCommittees(prevState => prevState.filter(el => el._id !== committee._id));
                            setAlert({ type: 'success', label: 'Il comitato è stato eliminato' });
                            setOpenModal(false);
                        } else {
                            setModalAlert({ type: 'failure', label: res.msg || 'Qualcosa è andato storto...' });
                        }
                    })
                    .catch(err => console.error(err));
                break;
            default:
        }
    }

    return (
        <form className="flex flex-col gap-4" onSubmit={handleSubmit}>
            <div className="flex flex-row gap-2">
                <div>
                    <div className="mb-2 block">
                        <FormLabel htmlFor="name" label="Nome" />
                    </div>
                    <TextInput
                        id="name"
                        type="text"
                        required={true}
                        value={committee.name}
                        onChange={handleInput}
                        disabled={action === 'delete' ? true : false}
                    />
                </div>
                <div>
                    <div className="mb-2 block">
                        <FormLabel htmlFor="title" label="Titolo" />
                    </div>
                    <TextInput
                        id="title"
                        type="text"
                        value={committee.title || ''}
                        onChange={handleInput}
                        disabled={action === 'delete' ? true : false}
                    />
                </div>
            </div>
            <div className="flex flex-row gap-2">
                <div>
                    <div className="mb-2 block">
                        <FormLabel htmlFor="urlcode" label="UrlCode" />
                    </div>
                    <TextInput
                        id="urlcode"
                        type="text"
                        required={true}
                        value={committee.urlcode}
                        onChange={handleInput}
                        disabled={action === 'delete' ? true : false}
                    />
                </div>
                <div>
                    <div className="mb-2 block">
                        <FormLabel htmlFor="file_prefix" label="Prefisso file" />
                    </div>
                    <TextInput
                        id="file_prefix"
                        type="text"
                        required={true}
                        value={committee.file_prefix}
                        onChange={handleInput}
                        disabled={action === 'delete' ? true : false}
                    />
                </div>
            </div>
            <div className="flex flex-row gap-2">
                <div>
                    <div className="mb-2 block">
                        <FormLabel htmlFor="email" label="Indirizzo e-mail" />
                    </div>
                    <TextInput
                        id="email"
                        type="email"
                        value={committee.email}
                        onChange={handleInput}
                        disabled={action === 'delete' ? true : false}
                    />
                </div>
                <div>
                    <div className="mb-2 block">
                        <FormLabel htmlFor="omolog_email" label="E-mail Giudice Sportivo" />
                    </div>
                    <TextInput
                        id="omolog_email"
                        type="email"
                        value={committee.omolog_email}
                        onChange={handleInput}
                        disabled={action === 'delete' ? true : false}
                    />
                </div>
            </div>
            <div className="flex flex-row gap-2">
                <div>
                    <div className="mb-2 block">
                        <FormLabel htmlFor="email" label="E-mail des. calcio" />
                    </div>
                    <Tooltip content="Se compilato, il designatore riceverà una mail per ogni rifiuto">
                        <TextInput
                            id="soc_email"
                            type="email"
                            value={committee.soc_email}
                            onChange={handleInput}
                            disabled={action === 'delete' ? true : false}
                        />
                    </Tooltip>
                </div>
                <div>
                    <div className="mb-2 block">
                        <FormLabel htmlFor="omolog_email" label="E-mail des. pallavolo" />
                    </div>
                    <Tooltip content="Se compilato, il designatore riceverà una mail per ogni rifiuto">
                        <TextInput
                            id="vbl_email"
                            type="email"
                            value={committee.vbl_email}
                            onChange={handleInput}
                            disabled={action === 'delete' ? true : false}
                        />
                    </Tooltip>
                </div>
            </div>
            <div className="flex flex-row gap-2">
                <div>
                    <div className="mb-2 block">
                        <FormLabel htmlFor="km_refund" label="Quota rimborso km" />
                    </div>
                    <TextInput
                        id="km_refund"
                        type="number"
                        value={committee.km_refund}
                        step="0.01"
                        onChange={handleInput}
                        disabled={action === 'delete' ? true : false}
                    />
                </div>
                <div>
                    <div className="mb-2 block">
                        <FormLabel htmlFor="daysToReject" label="Giorni entro cui rifiutare" />
                    </div>
                    <Tooltip content="Lasciare 0 per non mettere dei limiti">
                        <TextInput
                            id="daysToReject"
                            type="number"
                            value={committee.daysToReject}
                            step="1"
                            min="0"
                            onChange={handleInput}
                            disabled={action === 'delete' ? true : false}
                        />
                    </Tooltip>
                </div>
            </div>
            <div className="flex flex-row gap-2">
                <div className="basis-1/2">
                    <div className="mb-2 block">
                        <FormLabel htmlFor="daysToInsertUnavailaibility" label="Giorni per inserire indisponibilità" />
                    </div>
                    <Tooltip content="Lasciare 0 per non mettere dei limiti">
                        <TextInput
                            id="daysToInsertUnavailaibility"
                            type="number"
                            value={committee.daysToInsertUnavailaibility}
                            step="1"
                            min="0"
                            onChange={handleInput}
                            disabled={action === 'delete' ? true : false}
                        />
                    </Tooltip>
                </div>
                <div className="basis-1/2">
                    <div className="mb-2 block">
                        <FormLabel htmlFor="daysToEditUnavailaibility" label="Giorni per modificare indisponibilità" />
                    </div>
                    <Tooltip content="Lasciare 0 per non mettere dei limiti">
                        <TextInput
                            id="daysToEditUnavailaibility"
                            type="number"
                            value={committee.daysToEditUnavailaibility}
                            step="1"
                            min="0"
                            onChange={handleInput}
                            disabled={action === 'delete' ? true : false}
                        />
                    </Tooltip>
                </div>
            </div>
            <div className="flex flex-row gap-2 items-end">
                <div className="basis-1/2">
                    <div className="mb-2 block">
                        <FormLabel htmlFor="daysToDeleteAssignment" label="Entro questi giorni, mantenere le designazioni" />
                    </div>
                    <Tooltip content="Lasciando 0 le designazioni non verranno mai tolte">
                        <TextInput
                            id="daysToDeleteAssignment"
                            type="number"
                            value={committee.daysToDeleteAssignment}
                            step="1"
                            min="0"
                            onChange={handleInput}
                            disabled={action === 'delete' ? true : false}
                        />
                    </Tooltip>
                </div>
                <div className="basis-1/2">
                    <div className="mb-2 block">
                        <FormLabel htmlFor="receipt_name" label="Intestazione ente" />
                    </div>
                    <Tooltip content="Sarà visualizzato nelle ricevute PDF">
                        <TextInput
                            id="receipt_name"
                            type="text"
                            value={committee.receipt_name}
                            onChange={handleInput}
                            disabled={action === 'delete' ? true : false}
                        />
                    </Tooltip>
                </div>
            </div>
            <div className="flex flex-row gap-2 items-end">
                <div className="basis-1/2">
                    <div className="mb-2 block">
                        <FormLabel htmlFor="csiid" label="Codice CSI" />
                    </div>
                    <Tooltip content="Necessario per esportare le designazioni">
                        <TextInput
                            id="csiid"
                            type="text"
                            value={committee.csiid}
                            maxLength={3}
                            minLength={3}
                            onChange={handleInput}
                            disabled={action === 'delete' ? true : false}
                        />
                    </Tooltip>
                </div>
                <div className="basis-1/2">
                </div>
            </div>
            <div className="flex flex-row gap-2">
                <div className="basis-1/2 flex flex-row gap-2">
                    <Checkbox
                        id="rankingIfConfirmed"
                        onChange={handleInput}
                        checked={committee.rankingIfConfirmed}
                        disabled={action === 'delete' ? true : false}
                    />
                    <div className="mb-2 block">
                        <FormLabel htmlFor="rankingIfConfirmed" label="Classifica aggiornata sulle gare omologate" />
                    </div>
                </div>
                <div className="basis-1/2 flex flex-row gap-2">
                    <Checkbox
                        id="showReferees"
                        onChange={handleInput}
                        checked={committee.showReferees}
                        disabled={action === 'delete' ? true : false}
                    />
                    <div className="mb-2 block">
                        <FormLabel htmlFor="showReferees" label="Mostra gli UDG in home" />
                    </div>
                </div>
            </div>
            {
                action === 'delete' &&
                <GenericAlert type="warning" divclass="text-lg mx-auto" hideicon="true">Desideri eliminare questo comitato?</GenericAlert>
            }
            <div className="text-center">
                <SubmitButton />
            </div>
        </form>
    )
}

const CommitteeModal = ({ action, committee, openModal, setAlert, setCommittee, setCommittees, setOpenModal }) => {
    const [modalAlert, setModalAlert] = useState({ type: '', label: '' });

    // Azzera l'alert del modal ogni volta che viene aperto
    useEffect(() => {
        openModal === true && setModalAlert({ type: '', label: '' });
    }, [openModal]);

    return (
        <GenericModal openModal={openModal} setOpenModal={setOpenModal} title={`${action === 'new' ? 'Nuovo' : action === 'edit' ? 'Modifica' : 'Elimina'} Comitato`}>
            {modalAlert?.type && <GenericAlert type={modalAlert.type}>{modalAlert.label}</GenericAlert>}
            <CommitteeForm
                action={action}
                committee={committee}
                setAlert={setAlert}
                setCommittee={setCommittee}
                setCommittees={setCommittees}
                setModalAlert={setModalAlert}
                setOpenModal={setOpenModal}
            />
        </GenericModal>
    )
}

function Committees() {
    const [action, setAction] = useState('new');
    const [alert, setAlert] = useState({ type: '', label: '' });
    const [committees, setCommittees] = useState([]);
    const [committee, setCommittee] = useState({ csiid: '', name: '', urlcode: '', title: '', km_refund: 0, email: '', file_prefix: '', omolog_email: '', daysToInsertUnavailaibility: 0, daysToEditUnavailaibility: 0, daysToReject: 0, daysToDeleteAssignment: 0, showReferees: true, rankingIfConfirmed: false, soc_email: '', vbl_email: '', receipt_name: '' });
    const [openModal, setOpenModal] = useState(false);

    const openCommModal = () => {
        setCommittee({ csiid: '', name: '', urlcode: '', title: '', km_refund: 0, email: '', file_prefix: '', omolog_email: '', daysToInsertUnavailaibility: 0, daysToEditUnavailaibility: 0, daysToReject: 0, daysToDeleteAssignment: 0, showReferees: true, rankingIfConfirmed: false, soc_email: '', vbl_email: '', receipt_name: '' });
        setAction('new');
        setOpenModal(true);
    }

    return (
        <div className="flex flex-col gap-3">
            {alert?.type && <GenericAlert type={alert.type}>{alert.label}</GenericAlert>}
            <CommitteeTable
                committees={committees}
                setAlert={setAlert}
                setAction={setAction}
                setCommittee={setCommittee}
                setCommittees={setCommittees}
                setOpenModal={setOpenModal}
            />
            <div className="w-fit">
                <Button size="sm" onClick={openCommModal}>
                    Nuovo Comitato
                </Button>
            </div>
            <CommitteeModal
                action={action}
                committee={committee}
                openModal={openModal}
                setAlert={setAlert}
                setCommittee={setCommittee}
                setCommittees={setCommittees}
                setOpenModal={setOpenModal}
            />
        </div>
    )
}

export default Committees;