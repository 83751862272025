import { Spinner } from "flowbite-react";

function LoadingSpinner() {
    return (
        <div className="fixed h-full w-full flex items-center justify-center bg-gray-700">
            <Spinner aria-label="Loading Spinner" size="xl" />
        </div>
    )
}

export default LoadingSpinner;