import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { serviceProvider as API } from "../../../API/api";
import { sortByName } from "../../../lib/utils";

// Components
import { Button, Checkbox, Select, Table, TextInput } from "flowbite-react";
import DeleteButton from "../../buttons/DeleteButton";
import EditButton from "../../buttons/EditButton";
import FormLabel from "../../elements/FormLabel";
import GenericAlert from "../../elements/GenericAlert";
import GenericModal from "../../elements/GenericModal";
import LoadingRow from "../../elements/LoadingRow";
import NoResultsRow from "../../elements/NoResultsRow";
import SubmitButton from "../../buttons/SubmitButton";

const TableRow = ({ user, roles, setAction, setUser, setOpenModal }) => {
    const { _id, password, firstname, lastname, email, province, city, isreferee, role, sports, committees } = user;

    const rowCommittee = {
        _id: _id,
        password: password || '',
        firstname: firstname || '',
        lastname: lastname || '',
        email: email || '',
        province: province || '',
        city: city || '',
        isreferee: isreferee || false,
        role: role,
        sports: sports || [],
        committees: committees || []
    }

    const handleEdit = () => {
        setAction('edit');
        setUser(rowCommittee);
        setOpenModal(true);
    }

    const handleDelete = () => {
        setAction('delete');
        setUser(rowCommittee);
        setOpenModal(true);
    }

    return (
        <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
            <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                {lastname}
            </Table.Cell>
            <Table.Cell>
                {firstname}
            </Table.Cell>
            <Table.Cell>
                {roles[role]}
            </Table.Cell>
            <Table.Cell>
                <EditButton onClick={handleEdit} />
                <DeleteButton onClick={handleDelete} />
            </Table.Cell>
        </Table.Row>
    )
}

const UsersTable = ({ users, roles, setAlert, setAction, setUser, setUsers, setOpenModal }) => {
    const [loading, setLoading] = useState(true);
    const [noResults, setNoResults] = useState(false);
    const { _id: committeeId } = useSelector(state => state.committee);

    useEffect(() => {
        API.get(`users?committees=${committeeId}`, true)
            .then(res => {
                if (res.success) {
                    setUsers(sortByName(res.users, 'lastname'));
                    setAlert({ type: '', label: '' });
                    setNoResults(false);
                    setLoading(false);
                } else {
                    setUsers([]);
                    setAlert({ type: 'failure', label: res.msg });
                    setLoading(false);
                }
            })
            .catch(err => console.error(err))
    }, [committeeId, setUsers, setAlert])

    useEffect(() => {
        if (users.length) {
            setNoResults(false);
            setLoading(false);
        } else {
            setNoResults(true);
            setLoading(false);
        }
    }, [users])

    return (
        <Table hoverable={true}>
            <Table.Head>
                <Table.HeadCell>
                    Cognome
                </Table.HeadCell>
                <Table.HeadCell>
                    Nome
                </Table.HeadCell>
                <Table.HeadCell>
                    Ruolo
                </Table.HeadCell>
                <Table.HeadCell>
                    <span className="sr-only">
                        Edit
                    </span>
                </Table.HeadCell>
            </Table.Head>
            <Table.Body className="divide-y">
                {loading && <LoadingRow colspan={4} />}
                {(!loading && noResults) && <NoResultsRow colspan={4} />}
                {
                    users.map((user, i) => <TableRow key={i} user={user} roles={roles} setAction={setAction} setUser={setUser} setOpenModal={setOpenModal} />)
                }
            </Table.Body>
        </Table>
    )
}

const UsersForm = ({ action, locations, roles, user, setAlert, setUser, setUsers, setModalAlert, setOpenModal }) => {
    const [committees, setCommittees] = useState([]);
    const [cities, setCities] = useState([]);
    const { role: authRole } = useSelector(state => state.user);
    const { role: startingRole } = user;

    const handleInput = (e) => {
        const { id, selectedOptions, type, value } = e.currentTarget;
        setUser(prevState => ({
            ...prevState,
            [id]: selectedOptions
                ? Array.from(selectedOptions).map(opt => opt.value)
                : type === 'checkbox'
                    ? e.target.checked
                    : value,
            sports: id === 'isreferee' && !e.target.checked
                ? []
                : id === 'sports'
                    ? Array.from(selectedOptions).map(opt => opt.value)
                    : prevState.sports
        }));
    }

    const handleSelect = (e) => {
        const { id, value } = e.currentTarget;
        setUser(prevState => ({
            ...prevState,
            [id]: value
        }));
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        switch (action) {
            case 'new':
                API.insert('auth/register', JSON.stringify(user), true)
                    .then(res => {
                        if (res.success) {
                            setUsers(prevState => sortByName([...prevState, res.user], 'lastname'));
                            setAlert({ type: 'success', label: 'L\'utente è stato inserito' });
                            setOpenModal(false);
                        } else {
                            setModalAlert({ type: 'failure', label: res.msg || 'Qualcosa è andato storto...' });
                        }
                    })
                    .catch(err => console.error(err));
                break;
            case 'edit':
                const bodyObject = { ...user };
                delete bodyObject.password;
                API.update(`users/${user._id}`, JSON.stringify(bodyObject), true)
                    .then(res => {
                        if (res.success) {
                            setUsers(prevState => {
                                const index = prevState.findIndex(el => el._id === user._id);
                                prevState[index] = user;
                                return sortByName(prevState, 'lastname');
                            });
                            setAlert({ type: 'success', label: 'L\'utente è stato modificato correttamente' });
                            setOpenModal(false);
                        } else {
                            setModalAlert({ type: 'failure', label: res.msg || 'Qualcosa è andato storto...' });
                        }
                    })
                    .catch(err => console.error(err));
                break;
            case 'delete':
                API.delete(`users/${user._id}`, true)
                    .then(res => {
                        if (res.success) {
                            setUsers(prevState => prevState.filter(el => el._id !== user._id));
                            setAlert({ type: 'success', label: 'L\'utente è stato eliminato' });
                            setOpenModal(false);
                        } else {
                            setModalAlert({ type: 'failure', label: res.msg || 'Qualcosa è andato storto...' });
                        }
                    })
                    .catch(err => console.error(err));
                break;
            default:
        }
    }

    useEffect(() => {

        API.get('committees')
            .then(res => {
                if (res.success) {
                    setCommittees(sortByName(res.committees, 'name'));
                } else {
                    setCommittees([]);
                }
            })
            .catch(err => console.error(err))
    }, [])

    useEffect(() => {
        // Ogni volta che viene impostata una provincia, aggiorna l'array dei comuni
        if (user.province && locations.length > 0) {
            const loc = locations.find(loc => loc.provincia === user.province);
            setCities(loc.comuni);
        } else {
            setCities([]);
        }
    }, [locations, user.province])

    return (
        <form className="flex flex-col gap-4" onSubmit={handleSubmit}>
            <div>
                <div className="mb-2 block">
                    <FormLabel htmlFor="email" label="Indirizzo e-mail" />
                </div>
                <TextInput
                    id="email"
                    type="email"
                    required={true}
                    value={user.email}
                    onChange={handleInput}
                    disabled={action === 'delete' ? true : false}
                />
            </div>
            {action === 'new' &&
                <div>
                    <div className="mb-2 block">
                        <FormLabel htmlFor="password" label="Password" />
                    </div>
                    <TextInput
                        id="password"
                        type="text"
                        required={true}
                        value={user.password}
                        onChange={handleInput}
                        disabled={action === 'delete' ? true : false}
                    />
                </div>
            }
            <div>
                <div className="mb-2 block">
                    <FormLabel htmlFor="lastname" label="Cognome" />
                </div>
                <TextInput
                    id="lastname"
                    type="text"
                    required={true}
                    value={user.lastname}
                    onChange={handleInput}
                    disabled={action === 'delete' ? true : false}
                />
            </div>
            <div>
                <div className="mb-2 block">
                    <FormLabel htmlFor="fistname" label="Nome" />
                </div>
                <TextInput
                    id="firstname"
                    type="text"
                    required={true}
                    value={user.firstname}
                    onChange={handleInput}
                    disabled={action === 'delete' ? true : false}
                />
            </div>
            <div className="flex flex-row gap-4 items-center">
                <div className="basis-1/2">
                    <div className="mb-2 block">
                        <FormLabel htmlFor="province" label="Provincia" />
                    </div>
                    <Select
                        id="province"
                        required={true}
                        onChange={handleSelect}
                        value={user.province}
                        disabled={action === 'delete' ? true : false}
                    >
                        <option>-</option>
                        {
                            locations.map(location =>
                                <option
                                    key={location._id}
                                    value={location.provincia}
                                >
                                    {location.provincia}
                                </option>
                            )
                        }
                    </Select>
                </div>
                <div>
                    <div className="mb-2 block">
                        <FormLabel htmlFor="city" label="Comune" />
                    </div>
                    <Select
                        id="city"
                        required={true}
                        onChange={handleSelect}
                        value={user.city}
                        disabled={action === 'delete' ? true : false}
                    >
                        <option>-</option>
                        {
                            cities.map((city, i) =>
                                <option
                                    key={`city_${i}`}
                                    value={city}
                                >
                                    {city}
                                </option>
                            )
                        }
                    </Select>
                </div>
            </div>
            <div className="flex flex-row gap-4 items-center">
                {(authRole < 1 || (authRole < 2 && startingRole > 0)) &&
                    <div className="basis-2/3">
                        <div className="mb-2 block">
                            <FormLabel htmlFor="role" label="Ruolo" />
                        </div>
                        <Select
                            id="role"
                            required={true}
                            onChange={handleSelect}
                            value={user.role}
                            disabled={action === 'delete' ? true : false}
                        >
                            {
                                roles.map((role, i) => {
                                    if (i >= authRole) {
                                        return (
                                            <option
                                                key={`role_${i}`}
                                                value={i}
                                            >
                                                {role}
                                            </option>
                                        )
                                    } else {
                                        return (
                                            <option
                                                key={`role_${i}`}
                                                className="hidden"
                                                value={i}
                                            >
                                                {role}
                                            </option>
                                        )
                                    }
                                })
                            }
                        </Select>
                    </div>
                }
                <div>
                    <div className="mb-2 block">
                        <FormLabel htmlFor="isreferee" label="Arbitro?" />
                    </div>
                    <Checkbox
                        id="isreferee"
                        onChange={handleInput}
                        checked={user.isreferee}
                        disabled={action === 'delete' ? true : false}
                    />
                </div>
            </div>
            {user.isreferee &&
                <div>
                    <div className="mb-2 block">
                        <FormLabel htmlFor="sport" label="Sport" />
                    </div>
                    <Select
                        id="sports"
                        required={true}
                        onChange={handleInput}
                        value={user.sports}
                        multiple
                        disabled={action === 'delete' ? true : false}
                    >
                        <option value="Pallavolo">Pallavolo</option>
                        <option value="Calcio">Calcio</option>
                    </Select>
                </div>
            }
            {
                authRole < 1 && (
                    <div>
                        <div className="mb-2 block">
                            <FormLabel htmlFor="committees" label="Comitati" />
                        </div>
                        <Select
                            id="committees"
                            multiple
                            required={true}
                            onChange={handleInput}
                            value={user.committees}
                            disabled={action === 'delete' ? true : false}
                        >
                            {
                                committees.map(committee =>
                                    <option
                                        key={committee._id}
                                        value={committee._id}
                                    >
                                        {committee.name}
                                    </option>
                                )
                            }
                        </Select>
                    </div>
                )
            }
            {
                action === 'delete' &&
                <GenericAlert type="warning" divclass="text-lg mx-auto" hideicon="true">Desideri eliminare questo utente?</GenericAlert>
            }
            <div className="text-center">
                <SubmitButton />
            </div>
        </form>
    )
}

const UsersModal = ({ action, locations, user, openModal, roles, setAlert, setUser, setUsers, setOpenModal }) => {
    const [modalAlert, setModalAlert] = useState({ type: '', label: '' });

    // Azzera l'alert del modal ogni volta che viene aperto
    useEffect(() => {
        openModal === true && setModalAlert({ type: '', label: '' });
    }, [openModal]);

    return (
        <GenericModal openModal={openModal} setOpenModal={setOpenModal} title={`${action === 'new' ? 'Nuovo' : action === 'edit' ? 'Modifica' : 'Elimina'} Utente`}>
            {modalAlert?.type && <GenericAlert type={modalAlert.type}>{modalAlert.label}</GenericAlert>}
            <UsersForm action={action} locations={locations} roles={roles} user={user} setAlert={setAlert} setUser={setUser} setUsers={setUsers} setModalAlert={setModalAlert} setOpenModal={setOpenModal} />
        </GenericModal>
    )
}

function Users() {
    const { _id: committeeId } = useSelector(state => state.committee);
    const [action, setAction] = useState('new');
    const [alert, setAlert] = useState({ type: '', label: '' });
    const [locations, setLocations] = useState([]);
    const [users, setUsers] = useState([]);
    const [user, setUser] = useState({ password: '', firstname: '', lastname: '', email: '', province: '', city: '', isreferee: false, role: 5, sports: [], committees: [committeeId] });
    const [openModal, setOpenModal] = useState(false);
    const roles = ['Super Admin', 'Admin', 'Comm. Gare', 'Comm. Giudicante', 'Comm. Designante', 'Utente'];

    const openCommModal = () => {
        setUser({ password: '', firstname: '', lastname: '', email: '', province: '', city: '', isreferee: false, role: 5, sports: [], committees: [committeeId] });
        setAction('new');
        setOpenModal(true);
    }

    useEffect(() => {
        API.get('locations')
            .then(res => {
                if (res.success) {
                    setLocations(sortByName(res.locations, 'provincia'));
                } else {
                    setLocations([]);
                    setAlert({ type: 'failure', label: res.msg });
                }
            })
            .catch(err => console.error(err))
    }, [setLocations, setAlert])

    return (
        <div className="flex flex-col gap-3">
            {alert?.type && <GenericAlert type={alert.type}>{alert.label}</GenericAlert>}
            <UsersTable users={users} roles={roles} setAlert={setAlert} setAction={setAction} setUser={setUser} setUsers={setUsers} setOpenModal={setOpenModal} />
            <div className="w-fit">
                <Button size="sm" onClick={openCommModal}>
                    Nuovo Utente
                </Button>
            </div>
            <UsersModal action={action} locations={locations} user={user} openModal={openModal} roles={roles} setAlert={setAlert} setUser={setUser} setUsers={setUsers} setOpenModal={setOpenModal} />
        </div>
    )
}

export default Users;