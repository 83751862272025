import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    _id: undefined,
    name: 'Sport',
    urlcode: ''
};

export const committeeSlice = createSlice({
    name: 'committee',
    initialState,
    reducers: {
        assign: (state, action) => action.payload
    }
});

export const { assign } = committeeSlice.actions;

export default committeeSlice.reducer;