import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { serviceProvider as API } from "../../../API/api";
import { abbrText, formatDate, formatDateTime, genPaymentPdf, genPaymentXls, genReceipt, setPaymentMail, sortByDate, sortByName } from "../../../lib/utils";

// Components
import { Button, Checkbox, Label, Select, Table, TextInput, ToggleSwitch, Tooltip } from "flowbite-react";
import GenericAlert from "../../elements/GenericAlert";
import GenericModal from "../../elements/GenericModal";
import LoadingRow from "../../elements/LoadingRow";

const MoneyIcon = ({ colorClass }) =>
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={`w-4 h-4 ${colorClass}`}>
        <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
    </svg>




const PaymentsRow = ({ payment, referee, setAction, setOpenPaymentsModal, setPayment }) => {
    const {receipt_name: committeeName } = useSelector(state => state.committee);

    const deletePayment = () => {
        setAction('delete');
        setPayment(payment);
        setOpenPaymentsModal(true);
    }

    return (
        <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
            <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                {formatDate(payment.emission_date)}
            </Table.Cell>
            <Table.Cell>
                {payment.subtotal} €
            </Table.Cell>
            <Table.Cell>
                <div className="flex flex-row gap-2 w-fit float-right">
                    <Tooltip content="Elimina il pagamento">
                        <Button
                            color="failure"
                            size="sm"
                            onClick={deletePayment}
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                                className="w-5 h-5"
                            >
                                <path strokeLinecap="round" strokeLinejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                            </svg>
                        </Button>
                    </Tooltip>
                    <Tooltip content="Esporta in pdf">
                        <Button
                            color="purple"
                            size="sm"
                            onClick={() => genPaymentPdf(payment, referee)}
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                                className="w-5 h-5"
                            >
                                <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m.75 12l3 3m0 0l3-3m-3 3v-6m-1.5-9H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
                            </svg>
                        </Button>
                    </Tooltip>
                    <Tooltip content="Esporta in xls">
                        <Button
                            color="success"
                            size="sm"
                            onClick={() => genPaymentXls(payment, referee)}
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                                className="w-5 h-5"
                            >
                                <path strokeLinecap="round" strokeLinejoin="round" d="M3.375 19.5h17.25m-17.25 0a1.125 1.125 0 01-1.125-1.125M3.375 19.5h7.5c.621 0 1.125-.504 1.125-1.125m-9.75 0V5.625m0 12.75v-1.5c0-.621.504-1.125 1.125-1.125m18.375 2.625V5.625m0 12.75c0 .621-.504 1.125-1.125 1.125m1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125m0 3.75h-7.5A1.125 1.125 0 0112 18.375m9.75-12.75c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125m19.5 0v1.5c0 .621-.504 1.125-1.125 1.125M2.25 5.625v1.5c0 .621.504 1.125 1.125 1.125m0 0h17.25m-17.25 0h7.5c.621 0 1.125.504 1.125 1.125M3.375 8.25c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125m17.25-3.75h-7.5c-.621 0-1.125.504-1.125 1.125m8.625-1.125c.621 0 1.125.504 1.125 1.125v1.5c0 .621-.504 1.125-1.125 1.125m-17.25 0h7.5m-7.5 0c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125M12 10.875v-1.5m0 1.5c0 .621-.504 1.125-1.125 1.125M12 10.875c0 .621.504 1.125 1.125 1.125m-2.25 0c.621 0 1.125.504 1.125 1.125M13.125 12h7.5m-7.5 0c-.621 0-1.125.504-1.125 1.125M20.625 12c.621 0 1.125.504 1.125 1.125v1.5c0 .621-.504 1.125-1.125 1.125m-17.25 0h7.5M12 14.625v-1.5m0 1.5c0 .621-.504 1.125-1.125 1.125M12 14.625c0 .621.504 1.125 1.125 1.125m-2.25 0c.621 0 1.125.504 1.125 1.125m0 1.5v-1.5m0 0c0-.621.504-1.125 1.125-1.125m0 0h7.5" />
                            </svg>
                        </Button>
                    </Tooltip>
                    <Tooltip content="Esporta ricevuta">
                        <Button
                            color="dark"
                            size="sm"
                            onClick={() => genReceipt(payment, referee, committeeName)}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m5.231 13.481L15 17.25m-4.5-15H5.625c-.621 0-1.125.504-1.125 1.125v16.5c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9zm3.75 11.625a2.625 2.625 0 11-5.25 0 2.625 2.625 0 015.25 0z" />
                            </svg>

                        </Button>
                    </Tooltip>
                </div>
            </Table.Cell>
        </Table.Row>
    )
}

const PaymentsTable = ({ loading, payments, referee, setAction, setOpenPaymentsModal, setPayment }) => {
    return (
        <Table hoverable={true}>
            <Table.Head>
                <Table.HeadCell>
                    Data
                </Table.HeadCell>
                <Table.HeadCell>
                    Totale
                </Table.HeadCell>
                <Table.HeadCell>
                </Table.HeadCell>
            </Table.Head>
            <Table.Body>
                {
                    payments.length < 1 && !loading &&
                    <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                        <Table.Cell colSpan={4} className="text-center">
                            {referee._id ? 'Nessun pagamento ancora inserito' : 'Seleziona prima un arbitro'}
                        </Table.Cell>
                    </Table.Row>
                }
                {loading && <LoadingRow colspan={4} />}
                {
                    payments.map(p =>
                        <PaymentsRow
                            key={p._id}
                            payment={p}
                            referee={referee}
                            setAction={setAction}
                            setOpenPaymentsModal={setOpenPaymentsModal}
                            setPayment={setPayment}
                        />
                    )
                }
            </Table.Body>
        </Table>
    )
}

const AssignmentsRow = ({ assignment, setAlert, setAssignments }) => {
    const { km_refund } = useSelector(state => state.committee);

    const handleChange = (e) => {
        const { id, value } = e.currentTarget;
        setAssignments(prevState => prevState.map(a => {
            if (a._id === assignment._id) {
                return {
                    ...a,
                    refund: {
                        ...a.refund,
                        [id]: value ? Number(value) : value
                    }
                }
            }
            return a;
        }))
    }

    const handleVerify = () => {
        const verifyStatus = assignment.refund.verified ? false : true;
        API.update(`assignments/${assignment._id}`, JSON.stringify({ refund: { ...assignment.refund, verified: verifyStatus } }), true)
            .then(res => {
                if (!res.success) {
                    setAlert({ type: 'failure', label: res.msg || 'Qualcosa è andato storto' });
                }
            })
            .catch(err => console.error(err))
        setAssignments(prevState => prevState.map(a => {
            if (a._id === assignment._id) {
                return {
                    ...a,
                    refund: {
                        ...a.refund,
                        verified: verifyStatus
                    }
                }
            }
            return a;
        }))
    }

    const handleDistance = (e) => {
        const { value } = e.currentTarget;
        setAssignments(prevState => prevState.map(a => {
            if (a._id === assignment._id) {
                return {
                    ...a,
                    refund: {
                        ...a.refund,
                        distance: value ? Number(value) : value,
                        car: value ? Number(value) * (km_refund * 100) / 100 : a.refund.car
                    }
                }
            }
            return a;
        }))
    }

    const handleBlur = () => {
        API.update(`assignments/${assignment._id}`, JSON.stringify({ refund: assignment.refund }), true)
            .then(res => {
                if (!res.success) {
                    setAlert({ type: 'failure', label: res.msg || 'Qualcosa è andato storto' });
                }
            })
            .catch(err => console.error(err))
    }

    const handleCheckbox = () => {
        setAssignments(prevState => prevState.map(a => {
            if (a._id === assignment._id) {
                return {
                    ...a,
                    selected: !a.selected
                }
            }
            return a;
        }))
    }

    useEffect(() => {
        setAssignments(prevState => prevState.map(a => ({
            ...a,
            refund: {
                ...a.refund,
                subtotal: (a.refund.allowance * 100 + a.refund.car * 100 + a.refund.meal * 100 + a.refund.travel * 100 + a.refund.expenses * 100) / 100
            }
        })))
    }, [assignment.refund.allowance, assignment.refund.car, assignment.refund.meal, assignment.refund.travel, assignment.refund.expenses, setAssignments])

    return (
        <Table.Row className={`${assignment.refund?.edited_distance ? 'bg-red-100 hover:bg-red-200' : assignment.inserted ? 'bg-white' : 'bg-yellow-50 hover:bg-yellow-100'} dark:border-gray-700 dark:bg-gray-800`}>
            <Table.Cell>
                <div className="flex flex-row gap-2 items-center">
                    <Checkbox
                        checked={assignment.selected}
                        onChange={handleCheckbox}
                        disabled={!assignment.inserted}
                    />
                    <button
                        type="button"
                        className={`${assignment.refund.verified ? `bg-green-600 text-white hover:bg-green-800` : 'bg-gray-300 text-gray-900 hover:bg-gray-400'} ${!assignment.inserted && 'cursor-not-allowed opacity-50'} fond-bold focus:ring-2 focus:outline-none focus:ring-green-400 font-medium rounded-full text-sm p-0.5 text-center inline-flex items-center me-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800`}
                        onClick={handleVerify}
                        disabled={!assignment.inserted}
                    >
                        <MoneyIcon />
                    </button>
                </div>
            </Table.Cell>
            <Table.Cell>
                <Tooltip content={`${abbrText(assignment.match.field.city)} - ${abbrText(assignment.match.field.name)}`}>
                    {formatDateTime(assignment.match.date)}
                </Tooltip>
            </Table.Cell>
            <Table.Cell>
                {assignment.role}
            </Table.Cell>
            <Table.Cell className="hidden 2xl:table-cell">
                <Tooltip content={assignment.match.championship.name}>
                    {abbrText(assignment.match.championship.name, 6)}
                </Tooltip>
            </Table.Cell>
            <Table.Cell className="hidden 2xl:table-cell">
                <Tooltip content={`#${assignment.match.num} ${assignment.match.teamA.name} - ${assignment.match.teamB.name}`}>
                    #{assignment.match.num} {abbrText(assignment.match.teamA.name, 9)} - {abbrText(assignment.match.teamB.name, 9)}
                </Tooltip>
            </Table.Cell>
            <Table.Cell>
                <TextInput
                    type="number"
                    id="allowance"
                    disabled={!assignment.inserted}
                    value={assignment.refund.allowance}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
            </Table.Cell>
            <Table.Cell>
                <Tooltip content={`${assignment.refund.car} €`}>
                    <TextInput
                        type="number"
                        id="distance"
                        disabled={!assignment.inserted}
                        value={assignment.refund.distance}
                        onChange={handleDistance}
                        onBlur={handleBlur}
                    />
                </Tooltip>
            </Table.Cell>
            <Table.Cell>
                <TextInput
                    type="number"
                    id="meal"
                    disabled={!assignment.inserted}
                    value={assignment.refund.meal}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
            </Table.Cell>
            <Table.Cell>
                <Tooltip content={`${assignment.refund?.car ? `Auto utilizzata: ${assignment.refund?.carplate || 'n/a'} - ` : ''}Andata: partenza alle ore ${assignment.refund?.departureTime || '00:00'}, arrivo alle ore ${assignment.refund?.departureArrival || '00:00'} - Ritorno: partenza alle ore ${assignment.refund?.returnTime || '00:00'}, arrivo alle ore ${assignment.refund?.returnArrival || '00:00'}`}>
                    <TextInput
                        type="number"
                        id="travel"
                        disabled={!assignment.inserted}
                        value={assignment.refund.travel}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                </Tooltip>
            </Table.Cell>
            <Table.Cell>
                <TextInput
                    type="number"
                    id="expenses"
                    disabled={!assignment.inserted}
                    value={assignment.refund.expenses}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
            </Table.Cell>
            <Table.Cell className="truncate">
                {assignment.refund.subtotal} €
            </Table.Cell>
            <Table.Cell>
                <Tooltip content={assignment.refund.notes || 'Nessuna nota'}>
                    {!assignment.refund.notes &&
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m2.25 0H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
                        </svg>
                    }
                    {assignment.refund.notes &&
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
                        </svg>
                    }
                </Tooltip>
            </Table.Cell>
        </Table.Row>
    )
}

const AssignmentsTable = ({ allSelected, assignments, getTotals, loading, referee, setAlert, setAllSelected, setAssignments }) => {


    const selectAll = () => {
        setAssignments(prevState => prevState.map(a => ({
            ...a,
            selected: !allSelected
        })));
        setAllSelected(!allSelected);
    }

    return (
        <Table hoverable={true}>
            <Table.Head>
                <Table.HeadCell className="normal-case">
                    {assignments.length > 0 &&
                        <Tooltip content="Seleziona tutti">
                            <Checkbox
                                value={allSelected}
                                checked={allSelected}
                                onChange={selectAll}
                            />
                        </Tooltip>
                    }
                </Table.HeadCell>
                <Table.HeadCell>
                    Data
                </Table.HeadCell>
                <Table.HeadCell className="hidden 2xl:table-cell">
                    Ruolo
                </Table.HeadCell>
                <Table.HeadCell className="hidden 2xl:table-cell">
                    Campionato
                </Table.HeadCell>
                <Table.HeadCell>
                    Gara
                </Table.HeadCell>
                <Table.HeadCell>
                    Diaria / €
                </Table.HeadCell>
                <Table.HeadCell>
                    Distanza / Km
                </Table.HeadCell>
                <Table.HeadCell>
                    Pasti / €
                </Table.HeadCell>
                <Table.HeadCell>
                    Viaggio / €
                </Table.HeadCell>
                <Table.HeadCell>
                    Varie / €
                </Table.HeadCell>
                <Table.HeadCell>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M14.25 7.756a4.5 4.5 0 100 8.488M7.5 10.5h5.25m-5.25 3h5.25M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                </Table.HeadCell>
                <Table.HeadCell>
                    Note
                </Table.HeadCell>
            </Table.Head>
            <Table.Body className="divide-y">
                {assignments.length < 1 && !loading &&
                    <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                        <Table.Cell colSpan={12} className="text-center">
                            {referee._id ? 'Nessuna designazione per quest\'arbitro' : 'Seleziona prima un arbitro'}
                        </Table.Cell>
                    </Table.Row>
                }
                {loading && <LoadingRow colspan={12} />}
                {
                    assignments.map(a =>
                        <AssignmentsRow
                            key={a._id}
                            assignment={a}
                            setAlert={setAlert}
                            setAssignments={setAssignments}
                        />
                    )
                }
                {
                    assignments.length > 0 &&
                    <Table.Row className="font-semibold bg-indigo-50 hover:bg-indigo-100 dark:border-gray-700 dark:bg-gray-800">
                        <Table.Cell></Table.Cell>
                        <Table.Cell className="text-center uppercase tracking-widest" colSpan={4}>
                            Totali
                        </Table.Cell>
                        <Table.Cell className="truncate">
                            {getTotals('allowance')} €
                        </Table.Cell>
                        <Table.Cell className="truncate">
                            <Tooltip content={`${getTotals('distance')} km`}>
                                {getTotals('car')} €
                            </Tooltip>
                        </Table.Cell>
                        <Table.Cell className="truncate">
                            {getTotals('meal')} €
                        </Table.Cell>
                        <Table.Cell className="truncate">
                            {getTotals('travel')} €
                        </Table.Cell>
                        <Table.Cell className="truncate">
                            {getTotals('expenses')} €
                        </Table.Cell>
                        <Table.Cell className="truncate">
                            {getTotals('subtotal')} €
                        </Table.Cell>
                        <Table.Cell></Table.Cell>
                    </Table.Row>
                }
            </Table.Body>
        </Table>
    )
}

const RefereeSelect = ({ referee, referees, setReferee }) => {

    return (
        <div className="flex flex-row flex-wrap gap-8 items-end">
            <div>
                <div className="mb-2 block">
                    <Label
                        htmlFor="referee"
                        value="Scegli un arbitro"
                    />
                    <Select
                        id="referee"
                        value={referee._id}
                        onChange={(e) => setReferee(e.currentTarget.value ? referees.find(r => r._id.toString() === e.currentTarget.value) : '')}
                    >
                        <option value="">-</option>
                        {referees.map(r =>
                            <option
                                key={r._id}
                                value={r._id}
                            >
                                {r.lastname} {r.firstname}
                            </option>
                        )}
                    </Select>
                </div>
            </div>
        </div>
    )
}

const PaymentsModal = ({ action, assignments, getTotals, openModal, payment, referee, setAlert, setOpenModal, setPayments }) => {
    const { _id: committeeId } = useSelector(state => state.committee);
    const { _id: seasonId } = useSelector(state => state.season);
    const [modalAlert, setModalAlert] = useState({ type: '', label: '' });
    const [emissionDate, setEmissionDate] = useState(`${new Date().toLocaleDateString('it-IT', { year: 'numeric' })}-${new Date().toLocaleDateString('it-IT', { month: '2-digit' })}-${new Date().toLocaleDateString('it-IT', { day: '2-digit' })}`);
    const [notifySwitch, setNotifySwitch] = useState(false);

    const confirmPayment = () => {
        const payment = {
            committee: committeeId,
            referee: referee._id,
            emission_date: new Date(emissionDate).toISOString(),
            assignments: assignments.map(a => a._id),
            season: seasonId,
            details: assignments.map(a => ({
                assignment: a,
                subtotal: a.refund.subtotal,
                allowance: a.refund.allowance,
                car: a.refund.car,
                carplate: a.refund.carplate || 'n/a',
                departureArrival: a.refund.departureArrival || '',
                departureTime: a.refund.departureTime || '',
                distance: a.refund.distance,
                meal: a.refund.meal,
                notes: a.refund.notes || '',
                start_time: a.refund.start_time || '',
                end_time: a.refund.end_time || '',
                returnArrival: a.refund.returnArrival || '',
                returnTime: a.refund.returnTime || '',
                travel: a.refund.travel,
                expenses: a.refund.expenses
            })),
            subtotal: getTotals('subtotal'),
            allowance: getTotals('allowance'),
            car: getTotals('car'),
            distance: getTotals('distance'),
            meal: getTotals('meal'),
            travel: getTotals('travel'),
            expenses: getTotals('expenses')
        }
        API.insert('payments', JSON.stringify(payment), true)
            .then(res => {
                if (res.success) {
                    if (notifySwitch) {
                        const mailBody = setPaymentMail(payment, referee);
                        API.insert('emails', JSON.stringify(mailBody), true)
                            .then()
                            .catch(err => console.error(err))
                    }
                    setPayments(prevState => sortByDate([...prevState, res.payment], 'emission_date'));
                    setAlert({ type: 'success', label: 'Il pagamento è stato inserito' });
                    setOpenModal(false);
                } else {
                    setModalAlert({ type: 'failure', label: res.msg || 'Qualcosa è andato storto...' });
                }
            })
            .catch(err => console.error(err))
    }

    const deletePayment = () => {
        API.delete(`payments/${payment._id}`, true)
            .then(res => {
                if (res.success) {
                    setPayments(prevState => prevState.filter(p => p._id !== payment._id));
                    setAlert({ type: 'success', label: 'Il pagamento è stato eliminato' });
                    setOpenModal(false);
                } else {
                    setModalAlert({ type: 'failure', label: res.msg || 'Qualcosa è andato storto...' })
                }
            })
            .catch(err => console.error(err))
    }

    // Azzera l'alert del modal ogni volta che viene aperto
    useEffect(() => {
        openModal === true && setModalAlert({ type: '', label: '' });
    }, [openModal]);

    return (
        <GenericModal
            openModal={openModal}
            setOpenModal={setOpenModal}
            size="6xl"
            title={`${action === 'new' ? 'Nuovo' : 'Elimina'} Pagamento`}
        >
            {modalAlert?.type && <GenericAlert type={modalAlert.type}>{modalAlert.label}</GenericAlert>}
            {action === 'new' &&
                <>
                    Stai per effettuare un pagamento per le seguenti gare:
                    <Table hoverable={true}>
                        <Table.Head>
                            <Table.HeadCell>
                                Data
                            </Table.HeadCell>
                            <Table.HeadCell>
                                Ruolo
                            </Table.HeadCell>
                            <Table.HeadCell>
                                Gara
                            </Table.HeadCell>
                            <Table.HeadCell>
                                Diaria
                            </Table.HeadCell>
                            <Table.HeadCell>
                                Km
                            </Table.HeadCell>
                            <Table.HeadCell>
                                Pasti
                            </Table.HeadCell>
                            <Table.HeadCell>
                                Viaggio
                            </Table.HeadCell>
                            <Table.HeadCell>
                                Varie
                            </Table.HeadCell>
                        </Table.Head>
                        <Table.Body className="divide-y">
                            {
                                assignments.map(a =>
                                    <Table.Row key={a._id} className="bg-white dark:border-gray-700 dark:bg-gray-800 truncate">
                                        <Table.Cell>
                                            {formatDate(a.match.date)}
                                        </Table.Cell>
                                        <Table.Cell>
                                            {a.role}
                                        </Table.Cell>
                                        <Table.Cell>
                                            <Tooltip content={`${a.match.teamA.name} - ${a.match.teamB.name}`}>
                                                {abbrText(a.match.teamA.name, 9)} - {abbrText(a.match.teamB.name, 9)}
                                            </Tooltip>
                                        </Table.Cell>
                                        <Table.Cell>
                                            {a.refund.allowance} €
                                        </Table.Cell>
                                        <Table.Cell>
                                            <Tooltip content={`${a.refund.distance} km`}>
                                                {a.refund.car} €
                                            </Tooltip>
                                        </Table.Cell>
                                        <Table.Cell>
                                            {a.refund.meal} €
                                        </Table.Cell>
                                        <Table.Cell>
                                            {a.refund.travel} €
                                        </Table.Cell>
                                        <Table.Cell>
                                            {a.refund.expenses} €
                                        </Table.Cell>
                                    </Table.Row>
                                )
                            }
                            <Table.Row className="italic bg-indigo-50 hover:bg-indigo-100 dark:border-gray-700 dark:bg-gray-800">
                                <Table.Cell className="text-center uppercase tracking-widest" colSpan={3}>
                                    Totali
                                </Table.Cell>
                                <Table.Cell className="truncate">
                                    {getTotals('allowance')} €
                                </Table.Cell>
                                <Table.Cell className="truncate">
                                    <Tooltip content={`${getTotals('distance')} km`}>
                                        {getTotals('car')} €
                                    </Tooltip>
                                </Table.Cell>
                                <Table.Cell className="truncate">
                                    {getTotals('meal')} €
                                </Table.Cell>
                                <Table.Cell className="truncate">
                                    {getTotals('travel')} €
                                </Table.Cell>
                                <Table.Cell className="truncate">
                                    {getTotals('expenses')} €
                                </Table.Cell>
                            </Table.Row>
                            <Table.Row className="font-semibold bg-indigo-50 hover:bg-indigo-100 dark:border-gray-700 dark:bg-gray-800">
                                <Table.Cell className="text-center uppercase tracking-widest" colSpan={3}>
                                    Totale Complessivo
                                </Table.Cell>
                                <Table.Cell className="text-center truncate uppercase tracking-widest" colSpan={5}>
                                    {getTotals('subtotal')} €
                                </Table.Cell>
                            </Table.Row>
                        </Table.Body>
                    </Table>
                    <div className="flex flex-row gap-2 items-center">
                        <TextInput
                            id="emission_date"
                            type="date"
                            value={emissionDate}
                            onChange={(e) => setEmissionDate(e.currentTarget.value)}
                            required={true}
                        />
                        <div className="block">
                            <Label htmlFor="emission_date">Data di emissione</Label>
                        </div>
                    </div>
                    <div
                        className="flex flex-col gap-4"
                        id="toggle"
                    >
                        <ToggleSwitch
                            checked={notifySwitch}
                            label="Invia una mail di notifica all'arbitro"
                            onChange={() => setNotifySwitch(prevState => !prevState)}
                        />
                    </div>
                </>
            }
            {action === 'delete' &&
                <div className="font-semibold text-center text-xl">
                    Vuoi eliminare il pagamento del {formatDate(payment.emission_date)} di € {payment.subtotal} ?
                </div>
            }
            <div className="flex justify-center gap-4">
                {action === 'new' &&
                    <Button
                        onClick={confirmPayment}
                    >
                        Conferma il pagamento
                    </Button>
                }
                {action === 'delete' &&
                    <Button
                        color="failure"
                        onClick={deletePayment}
                    >
                        Elimina il pagamento
                    </Button>
                }
                <Button
                    color="light"
                    onClick={() => setOpenModal(false)}
                >
                    Annulla
                </Button>
            </div>
        </GenericModal>
    )
}

const Legenda = () => {
    return (
        <div className="mx-auto border rounded-lg bg-slate-500 p-2">
            <h4 className="font-semibold uppercase text-sm text-gray-100 text-center mb-2">Legenda colori</h4>
            <div className="flex flex-row gap-2">
                <p className="px-2 py-1.5 text-xs uppercase font-semibold text-gray-800 bg-yellow-100 border border-gray-200 rounded-lg dark:text-gray-100 dark:border-gray-500">
                    Rimborso non inserito
                </p>
                <p className="px-2 py-1.5 text-xs uppercase font-semibold text-gray-800 bg-gray-100 border border-gray-200 rounded-lg dark:text-gray-100 dark:border-gray-500">
                    Rimborso inserito
                </p>
                <p className="px-2 py-1.5 text-xs uppercase font-semibold text-gray-800 bg-red-100 border border-red-200 rounded-lg dark:text-gray-100 dark:border-gray-500">
                    Distanza modificata
                </p>
            </div>
        </div>
    )
}

function Payments() {
    const { _id: committeeId } = useSelector(state => state.committee);
    const { _id: seasonId } = useSelector(state => state.season);
    const [action, setAction] = useState('new');
    const [alert, setAlert] = useState({ type: '', label: '' });
    const [allSelected, setAllSelected] = useState(false);
    const [assignments, setAssignments] = useState([]);
    const [championships, setChampionships] = useState([]);
    const [loading, setLoading] = useState(true);
    const [openPaymentsModal, setOpenPaymentsModal] = useState(false);
    const [payment, setPayment] = useState({});
    const [payments, setPayments] = useState([]);
    const [referee, setReferee] = useState('');
    const [referees, setReferees] = useState([]);
    const [totalAssignments, setTotalAssignments] = useState([]);
    const getTotals = useCallback((prop) => {
        return assignments
            .filter(a => a.selected)
            .reduce((prev, curr) => (prev * 1000 + Number(curr.refund[prop]) * 1000) / 1000, 0)
    }, [assignments]);

    const checkAllowance = useCallback((a) => {
        if (a.refund?.hasOwnProperty('allowance')) return a.refund.allowance;
        const c = championships.find(c => c._id === a.match.championship._id);
        if (c) {
            const allowance = c.allowances?.find(all => all.role === a.role)
            return allowance?.amount || 0;
        }
        return 0;
    }, [championships])

    const newPayment = () => {
        setAction('new');
        setOpenPaymentsModal(true);
    }

    // Championships
    useEffect(() => {
        if (committeeId && seasonId) {
            API.get(`championships?season=${seasonId}&committee=${committeeId}`)
                .then(res => {
                    if (res.success) {
                        setChampionships(res.championships);
                    }
                })
                .catch(err => console.error(err))
        }
    }, [committeeId, seasonId])

    // Referee List
    useEffect(() => {
        if (committeeId) {
            API.get(`users?isreferee=true&committees=${committeeId}`, true)
                .then(res => {
                    if (res.success) {
                        setReferees(sortByName(res.users, 'lastname'));
                    } else {
                        setAlert({ type: 'failure', label: res.msg || 'Qualcosa è andato storto...' });
                    }
                })
                .catch(err => console.error(err))
        }
    }, [committeeId])

    // Get Assignments / Payments by Referee
    useEffect(() => {
        setLoading(true);
        setAllSelected(false);
        if (referee) {
            API.get(`payments?referee=${referee._id}&season=${seasonId}`)
                .then(res => {
                    if (res.success) {
                        setPayments(sortByDate(res.payments, 'emission_date'));
                    } else {
                        setPayments([]);
                    }
                })
                .catch(err => console.error(err))
            API.get(`assignments?referee=${referee._id}&season=${seasonId}&committee=${committeeId}&status=3`, true)
                .then(res => {
                    if (res.success) {
                        // Aggiorno lo state, aggiungendo lo stato della checkbox
                        setTotalAssignments(sortByDate(res.assignments
                            .map(a => {
                                const resultAss = {
                                    ...a,
                                    selected: false,
                                    inserted: a.refund ? true : false,
                                    refund: {
                                        allowance: checkAllowance(a),
                                        subtotal: 0,
                                        distance: a.refund?.distance || 0,
                                        car: a.refund?.car || 0,
                                        carplate: a.refund?.carplate || '',
                                        departureArrival: a.refund?.departureArrival || '00:00',
                                        departureTime: a.refund?.departureTime || '00:00',
                                        edited_distance: a.refund?.edited_distance || false,
                                        meal: a.refund?.meal || 0,
                                        travel: a.refund?.travel || 0,
                                        expenses: a.refund?.expenses || 0,
                                        returnArrival: a.refund?.returnArrival || '00:00',
                                        returnTime: a.refund?.returnTime || '00:00',
                                        start_time: a.refund?.start_time || '',
                                        end_time: a.refund?.end_time || '',
                                        notes: a.refund?.notes || '',
                                        updated_at: a.refund?.updated_at || '',
                                        verified: a.refund?.verified
                                    }
                                }
                                resultAss.refund.subtotal = resultAss.refund.allowance + resultAss.refund.car + resultAss.refund.meal + resultAss.refund.travel + resultAss.refund.expenses;
                                return resultAss;
                            }), 'match.date'));
                    } else {
                        setTotalAssignments([]);
                    }
                })
                .catch(err => console.error(err))
        } else {
            setTotalAssignments([]);
        }
        setLoading(false);
    }, [checkAllowance, committeeId, referee, seasonId])

    // Update Assignments / Payments
    useEffect(() => {
        const paidAssignments = payments.reduce((prev, p) => [...prev, ...p.assignments], []);
        setAssignments(sortByDate(totalAssignments
            .filter(a => !paidAssignments.includes(a._id) && new Date(a.match?.date) < new Date())
            .map(a => {
                const resultAss = {
                    ...a,
                    selected: false,
                    refund: {
                        allowance: checkAllowance(a),
                        subtotal: 0,
                        distance: a.refund?.distance || 0,
                        car: a.refund?.car || 0,
                        carplate: a.refund?.carplate || '',
                        departureArrival: a.refund?.departureArrival || '00:00',
                        departureTime: a.refund?.departureTime || '00:00',
                        edited_distance: a.refund?.edited_distance || false,
                        meal: a.refund?.meal || 0,
                        travel: a.refund?.travel || 0,
                        expenses: a.refund?.expenses || 0,
                        returnArrival: a.refund?.returnArrival || '00:00',
                        returnTime: a.refund?.returnTime || '00:00',
                        start_time: a.refund?.start_time || '',
                        end_time: a.refund?.end_time || '',
                        notes: a.refund?.notes || '',
                        updated_at: a.refund?.updated_at || '',
                        verified: a.refund?.verified
                    }
                }
                resultAss.refund.subtotal = resultAss.refund.allowance + resultAss.refund.car + resultAss.refund.meal + resultAss.refund.travel + resultAss.refund.expenses;
                return resultAss;
            }), 'match.date'));
    }, [checkAllowance, payments, totalAssignments])

    return (
        <div className="flex flex-col gap-3">
            {alert?.type && <GenericAlert type={alert.type}>{alert.label}</GenericAlert>}
            <div className="hidden xl:block">
                <div className="flex flex-row gap-3">
                    <RefereeSelect
                        referee={referee}
                        referees={referees}
                        setReferee={setReferee}
                    />
                    <div className="w-fit mt-7">
                        <Button
                            size="sm"
                            onClick={newPayment}
                            disabled={
                                referee
                                    ? assignments.some(a => a.selected)
                                        ? false
                                        : true
                                    : true
                            }
                        >
                            Nuovo Pagamento
                        </Button>
                    </div>
                    {referee &&
                        <div>
                            <p className="mt-5 block font-semibold">IBAN</p>
                            <p>{referee.iban || 'Non inserito'}</p>
                        </div>
                    }
                    <Legenda />
                </div>
                <h3 className="font-bold uppercase tracking-wider">Designazioni da verificare</h3>
                <AssignmentsTable
                    allSelected={allSelected}
                    assignments={assignments}
                    getTotals={getTotals}
                    loading={loading}
                    referee={referee}
                    setAlert={setAlert}
                    setAllSelected={setAllSelected}
                    setAssignments={setAssignments}
                />
                <h3 className="font-bold uppercase tracking-wider">Pagamenti inviati</h3>
                <PaymentsTable
                    loading={loading}
                    payments={payments}
                    referee={referee}
                    setAction={setAction}
                    setOpenPaymentsModal={setOpenPaymentsModal}
                    setPayment={setPayment}
                />
                <PaymentsModal
                    action={action}
                    assignments={assignments.filter(a => a.selected)}
                    getTotals={getTotals}
                    openModal={openPaymentsModal}
                    payment={payment}
                    referee={referee}
                    setAlert={setAlert}
                    setOpenModal={setOpenPaymentsModal}
                    setPayments={setPayments}
                />
            </div>
            <div className="xl:hidden">
                <GenericAlert type="warning">
                    Per visualizzare correttamente questa pagina, usa una risoluzione maggiore.
                </GenericAlert>
            </div>
        </div>
    )
}

export default Payments;