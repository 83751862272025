import { useState } from 'react';
import { abbrText, formatDateTime } from '../../../../lib/utils';
import { serviceProvider as API } from '../../../../API/api';

// Components
import { Button, Spinner, Tooltip } from 'flowbite-react';
import GenericAlert from '../../../elements/GenericAlert';
import GenericModal from '../../../elements/GenericModal';


const SendForm = ({ action, assignments, setModalAlert, setOpenModal, setViewAssignments, viewAssignments }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [startIndex, setStartIndex] = useState(0);

    const addNav = () => {
        setViewAssignments(assignments.slice(startIndex + 10, startIndex + 20));
        setStartIndex(prevState => prevState + 10);
    }

    const subNav = () => {
        setViewAssignments(assignments.slice(startIndex - 10, startIndex));
        setStartIndex(prevState => prevState - 10);
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        setIsLoading(true);
        assignments.forEach(a => {
            API.update(`assignments/${a._id}`, JSON.stringify({ sent_to_ras: action === 'cancel' ? false : true }), true)
                .then()
                .catch(err => {
                    setModalAlert({type: 'failure', label: err || 'Qualcosa è andato storto...'});
                    console.error(err);
                })
        })

        // Non servirebbe, ma simula il caricamento asincrono
        setTimeout(() => {
            setOpenModal(false);
            setIsLoading(false);
        }, 1000)
    }

    return (
        <form
            className="flex flex-col gap-4"
            onSubmit={handleSubmit}
        >
            <div>
                Stai segnando come inviate al Registro Attività Sportive le seguenti designazioni:
                <ul className="space-y-1 list-none list-inside p-1 cursor-default rounded-lg text-center text-gray-700 dark:text-gray-400">
                    {viewAssignments.map((a, i) =>
                        <li className={`grid grid-cols-2 sm:grid-cols-4 lg:grid-cols-5 border gap-1 items-center justify-between rounded-lg ${i % 2 === 0 ? 'bg-pink-100 hover:bg-pink-200 border-pink-300' : 'bg-purple-100 hover:bg-purple-200 border-purple-300'}`} key={a._id}>
                            <span>{formatDateTime(a.match.date)}</span>
                            {<span className="font-semibold">{a.referee.lastname} {a.referee.firstname[0]}.</span>}
                            <Tooltip content={`Gara #${a.match.num}: ${a.match.teamA.name} / ${a.match.teamB.name}`}>
                                <span>
                                    Gara #{a.match.num}: {abbrText(a.match.teamA.name || '', 8)} / {abbrText(a.match.teamB.name || '', 8)}
                                </span>
                            </Tooltip>
                            <Tooltip content={a.match.championship.name}>
                                <span className="hidden lg:block">
                                    {abbrText(a.match.championship.name, 8)}
                                </span>
                            </Tooltip>
                            <span>{a.role}</span>
                        </li>
                    )
                    }
                </ul>
            </div>
            {assignments.length > 10 &&
                <div className="flex flex-row gap-4 ">
                    <div>
                        <Button
                            onClick={subNav}
                            outline
                            disabled={startIndex < 1}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="m18.75 4.5-7.5 7.5 7.5 7.5m-6-15L5.25 12l7.5 7.5" />
                            </svg>
                        </Button>
                    </div>
                    <div>
                        <Button
                            onClick={addNav}
                            outline
                            disabled={assignments.length < startIndex + 10}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="m5.25 4.5 7.5 7.5-7.5 7.5m6-15 7.5 7.5-7.5 7.5" />
                            </svg>
                        </Button>
                    </div>
                </div>
            }
            <GenericAlert type="warning">
                <strong>Attenzione.</strong> Questa operazione serve come promemoria all'amministratore. Nessun dato viene effettivamente trasmesso in automatico al Portale Campionati.
            </GenericAlert>
            <div className="m-auto w-60">
                <Button
                    disabled={isLoading}
                    type="submit"
                >
                    {isLoading &&
                        <span className='mr-1'>
                            <Spinner />
                        </span>}
                    {`Segna come ${action === 'cancel' ? 'NON' : ''} inviati`}
                </Button>
            </div>
        </form>
    )
}


function SendRasModal({ action, assignments, openModal, setOpenModal, setViewAssignments, viewAssignments }) {
    const [modalAlert, setModalAlert] = useState({ type: '', label: '' });

    return (
        <GenericModal
            openModal={openModal}
            setOpenModal={setOpenModal}
            size="6xl"
            title={`Segna come ${action === 'cancel' ? 'NON' : ''} inviati`}
        >
            {modalAlert?.type && <GenericAlert type={modalAlert.type}>{modalAlert.label}</GenericAlert>}
            <SendForm
                action={action}
                assignments={assignments}
                setModalAlert={setModalAlert}
                setOpenModal={setOpenModal}
                setViewAssignments={setViewAssignments}
                viewAssignments={viewAssignments}
            />
        </GenericModal>
    )
}

export default SendRasModal;