import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { serviceProvider as API } from "../../../API/api";
import { sortByName } from "../../../lib/utils";

// Components
import { Button, Select, Table, TextInput, Tooltip } from "flowbite-react";
import DeleteButton from "../../buttons/DeleteButton";
import EditButton from "../../buttons/EditButton";
import FormLabel from "../../elements/FormLabel";
import GenericAlert from "../../elements/GenericAlert";
import GenericModal from "../../elements/GenericModal";
import LoadingRow from "../../elements/LoadingRow";
import NoResultsRow from "../../elements/NoResultsRow";
import SubmitButton from "../../buttons/SubmitButton";


const TableRow = ({ club, setAction, setClub, setOpenModal, teams }) => {
    const { _id, name, committees } = club;
    const { _id: committeeId } = useSelector(state => state.committee);
    const isDeletable = useCallback(() => {
        if (club.committees.length > 1) return false;
        if (teams.findIndex(t => t.club._id === club._id) > -1) return false;
        return true;
    }, [club, teams]);

    const rowClub = {
        _id: _id,
        name: name || '',
        committees: committees || [committeeId]
    }

    const handleEdit = () => {
        setAction('edit');
        setClub(rowClub);
        setOpenModal(true);
    }

    const handleDelete = () => {
        setAction('delete');
        setClub(rowClub);
        setOpenModal(true);
    }

    return (
        <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
            <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                {name}
            </Table.Cell>
            <Table.Cell>
                <div className="flex flex-row">
                <EditButton onClick={handleEdit} />
                {isDeletable() &&
                    <DeleteButton
                        onClick={handleDelete}
                    />
                }
                {!isDeletable() &&
                    <Tooltip content="Ci sono delle squadre associate, o la società è assegnata a più di un comitato">
                        <DeleteButton
                            disabled={true}
                            onClick={handleDelete}
                        />
                    </Tooltip>
                }
                </div>
            </Table.Cell>
        </Table.Row>
    )
}

const MainTable = ({ clubs, loading, setAction, setClub, setOpenModal, teams }) => {


    return (
        <Table hoverable={true}>
            <Table.Head>
                <Table.HeadCell>
                    Nome
                </Table.HeadCell>
                <Table.HeadCell>
                    <span className="sr-only">
                        Edit
                    </span>
                </Table.HeadCell>
            </Table.Head>
            <Table.Body className="divide-y">
                {loading && <LoadingRow colspan={2} />}
                {(!loading && clubs.length < 1) && <NoResultsRow colspan={2} />}
                {
                    clubs.map(club =>
                        <TableRow
                            key={club._id}
                            club={club}
                            setAction={setAction}
                            setClub={setClub}
                            setOpenModal={setOpenModal}
                            teams={teams}
                        />)
                }
            </Table.Body>
        </Table>
    )
}

const MainForm = ({ action, club, setAlert, setClub, setClubs, setModalAlert, setOpenModal }) => {
    const [committees, setCommittees] = useState([]);
    const { role: authRole } = useSelector(state => state.user);

    const handleInput = (e) => {
        const { id, selectedOptions, value } = e.currentTarget;
        setClub(prevState => ({
            ...prevState,
            [id]: selectedOptions && id === 'committees'
                ? Array.from(selectedOptions).map(opt => opt.value)
                : value
        }));
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        switch (action) {
            case 'new':
                API.insert('clubs', JSON.stringify(club), true)
                    .then(res => {
                        if (res.success) {
                            setClubs(prevState => sortByName([...prevState, res.club], 'name'));
                            setAlert({ type: 'success', label: 'La società è stata inserita' });
                            setOpenModal(false);
                        } else {
                            setModalAlert({ type: 'failure', label: res.msg || 'Qualcosa è andato storto...' });
                        }
                    })
                    .catch(err => console.error(err));
                break;
            case 'edit':
                API.update(`clubs/${club._id}`, JSON.stringify(club), true)
                    .then(res => {
                        if (res.success) {
                            setClubs(prevState => {
                                const index = prevState.findIndex(el => el._id === club._id);
                                prevState[index] = club;
                                return sortByName(prevState, 'name')
                            });
                            setAlert({ type: 'success', label: 'La società è stata modificata correttamente' });
                            setOpenModal(false);
                        } else {
                            setModalAlert({ type: 'failure', label: res.msg || 'Qualcosa è andato storto...' });
                        }
                    })
                    .catch(err => console.error(err));
                break;
            case 'delete':
                API.delete(`clubs/${club._id}`, true)
                    .then(res => {
                        if (res.success) {
                            setClubs(prevState => prevState.filter(el => el._id !== club._id));
                            setAlert({ type: 'success', label: 'La società è stata eliminata' });
                            setOpenModal(false);
                        } else {
                            setModalAlert({ type: 'failure', label: res.msg || 'Qualcosa è andato storto...' });
                        }
                    })
                    .catch(err => console.error(err));
                break;
            default:
        }
    }

    useEffect(() => {

        API.get('committees')
            .then(res => {
                if (res.success) {
                    setCommittees(sortByName(res.committees, 'name'));
                } else {
                    setCommittees([]);
                }
            })
            .catch(err => console.error(err))
    }, [])

    return (
        <form className="flex flex-col gap-4" onSubmit={handleSubmit}>
            <div>
                <div className="mb-2 block">
                    <FormLabel htmlFor="name" label="Nome" />
                </div>
                <TextInput
                    id="name"
                    type="text"
                    required={true}
                    value={club.name}
                    onChange={handleInput}
                    disabled={action === 'delete' ? true : false}
                />
            </div>
            {
                authRole < 1 && (
                    <div>
                        <div className="mb-2 block">
                            <FormLabel htmlFor="committees" label="Comitati" />
                        </div>
                        <Select
                            id="committees"
                            multiple
                            required={true}
                            onChange={handleInput}
                            value={club.committees}
                        >
                            {
                                committees.map(committee =>
                                    <option
                                        key={committee._id}
                                        value={committee._id}
                                    >
                                        {committee.name}
                                    </option>
                                )
                            }
                        </Select>
                    </div>
                )
            }
            {
                action === 'delete' &&
                <GenericAlert type="warning" divclass="text-lg mx-auto" hideicon="true">Desideri eliminare questa società?</GenericAlert>
            }
            <div className="text-center">
                <SubmitButton />
            </div>
        </form>
    )
}

const MainModal = ({ action, club, openModal, setAlert, setClub, setClubs, setOpenModal }) => {
    const [modalAlert, setModalAlert] = useState({ type: '', label: '' });

    // Azzera l'alert del modal ogni volta che viene aperto
    useEffect(() => {
        openModal === true && setModalAlert({ type: '', label: '' });
    }, [openModal]);

    return (
        <GenericModal openModal={openModal} setOpenModal={setOpenModal} title={`${action === 'new' ? 'Nuova' : action === 'edit' ? 'Modifica' : 'Elimina'} Società`}>
            {modalAlert?.type && <GenericAlert type={modalAlert.type}>{modalAlert.label}</GenericAlert>}
            <MainForm action={action} club={club} setAlert={setAlert} setClub={setClub} setClubs={setClubs} setModalAlert={setModalAlert} setOpenModal={setOpenModal} />
        </GenericModal>
    )
}

function Clubs() {
    const { _id: committeeId } = useSelector(state => state.committee);
    const [action, setAction] = useState('new');
    const [alert, setAlert] = useState({ type: '', label: '' });
    const [clubs, setClubs] = useState([]);
    const [club, setClub] = useState({ name: '', committees: [committeeId] });
    const [loading, setLoading] = useState(true);
    const [openModal, setOpenModal] = useState(false);
    const [teams, setTeams] = useState([]);

    const openCommModal = () => {
        setClub({ name: '', committees: [committeeId] });
        setAction('new');
        setOpenModal(true);
    }

    useEffect(() => {
        API.get(`clubs?committees=${committeeId}`)
            .then(res => {
                if (res.success) {
                    setClubs(sortByName(res.clubs, 'name'));
                    setAlert({ type: '', label: '' });
                    setLoading(false);
                } else {
                    setClubs([]);
                    setAlert({ type: 'failure', label: res.msg });
                    setLoading(false);
                }
            })
            .catch(err => console.error(err))
    }, [committeeId, setClubs, setAlert])

    useEffect(() => {
        if (committeeId) {
            API.get(`teams?&committee=${committeeId}`)
                .then(res => {
                    if (res.success) {
                        setTeams(res.teams);
                    } else {
                        setTeams([]);
                        setAlert({ type: 'failure', label: res.msg });
                    }
                })
                .catch(err => console.error(err))
        }
    }, [committeeId])

    return (
        <div className="flex flex-col gap-3">
            {alert?.type && <GenericAlert type={alert.type}>{alert.label}</GenericAlert>}
            <MainTable
                clubs={clubs}
                loading={loading}
                setAction={setAction}
                setClub={setClub}
                setOpenModal={setOpenModal}
                teams={teams}
            />
            <div className="w-fit">
                <Button size="sm" onClick={openCommModal}>
                    Nuova Società
                </Button>
            </div>
            <MainModal action={action} club={club} openModal={openModal} setAlert={setAlert} setClub={setClub} setClubs={setClubs} setOpenModal={setOpenModal} />
        </div>
    )
}

export default Clubs;