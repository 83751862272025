import { useState, useEffect } from "react";
import { serviceProvider as API } from "../../../../../API/api";

// Components
import { Select, TextInput } from "flowbite-react";
import FormLabel from "../../../../elements/FormLabel";
import GenericAlert from "../../../../elements/GenericAlert";
import GenericModal from "../../../../elements/GenericModal";
import SubmitButton from "../../../../buttons/SubmitButton";


const PenaltyForm = ({ action, championship, penalty, setAlert, setChampionship, setModalAlert, setOpenModal, setPenalty, teams }) => {
    const [isLoading, setIsLoading] = useState(false);

    const handleInput = (e) => {
        const { id, value } = e.currentTarget;
        setPenalty(prevState => ({
            ...prevState,
            [id]: id === 'team' ?
                { _id: value }
                : value
        }));
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        setIsLoading(true);
        const previousPenalties = championship.penalties || [];
        // Non è necessario un Switch, in quanto l'azione da eseguire è sempre un PATCH
        const penalties = action === 'new'
            // case NEW
            ? [...previousPenalties, penalty]
                .sort((a, b) => new Date(a.date) - new Date(b.date))
            : action === 'edit'
                // case EDIT
                ? [...previousPenalties.filter(p => p._id.toString() !== penalty._id.toString()), penalty]
                    .sort((a, b) => new Date(a.date) - new Date(b.date))
                // case DELETE
                : previousPenalties.filter(p => p._id.toString() !== penalty._id.toString())
            ;
        API.update(
            `championships/${championship._id}`,
            JSON.stringify({
                penalties: penalties
            }),
            true)
            .then(res => {
                if (res.success) {
                    setChampionship(res.championship);
                    setAlert({ type: 'success', label: `La penalità è stata ${action === 'new' ? 'inserita' : action === 'edit' ? 'modificata' : 'eliminata'} correttamente` });
                    setOpenModal(false);
                } else {
                    setModalAlert({ type: 'failure', label: 'Qualcosa è andato storto...' });
                }
                setIsLoading(false);
            });
    }

    return (
        <form
            className="flex flex-col gap-4"
            onSubmit={handleSubmit}
        >
            <div>
                <div className="mb-2 block">
                    <FormLabel htmlFor="group_name" label="Nome" />
                </div>
                <TextInput
                    id="date"
                    type="date"
                    required={true}
                    value={penalty.date}
                    onChange={handleInput}
                    disabled={action === 'delete' ? true : false}
                />
            </div>
            <div>
                <div className="mb-2 block">
                    <FormLabel htmlFor="teams" label="Squadre" />
                </div>
                <Select
                    id="team"
                    required={true}
                    onChange={handleInput}
                    value={penalty.team._id}
                    disabled={action === 'delete' ? true : false}
                >
                    <option value="">-</option>
                    {
                        teams?.map(t =>
                            <option key={t._id} value={t._id}>
                                {t.name}
                            </option>
                        )
                    }
                </Select>
            </div>
            <div>
                <div className="mb-2 block">
                    <FormLabel htmlFor="group_name" label="Valore" />
                </div>
                <TextInput
                    id="value"
                    type="number"
                    required={true}
                    min={-5}
                    max={5}
                    step={1}
                    value={penalty.value}
                    onChange={handleInput}
                    disabled={action === 'delete' ? true : false}
                />
            </div>
            <div>
                <div className="mb-2 block">
                    <FormLabel htmlFor="group_name" label="Motivazione / Note" />
                </div>
                <TextInput
                    id="notes"
                    type="text"
                    required={true}
                    value={penalty.notes}
                    onChange={handleInput}
                    disabled={action === 'delete' ? true : false}
                />
            </div>
            {
                action === 'delete' &&
                <GenericAlert type="warning" divclass="text-lg mx-auto" hideicon="true">Desideri eliminare questa penalità?</GenericAlert>
            }
            <div className="text-center">
                <SubmitButton isLoading={isLoading} />
            </div>
        </form>
    )
}

function PenaltyModal({ action, championship, openModal, penalty, setAlert, setChampionship, setOpenModal, setPenalty, teams }) {
    const [modalAlert, setModalAlert] = useState({ type: '', label: '' });

    // Azzera l'alert del modal ogni volta che viene aperto
    useEffect(() => {
        openModal === true && setModalAlert({ type: '', label: '' });
    }, [openModal]);

    return (
        <GenericModal
            openModal={openModal}
            setOpenModal={setOpenModal}
            title={`${action === 'new' ? 'Nuova' : action === 'edit' ? 'Modifica' : 'Elimina'} Penalità`}
        >
            {modalAlert?.type && <GenericAlert type={modalAlert.type}>{modalAlert.label}</GenericAlert>}
            <PenaltyForm
                action={action}
                championship={championship}
                penalty={penalty}
                setAlert={setAlert}
                setChampionship={setChampionship}
                setModalAlert={setModalAlert}
                setOpenModal={setOpenModal}
                setPenalty={setPenalty}
                teams={teams}
            />
        </GenericModal>
    )
}

export default PenaltyModal;