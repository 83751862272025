import { useEffect, useState } from "react";
import { serviceProvider as API } from "../../../API/api";
import { formatDate, formToIsoString, genUrlcode, isoToForm, sortByName } from "../../../lib/utils";

// Components
import { Button, Table, TextInput } from "flowbite-react";
import DeleteButton from "../../buttons/DeleteButton";
import EditButton from "../../buttons/EditButton";
import FormLabel from "../../elements/FormLabel";
import GenericAlert from "../../elements/GenericAlert";
import GenericModal from "../../elements/GenericModal";
import LoadingRow from "../../elements/LoadingRow";
import NoResultsRow from "../../elements/NoResultsRow";
import SubmitButton from "../../buttons/SubmitButton";

const TableRow = ({ season, setAction, setSeason, setOpenModal }) => {
    const { _id: id, name, season_code, startDate, endDate, urlcode } = season;

    const rowSeason = {
        _id: id,
        name: name || '',
        season_code: season_code || '',
        startDate: formToIsoString(`${startDate}T00:00`),
        endDate: formToIsoString(`${endDate}T23:59`),
        urlcode: urlcode || ''
    }

    const handleEdit = () => {
        setAction('edit');
        setSeason(rowSeason);
        setOpenModal(true);
    }

    const handleDelete = () => {
        setAction('delete');
        setSeason(rowSeason);
        setOpenModal(true);
    }


    return (
        <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
            <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                {name}
            </Table.Cell>
            <Table.Cell>
                {formatDate(startDate)}
            </Table.Cell>
            <Table.Cell>
                {formatDate(endDate)}
            </Table.Cell>
            <Table.Cell>
                <EditButton onClick={handleEdit} />
                <DeleteButton onClick={handleDelete} />
            </Table.Cell>
        </Table.Row>
    )
}

const SeasonsTable = ({ seasons, setAlert, setAction, setSeason, setSeasons, setOpenModal }) => {
    const [loading, setLoading] = useState(true);
    const [noResults, setNoResults] = useState(false);

    useEffect(() => {
        API.get('seasons')
            .then(res => {
                if (res.success) {
                    setSeasons(sortByName(res.seasons, 'name'));
                    setAlert({ type: '', label: '' });
                    setNoResults(false);
                    setLoading(false);
                } else {
                    setSeasons([]);
                    setAlert({ type: 'failure', label: res.msg });
                    setNoResults(true);
                    setLoading(false);
                }
            })
            .catch(err => console.error(err))
    }, [setSeasons, setAlert])

    useEffect(() => {
        if (seasons.length) {
            setNoResults(false);
            setLoading(false);
        } else {
            setNoResults(true);
            setLoading(false);
        }
    }, [seasons]);

    return (
        <Table hoverable={true}>
            <Table.Head>
                <Table.HeadCell>
                    Nome
                </Table.HeadCell>
                <Table.HeadCell>
                    Data d'Inizio
                </Table.HeadCell>
                <Table.HeadCell>
                    Data di Fine
                </Table.HeadCell>
                <Table.HeadCell>
                    <span className="sr-only">
                        Edit
                    </span>
                </Table.HeadCell>
            </Table.Head>
            <Table.Body className="divide-y">
                {loading && <LoadingRow colspan={4} />}
                {(!loading && noResults) && <NoResultsRow colspan={4} />}
                {
                    seasons.map((season, i) =>
                        <TableRow
                            key={i}
                            season={season}
                            setAction={setAction}
                            setSeason={setSeason}
                            setOpenModal={setOpenModal}
                        />
                    )
                }
            </Table.Body>
        </Table>
    )
}

const SeasonsForm = ({ action, season, setAlert, setSeason, setSeasons, setModalAlert, setOpenModal }) => {

    const autoUrlCode = () => {
        setSeason(prevState => ({
            ...prevState,
            urlcode: genUrlcode(prevState.name)
        }))
    }

    const handleInput = (e) => {
        const { id, value } = e.currentTarget;
        setSeason(prevState => ({
            ...prevState,
            [id]: value
        }));
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        const inputSeason = {
            _id: season._id,
            name: season.name,
            season_code: season.season_code,
            startDate: formToIsoString(`${season.startDate}T00:00`),
            endDate: formToIsoString(`${season.endDate}T23:59`),
            urlcode: season.urlcode
        }

        switch (action) {
            case 'new':
                delete inputSeason._id;
                API.insert('seasons', JSON.stringify(inputSeason), true)
                    .then(res => {
                        if (res.success) {
                            setSeasons(prevState => sortByName([...prevState, res.season], 'name'));
                            setAlert({ type: 'success', label: 'La nuova stagione è stata creata' });
                            setOpenModal(false);
                        } else {
                            setModalAlert({ type: 'failure', label: res.msg || 'Qualcosa è andato storto...' });
                        }
                    })
                    .catch(err => console.error(err));
                break;
            case 'edit':
                API.update(`seasons/${season._id}`, JSON.stringify(inputSeason), true)
                    .then(res => {
                        if (res.success) {
                            setSeasons(prevState => {
                                const index = prevState.findIndex(el => el._id === season._id);
                                prevState[index] = inputSeason;
                                return sortByName(prevState, 'name')
                            });
                            setAlert({ type: 'success', label: 'Modifica effettuata correttamente' });
                            setOpenModal(false);
                        } else {
                            setModalAlert({ type: 'failure', label: res.msg || 'Qualcosa è andato storto...' });
                        }
                    })
                    .catch(err => console.error(err));
                break;
            case 'delete':
                API.delete(`seasons/${season._id}`, true)
                    .then(res => {
                        if (res.success) {
                            setSeasons(prevState => prevState.filter(el => el._id !== season._id));
                            setAlert({ type: 'success', label: 'La stagione è stata eliminata' });
                            setOpenModal(false);
                        } else {
                            setModalAlert({ type: 'failure', label: res.msg || 'Qualcosa è andato storto...' });
                        }
                    })
                    .catch(err => console.error(err));
                break;
            default:
        }
    }

    return (
        <form className="flex flex-col gap-4" onSubmit={handleSubmit}>
            <div>
                <div className="mb-2 block">
                    <FormLabel htmlFor="name" label="Nome" />
                </div>
                <TextInput
                    id="name"
                    type="text"
                    required={true}
                    value={season.name}
                    onChange={handleInput}
                    disabled={action === 'delete' ? true : false}
                />
            </div>
            <div>
                <div className="mb-2 block">
                    <FormLabel htmlFor="startDate" label="Data d'Inizio" />
                </div>
                <TextInput
                    id="startDate"
                    type="date"
                    required={true}
                    value={isoToForm(season.startDate)}
                    onChange={handleInput}
                    disabled={action === 'delete' ? true : false}
                />
            </div>
            <div>
                <div className="mb-2 block">
                    <FormLabel htmlFor="endDate" label="Data di Fine" />
                </div>
                <TextInput
                    id="endDate"
                    type="date"
                    required={true}
                    value={isoToForm(season.endDate)}
                    onChange={handleInput}
                    disabled={action === 'delete' ? true : false}
                />
            </div>
            <div>
                <div className="mb-2 block">
                    <FormLabel htmlFor="season_code" label="Codice stagione" />
                </div>
                <TextInput
                    id="season_code"
                    type="text"
                    placeholder="Es: 2023"
                    value={season.season_code}
                    onChange={handleInput}
                    disabled={action === 'delete' ? true : false}
                />
            </div>
            <div className="flex flex-row gap-2 items-end">
                <div className="basis-10/12">
                    <div className="mb-2 block">
                        <FormLabel htmlFor="urlcode" label="Urlcode" />
                    </div>
                    <TextInput
                        id="urlcode"
                        type="text"
                        required={true}
                        value={season.urlcode}
                        onChange={handleInput}
                        disabled={action === 'delete' ? true : false}
                    />
                </div>
                <div>
                    <Button 
                        size="sm" 
                        color="dark"
                        onClick={autoUrlCode}
                    >
                        Auto
                    </Button>
                </div>
            </div>
            <span className="text-xs">L'urlcode deve essere univoco tra le stagioni, minuscolo e senza spazi</span>
            {
                action === 'delete' &&
                <GenericAlert type="warning" divclass="text-lg mx-auto" hideicon="true">Desideri eliminare questa stagione?</GenericAlert>
            }
            <div className="text-center">
                <SubmitButton />
            </div>
        </form>
    )
}

const SeasonsModal = ({ action, season, openModal, setAlert, setSeason, setSeasons, setOpenModal }) => {
    const [modalAlert, setModalAlert] = useState({ type: '', label: '' });

    // Azzera l'alert del modal ogni volta che viene aperto
    useEffect(() => {
        openModal === true && setModalAlert({ type: '', label: '' });
    }, [openModal]);

    return (
        <GenericModal openModal={openModal} setOpenModal={setOpenModal} title={`${action === 'new' ? 'Nuovo' : action === 'edit' ? 'Modifica' : 'Elimina'} Stagione`}>
            {modalAlert?.type && <GenericAlert type={modalAlert.type}>{modalAlert.label}</GenericAlert>}
            <SeasonsForm action={action} season={season} setAlert={setAlert} setSeason={setSeason} setSeasons={setSeasons} setModalAlert={setModalAlert} setOpenModal={setOpenModal} />
        </GenericModal>
    )
}

function Seasons() {
    const [action, setAction] = useState('new');
    const [alert, setAlert] = useState({ type: '', label: '' });
    const [seasons, setSeasons] = useState([]);
    const [season, setSeason] = useState({ name: '', season_code: '', startDate: '', endDate: '', urlcode: '' });
    const [openModal, setOpenModal] = useState(false);

    const openSeasonModal = () => {
        setSeason({ name: '', season_code: '', startDate: '', endDate: '', urlcode: '' });
        setAction('new');
        setOpenModal(true);
    }

    return (
        <div className="flex flex-col gap-3">
            {alert?.type && <GenericAlert type={alert.type}>{alert.label}</GenericAlert>}
            <SeasonsTable seasons={seasons} setAlert={setAlert} setAction={setAction} setSeason={setSeason} setSeasons={setSeasons} setOpenModal={setOpenModal} />
            <div className="w-fit">
                <Button size="sm" onClick={openSeasonModal}>
                    Nuova Stagione
                </Button>
            </div>
            <SeasonsModal action={action} season={season} openModal={openModal} setAlert={setAlert} setSeason={setSeason} setSeasons={setSeasons} setOpenModal={setOpenModal} />
        </div>
    )
}

export default Seasons;