import { useCallback } from "react";
import { useSelector } from "react-redux";
import { abbrText, genRanking } from "../../../../lib/utils";

// Components
import { Table, Tooltip } from "flowbite-react";


const TeamRow = ({ team }) => {
    return (
        <Table.Row className="text-xs sm:text-sm bg-white dark:border-gray-700 dark:bg-gray-800">
            <Table.Cell className="text-ellipsis text-left whitespace-nowrap font-medium text-gray-900 dark:text-white">
                {team.penalties === 0 &&
                    <>
                        <span className="hidden lg:inline-block">{team.name}</span>
                        <span className="inline-block lg:hidden">{abbrText(team.name, 8)}</span>
                    </>
                }
                {team.penalties !== 0 &&
                    <Tooltip content={`${team.penalties < 0 ? 'Penalità' : 'Bonus'}: ${team.penalties}`}>
                        <span className="hidden lg:inline-block">{team.name}</span>
                        <span className="inline-block lg:hidden">{abbrText(team.name, 8)}</span>
                    </Tooltip>
                }
            </Table.Cell>
            <Table.Cell>
                {team.points}
            </Table.Cell>
            <Table.Cell>
                {team.playedMatches}
            </Table.Cell>
            <Table.Cell>
                {team.wonMatches}
            </Table.Cell>
            <Table.Cell className="hidden lg:table-cell">
                {team.lostMatches}
            </Table.Cell>
            <Table.Cell className="hidden lg:table-cell">
                {team.wonSets}
            </Table.Cell>
            <Table.Cell className="hidden lg:table-cell">
                {team.lostSets}
            </Table.Cell>
            <Table.Cell>
                {team.qSets === 69 ? '-' : team.qSets}
            </Table.Cell>
            <Table.Cell className="hidden lg:table-cell">
                {team.wonPoints}
            </Table.Cell>
            <Table.Cell className="hidden lg:table-cell">
                {team.lostPoints}
            </Table.Cell>
            <Table.Cell>
                {team.qPoints === 69 ? '-' : team.qPoints}
            </Table.Cell>
        </Table.Row>
    )
}

const Rankings = ({ championship }) => {
    const { rankingIfConfirmed } = useSelector(state => state.committee);
    const rankings = useCallback(() => {
        return championship.calendar.map((group, i) => {
            const roundMatches = [];
            group.rounds.forEach(round => {
                round.matches.forEach(m => {
                    if (rankingIfConfirmed && m.status > 2) {
                        roundMatches.push(m)
                    }
                    if (!rankingIfConfirmed) {
                        roundMatches.push(m)
                    }
                })
            });
            return {
                group_name: championship.teams[i].group_name,
                grading: genRanking(championship.teams[i].teams, roundMatches, championship?.penalties)
            }
        })
    }, [championship, rankingIfConfirmed]);

    return (
        <>
            {rankings().map((group, i) =>
                <div key={`girone_${i}`} className="text-left p-2 bg-white rounded shadow-lg">
                    <p className="font-semibold text-base md:text-xl">
                        Girone{' '}
                        <span className="uppercase font-extrabold tracking-wide text-orange-600">{group.group_name}</span>
                    </p>
                    <Table hoverable={true} className="text-center">
                        <Table.Head>
                            <Table.HeadCell className="text-left">
                                Squadra
                            </Table.HeadCell>
                            <Table.HeadCell>
                                <span className="hidden lg:inline-block">Punti</span>
                                <span className="inline-block lg:hidden">P</span>
                            </Table.HeadCell>
                            <Table.HeadCell>
                                <span className="hidden lg:inline-block">Gare Giocate</span>
                                <span className="inline-block lg:hidden">G</span>
                            </Table.HeadCell>
                            <Table.HeadCell>
                                <span className="hidden lg:inline-block">Gare Vinte</span>
                                <span className="inline-block lg:hidden">GV</span>
                            </Table.HeadCell>
                            <Table.HeadCell className="hidden lg:table-cell">
                                <span className="hidden lg:inline-block">Gare Perse</span>
                                <span className="inline-block lg:hidden">GP</span>
                            </Table.HeadCell>
                            <Table.HeadCell className="hidden lg:table-cell">
                                <span>Set Vinti</span>
                            </Table.HeadCell>
                            <Table.HeadCell className="hidden lg:table-cell">
                                <span>Set Persi</span>
                            </Table.HeadCell>
                            <Table.HeadCell>
                                <span className="hidden lg:inline-block">Quoz. Set</span>
                                <span className="inline-block lg:hidden">QS</span>
                            </Table.HeadCell>
                            <Table.HeadCell className="hidden lg:table-cell">
                                <span>Punti Vinti</span>
                            </Table.HeadCell>
                            <Table.HeadCell className="hidden lg:table-cell">
                                <span>Punti Subiti</span>
                            </Table.HeadCell>
                            <Table.HeadCell>
                                <span className="hidden lg:inline-block">Quoz. Punti</span>
                                <span className="inline-block lg:hidden">QP</span>
                            </Table.HeadCell>
                        </Table.Head>
                        <Table.Body className="divide-y">
                            {
                                group.grading.map((team, i) =>
                                    <TeamRow key={`team_${i}`} team={team} />
                                )
                            }
                        </Table.Body>
                    </Table>
                </div>
            )}
        </>
    )
}

export default Rankings;