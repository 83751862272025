import decode from 'jwt-decode';
import { getToken, removeToken, setToken } from '../lib/storageUtils';

// Main Variables
import mainConfigs from '../configs/main';
const { PATH, PROTOCOL, REACT_APP_API_URL, REACT_ENV } = mainConfigs;

// Common Fetch
async function fetchData(endpoint, method, body = undefined, requireAuth = false, omitHeaders = false) {
    const url = REACT_ENV === 'development' ? `${PROTOCOL}://${PATH}/${endpoint}` : `${PROTOCOL}://${REACT_APP_API_URL}/${endpoint}`;
    const options = {
        mode: 'cors',
        method: method,
        headers: { 'Content-Type': 'application/json' }
    }
    body && (options.body = body);
    requireAuth && (options.headers['Authorization'] = getToken());
    omitHeaders && delete options.headers['Content-Type'];
    try {
        return await (await fetch(url, options)).json();
    } catch(err) {
        return {success: false, msg: 'Qualcosa è andato storto'}
    }
}

// Resource Fetch
async function fetchResource(endpoint, method = 'GET', requireAuth = false) {
    const url = REACT_ENV === 'development' ? `${PROTOCOL}://${PATH}/${endpoint}` : `${PROTOCOL}://${REACT_APP_API_URL}/${endpoint}`;
    const options = {
        method: method,
        headers: {}
    };
    requireAuth && (options.headers['Authorization'] = getToken());
    return fetch(url, options);
}

const authProvider = {

    signin: async (body) => {
        const result = await fetchData('auth/signin', 'POST', body)
            .then(response => {
                if (response.success) {
                    setToken(response.token.token);
                }
                return response;
            })
            .catch(err => {
                console.error(err);
            });
        return result;
    },

    verifyLogin: async () => {
        const token = getToken();
        const decoded = token ? decode(token) : undefined;
        if (token && Date.now() / 1000 > decoded.exp) { // il token è scaduto
            removeToken();
            return false;
        }
        if (token && Date.now() / 1000 < decoded.exp) { // il token è ancora valido
            return await fetchData('auth/authenticate', 'GET', undefined, true);
        }
        return false;
    }
};

const serviceProvider = {

    get: async (endpoint, requireAuth = false) => {
        return await fetchData(endpoint, 'GET', undefined, requireAuth);
    },

    getResource: async (endpoint, requireAuth = false) => {
        return fetchResource(endpoint, 'GET', requireAuth);
    },

    insert: async (endpoint, data, requireAuth = false, omitHeaders) => {
        return await fetchData(endpoint, 'POST', data, requireAuth, omitHeaders);
    },

    update: async (endpoint, data, requireAuth = false) => {
        return await fetchData(endpoint, 'PATCH', data, requireAuth);
    },
    
    delete: async (endpoint, requireAuth = false, data = undefined) => {
        return await fetchData(endpoint, 'DELETE', data, requireAuth);
    }
}

export { authProvider, serviceProvider };