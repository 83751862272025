import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { serviceProvider as API } from "../../../../API/api";
import { createPartials, formatDateTime, verifyPartials, verifyResult } from "../../../../lib/utils";
import { volleyball_formulas } from "../../../../lib/configs";

// Components
import { TextInput } from "flowbite-react";
import FormLabel from "../../../elements/FormLabel";
import GenericAlert from "../../../elements/GenericAlert";
import GenericModal from "../../../elements/GenericModal";
import SubmitButton from "../../../buttons/SubmitButton";


const MainForm = ({ assignment, match, resultContext, setAlert, setMatches, setModalAlert, setOpenModal, setTotalAssignments }) => {
    const { _id: userId } = useSelector(state => state.user);
    const [result, setResult] = useState({ a: 0, b: 0 });
    const [partials, setPartials] = useState([{ a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }]);
    const [setsCount, setSetsCount] = useState(0);

    const handleResult = (e) => {
        const { id, value } = e.currentTarget;
        setResult(prevState => ({
            ...prevState,
            [id]: value ? Number(value) : value
        }));
    }

    const editPartials = (e) => {
        const { value } = e.currentTarget;
        const { team, setindex } = e.currentTarget.dataset;
        setPartials(prevState => {
            prevState[Number(setindex)][team] = value ? Number(value) : value;
            return [...prevState];
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        const { points, possibleResults, minPartials } = volleyball_formulas[match.championship.formula];
        if (verifyResult(result, possibleResults)) {
            setModalAlert({ type: '', label: '' });
        } else {
            setModalAlert({ type: 'warning', label: 'Verifica il risultato inserito' });
            return;
        }
        if (verifyPartials(result, partials, minPartials, possibleResults[0].a)) {
            setModalAlert({ type: '', label: '' })
        } else {
            setModalAlert({ type: 'warning', label: 'Verifica il risultato inserito' });
            return;
        }
        const matchPoints = points[possibleResults.findIndex(pR => pR.a === result.a && pR.b === result.b)];
        const updBody = {
            points: matchPoints,
            result: result,
            result_updated_at: new Date().toISOString(),
            result_updated_by: userId,
            partials: createPartials(result, partials),
            status: 2
        };
        if (resultContext === 'referee') {
            API.update(`matches/${match._id}`, JSON.stringify(updBody), true)
                .then(res => {
                    if (res.success) {
                        setTotalAssignments(prevState => [
                            ...prevState.filter(a => a._id !== assignment._id),
                            {
                                ...assignment,
                                match: {
                                    ...match,
                                    points: updBody.points,
                                    result: updBody.result,
                                    result_updated_at: updBody.result_updated_at,
                                    result_updated_by: updBody.result_updated_by,
                                    partials: updBody.partials,
                                    status: 2
                                }
                            }
                        ])
                        setAlert({ type: 'success', label: 'Il risultato è stato inserito' });
                        setOpenModal(false);
                    } else {
                        setModalAlert({ type: 'failure', label: res.msg || 'Qualcosa è andato storto...' });
                    }
                })
                .catch(err => console.error(err));
        }
        if (resultContext === 'judge') {
            API.update(`matches/${match._id}`, JSON.stringify(updBody), true)
                .then(res => {
                    if (res.success) {
                        setMatches(prevState => [
                            ...prevState.filter(m => m._id !== match._id),
                            res.match
                        ]);
                        setAlert({ type: 'success', label: 'Il risultato è stato inserito' });
                        setOpenModal(false);
                    } else {
                        setModalAlert({ type: 'failure', label: res.msg || 'Qualcosa è andato storto...' });
                    }
                })
                .catch(err => console.error(err));
        }
    }

    useEffect(() => {
        setResult(match.result);
        const partials = [];
        for (let i = 0; i < 5; i += 1) {
            if (match.partials[i]) {
                partials.push(match.partials[i]);
            } else {
                partials.push({ a: 0, b: 0 });
            }
        }
        setPartials(partials);
    }, [match])

    useEffect(() => {
        const formula = volleyball_formulas[match.championship?.formula];
        if (formula) {
            if (verifyResult(result, formula.possibleResults)) {
                setSetsCount(result.a + result.b);
            } else {
                setSetsCount(0);
            }
        }
    }, [match.championship?.formula, result])

    return (
        <form className="flex flex-col gap-4" onSubmit={handleSubmit}>
            <div className="text-center">
                <FormLabel label="Conteggio Set" />
            </div>
            <div className="flex flex-row gap-2">
                <div className="basis-1/2">
                    <div className="mb-2 block text-center">
                        <FormLabel htmlFor="a" label={match?.teamA?.name} />
                    </div>
                    <TextInput
                        id="a"
                        type="number"
                        value={result.a}
                        step="1"
                        min="0"
                        max="20"
                        onChange={handleResult}
                    />
                </div>
                <div className="basis-1/2">
                    <div className="mb-2 block text-center">
                        <FormLabel htmlFor="b" label={match?.teamB?.name} />
                    </div>
                    <TextInput
                        id="b"
                        type="number"
                        value={result.b}
                        step="1"
                        min="0"
                        max="20"
                        onChange={handleResult}
                    />
                </div>
            </div>
            <div className="text-center">
                <label className="block text-sm font-medium text-gray-900 dark:text-gray-300">Parziali</label>
            </div>
            <div className={`${setsCount > 0 ? 'flex flex-row' : 'hidden'} gap-2`}>
                <div className="text-right">
                    <label className="block text-sm font-medium text-gray-900 dark:text-gray-300">Set 1</label>
                </div>
                <div className="basis-1/2">
                    <TextInput
                        type="number"
                        value={partials[0].a}
                        step="1"
                        min="0"
                        data-team="a"
                        data-setindex="0"
                        onChange={editPartials}
                    />
                </div>
                <div className="basis-1/2">
                    <TextInput
                        type="number"
                        value={partials[0].b}
                        step="1"
                        min="0"
                        data-team="b"
                        data-setindex="0"
                        onChange={editPartials}
                    />
                </div>
            </div>

            <div className={`${setsCount > 1 ? 'flex flex-row' : 'hidden'} gap-2`}>
                <div className="text-right">
                    <label className="block text-sm font-medium text-gray-900 dark:text-gray-300">Set 2</label>
                </div>
                <div className="basis-1/2">
                    <TextInput
                        type="number"
                        value={partials[1].a}
                        step="1"
                        min="0"
                        data-team="a"
                        data-setindex="1"
                        onChange={editPartials}
                    />
                </div>
                <div className="basis-1/2">
                    <TextInput
                        type="number"
                        value={partials[1].b}
                        step="1"
                        min="0"
                        data-team="b"
                        data-setindex="1"
                        onChange={editPartials}
                    />
                </div>
            </div>
            <div className={`${setsCount > 2 ? 'flex flex-row' : 'hidden'} gap-2`}>
                <div className="text-right">
                    <label className="block text-sm font-medium text-gray-900 dark:text-gray-300">Set 3</label>
                </div>
                <div className="basis-1/2">
                    <TextInput
                        type="number"
                        value={partials[2].a}
                        step="1"
                        min="0"
                        data-team="a"
                        data-setindex="2"
                        onChange={editPartials}
                    />
                </div>
                <div className="basis-1/2">
                    <TextInput
                        type="number"
                        value={partials[2].b}
                        step="1"
                        min="0"
                        data-team="b"
                        data-setindex="2"
                        onChange={editPartials}
                    />
                </div>
            </div>
            <div className={`${setsCount > 3 ? 'flex flex-row' : 'hidden'} gap-2`}>
                <div className="text-right">
                    <label className="block text-sm font-medium text-gray-900 dark:text-gray-300">Set 4</label>
                </div>
                <div className="basis-1/2">
                    <TextInput
                        type="number"
                        value={partials[3].a}
                        step="1"
                        min="0"
                        data-team="a"
                        data-setindex="3"
                        onChange={editPartials}
                    />
                </div>
                <div className="basis-1/2">
                    <TextInput
                        type="number"
                        value={partials[3].b}
                        step="1"
                        min="0"
                        data-team="b"
                        data-setindex="3"
                        onChange={editPartials}
                    />
                </div>
            </div>
            <div className={`${setsCount > 4 ? 'flex flex-row' : 'hidden'} gap-2`}>
                <div className="text-right">
                    <label className="block text-sm font-medium text-gray-900 dark:text-gray-300">Set 5</label>
                </div>
                <div className="basis-1/2">
                    <TextInput
                        type="number"
                        value={partials[4].a}
                        step="1"
                        min="0"
                        data-team="a"
                        data-setindex="4"
                        onChange={editPartials}
                    />
                </div>
                <div className="basis-1/2">
                    <TextInput
                        type="number"
                        value={partials[4].b}
                        step="1"
                        min="0"
                        data-team="b"
                        data-setindex="4"
                        onChange={editPartials}
                    />
                </div>
            </div>
            <div className="text-center">
                <SubmitButton />
            </div>
        </form>
    )
}

function ResultsModal({ assignment, match, openModal, resultContext, setAlert, setMatches, setOpenModal, setTotalAssignments }) {
    const [modalAlert, setModalAlert] = useState({ type: '', label: '' });

    // Azzera l'alert del modal ogni volta che viene aperto
    useEffect(() => {
        openModal === true && setModalAlert({ type: '', label: '' });
    }, [openModal]);

    return (
        <GenericModal openModal={openModal} setOpenModal={setOpenModal} title='Inserisci il risultato'>
            {modalAlert?.type && <GenericAlert type={modalAlert.type}>{modalAlert.label}</GenericAlert>}
            <ul className="space-y-1 list-none list-inside p-2 cursor-default border bg-gray-50 shadow-lg border-red-400 rounded-lg text-center text-gray-700 dark:text-gray-400">
                <li>
                    <span className="font-semibold text-sm uppercase">{match?.championship?.name}</span>
                </li>
                <li>
                    <span className="font-semibold">{match?.teamA?.name} - {match?.teamB?.name}</span>
                </li>
                <hr className="my-8 h-px bg-gray-300 border-0 dark:bg-gray-700" />
                <div className="flex flex-row gap-4 items-center">
                    <div className="text-right">
                        <li>
                            <span className="font-semibold">Data:</span> <span className="capitalize">{formatDateTime(match?.date, true)}</span>
                        </li>
                        <li>
                            <span className="font-semibold">Località:</span> {match?.field?.city} ({match?.field?.province})
                        </li>
                    </div>
                    <div className="text-left">
                        <li>
                            <span className="font-semibold">Campo:</span> {match?.field?.name}
                        </li>
                        {assignment?.role &&
                            <li>
                                <span className="font-semibold">Ruolo:</span> {assignment.role}
                            </li>
                        }
                    </div>
                </div>
            </ul>
            {match?._id &&
                <MainForm
                    assignment={assignment}
                    match={match}
                    resultContext={resultContext}
                    setAlert={setAlert}
                    setMatches={setMatches}
                    setModalAlert={setModalAlert}
                    setOpenModal={setOpenModal}
                    setTotalAssignments={setTotalAssignments}
                />
            }
        </GenericModal>
    )
}

export default ResultsModal;