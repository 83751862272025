// Days of the Week
const daysOTW = [
    { value: 1, day: 'Lunedì' },
    { value: 2, day: 'Martedì' },
    { value: 3, day: 'Mercoledì' },
    { value: 4, day: 'Giovedì' },
    { value: 5, day: 'Venerdì' },
    { value: 6, day: 'Sabato' },
    { value: 0, day: 'Domenica' },
]

const daysByIndex = ['Domenica', 'Lunedì', 'Martedì', 'Mercoledì', 'Giovedì', 'Venerdì', 'Sabato'];

// Game Formulas
const volleyball_formulas = [
    {
        short_desc: '3 set su 5',
        description: '3 set su 5 ([3-0, 3-1] => 3pt e 0 pt; [3-2] => 2pt e 1pt)',
        possibleResults: [{ a: 3, b: 0 }, { a: 3, b: 1 }, { a: 3, b: 2 }, { a: 0, b: 3 }, { a: 1, b: 3 }, { a: 2, b: 3 }],
        minPartials: [25, 25, 25, 25, 15],
        points: [{ a: 3, b: 0 }, { a: 3, b: 0 }, { a: 2, b: 1 }, { a: 0, b: 3 }, { a: 0, b: 3 }, { a: 1, b: 2 }]
    },
    {
        short_desc: '2 set su 3',
        description: '2 set su 3 ([2-0] => 3pt e 0 pt; [2-1] => 2pt e 1pt)',
        possibleResults: [{ a: 2, b: 0 }, { a: 2, b: 1 }, { a: 0, b: 2 }, { a: 1, b: 2 }],
        minPartials: [25, 25, 15],
        points: [{ a: 3, b: 0 }, { a: 2, b: 1 }, { a: 0, b: 3 }, { a: 1, b: 2 }]
    },
    {
        short_desc: '3 set obbligatori',
        description: '3 set obbligatori, 1pt per set vinto',
        possibleResults: [{ a: 3, b: 0 }, { a: 0, b: 3 }, { a: 2, b: 1 }, { a: 1, b: 2 }],
        minPartials: [25, 25, 25],
        points: [{ a: 3, b: 0 }, { a: 0, b: 3 }, { a: 2, b: 1 }, { a: 1, b: 2 }]
    },
    {
        short_desc: '[calcio] Fino a 20-0',
        description: 'Calcio: Risultati possibili fino a 20-0',
        possibleResults: [
            { a: 0, b: 0 }, { a: 0, b: 1 }, { a: 0, b: 2 }, { a: 0, b: 3 }, { a: 0, b: 4 }, { a: 0, b: 5 }, { a: 0, b: 6 }, { a: 0, b: 7 }, { a: 0, b: 8 }, { a: 0, b: 9 }, { a: 0, b: 10 }, { a: 0, b: 11 }, { a: 0, b: 12 }, { a: 0, b: 13 }, { a: 0, b: 14 }, { a: 0, b: 15 }, { a: 0, b: 16 }, { a: 0, b: 17 }, { a: 0, b: 18 }, { a: 0, b: 19 }, { a: 0, b: 20 },
            { a: 1, b: 0 }, { a: 1, b: 1 }, { a: 1, b: 2 }, { a: 1, b: 3 }, { a: 1, b: 4 }, { a: 1, b: 5 }, { a: 1, b: 6 }, { a: 1, b: 7 }, { a: 1, b: 8 }, { a: 1, b: 9 }, { a: 1, b: 10 }, { a: 1, b: 11 }, { a: 1, b: 12 }, { a: 1, b: 13 }, { a: 1, b: 14 }, { a: 1, b: 15 }, { a: 1, b: 16 }, { a: 1, b: 17 }, { a: 1, b: 18 }, { a: 1, b: 19 }, { a: 1, b: 20 },
            { a: 2, b: 0 }, { a: 2, b: 1 }, { a: 2, b: 2 }, { a: 2, b: 3 }, { a: 2, b: 4 }, { a: 2, b: 5 }, { a: 2, b: 6 }, { a: 2, b: 7 }, { a: 2, b: 8 }, { a: 2, b: 9 }, { a: 2, b: 10 }, { a: 2, b: 11 }, { a: 2, b: 12 }, { a: 2, b: 13 }, { a: 2, b: 14 }, { a: 2, b: 15 }, { a: 2, b: 16 }, { a: 2, b: 17 }, { a: 2, b: 18 }, { a: 2, b: 19 }, { a: 2, b: 20 },
            { a: 3, b: 0 }, { a: 3, b: 1 }, { a: 3, b: 2 }, { a: 3, b: 3 }, { a: 3, b: 4 }, { a: 3, b: 5 }, { a: 3, b: 6 }, { a: 3, b: 7 }, { a: 3, b: 8 }, { a: 3, b: 9 }, { a: 3, b: 10 }, { a: 3, b: 11 }, { a: 3, b: 12 }, { a: 3, b: 13 }, { a: 3, b: 14 }, { a: 3, b: 15 }, { a: 3, b: 16 }, { a: 3, b: 17 }, { a: 3, b: 18 }, { a: 3, b: 19 }, { a: 3, b: 20 },
            { a: 4, b: 0 }, { a: 4, b: 1 }, { a: 4, b: 2 }, { a: 4, b: 3 }, { a: 4, b: 4 }, { a: 4, b: 5 }, { a: 4, b: 6 }, { a: 4, b: 7 }, { a: 4, b: 8 }, { a: 4, b: 9 }, { a: 4, b: 10 }, { a: 4, b: 11 }, { a: 4, b: 12 }, { a: 4, b: 13 }, { a: 4, b: 14 }, { a: 4, b: 15 }, { a: 4, b: 16 }, { a: 4, b: 17 }, { a: 4, b: 18 }, { a: 4, b: 19 }, { a: 4, b: 20 },
            { a: 5, b: 0 }, { a: 5, b: 1 }, { a: 5, b: 2 }, { a: 5, b: 3 }, { a: 5, b: 4 }, { a: 5, b: 5 }, { a: 5, b: 6 }, { a: 5, b: 7 }, { a: 5, b: 8 }, { a: 5, b: 9 }, { a: 5, b: 10 }, { a: 5, b: 11 }, { a: 5, b: 12 }, { a: 5, b: 13 }, { a: 5, b: 14 }, { a: 5, b: 15 }, { a: 5, b: 16 }, { a: 5, b: 17 }, { a: 5, b: 18 }, { a: 5, b: 19 }, { a: 5, b: 20 },
            { a: 6, b: 0 }, { a: 6, b: 1 }, { a: 6, b: 2 }, { a: 6, b: 3 }, { a: 6, b: 4 }, { a: 6, b: 5 }, { a: 6, b: 6 }, { a: 6, b: 7 }, { a: 6, b: 8 }, { a: 6, b: 9 }, { a: 6, b: 10 }, { a: 6, b: 11 }, { a: 6, b: 12 }, { a: 6, b: 13 }, { a: 6, b: 14 }, { a: 6, b: 15 }, { a: 6, b: 16 }, { a: 6, b: 17 }, { a: 6, b: 18 }, { a: 6, b: 19 }, { a: 6, b: 20 },
            { a: 7, b: 0 }, { a: 7, b: 1 }, { a: 7, b: 2 }, { a: 7, b: 3 }, { a: 7, b: 4 }, { a: 7, b: 5 }, { a: 7, b: 6 }, { a: 7, b: 7 }, { a: 7, b: 8 }, { a: 7, b: 9 }, { a: 7, b: 10 }, { a: 7, b: 11 }, { a: 7, b: 12 }, { a: 7, b: 13 }, { a: 7, b: 14 }, { a: 7, b: 15 }, { a: 7, b: 16 }, { a: 7, b: 17 }, { a: 7, b: 18 }, { a: 7, b: 19 }, { a: 7, b: 20 },
            { a: 8, b: 0 }, { a: 8, b: 1 }, { a: 8, b: 2 }, { a: 8, b: 3 }, { a: 8, b: 4 }, { a: 8, b: 5 }, { a: 8, b: 6 }, { a: 8, b: 7 }, { a: 8, b: 8 }, { a: 8, b: 9 }, { a: 8, b: 10 }, { a: 8, b: 11 }, { a: 8, b: 12 }, { a: 8, b: 13 }, { a: 8, b: 14 }, { a: 8, b: 15 }, { a: 8, b: 16 }, { a: 8, b: 17 }, { a: 8, b: 18 }, { a: 8, b: 19 }, { a: 8, b: 20 },
            { a: 9, b: 0 }, { a: 9, b: 1 }, { a: 9, b: 2 }, { a: 9, b: 3 }, { a: 9, b: 4 }, { a: 9, b: 5 }, { a: 9, b: 6 }, { a: 9, b: 7 }, { a: 9, b: 8 }, { a: 9, b: 9 }, { a: 9, b: 10 }, { a: 9, b: 11 }, { a: 9, b: 12 }, { a: 9, b: 13 }, { a: 9, b: 14 }, { a: 9, b: 15 }, { a: 9, b: 16 }, { a: 9, b: 17 }, { a: 9, b: 18 }, { a: 9, b: 19 }, { a: 9, b: 20 },
            { a: 10, b: 0 }, { a: 10, b: 1 }, { a: 10, b: 2 }, { a: 10, b: 3 }, { a: 10, b: 4 }, { a: 10, b: 5 }, { a: 10, b: 6 }, { a: 10, b: 7 }, { a: 10, b: 8 }, { a: 10, b: 9 }, { a: 10, b: 10 }, { a: 10, b: 11 }, { a: 10, b: 12 }, { a: 10, b: 13 }, { a: 10, b: 14 }, { a: 10, b: 15 }, { a: 10, b: 16 }, { a: 10, b: 17 }, { a: 10, b: 18 }, { a: 10, b: 19 }, { a: 10, b: 20 },
            { a: 11, b: 0 }, { a: 11, b: 1 }, { a: 11, b: 2 }, { a: 11, b: 3 }, { a: 11, b: 4 }, { a: 11, b: 5 }, { a: 11, b: 6 }, { a: 11, b: 7 }, { a: 11, b: 8 }, { a: 11, b: 9 }, { a: 11, b: 10 }, { a: 11, b: 11 }, { a: 11, b: 12 }, { a: 11, b: 13 }, { a: 11, b: 14 }, { a: 11, b: 15 }, { a: 11, b: 16 }, { a: 11, b: 17 }, { a: 11, b: 18 }, { a: 11, b: 19 }, { a: 11, b: 20 },
            { a: 12, b: 0 }, { a: 12, b: 1 }, { a: 12, b: 2 }, { a: 12, b: 3 }, { a: 12, b: 4 }, { a: 12, b: 5 }, { a: 12, b: 6 }, { a: 12, b: 7 }, { a: 12, b: 8 }, { a: 12, b: 9 }, { a: 12, b: 10 }, { a: 12, b: 11 }, { a: 12, b: 12 }, { a: 12, b: 13 }, { a: 12, b: 14 }, { a: 12, b: 15 }, { a: 12, b: 16 }, { a: 12, b: 17 }, { a: 12, b: 18 }, { a: 12, b: 19 }, { a: 12, b: 20 },
            { a: 13, b: 0 }, { a: 13, b: 1 }, { a: 13, b: 2 }, { a: 13, b: 3 }, { a: 13, b: 4 }, { a: 13, b: 5 }, { a: 13, b: 6 }, { a: 13, b: 7 }, { a: 13, b: 8 }, { a: 13, b: 9 }, { a: 13, b: 10 }, { a: 13, b: 11 }, { a: 13, b: 12 }, { a: 13, b: 13 }, { a: 13, b: 14 }, { a: 13, b: 15 }, { a: 13, b: 16 }, { a: 13, b: 17 }, { a: 13, b: 18 }, { a: 13, b: 19 }, { a: 13, b: 20 },
            { a: 14, b: 0 }, { a: 14, b: 1 }, { a: 14, b: 2 }, { a: 14, b: 3 }, { a: 14, b: 4 }, { a: 14, b: 5 }, { a: 14, b: 6 }, { a: 14, b: 7 }, { a: 14, b: 8 }, { a: 14, b: 9 }, { a: 14, b: 10 }, { a: 14, b: 11 }, { a: 14, b: 12 }, { a: 14, b: 13 }, { a: 14, b: 14 }, { a: 14, b: 15 }, { a: 14, b: 16 }, { a: 14, b: 17 }, { a: 14, b: 18 }, { a: 14, b: 19 }, { a: 14, b: 20 },
            { a: 15, b: 0 }, { a: 15, b: 1 }, { a: 15, b: 2 }, { a: 15, b: 3 }, { a: 15, b: 4 }, { a: 15, b: 5 }, { a: 15, b: 6 }, { a: 15, b: 7 }, { a: 15, b: 8 }, { a: 15, b: 9 }, { a: 15, b: 10 }, { a: 15, b: 11 }, { a: 15, b: 12 }, { a: 15, b: 13 }, { a: 15, b: 14 }, { a: 15, b: 15 }, { a: 15, b: 16 }, { a: 15, b: 17 }, { a: 15, b: 18 }, { a: 15, b: 19 }, { a: 15, b: 20 },
            { a: 16, b: 0 }, { a: 16, b: 1 }, { a: 16, b: 2 }, { a: 16, b: 3 }, { a: 16, b: 4 }, { a: 16, b: 5 }, { a: 16, b: 6 }, { a: 16, b: 7 }, { a: 16, b: 8 }, { a: 16, b: 9 }, { a: 16, b: 10 }, { a: 16, b: 11 }, { a: 16, b: 12 }, { a: 16, b: 13 }, { a: 16, b: 14 }, { a: 16, b: 15 }, { a: 16, b: 16 }, { a: 16, b: 17 }, { a: 16, b: 18 }, { a: 16, b: 19 }, { a: 16, b: 20 },
            { a: 17, b: 0 }, { a: 17, b: 1 }, { a: 17, b: 2 }, { a: 17, b: 3 }, { a: 17, b: 4 }, { a: 17, b: 5 }, { a: 17, b: 6 }, { a: 17, b: 7 }, { a: 17, b: 8 }, { a: 17, b: 9 }, { a: 17, b: 10 }, { a: 17, b: 11 }, { a: 17, b: 12 }, { a: 17, b: 13 }, { a: 17, b: 14 }, { a: 17, b: 15 }, { a: 17, b: 16 }, { a: 17, b: 17 }, { a: 17, b: 18 }, { a: 17, b: 19 }, { a: 17, b: 20 },
            { a: 18, b: 0 }, { a: 18, b: 1 }, { a: 18, b: 2 }, { a: 18, b: 3 }, { a: 18, b: 4 }, { a: 18, b: 5 }, { a: 18, b: 6 }, { a: 18, b: 7 }, { a: 18, b: 8 }, { a: 18, b: 9 }, { a: 18, b: 10 }, { a: 18, b: 11 }, { a: 18, b: 12 }, { a: 18, b: 13 }, { a: 18, b: 14 }, { a: 18, b: 15 }, { a: 18, b: 16 }, { a: 18, b: 17 }, { a: 18, b: 18 }, { a: 18, b: 19 }, { a: 18, b: 20 },
            { a: 19, b: 0 }, { a: 19, b: 1 }, { a: 19, b: 2 }, { a: 19, b: 3 }, { a: 19, b: 4 }, { a: 19, b: 5 }, { a: 19, b: 6 }, { a: 19, b: 7 }, { a: 19, b: 8 }, { a: 19, b: 9 }, { a: 19, b: 10 }, { a: 19, b: 11 }, { a: 19, b: 12 }, { a: 19, b: 13 }, { a: 19, b: 14 }, { a: 19, b: 15 }, { a: 19, b: 16 }, { a: 19, b: 17 }, { a: 19, b: 18 }, { a: 19, b: 19 }, { a: 19, b: 20 },
            { a: 20, b: 0 }, { a: 20, b: 1 }, { a: 20, b: 2 }, { a: 20, b: 3 }, { a: 20, b: 4 }, { a: 20, b: 5 }, { a: 20, b: 6 }, { a: 20, b: 7 }, { a: 20, b: 8 }, { a: 20, b: 9 }, { a: 20, b: 10 }, { a: 20, b: 11 }, { a: 20, b: 12 }, { a: 20, b: 13 }, { a: 20, b: 14 }, { a: 20, b: 15 }, { a: 20, b: 16 }, { a: 20, b: 17 }, { a: 20, b: 18 }, { a: 20, b: 19 }, { a: 20, b: 20 }
        ],
        minPartials: [
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0
        ],
        points: [
            { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 },
            { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 },
            { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 },
            { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 },
            { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 },
            { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 },
            { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 },
            { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 },
            { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 },
            { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 },
            { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 },
            { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 },
            { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 },
            { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 },
            { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 },
            { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 },
            { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 },
            { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 },
            { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 },
            { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }, { a: 0, b: 0 }
        ]
    },
    {
        short_desc: '2 set obbligatori (min. 21 pt)',
        description: '2 set obbligatori => 1 pt a set',
        possibleResults: [{ a: 2, b: 0 }, { a: 0, b: 2 }, { a: 1, b: 1 }],
        minPartials: [21, 21],
        points: [{ a: 2, b: 0 }, { a: 0, b: 2 }, { a: 1, b: 1 }]
    },
    {
        short_desc: '2 set su 3 (21/15 pt)',
        description: '2 set su 3 (21/15 pt) ([2-0] => 3pt e 0 pt; [2-1] => 2pt e 1pt)',
        possibleResults: [{ a: 2, b: 0 }, { a: 2, b: 1 }, { a: 0, b: 2 }, { a: 1, b: 2 }],
        minPartials: [21, 21, 15],
        points: [{ a: 3, b: 0 }, { a: 2, b: 1 }, { a: 0, b: 3 }, { a: 1, b: 2 }]
    }
];

const localeOpts = {
    dateStyle: 'short',
    timeStyle: 'short'
}

export { daysByIndex, daysOTW, volleyball_formulas, localeOpts };