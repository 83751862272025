import { configureStore } from '@reduxjs/toolkit';

// Reducers
import committeeReducer from './features/committee/committeeSlice';
import seasonReducer from './features/season/seasonSlice';
import userReducer from './features/user/userSlice';

export const store = configureStore({
  reducer: {
    committee: committeeReducer,
    season: seasonReducer,
    user: userReducer
  },
})