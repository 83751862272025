import { useEffect, useMemo, useState } from "react";
import { serviceProvider as API } from "../../API/api";
import { Link } from "react-router-dom";

// Components
import MainFooter from "./index/MainFooter";

// Images
import imgBasket1 from "../../images/home/basketball.jpg";
import imgBasket2 from "../../images/home/basketball_2.jpg";
import imgBasket3 from "../../images/home/basketball_3.jpg";
import imgBeach1 from "../../images/home/beachvolley.jpg";
import imgBeach2 from "../../images/home/beachvolley_2.jpg";
import imgBeach3 from "../../images/home/beachvolley_3.jpg";
import imgMedals from "../../images/home/medals.jpg";
import imgSoccer1 from "../../images/home/soccer.jpg";
import imgSoccer2 from "../../images/home/soccer_2.jpg";
import imgVolley1 from "../../images/home/volleyball.jpg";
import imgVolley2 from "../../images/home/volleyball_2.jpg";
import imgVolley3 from "../../images/home/volleyball_3.jpg";
import imgVolley4 from "../../images/home/volleyball_4.jpg";


const Features = () => {
    return (
        <div className="container max-w-6xl mx-auto">
            <h2 className="text-4xl font-bold tracking-tight text-center">Features</h2>
            <div className="grid grid-cols-4 gap-8 mt-10 sm:grid-cols-8 lg:grid-cols-12 sm:px-8 xl:px-0">

                <div className="relative flex flex-col items-center justify-between col-span-4 px-8 py-12 space-y-4 overflow-hidden bg-gray-100 sm:rounded-xl" data-rounded="rounded-xl" data-rounded-max="rounded-full">
                    <div className="p-3 text-white bg-blue-500 rounded-full" data-primary="blue-500" data-rounded="rounded-full">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z" />
                        </svg>
                    </div>
                    <h4 className="text-xl font-medium text-gray-700">Calendari</h4>
                    <p className="text-base text-center text-gray-500">Creazione e gestione dei calendari sportivi.</p>
                </div>

                <div className="flex flex-col items-center justify-between col-span-4 px-8 py-12 space-y-4 bg-gray-100 sm:rounded-xl" data-rounded="rounded-xl" data-rounded-max="rounded-full">
                    <div className="p-3 text-white bg-blue-500 rounded-full" data-primary="blue-500" data-rounded="rounded-full">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 002.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 00-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75 2.25 2.25 0 00-.1-.664m-5.8 0A2.251 2.251 0 0113.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25zM6.75 12h.008v.008H6.75V12zm0 3h.008v.008H6.75V15zm0 3h.008v.008H6.75V18z" />
                        </svg>
                    </div>
                    <h4 className="text-xl font-medium text-gray-700">Gestione Arbitri</h4>
                    <p className="text-base text-center text-gray-500">Ruoli arbitrali personalizzabili, designazione delle gare.</p>
                </div>

                <div className="flex flex-col items-center justify-between col-span-4 px-8 py-12 space-y-4 bg-gray-100 sm:rounded-xl" data-rounded="rounded-xl" data-rounded-max="rounded-full">
                    <div className="p-3 text-white bg-blue-500 rounded-full" data-primary="blue-500" data-rounded="rounded-full">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M3.375 19.5h17.25m-17.25 0a1.125 1.125 0 01-1.125-1.125M3.375 19.5h7.5c.621 0 1.125-.504 1.125-1.125m-9.75 0V5.625m0 12.75v-1.5c0-.621.504-1.125 1.125-1.125m18.375 2.625V5.625m0 12.75c0 .621-.504 1.125-1.125 1.125m1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125m0 3.75h-7.5A1.125 1.125 0 0112 18.375m9.75-12.75c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125m19.5 0v1.5c0 .621-.504 1.125-1.125 1.125M2.25 5.625v1.5c0 .621.504 1.125 1.125 1.125m0 0h17.25m-17.25 0h7.5c.621 0 1.125.504 1.125 1.125M3.375 8.25c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125m17.25-3.75h-7.5c-.621 0-1.125.504-1.125 1.125m8.625-1.125c.621 0 1.125.504 1.125 1.125v1.5c0 .621-.504 1.125-1.125 1.125m-17.25 0h7.5m-7.5 0c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125M12 10.875v-1.5m0 1.5c0 .621-.504 1.125-1.125 1.125M12 10.875c0 .621.504 1.125 1.125 1.125m-2.25 0c.621 0 1.125.504 1.125 1.125M13.125 12h7.5m-7.5 0c-.621 0-1.125.504-1.125 1.125M20.625 12c.621 0 1.125.504 1.125 1.125v1.5c0 .621-.504 1.125-1.125 1.125m-17.25 0h7.5M12 14.625v-1.5m0 1.5c0 .621-.504 1.125-1.125 1.125M12 14.625c0 .621.504 1.125 1.125 1.125m-2.25 0c.621 0 1.125.504 1.125 1.125m0 1.5v-1.5m0 0c0-.621.504-1.125 1.125-1.125m0 0h7.5" />
                        </svg>
                    </div>
                    <h4 className="text-xl font-medium text-gray-700">Classifiche</h4>
                    <p className="text-base text-center text-gray-500">Aggiornamento in tempo reale dei calendari e delle classifiche.</p>
                </div>

                <div className="flex flex-col items-center justify-between col-span-4 px-8 py-12 space-y-4 bg-gray-100 sm:rounded-xl" data-rounded="rounded-xl" data-rounded-max="rounded-full">
                    <div className="p-3 text-white bg-blue-500 rounded-full" data-primary="blue-500" data-rounded="rounded-full">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M12 7.5h1.5m-1.5 3h1.5m-7.5 3h7.5m-7.5 3h7.5m3-9h3.375c.621 0 1.125.504 1.125 1.125V18a2.25 2.25 0 01-2.25 2.25M16.5 7.5V18a2.25 2.25 0 002.25 2.25M16.5 7.5V4.875c0-.621-.504-1.125-1.125-1.125H4.125C3.504 3.75 3 4.254 3 4.875V18a2.25 2.25 0 002.25 2.25h13.5M6 7.5h3v3H6v-3z" />
                        </svg>
                    </div>
                    <h4 className="text-xl font-medium text-gray-700">Comunicati</h4>
                    <p className="text-base text-center text-gray-500">Inserimento e pubblicazioni dei comunicati ufficiali.</p>
                </div>

                <div className="flex flex-col items-center justify-between col-span-4 px-8 py-12 space-y-4 bg-gray-100 sm:rounded-xl" data-rounded="rounded-xl" data-rounded-max="rounded-full">
                    <div className="p-3 text-white bg-blue-500 rounded-full" data-primary="blue-500" data-rounded="rounded-full">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 01-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 011.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 00-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 01-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 00-3.375-3.375h-1.5a1.125 1.125 0 01-1.125-1.125v-1.5a3.375 3.375 0 00-3.375-3.375H9.75" />
                        </svg>
                    </div>
                    <h4 className="text-xl font-medium text-gray-700">Documenti di gara</h4>
                    <p className="text-base text-center text-gray-500">Inserimento referti e documenti di gara, per le procedure di omologazione.</p>
                </div>

                <div className="flex flex-col items-center justify-between col-span-4 px-8 py-12 space-y-4 bg-gray-100 sm:rounded-xl" data-rounded="rounded-xl" data-rounded-max="rounded-full">
                    <div className="p-3 text-white bg-blue-500 rounded-full" data-primary="blue-500" data-rounded="rounded-full">
                        <svg
                            viewBox="0 0 512 512"
                            fill="currentColor"
                            className="w-6 h-6"
                        >
                            <path d="M511.8 267.4c-26.1 8.7-53.4 13.8-81 15.1C440 177.2 399.3 78.3 327.6 10.1 434.1 41.1 512 139.5 512 256c0 3.8-.1 7.6-.2 11.4zm-3.9 34.7c-5.8 32-17.6 62-34.2 88.7-97.5 48.5-217.7 42.6-311.9-24.5 23.7-36.2 55.4-67.7 94.5-91.8 79.9 43.2 170.1 50.8 251.6 27.6zm-236-55.5C269.4 155.7 230.8 73.9 170 14.9 196.8 5.2 225.8 0 256 0c2.7 0 5.3 0 7.9.1 90.8 60.2 145.7 167.2 134.7 282.3-43.1-2.4-86.4-14.1-126.8-35.9zM138 28.8c20.6 18.3 38.7 39.4 53.7 62.6C95.9 136.1 30.6 220.8 7.3 316.9 2.5 297.4 0 277 0 256 0 157.2 56 71.5 138 28.8zm69.6 90.5c19.5 38.6 31 81.9 32.3 127.7-77.4 47.6-129 121.9-149.7 204-24.2-20.6-44.6-45.6-59.8-73.8 6.7-108.7 71.9-209.9 177.1-257.9zM256 512c-50.7 0-98-14.7-137.8-40.2 5.6-27 14.8-53.1 27.4-77.7 86.6 60.5 192.5 74.7 287.4 46.9-46 44-108.3 71-177 71z" />
                        </svg>
                    </div>
                    <h4 className="text-xl font-medium text-gray-700">Sport</h4>
                    <p className="text-base text-center text-gray-500">Personalizzabile per includere più sport di squadra.</p>
                </div>

            </div>
        </div>
    )
}

const CommitteeCard = ({ committee }) => {
    return (
        <Link to={committee.urlcode} className="block max-w-sm p-6 bg-gray-100 border border-gray-200 rounded-lg shadow-md text-gray-900 hover:bg-sky-600 hover:text-white dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700 outline outline-2 outline-sky-700">
            <h5 className="mb-2 text-2xl font-bold tracking-tight dark:text-white">{committee.title}</h5>
        </Link>
    )
}

function Index() {
    const imgArray = useMemo(() => [imgBasket1, imgBasket2, imgBasket3, imgBeach1, imgBeach2, imgBeach3, imgMedals, imgSoccer1, imgSoccer2, imgVolley1, imgVolley2, imgVolley3, imgVolley4], []);
    const randomImage = useMemo(() => Math.floor(Math.random() * imgArray.length), [imgArray]);
    const [committees, setCommittees] = useState([]);

    useEffect(() => {
        API.get('committees')
            .then(res => {
                if (res.success) {
                    setCommittees(res.committees);
                }
            })
            .catch(err => console.error(err));
    }, [])

    return (
        <>
            <section className="w-full min-h-screen px-3 antialiased bg-gradient-to-bl from-sky-200 to-sky-100 lg:px-6 tails-selected-element" data-primary="indigo-600">
                <div className="mx-auto max-w-7xl">
                    <nav className="flex items-center w-full h-24 select-none cursor-default border-0 border-b-2 border-orange-700 mb-8">
                        <div className="relative flex flex-wrap items-center justify-between w-full h-24 font-medium">
                            <div className="py-4 pl-6 pr-4 md:pl-4 md:py-0">
                                <span className="block xl:inline text-xl sm:text-2xl md:text-3xl lg:text-4xl text-transparent bg-clip-text bg-gradient-to-br from-blue-500 to-orange-400 font-grand-sport">
                                    Csisport
                                </span>
                            </div>
                        </div>
                    </nav>
                    <div className="container items-center max-w-6xl px-8 mx-auto xl:px-5 bg-gray-100 py-8 rounded-lg cursor-default">
                        <div className="flex flex-wrap items-center sm:-mx-3">
                            <div className="w-full md:w-1/2 md:px-3">
                                <div className="w-full pb-6 space-y-6 sm:max-w-md lg:max-w-lg md:space-y-4 lg:space-y-8 xl:space-y-9 sm:pr-5 lg:pr-0 md:pb-0">
                                    <h1 className="text-4xl font-extrabold tracking-tight text-gray-900 sm:text-5xl md:text-4xl lg:text-5xl xl:text-6xl">
                                        <span className="block xl:inline font-extrabold text-transparent bg-clip-text bg-gradient-to-br from-blue-600 to-orange-600" data-primary="blue-600">Gestione Campionati Sportivi</span>
                                    </h1>
                                    {/*                                     <p className="mx-auto text-base text-gray-500 sm:max-w-md lg:text-xl md:max-w-3xl">
                                        Qui puoi consultare gli ultimi risultati, le classifiche, i comunicati e i calendari della stagione corrente!
                                    </p> */}
                                </div>
                            </div>
                            <div className="w-full md:w-1/2">
                                <div className="w-full h-auto overflow-hidden rounded-md shadow-xl sm:rounded-xl" data-rounded="rounded-xl" data-rounded-max="rounded-full">
                                    <img src={imgArray[randomImage]} alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="p-4 mt-5 text-center">
                        <span className="uppercase text-3xl font-bold text-gray-700 tracking-wider">Scegli un comitato</span>
                        <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-5 gap-4 mt-6">
                            {
                                committees.map(c => <CommitteeCard key={c._id} committee={c} />)
                            }
                        </div>
                    </div>
                </div>
                <div className="p-4">
                    <Features />
                </div>
            </section>
            <MainFooter />
        </>
    )
}

export default Index;