import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { calendarUrl, formatDateTime, getTime, readablePartials } from "../../../../lib/utils";
import { serviceProvider as API } from "../../../../API/api";

// Components
import { Button, Tooltip } from "flowbite-react";
import GenericCard from "../../../elements/GenericCard";


const RoleRow = ({ match, role }) => {
    const { _id: userId } = useSelector(state => state.user);
    const [roleAssignment, setRoleAssignment] = useState([]);
    const statusClasses = [
        'text-gray-600 bg-red-400 hover:bg-red-300',
        'text-gray-100 bg-blue-500 hover:bg-blue-400',
        'text-gray-600 bg-yellow-300 hover:bg-yellow-200',
        'text-white bg-green-600 hover:bg-green-500'
    ];

    useEffect(() => {
        if (userId) {
            setRoleAssignment(match.assignments?.filter(a =>
                (a.role === role.title && a.status > 1 && a.referee._id === userId)
                || (a.role === role.title && a.status > 2)));
        }
    }, [match.assignments, role.title, userId])


    return (
        roleAssignment?.length > 0 &&
        <li className="flex flex-row gap-2 items-center">
            <span className="font-semibold">{role.title}:</span>
            {roleAssignment
                .map(a =>
                    <div key={a._id} className="flex flex-row gap-1">
                        <span
                            id={a._id}
                            className={`p-1.5 rounded uppercase font-semibold text-xs cursor-default mb-0.5 ${statusClasses[a.status]}`}
                        >
                            {a.referee.lastname} {a.referee.firstname}
                        </span>
                    </div>
                )
            }
        </li>
    )
}

function MatchCard({ assignment, index, rejected = false, roles, setAction, setAssignment, setCarCheckbox, setOpenConfirmationModal, setOpenDocsModal, setOpenModal, setOpenRefundModal, setOpenReportModal, setOpenResultsModal, setRefund, setReport }) {
    const { daysToReject } = useSelector(state => state.committee);
    const findAllowance = useCallback(() => assignment.match.championship.allowances?.find(a => a.role === assignment.role), [assignment]);
    const findSubtotal = useCallback((allowance = 0) => {
        const { refund } = assignment;
        if (refund?._id) {
            return allowance + refund.car + refund.meal + refund.travel + refund.expenses;
        }
        return allowance;
    }, [assignment])
    const findRole = useCallback((role) => roles.find(r => r.title === role), [roles]);
    const noRefund = useCallback(() => assignment.refund?._id ? false : true, [assignment.refund]);
    const noReport = useCallback(() => assignment.match.report?.length < 1 ? true : false, [assignment]);
    const noResults = useCallback(() => !assignment.match.hasOwnProperty('result_updated_at'), [assignment]);
    const [distance, setDistance] = useState({});

    const handleAccept = () => {
        setAction('accept');
        setAssignment(assignment);
        setOpenModal(true);
    }

    const handleReject = () => {
        setAction('reject');
        setAssignment(assignment);
        setOpenModal(true);
    }

    const editResults = () => {
        setAssignment(assignment);
        setOpenResultsModal(true);
    }

    const editReport = () => {
        const report = {};
        if (assignment.match.report?.length > 0) {
            assignment.match.report.forEach(r => {
                report[r.id] = r.value
            })
        } else {
            assignment.match.championship.report_model?.model_schema.forEach(m => {
                report[m.id] = m.default_value
            });
        }
        setReport(report);
        setAssignment(assignment);
        setOpenReportModal(true);
    }

    const editDocs = () => {
        setAssignment(assignment);
        setOpenDocsModal(true);
    }

    const editRefund = () => {
        const { refund } = assignment;
        setAssignment(assignment);
        setCarCheckbox(refund?.car ? true : false);
        setRefund({
            allowance: findAllowance()?.amount || refund?.allowance || 0,
            subtotal: findSubtotal(findAllowance()?.amount || 0) || 0,
            edited_distance: false,
            departureArrival: refund?.departureArrival || getTime(assignment.match.date, -75),
            departureTime: refund?.departureTime || getTime(assignment.match.date, -105),
            distance: refund?.distance || distance?.distance * 2,
            car: refund?.car || 0,
            carplate: refund?.carplate || '',
            meal: refund?.meal || 0,
            travel: refund?.travel || 0,
            expenses: refund?.expenses || 0,
            returnArrival: refund?.returnArrival || getTime(assignment.match.date, 150),
            returnTime: refund?.returnTime || getTime(assignment.match.date, 120),
            start_time: refund?.start_time || getTime(assignment.match.date) || '',
            end_time: refund?.end_time || '',
            notes: refund?.notes || '',
            verified: refund?.verified
        })
        setOpenRefundModal(true);
    }

    const openConfirm = () => {
        setAssignment(assignment);
        setOpenConfirmationModal(true)
    }

    // Find distance
    useEffect(() => {
        const provinces = [assignment.referee.province, assignment.match.field.province];
        const locations = [assignment.referee.city, assignment.match.field.city];
        if ((provinces[0] === provinces[1]) && (locations[0] === locations[1])) {
            setDistance({ provinces, locations, distance: 0 })
        } else {
            const url = `distances?provinces=${provinces[0]}&provinces=${provinces[1]}&locations=${locations[0]}&locations=${locations[1]}`;
            API.get(url)
                .then(res => {
                    if (res.success) {
                        setDistance(res.distances[0]);
                    } else {
                        setDistance({ provinces, locations, distance: 0 });
                    }
                })
                .catch(err => console.error(err));
        }
    }, [assignment])

    return (
        <div className="py-1">
            <GenericCard colorClasses={rejected ? 'bg-red-50 hover:bg-red-100' : index % 2 === 0 ? 'bg-green-50 hover:bg-green-100' : 'bg-blue-50 hover:bg-blue-100'}>
                <h5 className="w-1/2 lg:w-1/3 xl:w-1/5 mx-auto rounded-lg text-md text-center font-bold tracking-wide uppercase text-gray-700 dark:text-white">
                    {assignment.match.championship.name}
                </h5>
                <div className="flex flex-row gap-4">
                    <div className="basis-1/2 my-auto">
                        <ul className="text-right lg:text-left space-y-1 max-w-md list-none list-inside text-gray-700 dark:text-gray-400">
                            <li>
                                <span className="font-semibold">Gara #:</span> {assignment.match.num}
                            </li>
                            <li>
                                <span className="font-semibold text-sm uppercase">{assignment.match.teamA.name} - {assignment.match.teamB.name}</span>
                            </li>
                            <li>
                                <span className="font-semibold">Data:</span> <span className="capitalize">{formatDateTime(assignment.match.date, true)}</span>
                            </li>
                            <li>
                                <span className="font-semibold">Campo:</span> {assignment.match.field.name}
                            </li>
                            <li>
                                <span className="font-semibold">Località:</span> {assignment.match.field.city} ({assignment.match.field.province})
                            </li>
                            <li>
                                <span className="font-semibold">Indirizzo:</span> {assignment.match.field.address}
                            </li>
                            {!rejected && new Date(assignment.match.date) >= new Date() &&
                                <li>
                                    <div className="lg:w-2/3">
                                        <a href={calendarUrl(assignment)} target="_blank" rel="noreferrer">
                                            <Button size="xs">
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z" />
                                                </svg>
                                                Aggiungi a Google Calendar
                                            </Button>
                                        </a>
                                    </div>
                                </li>
                            }
                        </ul>
                    </div>
                    <div className="text-left my-auto">
                        <ul className="max-w-md list-none list-inside text-gray-700 dark:text-gray-400">
                            <li className="mb-1">
                                <span className="font-semibold uppercase">{rejected ? 'Motivazione Rifiuto' : 'Riepilogo Ruoli'}</span>
                            </li>
                            {
                                !rejected && roles
                                    .filter(r => r.sport === assignment.match.championship.sport)
                                    .map(r =>
                                        <RoleRow
                                            key={r._id}
                                            match={assignment.match}
                                            role={r}
                                        />
                                    )
                            }
                            {
                                !rejected && !noResults() &&
                                <li className="pt-3">
                                    <span className="font-semibold">Risultato Inserito:</span> {assignment.match.result.a} - {assignment.match.result.b}
                                    {' '}({readablePartials(assignment.match.partials)}) <span className="text-xs">({formatDateTime(assignment.match.result_updated_at)})</span>
                                </li>
                            }
                            {
                                !rejected && noReport() === false &&
                                <li>
                                    <span className="font-semibold">Rapporto gara:</span> Compilato <span className="text-xs">({formatDateTime(assignment.match.report_updated_at)})</span>
                                </li>
                            }
                            {
                                !rejected && noRefund() === false &&
                                <li>
                                    <span className="font-semibold">Richiesta di rimborso:</span> Compilata <span className="text-xs">({formatDateTime(assignment.refund.updated_at)})</span>
                                </li>
                            }
                            {
                                rejected &&
                                <li>
                                    <span className='italic'>{assignment.reason || 'Non inserita'}</span>
                                </li>
                            }
                        </ul>
                    </div>
                </div>
                {!rejected && new Date(assignment.match.date) > new Date() &&
                    <div className="mx-auto">
                        {assignment.status > 1 &&
                            <div className="flex flex-col flex-wrap items-center gap-2">
                                <span className="font-semibold uppercase tracking-wide">
                                    {assignment.status === 2
                                        ? 'Questa gara non è ancora stata accettata'
                                        : `Hai accettato questa gara in data ${formatDateTime(assignment.updated_at)}`
                                    }
                                </span>
                                <div className="flex flex-row gap-2">
                                    {
                                        assignment.status === 2 &&
                                        <div>
                                            <Button size="sm" onClick={handleAccept}>
                                                Clicca qui per accettare
                                            </Button>
                                        </div>
                                    }
                                    {
                                        assignment.status === 2 &&
                                        <div>
                                            <Button size="sm" color="failure" onClick={handleReject}>
                                                Clicca qui per rifiutare
                                            </Button>
                                        </div>
                                    }
                                    {
                                        assignment.status === 3 && (new Date(assignment.match.date) - new Date()) >= daysToReject * 24 * 60 * 60 * 1000 &&
                                        <div>
                                            <Button
                                                size="sm"
                                                color="failure"
                                                onClick={handleReject}
                                            >
                                                Rifiuta
                                            </Button>
                                        </div>
                                    }
                                    {
                                        assignment.status === 3 && (new Date(assignment.match.date) - new Date()) < daysToReject * 24 * 60 * 60 * 1000 &&
                                        <Tooltip content="Questa gara è troppo vicina, contatta il tuo responsabile">
                                            <Button
                                                size="sm"
                                                color="failure"
                                                onClick={handleReject}
                                                disabled={true}
                                            >
                                                Rifiuta
                                            </Button>
                                        </Tooltip>
                                    }
                                </div>
                            </div>
                        }
                    </div>
                }
                {!rejected && new Date(assignment.match.date) < new Date() &&
                    <div className="mx-auto mt-2 flex flex-col gap-2">
                        {!assignment.match.referee_confirmation &&
                            <div className="flex flex-row gap-2">
                                <div className="flex flex-row gap-1 mx-auto">
                                    {findRole(assignment.role)?.insert_results &&
                                        <div>
                                            <Button
                                                size="sm"
                                                {...noResults() ? {} : { color: 'warning' }}
                                                onClick={editResults}
                                            >
                                                {!noResults() ? 'Modifica il risultato' : 'Inserisci il risultato'}
                                            </Button>
                                        </div>
                                    }
                                    {findRole(assignment.role)?.insert_report &&
                                        <>
                                            {noResults() &&
                                                <Tooltip content="Inserisci prima il risultato">
                                                    <div>
                                                        <Button
                                                            size="sm"
                                                            disabled={true}
                                                        >
                                                            Rapporto Gara
                                                        </Button>
                                                    </div>
                                                </Tooltip>
                                            }
                                            {!noResults() &&
                                                <div>
                                                    <Button size="sm"
                                                        {...noReport() ? {} : { color: 'warning' }}
                                                        onClick={editReport}
                                                    >
                                                        {noReport() ? 'Rapporto Gara' : 'Modifica Rapporto Gara'}
                                                    </Button>
                                                </div>
                                            }
                                        </>
                                    }
                                    {findRole(assignment.role)?.insert_docs &&
                                        <>
                                            {noResults() &&
                                                <Tooltip content="Inserisci prima il risultato">
                                                    <div>
                                                        <Button
                                                            size="sm"
                                                            disabled={true}
                                                        >
                                                            Documenti Gara
                                                        </Button>
                                                    </div>
                                                </Tooltip>
                                            }
                                            {!noResults() &&
                                                <div>
                                                    <Button
                                                        {...assignment.match?.documents.length > 0 ? { color: 'warning', label: assignment.match?.documents.length } : {}}
                                                        size="sm"
                                                        onClick={editDocs}
                                                    >
                                                        Documenti Gara
                                                    </Button>
                                                </div>
                                            }
                                        </>
                                    }

                                    {findRole(assignment.role)?.insert_confirmation &&
                                        <>
                                            {(noResults() || noReport()) &&
                                                <Tooltip content="Inserisci prima risultato e rapporto gara">
                                                    <div>
                                                        <Button
                                                            size="sm"
                                                            disabled={true}
                                                        >
                                                            Finalizza Gara
                                                        </Button>
                                                    </div>
                                                </Tooltip>
                                            }
                                            {(!noResults() && !noReport()) &&
                                                <div>
                                                    <Button
                                                        size="sm"
                                                        onClick={openConfirm}
                                                    >
                                                        Finalizza Gara
                                                    </Button>
                                                </div>
                                            }
                                        </>
                                    }
                                </div>
                            </div>

                        }
                        <div className="w-fit mx-auto">
                            <Button
                                {...assignment.refund ? { color: 'warning' } : {}}
                                size="sm"
                                onClick={editRefund}
                            >
                                Rimborso
                            </Button>
                        </div>
                        {assignment.match.referee_confirmation && findRole(assignment.role)?.insert_confirmation &&
                            <div className="flex items-center gap-2">
                                <span className="font-semibold uppercase tracking-wide mx-auto">
                                    Hai finalizzato questa gara in data {formatDateTime(assignment.match.referee_confirmation_at)}
                                </span>
                            </div>
                        }
                    </div>
                }
            </GenericCard>
        </div>
    )
}

export default MatchCard;