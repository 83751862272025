import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { serviceProvider as API } from "../../../../API/api";
import { formatDateTime } from "../../../../lib/utils";

// Components
import { Button, Select, Spinner, Tooltip } from "flowbite-react";
import FormLabel from "../../../elements/FormLabel";
import GenericAlert from "../../../elements/GenericAlert";
import GenericModal from "../../../elements/GenericModal";

const MainForm = ({ assignment, docTypes, setAssignment, setModalAlert, setTotalAssignments }) => {
    const { _id: userId } = useSelector(state => state.user);
    const { file_prefix } = useSelector(state => state.committee);
    const fileUpload = useRef(null);
    const [fileType, setFileType] = useState('');
    const [loading, setLoading] = useState(false);
    const [noFile, setNoFile] = useState(true);


    const handleFileChange = e => {
        const selectedFile = e.target?.files[0];
        const allowedFiles = ["image/jpeg", "image/png", "image/gif", "image/webp"];
        if (!allowedFiles.includes(selectedFile?.type)) {
            setModalAlert({ type: 'failure', label: 'Il tipo di file selezionato non è consentito' });
            setNoFile(true);
        } else {
            setModalAlert({ type: '', label: ''});
            setNoFile(false);
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        const docType = docTypes.find(d => d._id === fileType);
        const formData = new FormData();
        const updated_at = new Date().toISOString();
        formData.append('doc_prefix', docType.shortname);
        formData.append('champ_prefix', assignment.match.championship.abbreviation);
        formData.append('committee_prefix', file_prefix);
        formData.append('updated_at', updated_at);
        formData.append('ref', 'match');
        formData.append('ref_num', assignment.match.num);
        formData.append('file', fileUpload.current.files[0]);
        API.insert('files', formData, true, true)
            .then(res => {
                if (res.success) {
                    const document = {
                        filename: res.file.filename,
                        doctype: docType.name,
                        updated_at: updated_at,
                        updated_by: userId
                    }
                    const updBody = { documents: [...assignment.match.documents, document] };
                    API.update(`matches/${assignment.match._id}`, JSON.stringify(updBody), true)
                        .then(res => {
                            if (res.success) {
                                setAssignment(prevState => ({
                                    ...prevState,
                                    match: res.match
                                }));
                                setTotalAssignments(prevState => [
                                    ...prevState.filter(a => a._id !== assignment._id),
                                    {
                                        ...assignment,
                                        match: res.match
                                    }
                                ]);
                                fileUpload.current.value = '';
                                setFileType('');
                                setNoFile(true);
                                setModalAlert({ type: 'success', label: 'Il documento è stato inserito' });
                            } else {
                                setModalAlert({ type: 'failure', label: res.msg || 'Qualcosa è andato storto...' });
                                setLoading(false);
                                setNoFile(true);
                            }
                            setLoading(false);
                        })
                } else {
                    setModalAlert({ type: 'failure', label: res.msg || 'Qualcosa è andato storto...' });
                }
            })
            .catch(err => console.error(err));
    }

    return (
        <form className="flex flex-col gap-4" onSubmit={handleSubmit}>
            <div>
                <input
                    className="block w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 cursor-pointer dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                    aria-describedby="file_upload_help"
                    id="file"
                    type="file"
                    accept="image/png,image/jpeg,image/bmp,image/webp"
                    ref={fileUpload}
                    onChange={handleFileChange}
                />
                <div>
                    <div
                        className="mt-1 text-sm text-gray-500 dark:text-gray-300"
                        id="file_upload_help"
                    >
                        Seleziona il documento
                    </div>

                </div>
            </div>
            <div className="flex flex-row gap-2 items-end">
                <div className="basis-full">
                    <div>
                        <div className="mb-2 block">
                            <FormLabel htmlFor="type" label="Che tipo di documento stai inviando?" />
                        </div>
                        <Select
                            id="type"
                            value={fileType}
                            required={true}
                            onChange={(e) => setFileType(e.currentTarget.value)}
                        >
                            <option value="">-</option>
                            {
                                docTypes.map(d => <option key={d._id} value={d._id}>{d.name}</option>)
                            }
                        </Select>
                    </div>
                </div>
                <div className="basis-1/8">
                    <Tooltip content={noFile ? "Seleziona un file" : loading ? "Sto inviando il file..." : "Clicca per inviare il file"}>
                        <Button
                            size="sm"
                            type="submit"
                            disabled={noFile ? true : loading}
                        >
                            {!loading &&
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth={1.5}
                                    stroke="currentColor"
                                    className="w-6 h-6"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M9 8.25H7.5a2.25 2.25 0 00-2.25 2.25v9a2.25 2.25 0 002.25 2.25h9a2.25 2.25 0 002.25-2.25v-9a2.25 2.25 0 00-2.25-2.25H15m0-3l-3-3m0 0l-3 3m3-3V15"
                                    />
                                </svg>
                            }
                            {loading &&
                                <Spinner aria-label="Loading Spinner" size="md" />
                            }
                        </Button>
                    </Tooltip>
                </div>
            </div>
        </form>
    )
}

const DocsList = ({ assignment, setAssignment, setImgInfo, setModalAlert, setOpenImgModal, setTotalAssignments }) => {
    const [loading, setLoading] = useState(false);

    const getDocument = (e) => {
        const docId = e.currentTarget.dataset.id;
        const { filename } = assignment.match.documents.find(d => d._id === docId);
        const queryParams = `ref=match&refId=${assignment.match._id}&filename=${filename}`;
        API.getResource(`files?${queryParams}`, true)
            .then(res => {
                if (res.ok) {
                    return res.blob();
                } else {
                    setModalAlert({ type: 'failure', label: 'Impossibile visualizzare questo file' });
                }
            })
            .then(blob => {
                const objectURL = URL.createObjectURL(blob);
                setImgInfo({ scope: 'docsmodal', src: objectURL });
                setOpenImgModal(true);
            })
            .catch(err => console.error(err));
    }

    const deleteDocument = (e) => {
        setLoading(true);
        const docId = e.currentTarget.dataset.id;
        const { filename } = assignment.match.documents.find(d => d._id === docId);
        const queryParams = `ref=match&refId=${assignment.match._id}&filename=${filename}`;
        API.delete(`files?${queryParams}`, true)
            .then(res => {
                if (res.success) {
                    const updBody = {
                        documents: [...assignment.match.documents.filter(d => d._id !== docId)]
                    };
                    API.update(`matches/${assignment.match._id}`, JSON.stringify(updBody), true)
                        .then(res => {
                            if (res.success) {
                                setAssignment(prevState => ({
                                    ...prevState,
                                    match: res.match
                                }));
                                setTotalAssignments(prevState => [
                                    ...prevState.filter(a => a._id !== assignment._id),
                                    {
                                        ...assignment,
                                        match: res.match
                                    }
                                ]);
                                setModalAlert({ type: 'success', label: 'Il documento è stato eliminato' });
                            } else {
                                setModalAlert({ type: 'failure', label: res.msg || 'Qualcosa è andato storto...' });
                            }
                            setLoading(false);
                        })
                } else {
                    setModalAlert({ type: 'failure', label: 'Impossibile eliminare' });
                    setLoading(false);
                }
            })
    }

    return (
        <div>
            <p className="mb-1 text-center text-sm font-semibold uppercase tracking-wide">Attualmente inseriti</p>
            {
                assignment.match.documents.map((d, i) =>
                    <div key={`doc_${i}`} className="flex flex-row gap-2 items-center">
                        <div className="mb-2 bg-blue-100 rounded-lg p-2 basis-11/12">
                            <div
                                className="text-gray-800 uppercase tracking-wide font-semibold cursor-pointer"
                                data-id={d._id}
                                onClick={getDocument}
                            >
                                {d.doctype}
                            </div>
                        </div>
                        <div className="w-fit mb-2">
                            <Tooltip content={loading ? "Elimino il file..." : "Clicca per eliminare il file"}>
                                <Button
                                    color="failure"
                                    size="xs"
                                    className="mb-1"
                                    data-id={d._id}
                                    onClick={deleteDocument}
                                    disabled={loading}
                                >
                                    {!loading &&
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                                        </svg>
                                    }
                                    {loading &&
                                        <Spinner aria-label="Loading Spinner" size="md" />
                                    }
                                </Button>
                            </Tooltip>
                        </div>
                    </div>
                )
            }
        </div>
    )
}

function DocsModal({ assignment, docTypes, openModal, setAssignment, setImgInfo, setOpenImgModal, setOpenModal, setTotalAssignments }) {
    const [modalAlert, setModalAlert] = useState({ type: '', label: '' });

    // Azzera l'alert del modal ogni volta che viene aperto
    useEffect(() => {
        openModal === true && setModalAlert({ type: '', label: '' });
    }, [openModal]);

    return (
        <GenericModal openModal={openModal} setOpenModal={setOpenModal} title='Inserisci documenti di gara' size="xl">
            {modalAlert?.type && <GenericAlert type={modalAlert.type}>{modalAlert.label}</GenericAlert>}
            <ul className="space-y-1 list-none list-inside p-2 cursor-default border bg-gray-50 shadow-lg border-red-400 rounded-lg text-center text-gray-700 dark:text-gray-400">
                <li>
                    <span className="font-semibold text-sm uppercase">{assignment.match?.championship.name}</span>
                </li>
                <li>
                    <span className="font-semibold">{assignment.match?.teamA.name} - {assignment.match?.teamB.name}</span>
                </li>
                <hr className="my-8 h-px bg-gray-300 border-0 dark:bg-gray-700" />
                <div className="flex flex-row gap-4 items-center">
                    <div className="text-right basis-1/2">
                        <li>
                            <span className="font-semibold">Data:</span> <span className="capitalize">{formatDateTime(assignment.match?.date, true)}</span>
                        </li>
                        <li>
                            <span className="font-semibold">Località:</span> {assignment.match?.field.city} ({assignment.match?.field.province})
                        </li>
                    </div>
                    <div className="text-left basis-1/2">
                        <li>
                            <span className="font-semibold">Campo:</span> {assignment.match?.field.name}
                        </li>
                        <li>
                            <span className="font-semibold">Ruolo:</span> {assignment.role}
                        </li>
                    </div>
                </div>
            </ul>
            <MainForm
                assignment={assignment}
                docTypes={docTypes}
                setAssignment={setAssignment}
                setModalAlert={setModalAlert}
                setTotalAssignments={setTotalAssignments}
            />
            {
                assignment.match?.documents.length > 0 &&
                <DocsList
                    assignment={assignment}
                    setAssignment={setAssignment}
                    setImgInfo={setImgInfo}
                    setModalAlert={setModalAlert}
                    setOpenImgModal={setOpenImgModal}
                    setTotalAssignments={setTotalAssignments}
                />
            }
        </GenericModal>
    )
}

export default DocsModal;