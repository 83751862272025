import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { serviceProvider as API } from "../../../API/api";
import { abbrText, findOverlapses, formatDateTime } from "../../../lib/utils";

// Components
import { Button, Table, Tooltip } from "flowbite-react";
import GenericAlert from "../../elements/GenericAlert";
import GenericTitle from "../../elements/GenericTitle";
import ShowButton from "../../buttons/ShowButton";


const TableRow = ({ hide, match }) => {
    const { urlcode } = useSelector(state => state.committee);

    return (
        <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
            <Table.Cell className="hidden lg:table-cell whitespace-nowrap font-medium text-gray-900 dark:text-white">
                {match.num}
            </Table.Cell>
            <Table.Cell className={hide === 'date' ? 'hidden lg:table-cell' : ''}>
                {formatDateTime(match.date)}
            </Table.Cell>
            <Table.Cell>
                <span className="hidden lg:table-cell">{match.teamA.name}</span>
                <div className="lg:hidden">
                    <Tooltip content={`${match.teamA.name} - ${match.teamB.name}`}>
                        {abbrText(match.teamA.name, 8)} - {abbrText(match.teamB.name, 8)}
                    </Tooltip>
                </div>
            </Table.Cell>
            <Table.Cell className="hidden lg:table-cell">
                {match.teamB.name}
            </Table.Cell>
            <Table.Cell className={hide === 'field' ? 'hidden lg:table-cell' : ''}>
                <span className="hidden xl:block">{`${match.field.city} - ${match.field.name}`}</span>
                <div className="lg:hidden">
                    <Tooltip className="xl:hidden" content={`${match.field.city} - ${match.field.name}`}>
                        {abbrText(`${match.field.city} - ${match.field.name}`, 12)}
                    </Tooltip>
                </div>
            </Table.Cell>
            <Table.Cell className="hidden xl:table-cell">
                {match.championship.name}
            </Table.Cell>
            <Table.Cell>
                <ShowButton href={`/${urlcode}/riservata/campionati/${match.championship._id}`} />
            </Table.Cell>
        </Table.Row>
    )
}

const MainTable = ({ hide, matches }) => {
    const [fading, setFading] = useState(false);
    const [hidden, setHidden] = useState('flex');

    const fade = () => {
        setFading(true);
        setTimeout(() => {
            setHidden('hidden');
        }, 500)
    }

    return (
        <div className={`${fading ? `transition-opacity duration-500 opacity-0 ease-in-out ${hidden}` : 'flex'} items-center p-4 min-w-full max-w-xs text-gray-500 bg-white rounded-lg shadow dark:text-gray-400 dark:bg-gray-800`} role="alert">
            <div className="ring-1 ring-orange-300 rounded-lg mr-1 w-full">
                <Table hoverable={true}>
                    <Table.Head>
                        <Table.HeadCell className="hidden lg:table-cell">
                            #
                        </Table.HeadCell>
                        <Table.HeadCell className={hide === 'date' ? 'hidden lg:table-cell' : ''}>
                            Data
                        </Table.HeadCell>
                        <Table.HeadCell>
                            <span className="hidden lg:table-cell">Squadra 1</span>
                            <span className="lg:hidden">Squadre</span>
                        </Table.HeadCell>
                        <Table.HeadCell className="hidden lg:table-cell">
                            Squadra 2
                        </Table.HeadCell>
                        <Table.HeadCell className={hide === 'field' ? 'hidden lg:table-cell' : ''}>
                            Campo
                        </Table.HeadCell>
                        <Table.HeadCell className="hidden xl:table-cell">
                            Campionato
                        </Table.HeadCell>
                        <Table.HeadCell>
                            <span className="sr-only">
                                Edit
                            </span>
                        </Table.HeadCell>
                    </Table.Head>
                    <Table.Body className="divide-y">
                        {
                            matches.map((match, i) => {
                                return <TableRow key={match._id} hide={hide} match={match} />
                            })
                        }
                    </Table.Body>
                </Table>
            </div>
            <div>
                <Button
                    aria-label="Close"
                    size="xs"
                    color="light"
                    onClick={fade}
                >
                    <span className="sr-only">Close</span>
                    <svg
                        aria-hidden="true"
                        className="w-4 h-4"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            fillRule="evenodd"
                            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                            clipRule="evenodd"
                        >
                        </path>
                    </svg>
                </Button>
            </div>
        </div>
    )
}

function Overlapses() {
    const { _id: seasonId } = useSelector(state => state.season);
    const { _id: committeeId } = useSelector(state => state.committee);
    const [alert, setAlert] = useState({ type: '', label: '' });
    const [matches, setMatches] = useState([]);
    const [overlapses, setOverlapses] = useState({});
    const noOverlapses = useCallback(() => (overlapses?.byTeam?.length + overlapses?.byClub?.length + overlapses?.byField?.length) < 1, [overlapses]);

    useEffect(() => {
        if (seasonId && committeeId) {
            API.get(`championships?season=${seasonId}&committee=${committeeId}`)
                .then(res => {
                    if (res.success) {
                        // Creo un array con tutte le gare dei campionati
                        const matches = [];
                        res.championships.forEach(c =>
                            c.calendar.forEach(g =>
                                g.rounds.forEach(r =>
                                    r.matches.forEach(m => {
                                        matches.push(m)
                                    })
                                )
                            )
                        )
                        setMatches(matches);
                    } else {
                        setAlert({ type: 'failure', label: res.msg || 'Qualcosa è andato storto...' });
                    }
                })
                .catch(err => console.error(err))
        }
    }, [committeeId, seasonId]);

    useEffect(() => {
        if (matches.length) {
            setOverlapses(findOverlapses(matches));
        }
    }, [matches]);

    return (
        <div className="flex flex-col gap-3">
            {alert?.type && <GenericAlert type={alert.type}>{alert.label}</GenericAlert>}
            {overlapses?.byTeam?.length > 0 &&
                <>
                    <GenericTitle title="Sovrapposizioni per squadra" size="xl" />
                    {overlapses?.byTeam?.map((array, i) =>
                        <MainTable
                            key={`byTeam_${i}`}
                            id={`byTeam_${i}`}
                            hide={'field'}
                            matches={array}
                        />
                    )}
                </>
            }
            {overlapses?.byClub?.length > 0 &&
                <>
                    <GenericTitle title="Sovrapposizioni per società" size="xl" />
                    {overlapses?.byClub?.map((array, i) =>
                        <MainTable
                            key={`byClub_${i}`}
                            id={`byClub_${i}`}
                            hide={'field'}
                            matches={array}
                        />
                    )}
                </>
            }
            {overlapses?.byField?.length > 0 &&
                <>
                    <GenericTitle title="Sovrapposizioni per campo" size="xl" />
                    {overlapses?.byField?.map((array, i) =>
                        <MainTable
                            key={`byField_${i}`}
                            id={`byField_${i}`}
                            hide={'date'}
                            matches={array}
                        />
                    )}
                </>
            }
            {noOverlapses() &&
                <div className="bg-gray-200 rounded border border-gray-500 p-2 uppercase font-semibold text-xs">
                    Nessuna sovrapposizione futura
                </div>
            }
        </div>
    )
}

export default Overlapses;