import { Link } from "react-router-dom";

function ShowButton({ href, classes }) {
    return (
        <Link to={href}>
            <button
                type="button"
                className="focus:outline-none text-xs text-white bg-blue-400 hover:bg-blue-500 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg px-2.5 py-2.5 mr-2 mb-2 dark:focus:ring-blue-900"
            >
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={classes || 'w-5 h-5'}>
                    <path strokeLinecap="round" strokeLinejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
                </svg>
            </button>
        </Link>
    )
}

export default ShowButton;