import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { serviceProvider as API } from "../../../API/api";
import { sortByName } from "../../../lib/utils";

// Components
import { Button, Table, TextInput } from "flowbite-react";
import DeleteButton from "../../buttons/DeleteButton";
import EditButton from "../../buttons/EditButton";
import FormLabel from "../../elements/FormLabel";
import GenericAlert from "../../elements/GenericAlert";
import GenericModal from "../../elements/GenericModal";
import LoadingRow from "../../elements/LoadingRow";
import NoResultsRow from "../../elements/NoResultsRow";
import SubmitButton from "../../buttons/SubmitButton";

const TableRow = ({ docType, setAction, setDocType, setOpenModal }) => {
    const { _id, name, shortname, committee } = docType;

    const rowCommittee = {
        _id: _id,
        name: name || '',
        shortname: shortname || '',
        committee: committee
    }

    const handleEdit = () => {
        setAction('edit');
        setDocType(rowCommittee);
        setOpenModal(true);
    }

    const handleDelete = () => {
        setAction('delete');
        setDocType(rowCommittee);
        setOpenModal(true);
    }

    return (
        <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
            <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                {name}
            </Table.Cell>
            <Table.Cell>
                {shortname}
            </Table.Cell>
            <Table.Cell>
                <EditButton onClick={handleEdit} />
                <DeleteButton onClick={handleDelete} />
            </Table.Cell>
        </Table.Row>
    )
}

const DocTypesTable = ({ committee, docTypes, setAlert, setAction, setDocType, setDocTypes, setOpenModal }) => {
    const [loading, setLoading] = useState(true);
    const [noResults, setNoResults] = useState(false);

    useEffect(() => {
        API.get(`doctypes?committee=${committee}`)
            .then(res => {
                if (res.success) {
                    setDocTypes(sortByName(res.doctypes, 'name'));
                    setAlert({ type: '', label: '' });
                    setNoResults(false);
                    setLoading(false);
                } else {
                    setDocTypes([]);
                    setAlert({ type: 'failure', label: res.msg });
                    setLoading(false);
                }
            })
            .catch(err => console.error(err))
    }, [committee, setDocTypes, setAlert])

    useEffect(() => {
        if (docTypes.length) {
            setNoResults(false);
            setLoading(false);
        } else {
            setNoResults(true);
            setLoading(false);
        }
    }, [docTypes])

    return (
        <Table hoverable={true}>
            <Table.Head>
                <Table.HeadCell>
                    Nome
                </Table.HeadCell>
                <Table.HeadCell>
                    Abbreviazione
                </Table.HeadCell>
                <Table.HeadCell>
                    <span className="sr-only">
                        Edit
                    </span>
                </Table.HeadCell>
            </Table.Head>
            <Table.Body className="divide-y">
                {loading && <LoadingRow colspan={3} />}
                {(!loading && noResults) && <NoResultsRow colspan={3} />}
                {
                    docTypes.map((docType, i) => <TableRow key={i} docType={docType} setAction={setAction} setDocType={setDocType} setOpenModal={setOpenModal} />)
                }
            </Table.Body>
        </Table>
    )
}

const DocTypeForm = ({ action, docType, setAlert, setDocType, setDocTypes, setModalAlert, setOpenModal }) => {

    const handleInput = (e) => {
        const { id, value } = e.currentTarget;
        setDocType(prevState => ({
            ...prevState,
            [id]: value
        }));
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        switch (action) {
            case 'new':
                API.insert('doctypes', JSON.stringify(docType), true)
                    .then(res => {
                        if (res.success) {
                            setDocTypes(prevState => sortByName([...prevState, res.doctype], 'name'));
                            setAlert({ type: 'success', label: 'Il documento è stato inserito' });
                            setOpenModal(false);
                        } else {
                            setModalAlert({ type: 'failure', label: res.msg || 'Qualcosa è andato storto...' });
                        }
                    })
                    .catch(err => console.error(err));
                break;
            case 'edit':
                API.update(`doctypes/${docType._id}`, JSON.stringify(docType), true)
                    .then(res => {
                        if (res.success) {
                            setDocTypes(prevState => {
                                const index = prevState.findIndex(el => el._id === docType._id);
                                prevState[index] = docType;
                                return sortByName(prevState, 'name')
                            });
                            setAlert({ type: 'success', label: 'Il documento è stato modificato correttamente' });
                            setOpenModal(false);
                        } else {
                            setModalAlert({ type: 'failure', label: res.msg || 'Qualcosa è andato storto...' });
                        }
                    })
                    .catch(err => console.error(err));
                break;
            case 'delete':
                API.delete(`doctypes/${docType._id}`, true)
                    .then(res => {
                        if (res.success) {
                            setDocTypes(prevState => prevState.filter(el => el._id !== docType._id));
                            setAlert({ type: 'success', label: 'Il documento è stato eliminato' });
                            setOpenModal(false);
                        } else {
                            setModalAlert({ type: 'failure', label: res.msg || 'Qualcosa è andato storto...' });
                        }
                    })
                    .catch(err => console.error(err));
                break;
            default:
        }
    }

    return (
        <form className="flex flex-col gap-4" onSubmit={handleSubmit}>
            <div>
                <div className="mb-2 block">
                    <FormLabel htmlFor="name" label="Nome" />
                </div>
                <TextInput
                    id="name"
                    type="text"
                    required={true}
                    value={docType.name}
                    onChange={handleInput}
                    disabled={action === 'delete' ? true : false}
                />
            </div>
            <div>
                <div className="mb-2 block">
                    <FormLabel htmlFor="shortname" label="Abbreviazione" />
                </div>
                <TextInput
                    id="shortname"
                    type="text"
                    required={true}
                    value={docType.shortname}
                    onChange={handleInput}
                    disabled={action === 'delete' ? true : false}
                />
            </div>
            {
                action === 'delete' &&
                <GenericAlert type="warning" divclass="text-lg mx-auto" hideicon="true">Desideri eliminare questo documento?</GenericAlert>
            }
            <div className="text-center">
                <SubmitButton />
            </div>
        </form>
    )
}

const DocTypesModal = ({ action, docType, openModal, setAlert, setDocType, setDocTypes, setOpenModal }) => {
    const [modalAlert, setModalAlert] = useState({ type: '', label: '' });

    // Azzera l'alert del modal ogni volta che viene aperto
    useEffect(() => {
        openModal === true && setModalAlert({ type: '', label: '' });
    }, [openModal]);

    return (
        <GenericModal openModal={openModal} setOpenModal={setOpenModal} title={`${action === 'new' ? 'Nuovo' : action === 'edit' ? 'Modifica' : 'Elimina'} Tipo di Documento`}>
            {modalAlert?.type && <GenericAlert type={modalAlert.type}>{modalAlert.label}</GenericAlert>}
            <DocTypeForm action={action} docType={docType} setAlert={setAlert} setDocType={setDocType} setDocTypes={setDocTypes} setModalAlert={setModalAlert} setOpenModal={setOpenModal} />
        </GenericModal>
    )
}

function DocTypes() {
    const { _id: committeeId } = useSelector(state => state.committee);
    const [action, setAction] = useState('new');
    const [alert, setAlert] = useState({ type: '', label: '' });
    const [docTypes, setDocTypes] = useState([]);
    const [docType, setDocType] = useState({ name: '', shortname: '', committee: committeeId });
    const [openModal, setOpenModal] = useState(false);

    const openCommModal = () => {
        setDocType({ name: '', shortname: '', committee: committeeId });
        setAction('new');
        setOpenModal(true);
    }

    return (
        <div className="flex flex-col gap-3">
            {alert?.type && <GenericAlert type={alert.type}>{alert.label}</GenericAlert>}
            <DocTypesTable committee={committeeId} docTypes={docTypes} setAlert={setAlert} setAction={setAction} setDocType={setDocType} setDocTypes={setDocTypes} setOpenModal={setOpenModal} />
            <div className="w-fit">
                <Button size="sm" onClick={openCommModal}>
                    Nuovo Documento
                </Button>
            </div>
            <DocTypesModal action={action} docType={docType} openModal={openModal} setAlert={setAlert} setDocType={setDocType} setDocTypes={setDocTypes} setOpenModal={setOpenModal} />
        </div>
    )
}

export default DocTypes;