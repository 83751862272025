import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { serviceProvider as API } from "../../../../API/api";
import { createFormElement } from "../../../../lib/utils";

// Components
import { Button, Card, Select, TextInput, Tooltip } from "flowbite-react";
import DeleteButton from "../../../buttons/DeleteButton";
import EditButton from "../../../buttons/EditButton";
import FormLabel from "../../../elements/FormLabel";
import GenericAlert from "../../../elements/GenericAlert";
import GenericModal from "../../../elements/GenericModal";
import GenericTitle from "../../../elements/GenericTitle";
import SubmitButton from "../../../buttons/SubmitButton";


const ElementCard = ({ index, formElement, setAction, setElement, setOpenModal }) => {
    const { _id, id, type, label_text, options, default_value } = formElement;

    const rowElement = {
        _id: _id,
        id: id || '',
        type: type || 'text',
        label_text: label_text || '',
        options: options || [],
        default_value: default_value || ''
    }

    const handleEdit = () => {
        setAction('edit');
        setElement(rowElement);
        setOpenModal(true);
    }

    const handleDelete = () => {
        setAction('delete');
        setElement(rowElement);
        setOpenModal(true);
    }

    return (
        <Draggable draggableId={formElement._id} index={index}>
            {
                (provided) =>
                    <div
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        ref={provided.innerRef}
                        className="flex flex-row gap-2 items-center"
                    >
                        <div className="p-6 bg-purple-50 hover:bg-purple-100 basis-11/12 text-justify rounded-lg border border-gray-200 shadow-md dark:bg-gray-800 dark:border-gray-700">
                            <div className="w-11/12 md:w-10/12 lg:w-8/12 xl:w-1/2 mx-auto">
                                {createFormElement(formElement, false)}
                            </div>
                        </div>
                        <div className="flex flex-col gap-2">
                            <EditButton onClick={handleEdit} />
                            <DeleteButton onClick={handleDelete} />
                        </div>
                    </div>
            }
        </Draggable>
    )
}

const SchemaList = ({ schema, setAction, setAlert, setElement, setNeedSaving, setOpenModal, setSchema }) => {
    const handleOnDragEnd = (result) => {
        const items = [...schema];
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);
        setAlert({ type: 'warning', label: 'Le modifiche devono essere salvate' })
        setSchema(items);
        setNeedSaving(true);
    }

    return (
        <>
            <DragDropContext onDragEnd={handleOnDragEnd}>
                <Droppable droppableId="formElements">
                    {(provided) =>
                        <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            className="flex flex-col gap-1"
                        >
                            {schema.map((el, index) =>
                                <ElementCard
                                    key={el._id}
                                    index={index}
                                    formElement={el}
                                    setAction={setAction}
                                    setElement={setElement}
                                    setOpenModal={setOpenModal}
                                />
                            )}
                            {provided.placeholder}
                        </div>
                    }
                </Droppable>
            </DragDropContext>
            {
                schema?.length < 1 &&
                <Card className="bg-purple-50 hover:bg-purple-100">
                    <h5 className="w-1/2 lg:w-1/3 xl:w-1/5 mx-auto rounded-lg text-md text-center font-bold tracking-wide uppercase text-gray-700 dark:text-white">
                        Nessun elemento presente
                    </h5>
                </Card>
            }
        </>
    )
}

const MainForm = ({ action, element, reportModel, setAlert, setElement, setModalAlert, setModelSchema, setOpenModal, setReportModel }) => {

    const handleInput = (e) => {
        const { id, value } = e.currentTarget;
        let options = [];
        if (id === 'type' && value === 'select') {
            if (element.type === 'select') {
                // Non devo cambiare options
                options = element.options;
            } else {
                options = [{ label: '', value: '' }];
            }
        }
        if (id !== 'type') {
            // Se non sto cambiando il tipo, lascio le opzioni così come sono
            options = element.options
        }
        setElement(prevState => ({
            ...prevState,
            options: options,
            [id]: value
        }));
    }

    const editOption = (e) => {
        const { id, value } = e.currentTarget;
        const { index } = e.currentTarget.dataset;
        const updElem = { ...element };
        updElem.options[index][id] = value;
        setElement(updElem);
    }

    const addOption = (e) => {
        const index = e.currentTarget.dataset.index;
        const options = [...element.options];
        options.splice(index, 0, { label: '', value: '' });
        setElement(prevState => ({
            ...prevState,
            options: options
        }));
    }

    const deleteOption = (e) => {
        const index = e.currentTarget.dataset.index;
        const options = [...element.options];
        options.splice(index, 1);
        setElement(prevState => ({
            ...prevState,
            options: options
        }));
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        switch (action) {
            case 'new':
                const newBody = {
                    ...reportModel,
                    model_schema: [
                        ...reportModel.model_schema,
                        element
                    ]
                }
                API.update(`reportmodels/${reportModel._id}`, JSON.stringify(newBody), true)
                    .then(res => {
                        if (res.success) {
                            setAlert({ type: 'success', label: 'L\'elemento è stato aggiunto' })
                            setReportModel(res.reportmodel);
                            setModelSchema(res.reportmodel.model_schema);
                            setOpenModal(false);
                        } else {
                            setModalAlert({ type: 'failure', label: res.msg || 'Qualcosa è andato storto...' });
                        }
                    })
                    .catch(err => console.error(err));
                break;
            case 'edit':
                const index = reportModel.model_schema.findIndex(el => el._id === element._id);
                const editBody = { ...reportModel };
                editBody.model_schema[index] = element;
                API.update(`reportmodels/${reportModel._id}`, JSON.stringify(editBody), true)
                    .then(res => {
                        if (res.success) {
                            setAlert({ type: 'success', label: 'L\'elemento è stato modificato' })
                            setReportModel(res.reportmodel);
                            setModelSchema(res.reportmodel.model_schema);
                            setOpenModal(false);
                        } else {
                            setModalAlert({ type: 'failure', label: res.msg || 'Qualcosa è andato storto...' });
                        }
                    })
                    .catch(err => console.error(err));
                break;
            case 'delete':
                const deleteIndex = reportModel.model_schema.findIndex(el => el._id === element._id);
                const deleteBody = { ...reportModel };
                deleteBody.model_schema.splice(deleteIndex, 1);
                API.update(`reportmodels/${reportModel._id}`, JSON.stringify(deleteBody), true)
                    .then(res => {
                        if (res.success) {
                            setAlert({ type: 'success', label: 'L\'elemento è stato eliminato' })
                            setReportModel(res.reportmodel);
                            setModelSchema(res.reportmodel.model_schema);
                            setOpenModal(false);
                        } else {
                            setModalAlert({ type: 'failure', label: res.msg || 'Qualcosa è andato storto...' });
                        }
                    })
                    .catch(err => console.error(err));
                break;
            default:
        }
    }

    return (
        <form className="flex flex-col gap-4" onSubmit={handleSubmit}>
            <div>
                <div className="mb-2 block">
                    <FormLabel htmlFor="id" label="ID (univoco per lo schema)" />
                </div>
                <TextInput
                    id="id"
                    type="text"
                    required={true}
                    value={element.id}
                    onChange={handleInput}
                    disabled={action === 'delete' ? true : false}
                />
            </div>
            <div>
                <div className="mb-2 block">
                    <FormLabel htmlFor="label_text" label="Testo per l'etichetta" />
                </div>
                <TextInput
                    id="label_text"
                    type="text"
                    required={true}
                    value={element.label_text}
                    onChange={handleInput}
                    disabled={action === 'delete' ? true : false}
                />
            </div>
            <div>
                <div className="mb-2 block">
                    <FormLabel htmlFor="type" label="Tipo di input" />
                </div>
                <Select
                    id="type"
                    required={true}
                    onChange={handleInput}
                    value={element.type}
                    disabled={action === 'delete' ? true : false}
                >
                    <option value="text">Casella di testo</option>
                    <option value="select">Scelta multipla</option>
                    <option value="textarea">Area di testo</option>
                </Select>
            </div>
            {
                element.options.map((el, i, options) =>
                    <div key={`element_${i}`} className="flex flex-row gap-2 items-end">
                        <div className="basis-2/5">
                            <div className="mb-2 block">
                                <FormLabel htmlFor="value" label="Value" />
                            </div>
                            <TextInput
                                id="value"
                                type="text"
                                required={true}
                                value={el.value}
                                data-index={i}
                                onChange={editOption}
                                disabled={action === 'delete' ? true : false}
                            />
                        </div>
                        <div className="basis-2/5">
                            <div className="mb-2 block">
                                <FormLabel htmlFor="label" label="Etichetta" />
                            </div>
                            <TextInput
                                id="label"
                                type="text"
                                required={true}
                                value={el.label}
                                data-index={i}
                                onChange={editOption}
                                disabled={action === 'delete' ? true : false}
                            />
                        </div>
                        <Tooltip content="Aggiungi un'opzione">
                            <Button
                                color="green"
                                pill="true"
                                size="xs"
                                className="mb-1"
                                data-index={i + 1}
                                onClick={addOption}
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                                </svg>
                            </Button>
                        </Tooltip>
                        <Tooltip content="Elimina l'opzione">
                            <Button
                                color="red"
                                pill="true"
                                size="xs"
                                className="mb-1"
                                data-index={i}
                                onClick={deleteOption}
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                </svg>
                            </Button>
                        </Tooltip>
                    </div>
                )
            }
            <div>
                <div className="mb-2 block">
                    <FormLabel htmlFor="default_value" label="Risposta di default" />
                </div>
                {
                    element.type === 'select' ?
                        <Select
                            id="default_value"
                            required={true}
                            onChange={handleInput}
                            value={element.default_value}
                            disabled={action === 'delete' ? true : false}
                        >
                            <option value="">-</option>
                            {
                                element.options.map((el, i) =>
                                    <option key={`default_opt_${i}`} value={el.value}>{el.label}</option>
                                )}
                        </Select>
                        : <TextInput
                            id="default_value"
                            type="text"
                            required={true}
                            value={element.default_value}
                            onChange={handleInput}
                            disabled={action === 'delete' ? true : false}
                        />
                }
            </div>
            {
                action === 'delete' &&
                <GenericAlert type="warning" divclass="text-lg mx-auto" hideicon="true">Desideri eliminare questo elemento?</GenericAlert>
            }
            <div className="text-center">
                <SubmitButton />
            </div>
        </form>
    )
}

const MainModal = ({ action, element, openModal, reportModel, setAlert, setElement, setModelSchema, setOpenModal, setReportModel }) => {
    const [modalAlert, setModalAlert] = useState({ type: '', label: '' });

    // Azzera l'alert del modal ogni volta che viene aperto
    useEffect(() => {
        openModal === true && setModalAlert({ type: '', label: '' });
    }, [openModal]);

    return (
        <GenericModal openModal={openModal} setOpenModal={setOpenModal} title={`${action === 'new' ? 'Nuovo' : action === 'edit' ? 'Modifica' : 'Elimina'} Elemento`}>
            {modalAlert?.type && <GenericAlert type={modalAlert.type}>{modalAlert.label}</GenericAlert>}
            <MainForm
                action={action}
                element={element}
                reportModel={reportModel}
                setAlert={setAlert}
                setElement={setElement}
                setModalAlert={setModalAlert}
                setModelSchema={setModelSchema}
                setOpenModal={setOpenModal}
                setReportModel={setReportModel}
            />
        </GenericModal>
    )
}

function ReportModel() {
    const { modelId } = useParams();
    const [action, setAction] = useState('new');
    const [alert, setAlert] = useState({ type: '', label: '' });
    const [element, setElement] = useState({ id: '', type: 'text', label_text: '', options: [], default_value: '' });
    const [modelSchema, setModelSchema] = useState([]);
    const [needSaving, setNeedSaving] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [reportModel, setReportModel] = useState({});

    const openNewModal = () => {
        setElement({ id: '', type: 'text', label_text: '', options: [], default_value: '' });
        setAction('new');
        setOpenModal(true);
    }

    const saveSchema = () => {
        const updBody = {
            ...reportModel,
            model_schema: modelSchema
        };
        API.update(`reportmodels/${reportModel._id}`, JSON.stringify(updBody), true)
            .then(res => {
                if (res.success) {
                    setReportModel(res.reportmodel);
                    setModelSchema(res.reportmodel.model_schema);
                    setAlert({ type: 'success', label: 'Le modifiche sono state salvate' });
                    setNeedSaving(false);
                } else {
                    setAlert({ type: 'failure', label: res.msg || 'Qualcosa è andato storto...' });
                }
            })
    }

    useEffect(() => {
        if (modelId) {
            API.get(`reportmodels/${modelId}`, true)
                .then(res => {
                    if (res.success) {
                        setReportModel(res.reportmodel);
                        setModelSchema(res.reportmodel.model_schema);
                    } else {
                        setAlert({ type: 'failure', label: res.msg || 'Qualcosa è andato storto...' });
                    }
                })
                .catch(err => console.error(err));
        }
    }, [modelId])

    return (
        <div className="flex flex-col gap-3">
            <GenericTitle title="Modifica modello di rapporto gara" size="xl" />
            {alert?.type && <GenericAlert type={alert.type}>{alert.label}</GenericAlert>}
            <SchemaList
                schema={modelSchema}
                setAction={setAction}
                setAlert={setAlert}
                setElement={setElement}
                setNeedSaving={setNeedSaving}
                setOpenModal={setOpenModal}
                setSchema={setModelSchema}
            />
            <div className="flex flex-row gap-2">
                <div>
                <Button size="sm" onClick={openNewModal}>
                    Nuovo elemento
                </Button>
                </div>
                <div>
                <Tooltip content={needSaving ? 'Le modifiche che hai fatto devono essere salvate' : 'Le modifiche sono già aggiornate'}>
                    <Button
                        size="sm"
                        color="warning"
                        disabled={needSaving ? false : true}
                        onClick={saveSchema}
                    >
                        Salva le modifiche
                    </Button>
                </Tooltip>
                </div>
            </div>
            <MainModal
                action={action}
                element={element}
                openModal={openModal}
                reportModel={reportModel}
                setAlert={setAlert}
                setElement={setElement}
                setModelSchema={setModelSchema}
                setOpenModal={setOpenModal}
                setReportModel={setReportModel}
            />
        </div>
    )
}

export default ReportModel;