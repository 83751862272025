import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { serviceProvider as API } from "../../../API/api";
import { sortByName } from "../../../lib/utils";

// Components
import { Button, Select, Table, TextInput } from "flowbite-react";
import DeleteButton from "../../buttons/DeleteButton";
import EditButton from "../../buttons/EditButton";
import FormLabel from "../../elements/FormLabel";
import GenericAlert from "../../elements/GenericAlert";
import GenericModal from "../../elements/GenericModal";
import LoadingRow from "../../elements/LoadingRow";
import NoResultsRow from "../../elements/NoResultsRow";
import ShowButton from "../../buttons/ShowButton";
import SubmitButton from "../../buttons/SubmitButton";


const TableRow = ({ committeesList, reportModel, setAction, setReportModel, setOpenModal }) => {
    const { _id, name, committees } = reportModel;
    const { urlcode } = useSelector(state => state.committee);
    const path = `/${urlcode}/riservata`;

    const rowReportModel = {
        _id: _id,
        name: name || '',
        committees: committees || []
    }

    const handleEdit = () => {
        setAction('edit');
        setReportModel(rowReportModel);
        setOpenModal(true);
    }

    const handleDelete = () => {
        setAction('delete');
        setReportModel(rowReportModel);
        setOpenModal(true);
    }

    return (
        <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
            <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                {name}
            </Table.Cell>
            <Table.Cell>
                {
                    <ul>
                        {
                            committeesList
                                .filter(c => reportModel.committees.includes(c._id))
                                .map(championship => <li key={championship._id}>{championship.name}</li>)
                        }
                    </ul>
                }
            </Table.Cell>
            <Table.Cell>
                <ShowButton href={`${path}/modelli-rapporto-gara/${reportModel._id}`} />
                <EditButton onClick={handleEdit} />
                <DeleteButton onClick={handleDelete} />
            </Table.Cell>
        </Table.Row>
    )
}

const MainTable = ({ committees, reportModel, reportModels, setAction, setReportModel, setOpenModal }) => {
    const [loading, setLoading] = useState(true);
    const [noResults, setNoResults] = useState(false);

    useEffect(() => {
        if (reportModels.length) {
            setNoResults(false);
            setLoading(false);
        } else {
            setNoResults(true);
            setLoading(false);
        }
    }, [reportModels])

    return (
        <Table hoverable={true}>
            <Table.Head>
                <Table.HeadCell>
                    Nome
                </Table.HeadCell>
                <Table.HeadCell>
                    Comitati
                </Table.HeadCell>
                <Table.HeadCell>
                    <span className="sr-only">
                        Edit
                    </span>
                </Table.HeadCell>
            </Table.Head>
            <Table.Body className="divide-y">
                {loading && <LoadingRow colspan={3} />}
                {(!loading && noResults) && <NoResultsRow colspan={3} />}
                {
                    reportModels.map(m =>
                        <TableRow
                            key={m._id}
                            committeesList={committees}
                            reportModel={m}
                            setAction={setAction}
                            setReportModel={setReportModel}
                            setOpenModal={setOpenModal}
                        />
                    )
                }
            </Table.Body>
        </Table>
    )
}

const MainForm = ({ action, committees, reportModel, setAlert, setReportModel, setReportModels, setModalAlert, setOpenModal }) => {

    const handleInput = (e) => {
        const { id, selectedOptions, value } = e.currentTarget;
        setReportModel(prevState => ({
            ...prevState,
            [id]: selectedOptions && id === 'committees'
                ? Array.from(selectedOptions).map(opt => committees.find(c => c._id === opt.value)._id)
                : value
        }));
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        switch (action) {
            case 'new':
                API.insert('reportmodels', JSON.stringify(reportModel), true)
                    .then(res => {
                        if (res.success) {
                            setReportModels(prevState => sortByName([...prevState, res.reportmodel], 'name'));
                            setAlert({ type: 'success', label: 'Il modello è stato inserito' });
                            setOpenModal(false);
                        } else {
                            setModalAlert({ type: 'failure', label: res.msg || 'Qualcosa è andato storto...' });
                        }
                    })
                    .catch(err => console.error(err));
                break;
            case 'edit':
                API.update(`reportmodels/${reportModel._id}`, JSON.stringify(reportModel), true)
                    .then(res => {
                        if (res.success) {
                            setReportModels(prevState => {
                                const index = prevState.findIndex(el => el._id === reportModel._id);
                                prevState[index] = res.reportmodel;
                                return sortByName(prevState, 'name')
                            });
                            setAlert({ type: 'success', label: 'Il modello è stato modificato correttamente' });
                            setOpenModal(false);
                        } else {
                            setModalAlert({ type: 'failure', label: res.msg || 'Qualcosa è andato storto...' });
                        }
                    })
                    .catch(err => console.error(err));
                break;
            case 'delete':
                API.delete(`reportmodels/${reportModel._id}`, true)
                    .then(res => {
                        if (res.success) {
                            setReportModels(prevState => prevState.filter(el => el._id !== reportModel._id));
                            setAlert({ type: 'success', label: 'Il modello è stato eliminato' });
                            setOpenModal(false);
                        } else {
                            setModalAlert({ type: 'failure', label: res.msg || 'Qualcosa è andato storto...' });
                        }
                    })
                    .catch(err => console.error(err));
                break;
            default:
        }
    }

    return (
        <form className="flex flex-col gap-4" onSubmit={handleSubmit}>
            <div>
                <div className="mb-2 block">
                    <FormLabel htmlFor="name" label="Nome" />
                </div>
                <TextInput
                    id="name"
                    type="text"
                    required={true}
                    value={reportModel.name}
                    onChange={handleInput}
                    disabled={action === 'delete' ? true : false}
                />
            </div>
            <div>
                <div className="mb-2 block">
                    <FormLabel htmlFor="committees" label="Comitati" />
                </div>
                <Select
                    id="committees"
                    required={true}
                    onChange={handleInput}
                    value={reportModel.committees}
                    multiple
                    disabled={action === 'delete' ? true : false}
                >
                    {
                        committees.map(c =>
                            <option
                                key={c._id}
                                value={c._id}
                            >
                                {c.name}
                            </option>
                        )
                    }
                </Select>
            </div>
            {
                action === 'delete' &&
                <GenericAlert type="warning" divclass="text-lg mx-auto" hideicon="true">Desideri eliminare questo modello?</GenericAlert>
            }
            <div className="text-center">
                <SubmitButton />
            </div>
        </form>
    )
}

const MainModal = ({ action, committees, openModal, reportModel, setAlert, setReportModel, setReportModels, setOpenModal }) => {
    const [modalAlert, setModalAlert] = useState({ type: '', label: '' });

    // Azzera l'alert del modal ogni volta che viene aperto
    useEffect(() => {
        openModal === true && setModalAlert({ type: '', label: '' });
    }, [openModal]);

    return (
        <GenericModal openModal={openModal} setOpenModal={setOpenModal} title={`${action === 'new' ? 'Nuovo' : action === 'edit' ? 'Modifica' : 'Elimina'} Modello`}>
            {modalAlert?.type && <GenericAlert type={modalAlert.type}>{modalAlert.label}</GenericAlert>}
            <MainForm
                action={action}
                committees={committees}
                reportModel={reportModel}
                setAlert={setAlert}
                setReportModel={setReportModel}
                setReportModels={setReportModels}
                setModalAlert={setModalAlert}
                setOpenModal={setOpenModal}
            />
        </GenericModal>
    )
}

function ReportModels() {
    const [action, setAction] = useState('new');
    const [alert, setAlert] = useState({ type: '', label: '' });
    const [committees, setCommittees] = useState([]);
    const [reportModels, setReportModels] = useState([]);
    const [reportModel, setReportModel] = useState({ committees: [], name: '' });
    const [openModal, setOpenModal] = useState(false);

    const openNewModal = () => {
        setReportModel({ committees: [], name: '' });
        setAction('new');
        setOpenModal(true);
    }

    useEffect(() => {
        API.get('reportmodels', true)
            .then(res => {
                if (res.success) {
                    setReportModels(sortByName(res.reportmodels, 'name'));
                } else {
                    setAlert({ type: 'failure', label: res.msg || 'Qualcosa è andato storto...' });
                }
            })
            .catch(err => console.error(err));
    }, []);

    useEffect(() => {
        API.get('committees')
            .then(res => {
                if (res.success) {
                    setCommittees(sortByName(res.committees, 'name'));
                } else {
                    setAlert({ type: 'failure', label: res.msg || 'Qualcosa è andato storto...' });
                }
            })
            .catch(err => console.error(err));
    }, []);

    return (
        <div className="flex flex-col gap-3">
            {alert?.type && <GenericAlert type={alert.type}>{alert.label}</GenericAlert>}
            <MainTable
                committees={committees}
                reportModel={reportModel}
                reportModels={reportModels}
                setAction={setAction}
                setReportModel={setReportModel}
                setOpenModal={setOpenModal}
            />
            <div className="w-fit">
                <Button size="sm" onClick={openNewModal}>
                    Nuovo Modello
                </Button>
            </div>
            <MainModal
                action={action}
                committees={committees}
                openModal={openModal}
                reportModel={reportModel}
                setAlert={setAlert}
                setReportModel={setReportModel}
                setReportModels={setReportModels}
                setOpenModal={setOpenModal}
            />
        </div>
    )
}

export default ReportModels;