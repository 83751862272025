import { useState } from "react";

// Components
import { Modal } from "flowbite-react";


function ImgModal({ imgInfo, openModal, setOpenModal }) {
    const [size, setSize] = useState('xl');

    const imageLoad = (e) => {
        const { naturalHeight: height, naturalWidth: width } = e.currentTarget
        if (height > width) {
            setSize('3xl')
        } else {
            setSize('7xl')
        }
    }

    const downloadImage = () => {
        const link = document.createElement('a');
        link.download = imgInfo.filename;
        let reader = new FileReader();
        reader.readAsDataURL(imgInfo.blob);
        link.target = '_blank';
        reader.onload = () => {
            link.href = reader.result;
            link.click();
        }
    }

    return (
        <Modal
            show={openModal}
            size={size}
            popup={true}
            position="bottom-center"
            onClose={() => setOpenModal(false)}
        >
            <Modal.Header>
                <div className="ml-4 mt-2">
                    Visualizza Documento
                </div>
            </Modal.Header>
            <Modal.Body>
                <div className="p-2">
                    <img id="documentId" src={imgInfo.src} className="cursor-pointer" alt="Documento di gara" onLoad={imageLoad} onClick={downloadImage} />
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default ImgModal;
