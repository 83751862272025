import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { serviceProvider as API } from "../../../../API/api";
import { formatDateTime, setRejectMail } from "../../../../lib/utils";

// Components
import { TextInput } from "flowbite-react";
import FormLabel from "../../../elements/FormLabel";
import GenericAlert from "../../../elements/GenericAlert";
import GenericModal from "../../../elements/GenericModal";
import SubmitButton from "../../../buttons/SubmitButton";


const MainForm = ({ action, assignment, setAlert, setAssignment, setModalAlert, setToasts, setTotalAssignments, setOpenModal }) => {
    const { _id: userId } = useSelector(state => state.user);
    const committee = useSelector(state => state.committee);

    const handleInput = (e) => {
        const { id, value } = e.currentTarget;
        setAssignment(prevState => ({
            ...prevState,
            [id]: value
        }));
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        switch (action) {
            case 'accept':
                const acceptBody = { status: 3, updated_at: new Date().toISOString(), updated_by: { _id: userId } };
                API.update(`assignments/${assignment._id}`, JSON.stringify(acceptBody), true)
                    .then(res => {
                        if (res.success) {
                            setTotalAssignments(prevState => [...prevState.filter(a => a._id !== assignment._id), res.assignment]);
                            setAlert({ type: 'success', label: 'La designazione è stata accettata' });
                            setOpenModal(false);
                        } else {
                            setModalAlert({ type: 'failure', label: res.msg || 'Qualcosa è andato storto...' });
                        }
                    })
                    .catch(err => console.error(err));
                break;
            case 'reject':
                const rejectBody = { status: 0, reason: assignment.reason, updated_at: new Date().toISOString(), updated_by: { _id: userId } };
                API.update(`assignments/${assignment._id}`, JSON.stringify(rejectBody), true)
                    .then(res => {
                        if (res.success) {
                            setTotalAssignments(prevState => prevState.filter(a => a._id !== assignment._id));
                            setToasts(prevState => [...prevState, 'La designazione è stata rifiutata']);
                            setOpenModal(false);
                        } else {
                            setModalAlert({ type: 'failure', label: res.msg || 'Qualcosa è andato storto...' });
                        }
                    })
                    .catch(err => console.error(err));
                const sendMailTo = assignment.match.championship?.sport === 'Calcio'
                    ? committee.soc_email
                    : assignment.match.championship?.sport === 'Pallavolo'
                        ? committee.vbl_email
                        : ''
                console.log(sendMailTo)
                if (sendMailTo) {
                    console.log('ok')
                    const mailBody = setRejectMail(assignment, committee);
                    API.insert('emails', JSON.stringify(mailBody), true)
                        .then()
                        .catch(err => console.error(err));
                }
                break;
            default:
        }
    }

    return (
        <form className="flex flex-col gap-4" onSubmit={handleSubmit}>
            <ul className="space-y-1 list-none list-inside p-2 cursor-default border bg-gray-50 shadow-lg border-red-400 rounded-lg text-center text-gray-700 dark:text-gray-400">
                <li>
                    <span className="font-semibold text-sm uppercase">{assignment.match?.championship.name}</span>
                </li>
                <li>
                    <span className="font-semibold">{assignment.match?.teamA.name} - {assignment.match?.teamB.name}</span>
                </li>
                <hr className="my-8 h-px bg-gray-300 border-0 dark:bg-gray-700" />
                <div className="flex flex-row gap-4 items-center">
                    <div className="text-right">
                        <li>
                            <span className="font-semibold">Ruolo:</span> {assignment.role}
                        </li>
                        <li>
                            <span className="font-semibold">Data:</span> <span className="capitalize">{formatDateTime(assignment.match?.date, true)}</span>
                        </li>
                    </div>
                    <div className="text-left">
                        <li>
                            <span className="font-semibold">Campo:</span> {assignment.match?.field.name}
                        </li>
                        <li>
                            <span className="font-semibold">Località:</span> {assignment.match?.field.city} ({assignment.match?.field.province})
                        </li>
                        <li>
                            <span className="font-semibold">Indirizzo:</span> {assignment.match?.field.address}
                        </li>
                    </div>
                </div>
            </ul>
            {action === 'reject' &&
                <div>
                    <div className="mb-2 block">
                        <FormLabel htmlFor="reason" label="Inserisci una motivazione" />
                    </div>
                    <TextInput
                        id="reason"
                        type="text"
                        required={true}
                        value={assignment.reason}
                        onChange={handleInput}
                        disabled={action === 'delete' ? true : false}
                    />
                </div>
            }
            <GenericAlert
                type="warning"
                divclass="text-lg mx-auto"
                hideicon="true"
            >
                Confermi di {action === 'accept' ? 'accettare' : 'rifiutare'} questa designazione?
            </GenericAlert>
            <div className="text-center">
                <SubmitButton />
            </div>
        </form>
    )
}

function UserModal({ action, assignment, openModal, setAlert, setAssignment, setToasts, setTotalAssignments, setOpenModal }) {
    const [modalAlert, setModalAlert] = useState({ type: '', label: '' });

    // Azzera l'alert del modal ogni volta che viene aperto
    useEffect(() => {
        openModal === true && setModalAlert({ type: '', label: '' });
    }, [openModal]);

    return (
        <GenericModal
            openModal={openModal}
            setOpenModal={setOpenModal}
            title={`${action === 'accept' ? 'Accetto' : 'Rifiuta'} Designazione`}
            size="2xl"
        >
            {modalAlert?.type && <GenericAlert type={modalAlert.type}>{modalAlert.label}</GenericAlert>}
            <MainForm
                action={action}
                assignment={assignment}
                setAlert={setAlert}
                setAssignment={setAssignment}
                setModalAlert={setModalAlert}
                setToasts={setToasts}
                setTotalAssignments={setTotalAssignments}
                setOpenModal={setOpenModal}
            />
        </GenericModal>
    )
}

export default UserModal;