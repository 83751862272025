import notfound from '../../images/notfound.png';

function NotFound() {

    return (
        <section className="px-2 pt-32 bg-gray-200 md:px-0 tails-selected-element">
            <div className="container items-center max-w-4xl px-5 mx-auto mt-16 text-center">
                <img src={notfound} className="tails-hover tails-relative" alt="" />
            </div>
            <div className="container items-center max-w-6xl px-5 mx-auto space-y-6 text-center">
                <h1 className="text-4xl font-extrabold tracking-tight text-left text-gray-900 sm:text-5xl md:text-6xl md:text-center">
                    <span className="block">Pagina <span className="block mt-1 text-blue-500 lg:inline lg:mt-0" data-primary="purple-500">Non Trovata</span></span>
                </h1>
                <p className="w-full mx-auto text-base text-left text-gray-500 md:max-w-md sm:text-lg lg:text-2xl md:max-w-3xl md:text-center">
                    Verifica l'URL e riprova!
                </p>
            </div>
        </section>
    )
}

export default NotFound;