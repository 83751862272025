import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { serviceProvider as API } from "../../../../../API/api";
import { formToIsoString, isoToForm, setAssignmentMail, setRemovalMail, timeDifferenceInDays } from "../../../../../lib/utils";

// Components
import { Checkbox, Select, TextInput } from "flowbite-react";
import FormLabel from "../../../../elements/FormLabel";
import GenericAlert from "../../../../elements/GenericAlert";
import GenericModal from "../../../../elements/GenericModal";
import SubmitButton from "../../../../buttons/SubmitButton";


const MatchForm = ({ action, championship, closeModal, fields, groupName, match, pointers, round, setAlert, setMatch, setChampionship, setGroupName, setModalAlert, setOpenModal, setRound }) => {
    const { champId } = useParams();
    const { title: committeeName, daysToDeleteAssignment } = useSelector(state => state.committee);
    const [rounds, setRounds] = useState([]);
    const [teams, setTeams] = useState([]);

    const handleInput = (e) => {
        const { id, type, value } = e.currentTarget;
        setMatch(prevState => ({
            ...prevState,
            [id]: type === 'datetime-local'
                ? formToIsoString(value)
                : type === 'checkbox'
                    ? e.target.checked
                    : value
        }));
    }

    const handleSelect = (e) => {
        const { id, value } = e.currentTarget;
        setMatch(prevState => ({
            ...prevState,
            [id]: { _id: value }
        }));
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        const calendar = [...championship.calendar];
        switch (action) {
            case 'new':
                const groupIndex = calendar.findIndex(g => g.group_name === groupName);
                API.insert(`matches`, JSON.stringify(match), true)
                    .then(res => {
                        if (res.success) {
                            if (calendar.length) {
                                if (groupIndex < 0) {
                                    // In questo caso devo creare il girone all'interno del calendario
                                    calendar.push({
                                        group_name: groupName,
                                        rounds: [
                                            {
                                                round: 1,
                                                matches: [res.match]
                                            }
                                        ]
                                    })
                                } else {
                                    if (round > calendar[groupIndex].rounds.length) {
                                        // In questo caso sto creando una giornata
                                        calendar[groupIndex].rounds[round - 1] = {
                                            round: round,
                                            matches: [res.match]
                                        }
                                    } else {
                                        calendar[groupIndex].rounds[round - 1].matches.push(res.match);
                                    }
                                }
                            } else {
                                // In questo caso devo creare il calendario
                                calendar.push({
                                    group_name: groupName,
                                    rounds: [
                                        {
                                            round: 1,
                                            matches: [res.match]
                                        }
                                    ]
                                })
                            }
                            API.update(`championships/${champId}`, JSON.stringify({ calendar: calendar }), true)
                                .then(res => {
                                    if (res.success) {
                                        setChampionship(res.championship);
                                        setAlert({ type: 'success', label: 'La gara è stata creata' });
                                        closeModal();
                                    } else {
                                        setModalAlert({ type: 'failure', label: res.msg || 'Qualcosa è andato storto...' });
                                    }
                                })
                        } else {
                            setModalAlert({ type: 'failure', label: res.msg || 'Qualcosa è andato storto...' });
                        }
                    })
                    .catch(err => console.error(err));
                break;
            case 'edit':
                API.update(`matches/${match._id}`, JSON.stringify(match), true)
                    .then(res => {
                        if (res.success) {
                            const previousMatch = championship.calendar[pointers.groupIndex].rounds[pointers.roundIndex]
                                .matches.find(el => el._id === match._id);
                            setChampionship(prevState => {
                                const index = prevState.calendar[pointers.groupIndex].rounds[pointers.roundIndex]
                                    .matches.findIndex(el => el._id === match._id);
                                prevState.calendar[pointers.groupIndex].rounds[pointers.roundIndex].matches[index] = res.match;
                                return prevState;
                            });
                            const difference = timeDifferenceInDays(previousMatch.date, res.match.date);
                            res.match.assignments.forEach(assignment => {
                                // La designazione dev'essere notificata o accettata
                                if (assignment.status > 1) {
                                    if ((daysToDeleteAssignment === 0 || difference <= daysToDeleteAssignment) && !match.postponed) {
                                        // Modifico la designazione e invio una mail di notifica all'arbitro
                                        const mailBody = setAssignmentMail(res.match, assignment, committeeName, true);
                                        API.insert('emails', JSON.stringify(mailBody), true)
                                            .then(res => {
                                                if (!res.success) {
                                                    setAlert({ type: 'failure', label: res.msg || 'Potrebbe non essere stato possibile inviare la mail agli UDG designati' });
                                                }
                                            })
                                            .catch(err => console.error(err))
                                        API.update(`assignments/${assignment._id}`, JSON.stringify({ status: 2 }), true)
                                            .then(res => {
                                                if (!res.success) {
                                                    setAlert({ type: 'failure', label: res.msg || 'Potrebbe non essere stato possibile modificare le designazioni associate alla gara' });
                                                }
                                            })
                                            .catch(err => console.error(err))
                                    }
                                    if ((daysToDeleteAssignment > 0 && difference > daysToDeleteAssignment) || res.match.postponed) {
                                        // Devo Rimuovere la designazione: passo alla funzione previousmatch, per inviare i vecchi dati gara
                                        const mailBody = setRemovalMail(previousMatch, assignment, committeeName);
                                        API.insert('emails', JSON.stringify(mailBody), true)
                                            .then(res => {
                                                if (!res.success) {
                                                    setAlert({ type: 'failure', label: res.msg || 'Potrebbe non essere stato possibile inviare la mail agli UDG designati' });
                                                }
                                            })
                                            .catch(err => console.error(err))
                                        API.delete(`assignments/${assignment._id}`, true)
                                            .then(res => {
                                                if (!res.success) {
                                                    setAlert({ type: 'failure', label: res.msg || 'Potrebbe non essere stato possibile eliminare una o più designazioni associate alla gara' });
                                                }
                                            })
                                            .catch(err => console.error(err))
                                    }
                                }
                            })
                            setAlert({ type: 'success', label: 'La gara è stata modificata correttamente' });
                            closeModal();
                        } else {
                            setModalAlert({ type: 'failure', label: res.msg || 'Qualcosa è andato storto...' });
                        }
                    })
                    .catch(err => console.error(err));
                break;
            case 'delete':
                API.get(`assignments?match=${match._id}`, true)
                    .then(res => {
                        if (res.success) {
                            // Elimino tutte le designazioni
                            res.assignments.forEach(assignment => {
                                API.delete(`assignments/${assignment._id}`, true)
                                    .then(res => {
                                        if (!res.success) {
                                            setAlert({ type: 'failure', label: res.msg || 'Potrebbe non essere stato possibile eliminare una o più designazioni associate alla gara' });
                                        }
                                    })
                                    .catch(err => console.error(err))
                            })
                        }
                        // Moved inside the following to prevent 500 response error
                        const matchesArray = calendar[pointers.groupIndex].rounds[pointers.roundIndex].matches.filter(el => el._id !== match._id);
                        calendar[pointers.groupIndex].rounds[pointers.roundIndex].matches = matchesArray;
                        API.update(`championships/${champId}`, JSON.stringify({ calendar: calendar }), true)
                            .then(res => {
                                if (res.success) {
                                    setChampionship(res.championship);
                                } else {
                                    setModalAlert({ type: 'failure', label: res.msg || 'Qualcosa è andato storto...' });
                                }
                            })
                            .catch(err => console.error(err));
                        API.delete(`matches/${match._id}`, true)
                            .then(res => {
                                if (res.success) {
                                    setAlert({ type: 'success', label: 'La gara è stata eliminata' });
                                    closeModal();
                                } else {
                                    setModalAlert({ type: 'failure', label: res.msg || 'Qualcosa è andato storto...' });
                                }
                            })
                            .catch(err => console.error(err));
                    })
                    .catch(err => console.error(err))

                break;
            default:
        }
    }

    useEffect(() => {
        if (championship.teams?.length > 0) {
            const g = championship.teams.find(g => g.group_name === groupName);
            setTeams(g?.teams || []);
        }
    }, [championship, groupName])

    useEffect(() => {
        if (championship.calendar?.length > 0) {
            const g = championship.calendar.find(g => g.group_name === groupName);
            if (g) {
                setRounds(() => {
                    if (groupName) {
                        const rounds = g.rounds.map(r => r.round);
                        return [...rounds, rounds[rounds.length - 1] + 1];
                    } else return [1]
                });
            } else {
                // In questo caso nel calendario non è ancora presente il girone scelto
                setRounds([1]);
            }
        } else {
            setRounds([1]);
        }
    }, [championship, groupName])

    return (
        <form className="flex flex-col gap-4" onSubmit={handleSubmit}>
            {action === 'new' &&
                <div className="flex flex-row gap-2">
                    <div className="basis-1/2">
                        <div className="mb-2 block">
                            <FormLabel htmlFor="group" label="Girone" />
                        </div>
                        <Select
                            id="group"
                            required={true}
                            onChange={e => setGroupName(e.currentTarget.value)}
                            value={groupName}
                        >
                            {!groupName && <option>-</option>}
                            {
                                championship.teams?.map(group =>
                                    <option
                                        key={group._id}
                                        value={group.group_name}
                                    >
                                        {group.group_name}
                                    </option>
                                )
                            }
                        </Select>
                    </div>
                    <div className="basis-1/2">
                        <div className="mb-2 block">
                            <FormLabel htmlFor="round" label="Giornata" />
                        </div>
                        <Select
                            id="round"
                            required={true}
                            onChange={e => setRound(Number(e.currentTarget.value))}
                            value={round}
                        >
                            {!round && <option>-</option>}
                            {
                                rounds.map((round, i) =>
                                    <option
                                        key={`round_${i}`}
                                        value={round}
                                    >
                                        {round}
                                    </option>
                                )
                            }
                        </Select>
                    </div>
                </div>
            }
            <div className="flex flex-row gap-2 items-center">
                <div className="basis-1/3">
                    <div className="mb-2 block">
                        <FormLabel htmlFor="num" label="Numero gara" />
                    </div>
                    <TextInput
                        id="num"
                        type="text"
                        value={match.num}
                        step="1"
                        min="1"
                        onChange={handleInput}
                        disabled={action === 'delete' ? true : false}
                    />
                </div>
                <div className="basis-2/3">
                    <div className="mb-2 block">
                        <FormLabel htmlFor="date" label="Data" />
                    </div>
                    <TextInput
                        id="date"
                        type="datetime-local"
                        required={true}
                        value={match.date ? isoToForm(match.date, 'datetime') : ''}
                        onChange={handleInput}
                        disabled={action === 'deleteCalendar' ? true : false}
                    />
                </div>
            </div>
            <div className="flex flex-row gap-2 items-center">
                <div className="basis-1/2">
                    <div className="mb-2 block">
                        <FormLabel htmlFor="teamA" label="Squadra 1" />
                    </div>
                    <Select
                        id="teamA"
                        required={true}
                        onChange={handleSelect}
                        value={match.teamA._id}
                        disabled={action === 'delete' ? true : false}
                    >
                        {action === 'new' &&
                            <option>-</option>
                        }
                        {
                            teams.map(team =>
                                <option
                                    key={team._id}
                                    value={team._id}
                                >
                                    {team.name}
                                </option>
                            )
                        }
                    </Select>
                </div>
                <div className="basis-1/2">
                    <div className="mb-2 block">
                        <FormLabel htmlFor="teamB" label="Squadra 2" />
                    </div>
                    <Select
                        id="teamB"
                        required={true}
                        onChange={handleSelect}
                        value={match.teamB._id}
                        disabled={action === 'delete' ? true : false}
                    >
                        {action === 'new' &&
                            <option>-</option>
                        }
                        {
                            teams.map(team =>
                                <option
                                    key={team._id}
                                    value={team._id}
                                >
                                    {team.name}
                                </option>
                            )
                        }
                    </Select>
                </div>
            </div>
            <div>
                <div className="mb-2 block">
                    <FormLabel htmlFor="field" label="Campo di gioco" />
                </div>
                <Select
                    id="field"
                    required={true}
                    onChange={handleInput}
                    value={match.field}
                    disabled={action === 'delete' ? true : false}
                >
                    <option>-</option>
                    {
                        fields.map(field =>
                            <option
                                key={field._id}
                                value={field._id}
                            >
                                {`${field.province} - ${field.city} - ${field.name}`}
                            </option>
                        )
                    }
                </Select>
            </div>
            {action !== 'new' &&
                <div className="flex flex-row gap-2">
                    <Checkbox
                        id="postponed"
                        onChange={handleInput}
                        checked={match.postponed}
                    />
                    <div className="mb-2 block">
                        <FormLabel htmlFor="postponed" label="Rinviata a data da definire?" />
                    </div>

                </div>
            }
            {
                action === 'delete' &&
                <GenericAlert type="warning" divclass="text-lg mx-auto" hideicon="true">Desideri eliminare questa gara?</GenericAlert>
            }
            <div className="text-center">
                <SubmitButton />
            </div>
        </form>
    )
}

const MatchModal = ({ action, championship, fields, groupName, match, openModal, pointers, setAlert, setChampionship, setGroupName, setMatch, setOpenModal }) => {
    const { champId } = useParams();
    const [modalAlert, setModalAlert] = useState({ type: '', label: '' });
    const [round, setRound] = useState('');

    // Azzera l'alert del modal ogni volta che viene aperto
    useEffect(() => {
        openModal === true && setModalAlert({ type: '', label: '' });
    }, [openModal]);

    const close = () => {
        setGroupName('');
        setRound('');
        setMatch({
            championship: champId,
            season: championship.season,
            date: '',
            field: '',
            noteams: false,
            noteams_title: '',
            num: 1,
            postponed: false,
            status: 0,
            teamA: '',
            teamB: ''
        });
        setOpenModal(false);
    }

    return (
        <GenericModal openModal={openModal} setOpenModal={close} title={`${action === 'new' ? 'Nuova' : action === 'edit' ? 'Modifica' : 'Elimina'} Gara`}>
            {modalAlert?.type && <GenericAlert type={modalAlert.type}>{modalAlert.label}</GenericAlert>}
            <MatchForm
                action={action}
                championship={championship}
                closeModal={close}
                fields={fields}
                groupName={groupName}
                match={match}
                pointers={pointers}
                round={round}
                setAlert={setAlert}
                setGroupName={setGroupName}
                setMatch={setMatch}
                setChampionship={setChampionship}
                setModalAlert={setModalAlert}
                setOpenModal={setOpenModal}
                setRound={setRound}
            />
        </GenericModal>
    )
}

export default MatchModal;