import { useEffect, useState } from "react";
import { serviceProvider as API } from "../../../../../API/api";
import { createCalendar, deleteMatches, storeMatches } from "../../../../../lib/utils";
import "flatpickr/dist/themes/material_blue.css";

// Components
import { Select, TextInput } from "flowbite-react";
import Flatpickr from "react-flatpickr";
import FormLabel from "../../../../elements/FormLabel";
import GenericAlert from "../../../../elements/GenericAlert";
import GenericModal from "../../../../elements/GenericModal";
import SubmitButton from "../../../../buttons/SubmitButton";
import { Italian } from 'flatpickr/dist/l10n/it';

const CalendarForm = ({ action, calendarInfo, championship, fp, setAlert, setCalendarInfo, setChampionship, setModalAlert, setOpenModal }) => {
    const [isLoading, setIsLoading] = useState(false);

    const handleInput = (e) => {
        const { id, value } = e.currentTarget;
        setCalendarInfo(prevState => ({
            ...prevState,
            [id]: value
        }));
    }

    const handleMultipleDates = (date) => {
        setCalendarInfo(prevState => ({
            ...prevState,
            datesToSkip: Array.from(date).map(date => date.toISOString())
        }));
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        const { datesToSkip, phases, startingDate, startingNumber } = calendarInfo
        switch (action) {
            case 'newCalendar':
                const calendar = createCalendar(championship, phases, startingNumber, datesToSkip, startingDate);
                const storedCalendar = await storeMatches(calendar);
                API.update(`championships/${championship._id}`, JSON.stringify({
                    calendar: storedCalendar
                }), true)
                    .then(res => {
                        if (res.success) {
                            setChampionship(res.championship);
                            setAlert({ type: 'success', label: 'Il calendario è stato creato' });
                            setOpenModal(false);
                        } else {
                            setModalAlert({ type: 'failure', label: res.msg || 'Qualcosa è andato storto...' });
                        }
                        setIsLoading(false);
                    })
                    .catch(err => console.error(err));
                break;
            case 'deleteCalendar':
                const matchesArray = championship.calendar.reduce((acc, group) =>
                    acc.concat(group.rounds.reduce((acc, round) =>
                        acc.concat(round.matches.map(match => match._id)
                        ), []
                    )), []
                );
                await deleteMatches(matchesArray);
                API.update(`championships/${championship._id}`, JSON.stringify({ calendar: [] }), true)
                    .then(res => {
                        if (res.success) {
                            setChampionship(res.championship);
                            setAlert({ type: 'success', label: 'Il calendario è stato eliminato' });
                            setOpenModal(false);
                        } else {
                            setModalAlert({ type: 'failure', label: res.msg || 'Qualcosa è andato storto...' });
                        }
                    })
                setIsLoading(false);
                break;
            default:
        }
    }

    return (
        <form className="flex flex-col gap-4" onSubmit={handleSubmit}>
            {action === 'newCalendar' &&
                <>
                    <div>
                        <div className="mb-2 block">
                            <FormLabel htmlFor="startingNumber" label="N. gara di partenza" />
                        </div>
                        <TextInput
                            id="startingNumber"
                            type="number"
                            min="1"
                            required={true}
                            value={calendarInfo.startingNumber}
                            onChange={handleInput}
                            disabled={action === 'deleteCalendar' ? true : false}
                        />
                    </div>
                    <div>
                        <div className="mb-2 block">
                            <FormLabel htmlFor="phases" label="Tipologia" />
                        </div>
                        <Select
                            id="phases"
                            required={true}
                            onChange={handleInput}
                            value={calendarInfo.phases}
                            disabled={action === 'deleteCalendar' ? true : false}
                        >
                            <option value="andata">Sola andata</option>
                            <option value="ritorno">Andata e ritorno</option>
                        </Select>
                    </div>
                    <div>
                        <div className="mb-2 block">
                            <FormLabel htmlFor="startingDate" label="Data di partenza" />
                        </div>
                        <TextInput
                            id="startingDate"
                            type="date"
                            required={true}
                            value={calendarInfo.startingDate}
                            onChange={handleInput}
                            disabled={action === 'deleteCalendar' ? true : false}
                        />
                    </div>
                    <div>
                        <div className="mb-2 block">
                            <FormLabel label="Date da escludere" />
                        </div>
                        <Flatpickr
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            ref={fp}
                            value={calendarInfo.datesToSkip}
                            options={{
                                mode: "multiple",
                                dateFormat: "d-m-Y",
                                altFormat: "Y-m-d",
                                locale: Italian
                            }}
                            onChange={handleMultipleDates}
                        />
                    </div>
                </>
            }
            {
                action === 'deleteCalendar' &&
                <>
                    <div className="p-4 mb-4 text-md text-justify text-blue-700 bg-blue-100 rounded-lg dark:bg-blue-200 dark:text-blue-800" role="alert">
                        <p className="font-medium text-red-700 text-2xl uppercase text-center">Attenzione!</p>
                        Insieme al calendario, eliminerai tutte le gare a esso collegate e non saranno ripristinabili se non manualmente.
                    </div>
                    <GenericAlert type="failure" divclass="text-lg mx-auto" hideicon="true">
                        Desideri eliminare questo calendario?
                    </GenericAlert>
                </>
            }
            <div className="flex flex-wrap items-center gap-2">
                <SubmitButton isLoading={isLoading} />
            </div>
        </form>
    )
}

const CalendarModal = ({ action, calendarInfo, championship, fp, openCalModal, setAlert, setCalendarInfo, setChampionship, setOpenCalModal }) => {
    const [modalAlert, setModalAlert] = useState({ type: '', label: '' });

    // Azzera l'alert del modal ogni volta che viene aperto
    useEffect(() => {
        openCalModal === true && setModalAlert({ type: '', label: '' });
    }, [openCalModal]);

    return (
        <GenericModal openModal={openCalModal} setOpenModal={setOpenCalModal} title={`${action === 'newCalendar' ? 'Nuovo' : 'Elimina'} Calendario`}>
            {modalAlert?.type && <GenericAlert type={modalAlert.type}>{modalAlert.label}</GenericAlert>}
            <CalendarForm
                action={action}
                calendarInfo={calendarInfo}
                championship={championship}
                fp={fp}
                setAlert={setAlert}
                setCalendarInfo={setCalendarInfo}
                setChampionship={setChampionship}
                setModalAlert={setModalAlert}
                setOpenModal={setOpenCalModal}
            />
        </GenericModal>
    )
}

export default CalendarModal;