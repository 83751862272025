import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { serviceProvider as API } from "../../../../API/api";
import { createFormElement, formatDateTime } from "../../../../lib/utils";

// Components
import GenericAlert from "../../../elements/GenericAlert";
import GenericModal from "../../../elements/GenericModal";
import SubmitButton from "../../../buttons/SubmitButton";


const MainForm = ({ assignment, report, setAlert, setModalAlert, setOpenModal, setReport, setTotalAssignments }) => {
    const formModel = useCallback(() => assignment.match?.championship.report_model?.model_schema, [assignment.match]);
    const user = useSelector(state => state.user);

    const handleInput = (e) => {
        const { id, value } = e.currentTarget;
        setReport(prevState => ({
            ...prevState,
            [id]: value
        }));
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        const updReport = formModel().map(m => ({
            id: m.id,
            default_value: m.default_value,
            options: m.options,
            label_text: m.label_text,
            type: m.type,
            value: report[m.id],
        }));
        const updBody = {
            report: updReport,
            report_updated_at: new Date().toISOString(),
            report_updated_by: user
        }
        API.update(`matches/${assignment.match._id}`, JSON.stringify(updBody), true)
            .then(res => {
                if (res.success) {
                    setTotalAssignments(prevState => [
                        ...prevState.filter(a => a._id !== assignment._id),
                        {
                            ...assignment,
                            match: res.match
                        }
                    ]);
                    setAlert({ type: 'success', label: 'Il rapporto gara è stato inserito' });
                    setOpenModal(false);
                } else {
                    setModalAlert({ type: 'failure', label: res.msg || 'Qualcosa è andato storto...' });
                }
            })
            .catch(err => console.error(err));
    }

    return (
        <form className="flex flex-col gap-4" onSubmit={handleSubmit}>
            <div className="h-80 overflow-auto px-2">
                {
                    formModel()?.map((schema, i) =>
                        <div key={`schema_${i}`} className="mb-2">
                            {createFormElement(schema, false, false, report[schema.id], handleInput)}
                        </div>
                    )
                }
            </div>
            <div className="text-center">
                <SubmitButton />
            </div>
        </form>
    )
}

const ShowReport = ({ report }) => {

    return (
        <ul className="space-y-1 list-none list-inside cursor-default shadow-lg rounded-lg text-center text-gray-700 dark:text-gray-400">
            {report?.map(el => 
            <div key={el.id} className={`py-0.5 rounded-lg border border-gray-300 ${el.value === el.default_value ? 'hover:bg-gray-50' : 'bg-amber-200 hover:bg-amber-100'}`}>
                <li className="font-semibold">{el.label_text}</li>
                <li>{el.value}</li>
            </div>
            )}
        </ul>
    )
}

const RoleRow = ({ match, role }) => {
    const [roleAssignment, setRoleAssignment] = useState([]);

    useEffect(() => {
        setRoleAssignment(match.assignments?.filter(a =>
            (a.role === role.title && a.status > 2)));
    }, [match.assignments, role.title])


    return (
        roleAssignment?.length > 0 &&
        <li>
            <span className="font-semibold">{role.title}:</span>
            {roleAssignment
                .map(a => ` ${a.referee.lastname} ${a.referee.firstname}`)
            }
        </li>
    )
}

const ModalHeader = ({ match, roles }) => {
    return (
        <ul className="space-y-1 list-none list-inside p-2 mb-4 cursor-default border bg-gray-50 shadow-lg border-red-400 rounded-lg text-center text-gray-700 dark:text-gray-400">
            <li>
                <span className="font-semibold text-sm uppercase">{match?.championship.name}</span>
            </li>
            <li>
                <span className="font-semibold">{match?.teamA.name} - {match?.teamB.name}</span>
            </li>
            <hr className="my-8 h-px bg-gray-300 border-0 dark:bg-gray-700" />
            <div className="flex flex-row gap-4 items-center">
                <div className="text-right basis-1/2">
                    <li>
                        <span className="font-semibold">Data:</span> <span className="capitalize">{formatDateTime(match?.date, true)}</span>
                    </li>
                    <li>
                        <span className="font-semibold">Campo:</span> {match?.field.name}
                    </li>
                    <li>
                        <span className="font-semibold">Località:</span> {match?.field.city} ({match?.field.province})
                    </li>
                    <li>
                        <span className="font-semibold">Indirizzo:</span> {match?.field.address}
                    </li>
                </div>
                <div className="text-left basis-1/2">
                    {
                        roles
                            .filter(r => r.sport === match?.championship.sport)
                            .map(r =>
                                <RoleRow
                                    key={r._id}
                                    match={match}
                                    role={r}
                                />
                            )
                    }
                </div>
            </div>
        </ul>
    )
}

function ReportModal({ assignment, match, openModal, report, reportAction, roles, setAlert, setOpenModal, setReport, setTotalAssignments }) {
    const [modalAlert, setModalAlert] = useState({ type: '', label: '' });

    // Azzera l'alert del modal ogni volta che viene aperto
    useEffect(() => {
        openModal === true && setModalAlert({ type: '', label: '' });
    }, [openModal]);

    return (
        <GenericModal openModal={openModal} setOpenModal={setOpenModal} title='Compila il rapporto gara' size="4xl">
            {modalAlert?.type && <GenericAlert type={modalAlert.type}>{modalAlert.label}</GenericAlert>}
            {(match?._id || assignment?.match?._id) &&
                <ModalHeader
                    match={reportAction === 'edit' ? assignment.match : match}
                    roles={roles}
                />
            }
            {reportAction === 'edit' &&
                <MainForm
                    assignment={assignment}
                    report={report}
                    roles={roles}
                    setAlert={setAlert}
                    setModalAlert={setModalAlert}
                    setOpenModal={setOpenModal}
                    setReport={setReport}
                    setTotalAssignments={setTotalAssignments}
                />
            }
            {reportAction === 'show' &&
                <ShowReport
                    report={match.report}
                />
            }
        </GenericModal>
    )
}

export default ReportModal;