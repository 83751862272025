import { useSelector } from 'react-redux';

// Images
import cards from '../../../../images/red-and-yellow-cards.png';
import volleyballCourt from '../../../../images/volleyballcourt.jpg';


function WelcomeUser() {
    const user = useSelector(state => state.user);
    const titles = ['Super Admin', 'Admin', 'Commissione Gare', 'Commissione Giudicante', 'Commissione Designante', 'Utente'];

    return (
        <section className="px-2 py-32 bg-white md:px-0 rounded mb-4 shadow">
            <div className="container items-center max-w-6xl px-8 mx-auto xl:px-5">
                <div className="flex flex-wrap items-center sm:-mx-3">
                    <div className="w-full md:w-1/2">
                        <div className="w-full h-auto overflow-hidden rounded-md shadow-xl sm:rounded-xl" data-rounded="rounded-xl" data-rounded-max="rounded-full">
                            <img src={volleyballCourt} alt="Volleyball Court" />
                        </div>
                    </div>
                    <div className="w-full md:w-1/2 md:px-3">
                        <div className="w-full mx-auto pb-6 space-y-6 sm:max-w-md lg:max-w-lg md:space-y-4 lg:space-y-8 xl:space-y-9 sm:pr-5 lg:pr-0 md:pb-0">
                            <h1 className="text-4xl font-extrabold tracking-tight text-gray-900 sm:text-5xl md:text-3xl lg:text-4xl xl:text-6xl">
                                <span className="block xl:inline">Benvenuto</span>
                                <span className="block text-blue-700 xl:inline" data-primary="indigo-600"> {user.firstname}!</span>
                            </h1>
                            <p className="mx-auto text-base text-gray-500 sm:max-w-md lg:text-xl md:max-w-3xl">
                                Questa è la tua area riservata in qualità di {user.isreferee ? 'Arbitro' : titles[user.role]}.
                                </p>
                            <div className="hidden lg:inline-block w-1/3 md:w-1/4 mx-auto">
                                <img src={cards} alt="Red and Yellow cards" />
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </section>
    )
}

export default WelcomeUser;