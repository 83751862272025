import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { serviceProvider as API } from "../../../API/api";
import { useDispatch } from "react-redux";
import { assign as assignUser } from "../../../features/user/userSlice";
import { daysOTW } from "../../../lib/configs";

// Components
import { Button, Table, TextInput } from "flowbite-react";
import FormLabel from "../../elements/FormLabel";
import GenericAlert from "../../elements/GenericAlert";
import GenericModal from "../../elements/GenericModal";
import GenericTitle from "../../elements/GenericTitle";
import LoadingRow from "../../elements/LoadingRow";
import SubmitButton from "../../buttons/SubmitButton";


const TableRow = ({ day, startTime, endTime }) => {

    return (
        <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
            <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                {day}
            </Table.Cell>
            <Table.Cell colSpan={startTime !== endTime ? 1 : 2}>
                {
                    startTime !== endTime
                        ? startTime
                        : 'Nessuna disponibilità'
                }
            </Table.Cell>
            {startTime !== endTime &&
                <Table.Cell>
                    {endTime}
                </Table.Cell>
            }
        </Table.Row>
    )
}

const MainTable = ({ availability }) => {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (availability) {
            setLoading(false);
        }
    }, [availability])

    return (
        <Table hoverable={true}>
            <Table.Head>
                <Table.HeadCell>
                    Giorno
                </Table.HeadCell>
                <Table.HeadCell>
                    Dalle
                </Table.HeadCell>
                <Table.HeadCell>
                    Alle
                </Table.HeadCell>
            </Table.Head>
            <Table.Body className="divide-y">
                {loading && <LoadingRow colspan={3} />}
                {
                    daysOTW.map(day =>
                        <TableRow
                            key={`day_${day.value}`}
                            day={day.day}
                            startTime={availability[day.value].startTime}
                            endTime={availability[day.value].endTime}
                        />)
                }
            </Table.Body>
        </Table>
    )
}

const MainForm = ({ form, modalAlert, setAlert, setAvailability, setForm, setModalAlert, setOpenModal }) => {
    const { _id: userId } = useSelector(state => state.user);
    const dispatch = useDispatch();

    const validateTime = () =>
        form.findIndex(av =>
            (new Date(`2022-06-17T${av.endTime}:00.000Z`) - new Date(`2022-06-17T${av.startTime}:00.000Z`)) < 0);


    const handleInput = (e) => {
        setModalAlert({ type: '', label: '', index: 0 });
        const { id, value } = e.currentTarget;
        const [property, day] = id.split('_');
        setForm(prevState => {
            const array = [...prevState];
            array[day][property] = value;
            return array;
        })
    }

    const set0Hours = (e) => {
        const index = e.currentTarget.dataset.day;
        setForm(prevState => {
            const array = [...prevState];
            array[index].startTime = '00:00';
            array[index].endTime = '00:00';
            return array;
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        const findIndex = validateTime();
        if (findIndex < 0) {
            API.update(`users/${userId}`, JSON.stringify({ availability: form }), true)
                .then(res => {
                    if (res.success) {
                        dispatch(assignUser(res.user));
                        setAvailability(res.user.availability);
                        setAlert({ type: 'success', label: 'La disponibilità è stata modificata correttamente' });
                        setOpenModal(false);
                    } else {
                        setModalAlert({ type: 'failure', label: res.msg || 'Qualcosa è andato storto...' });
                    }
                })
        } else {
            setModalAlert({ type: 'failure', label: 'Verifica gli orari inseriti', index: findIndex })
        }
    }

    return (
        <form className="flex flex-col gap-4" onSubmit={handleSubmit}>
            {daysOTW.map(d =>

                <div key={`form_day_${d.value}`}>
                    <div className="mb-2 block">
                        <FormLabel label={d.day} />
                    </div>
                    <div className="flex flex-row gap-2 items-center">
                        <div className={`basis-1/3 ${modalAlert.type && modalAlert.index === d.value && 'outline outline-2 outline-red-600 rounded-lg'}`}>
                            <TextInput
                                id={`startTime_${d.value}`}
                                type="time"
                                required={true}
                                placeholder="Dalle"
                                value={form[d.value]?.startTime}
                                onChange={handleInput}
                            />
                        </div>
                        <div className={`basis-1/3 ${modalAlert.type && modalAlert.index === d.value && 'outline outline-2 outline-red-600 rounded-lg'}`}>
                            <TextInput
                                id={`endTime_${d.value}`}
                                type="time"
                                required={true}
                                placeholder="Alle"
                                value={form[d.value]?.endTime}
                                onChange={handleInput}
                            />
                        </div>
                        <button
                            type="button"
                            className="text-white text-xs mt-2 bg-red-700 hover:bg-red-800 focus:outline-none focus:ring-4 focus:ring-red-300 font-medium rounded-full py-2 px-3 text-center mr-2 mb-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900"
                            data-day={d.value}
                            onClick={set0Hours}
                        >
                            Azzera
                        </button>
                    </div>
                </div>
            )}
            <div className="text-center">
                <SubmitButton />
            </div>
        </form>
    )
}

const MainModal = ({ form, openModal, setAlert, setAvailability, setForm, setOpenModal }) => {
    const [modalAlert, setModalAlert] = useState({ type: '', label: '', index: 0 });

    // Azzera l'alert del modal ogni volta che viene aperto
    useEffect(() => {
        openModal === true && setModalAlert({ type: '', label: '' });
    }, [openModal]);

    return (
        <GenericModal openModal={openModal} setOpenModal={setOpenModal} title='Modifica disponibilità'>
            {modalAlert?.type && <GenericAlert type={modalAlert.type}>{modalAlert.label}</GenericAlert>}
            <MainForm
                form={form}
                modalAlert={modalAlert}
                setAlert={setAlert}
                setAvailability={setAvailability}
                setForm={setForm}
                setModalAlert={setModalAlert}
                setOpenModal={setOpenModal}
            />
        </GenericModal>
    )
}

function Availability() {
    const { _id: userId } = useSelector(state => state.user);
    const [alert, setAlert] = useState({ type: '', label: '' });
    const [availability, setAvailability] = useState([
        { day: 0, startTime: '00:00', endTime: '00:00' },
        { day: 1, startTime: '00:00', endTime: '00:00' },
        { day: 2, startTime: '00:00', endTime: '00:00' },
        { day: 3, startTime: '00:00', endTime: '00:00' },
        { day: 4, startTime: '00:00', endTime: '00:00' },
        { day: 5, startTime: '00:00', endTime: '00:00' },
        { day: 6, startTime: '00:00', endTime: '00:00' }
    ]);
    const [form, setForm] = useState([
        { day: 0, startTime: '00:00', endTime: '00:00' },
        { day: 1, startTime: '00:00', endTime: '00:00' },
        { day: 2, startTime: '00:00', endTime: '00:00' },
        { day: 3, startTime: '00:00', endTime: '00:00' },
        { day: 4, startTime: '00:00', endTime: '00:00' },
        { day: 5, startTime: '00:00', endTime: '00:00' },
        { day: 6, startTime: '00:00', endTime: '00:00' }
    ]);
    const [openModal, setOpenModal] = useState(false);

    const setModal = () => {
        setForm([
            { day: 0, startTime: availability[0].startTime || '00:00', endTime: availability[0].endTime || '00:00' },
            { day: 1, startTime: availability[1].startTime || '00:00', endTime: availability[1].endTime || '00:00' },
            { day: 2, startTime: availability[2].startTime || '00:00', endTime: availability[2].endTime || '00:00' },
            { day: 3, startTime: availability[3].startTime || '00:00', endTime: availability[3].endTime || '00:00' },
            { day: 4, startTime: availability[4].startTime || '00:00', endTime: availability[4].endTime || '00:00' },
            { day: 5, startTime: availability[5].startTime || '00:00', endTime: availability[5].endTime || '00:00' },
            { day: 6, startTime: availability[6].startTime || '00:00', endTime: availability[6].endTime || '00:00' }
        ]);
        setOpenModal(true);
    }

    useEffect(() => {
        if (userId) {
            API.get(`users/${userId}`, true)
                .then(res => {
                    if (res.success) {
                        setAvailability(res.user.availability);
                        setAlert({ type: '', label: '' });
                    } else {
                        setAlert({ type: 'failure', label: res.msg });
                    }
                })
        }
    }, [userId]);

    return (
        <div className="flex flex-col gap-3">
            {alert?.type && <GenericAlert type={alert.type}>{alert.label}</GenericAlert>}
            <GenericTitle title="Disponibilità inserita" size="2xl" />
            <MainTable availability={availability} />
            <div className="w-fit">
                <Button size="sm" onClick={setModal}>
                    Modifica disponibilità
                </Button>
            </div>
            <MainModal
                form={form}
                openModal={openModal}
                setAlert={setAlert}
                setAvailability={setAvailability}
                setForm={setForm}
                setOpenModal={setOpenModal}
            />
        </div>
    )
}

export default Availability;