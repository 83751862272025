import { Table } from "flowbite-react";

function NoResultsRow({ colspan, content="Nessun risultato trovato" }) {

    return (
        <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
            <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white text-center" colSpan={colspan}>
                {content}
            </Table.Cell>
        </Table.Row>
    )
}

export default NoResultsRow;