import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { serviceProvider as API } from "../../../API/api";
import { genUrlcode, sortByName } from "../../../lib/utils";

// Components
import { Button, Checkbox, Select, Spinner, Table, TextInput, Tooltip } from "flowbite-react";
import DeleteButton from "../../buttons/DeleteButton";
import EditButton from "../../buttons/EditButton";
import FormLabel from "../../elements/FormLabel";
import GenericAlert from "../../elements/GenericAlert";
import GenericModal from "../../elements/GenericModal";
import NoResultsRow from "../../elements/NoResultsRow";
import ShowButton from "../../buttons/ShowButton";
import SubmitButton from "../../buttons/SubmitButton";

// Game Formulas
import { volleyball_formulas } from "../../../lib/configs";

// Icons
const checkCircle = <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
    <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
</svg>

const crossCircle = <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
    <path strokeLinecap="round" strokeLinejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
</svg>


const TableRow = ({ championship, setAction, setChampionship, setOpenModal, teams }) => {
    const { _id, name, abbreviation, committee, formula, isPublic, isDesignable, rascode, report_model, season, isTournament, sport, urlcode } = championship;
    const hasGotTeams = useCallback(() => teams.some(t => t.championships.some(c => c._id === championship._id)), [championship._id, teams])
    const { _id: committeeId } = useSelector(state => state.committee);
    const { _id: seasonId } = useSelector(state => state.season);
    const { urlcode: committeeUrlcode } = useSelector(state => state.committee);
    const path = `/${committeeUrlcode}/riservata`;

    const rowChampionship = {
        _id: _id,
        name: name || '',
        abbreviation: abbreviation || '',
        committee: committee || committeeId,
        formula: formula || 0,
        isDesignable: isDesignable,
        isPublic: isPublic,
        season: season || seasonId,
        isTournament: isTournament || false,
        rascode: rascode || '',
        report_model: report_model || '',
        sport: sport || 'Pallavolo',
        urlcode: urlcode || ''
    }

    const handleEdit = () => {
        setAction('edit');
        setChampionship(rowChampionship);
        setOpenModal(true);
    }

    const handleDelete = () => {
        setAction('delete');
        setChampionship(rowChampionship);
        setOpenModal(true);
    }

    return (
        <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
            <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                {name}
            </Table.Cell>
            <Table.Cell>
                <span className={isPublic ? 'text-green-700' : 'text-red-700'}>
                    {isPublic ? checkCircle : crossCircle}
                </span>
            </Table.Cell>
            <Table.Cell>
                <span className={isDesignable ? 'text-green-700' : 'text-red-700'}>
                    {isDesignable ? checkCircle : crossCircle}
                </span>
            </Table.Cell>
            <Table.Cell className="flex flex-row">
                <Tooltip content="Mostra il campionato">
                    <ShowButton href={`${path}/campionati/${championship._id}`} />
                </Tooltip>
                <Tooltip content="Modifica il campionato">
                    <EditButton onClick={handleEdit} />
                </Tooltip>
                <Tooltip content={hasGotTeams() ? 'Ci sono delle squadre assegnate a questo campionato' : 'Elimina il campionato'}>
                    <DeleteButton
                        onClick={handleDelete}
                        {...hasGotTeams() && { disabled: true }}
                    />
                </Tooltip>
            </Table.Cell>
        </Table.Row>
    )
}

const MainTable = ({ championships, loading, selectedSeason, setAlert, setAction, setChampionship, setChampionships, setLoading, setOpenModal, teams }) => {

    return (
        <Table hoverable={true}>
            <Table.Head>
                <Table.HeadCell>
                    Nome
                </Table.HeadCell>
                <Table.HeadCell>
                    Pubblica in Home
                </Table.HeadCell>
                <Table.HeadCell>
                    Designabile
                </Table.HeadCell>
                <Table.HeadCell>
                    <span className="sr-only">
                        Edit
                    </span>
                </Table.HeadCell>
            </Table.Head>
            <Table.Body className="divide-y">
                {loading &&
                    <Table.Row>
                        <Table.Cell colSpan={4} className="bg-white p-4">
                            <div className="text-center">
                                <Spinner color="purple" size="xl" />
                            </div>
                        </Table.Cell>
                    </Table.Row>
                }
                {(!loading && championships.length < 1) && <NoResultsRow colspan={4} />}
                {
                    championships.map(championship =>
                        <TableRow
                            key={championship._id}
                            championship={championship}
                            setAction={setAction}
                            setChampionship={setChampionship}
                            setOpenModal={setOpenModal}
                            teams={teams}
                        />
                    )
                }
            </Table.Body>
        </Table>
    )
}

const MainForm = ({ action, championship, reportModels, seasons, selectedSeason, setAlert, setChampionship, setChampionships, setModalAlert, setOpenModal, setSeasons }) => {
    const { role: authRole } = useSelector(state => state.user);

    const handleInput = (e) => {
        const { id, type, value } = e.currentTarget;
        setChampionship(prevState => ({
            ...prevState,
            [id]: type === 'checkbox'
                ? e.target.checked
                : id === 'rascode'
                    ? value.trim()
                    : value,
            report_model: id === 'report_model'
                ? reportModels.find(r => r._id === value) : prevState.report_model
        }));
    }

    const autoUrlcode = () => {
        setChampionship(prevState => ({
            ...prevState,
            urlcode: genUrlcode(prevState.name)
        }))
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        switch (action) {
            case 'new':
                API.insert('championships', JSON.stringify(championship), true)
                    .then(res => {
                        if (res.success) {
                            setChampionships(prevState => sortByName([...prevState, res.championship], 'name'));
                            setAlert({ type: 'success', label: 'Il campionato è stato inserito' });
                            setOpenModal(false);
                        } else {
                            setModalAlert({ type: 'failure', label: res.msg || 'Qualcosa è andato storto...' });
                        }
                    })
                    .catch(err => console.error(err));
                break;
            case 'edit':
                API.update(`championships/${championship._id}`, JSON.stringify(championship), true)
                    .then(res => {
                        if (res.success) {
                            // Se ho cambiato stagione, elimino il campionato dalla table
                            if (selectedSeason === championship.season) {
                                setChampionships(prevState => {
                                    const index = prevState.findIndex(el => el._id === championship._id);
                                    prevState[index] = championship;
                                    return sortByName(prevState, 'name')
                                });
                            } else {
                                setChampionships(prevState => prevState.filter(el => el._id !== championship._id));
                            }
                            setAlert({ type: 'success', label: 'Il campionato è stato modificato correttamente' });
                            setOpenModal(false);
                        } else {
                            setModalAlert({ type: 'failure', label: res.msg || 'Qualcosa è andato storto...' });
                        }
                    })
                    .catch(err => console.error(err));
                break;
            case 'delete':
                API.delete(`championships/${championship._id}`, true)
                    .then(res => {
                        if (res.success) {
                            setChampionships(prevState => prevState.filter(el => el._id !== championship._id));
                            setAlert({ type: 'success', label: 'Il campionato è stato eliminato' });
                            setOpenModal(false);
                        } else {
                            setModalAlert({ type: 'failure', label: res.msg || 'Qualcosa è andato storto...' });
                        }
                    })
                    .catch(err => console.error(err));
                break;
            default:
        }
    }

    return (
        <form className="flex flex-col gap-4" onSubmit={handleSubmit}>
            <div>
                <div className="mb-2 block">
                    <FormLabel htmlFor="name" label="Nome" />
                </div>
                <TextInput
                    id="name"
                    type="text"
                    required={true}
                    value={championship.name}
                    onChange={handleInput}
                    disabled={action === 'delete' ? true : false}
                />
            </div>
            <div>
                <div className="mb-2 block">
                    <FormLabel htmlFor="abbreviation" label="Abbreviazione" />
                </div>
                <TextInput
                    id="abbreviation"
                    type="text"
                    required={true}
                    value={championship.abbreviation}
                    onChange={handleInput}
                    placeholder="U18M"
                    disabled={action === 'delete' ? true : false}
                />
            </div>
            <div className="flex flex-row gap-2 items-end">
                <div className="basis-11/12">
                    <div className="mb-2 block">
                        <FormLabel htmlFor="urlcode" label="Urlcode" />
                    </div>
                    <TextInput
                        id="urlcode"
                        type="text"
                        required={true}
                        value={championship.urlcode}
                        onChange={handleInput}
                        disabled={action === 'delete' ? true : false}
                    />
                </div>
                <div>
                    <Button
                        size="sm"
                        color="dark"
                        onClick={autoUrlcode}
                    >
                        Auto
                    </Button>
                </div>
            </div>
            <span className="text-xs">L'urlcode deve essere univoco tra i campionati, minuscolo e senza spazi: è preferibile usare il bottone per auto-generarlo</span>
            <div>
                <div className="mb-2 block">
                    <FormLabel htmlFor="formula" label="Formula di Gioco" />
                </div>
                <Select
                    id="formula"
                    required={true}
                    onChange={handleInput}
                    value={championship.formula}
                    disabled={action === 'delete' ? true : false}
                >
                    <option>-</option>
                    {
                        volleyball_formulas.map((formula, i) =>
                            <option
                                key={`formula_${i}`}
                                value={i}
                            >
                                {formula.description}
                            </option>
                        )
                    }
                </Select>
            </div>
            <div>
                <div className="mb-2 block">
                    <FormLabel htmlFor="report_model" label="Modello Rapporto Gara" />
                </div>
                <Select
                    id="report_model"
                    required={true}
                    onChange={handleInput}
                    value={championship.report_model?._id || ''}
                    disabled={action === 'delete' ? true : false}
                >
                    <option value="">-</option>
                    {
                        reportModels.map(r => <option key={r._id} value={r._id}>{r.name}</option>)
                    }
                </Select>
            </div>
            <div>
                <div className="mb-2 block">
                    <FormLabel htmlFor="sport" label="Sport" />
                </div>
                <Select
                    id="sport"
                    required={true}
                    onChange={handleInput}
                    value={championship.sport}
                    disabled={action === 'delete' ? true : false}
                >
                    <option>-</option>
                    <option value="Pallavolo">Pallavolo</option>
                    <option value="Calcio">Calcio</option>
                </Select>
            </div>
            <div>
                <div className="mb-2 block">
                    <FormLabel htmlFor="rascode" label="Codice Attività RAS" />
                </div>
                <TextInput
                    id="rascode"
                    type="text"
                    value={championship.rascode}
                    onChange={handleInput}
                    placeholder="Es: 22/020/CAL/42"
                    disabled={action === 'delete' ? true : false}
                />
            </div>
            {
                authRole < 1 &&
                <div>
                    <div className="mb-2 block">
                        <FormLabel htmlFor="season" label="Stagione" />
                    </div>
                    <Select
                        id="season"
                        required={true}
                        onChange={handleInput}
                        value={championship.season}
                        disabled={action === 'delete' ? true : false}
                    >
                        <option>-</option>
                        {
                            seasons.map(season =>
                                <option
                                    key={season._id}
                                    value={season._id}
                                >
                                    {season.name}
                                </option>
                            )
                        }
                    </Select>
                </div>
            }
            <div className="flex flex-row gap-4 items-center">
                <div>
                    <div className="mb-2 block">
                        <FormLabel htmlFor="isPublic" label="Pubblica in home?" />
                    </div>
                    <Checkbox
                        id="isPublic"
                        onChange={handleInput}
                        checked={championship.isPublic}
                        disabled={action === 'delete' ? true : false}
                    />
                </div>
                <div>
                    <div className="mb-2 block">
                        <FormLabel htmlFor="isTournament" label="È un torneo?" />
                    </div>
                    <Checkbox
                        id="isTournament"
                        onChange={handleInput}
                        checked={championship.isTournament}
                        disabled={action === 'delete' ? true : false}
                    />
                </div>
                <div>
                    <div className="mb-2 block">
                        <FormLabel htmlFor="isDesignable" label="È designabile?" />
                    </div>
                    <Checkbox
                        id="isDesignable"
                        onChange={handleInput}
                        checked={championship.isDesignable}
                        disabled={action === 'delete' ? true : false}
                    />
                </div>
            </div>
            {
                action === 'delete' &&
                <GenericAlert type="warning" divclass="text-lg mx-auto" hideicon="true">Desideri eliminare questo campionato?</GenericAlert>
            }
            <div className="text-center">
                <SubmitButton />
            </div>
        </form>
    )
}

const MainModal = ({ action, championship, openModal, reportModels, seasons, selectedSeason, setAlert, setChampionship, setChampionships, setOpenModal, setSeasons }) => {
    const [modalAlert, setModalAlert] = useState({ type: '', label: '' });

    // Azzera l'alert del modal ogni volta che viene aperto
    useEffect(() => {
        openModal === true && setModalAlert({ type: '', label: '' });
    }, [openModal]);

    return (
        <GenericModal openModal={openModal} setOpenModal={setOpenModal} title={`${action === 'new' ? 'Nuovo' : action === 'edit' ? 'Modifica' : 'Elimina'} Campionato`}>
            {modalAlert?.type && <GenericAlert type={modalAlert.type}>{modalAlert.label}</GenericAlert>}
            <MainForm
                action={action}
                championship={championship}
                reportModels={reportModels}
                seasons={seasons}
                selectedSeason={selectedSeason}
                setAlert={setAlert}
                setChampionship={setChampionship}
                setChampionships={setChampionships}
                setModalAlert={setModalAlert}
                setOpenModal={setOpenModal}
                setSeasons={setSeasons}
            />
        </GenericModal>
    )
}

const SelectSeason = ({ seasons, selectedSeason, setSelectedSeason }) => {

    return (
        <div className="flex flex-row gap-4 items-center">
            <div className="mb-2 block">
                <FormLabel htmlFor="selectedSeason" label="Stagione" />
            </div>
            <Select
                id="selectedSeason"
                onChange={e => setSelectedSeason(e.currentTarget.value)}
                value={selectedSeason}
                className="mb-4 w-40"
            >
                <option>-</option>
                {
                    seasons.map(season =>
                        <option
                            key={season._id}
                            value={season._id}
                        >
                            {season.name}
                        </option>
                    )
                }
            </Select>
        </div>
    )
}


function Championships() {
    const { _id: committeeId } = useSelector(state => state.committee);
    const { _id: seasonId } = useSelector(state => state.season);
    const [action, setAction] = useState('new');
    const [alert, setAlert] = useState({ type: '', label: '' });
    const [championships, setChampionships] = useState([]);
    const [championship, setChampionship] = useState({ name: '', abbreviation: '', committee: committeeId, formula: 0, isPublic: true, isDesignable: true, rascode: '', season: seasonId, isTournament: false, report_model: '', sport: 'Pallavolo', urlcode: '' });
    const [loading, setLoading] = useState(true);
    const [openModal, setOpenModal] = useState(false);
    const [reportModels, setReportModels] = useState([]);
    const [seasons, setSeasons] = useState([]);
    const [selectedSeason, setSelectedSeason] = useState(seasonId);
    const [teams, setTeams] = useState([]);

    const openCommModal = () => {
        setChampionship({
            name: '',
            abbreviation: '',
            committee: committeeId,
            formula: 0,
            isPublic: true,
            isDesignable: true,
            rascode: '',
            season: seasonId,
            isTournament: false,
            report_model: '',
            sport: 'Pallavolo',
            urlcode: ''
        });
        setAction('new');
        setOpenModal(true);
    }

    useEffect(() => {
        if (committeeId) {
            setLoading(true);
            API.get(`championships?committee=${committeeId}&page=restr-championships${selectedSeason && `&season=${selectedSeason}`}`)
                .then(res => {
                    if (res.success) {
                        setChampionships(sortByName(res.championships, 'name'));
                        setAlert({ type: '', label: '' });

                    } else {
                        setChampionships([]);
                        setAlert({ type: 'failure', label: res.msg });
                    }
                    setLoading(false);
                })
                .catch(err => console.error(err))
        }
    }, [committeeId, selectedSeason])

    useEffect(() => {
        API.get('seasons')
            .then(res => {
                if (res.success) {
                    setSeasons(sortByName(res.seasons, 'name'));
                } else {
                    setSeasons([]);
                }
            })
    }, [])

    useEffect(() => {
        if (seasonId) {
            setSelectedSeason(seasonId);
        }
    }, [seasonId])

    useEffect(() => {
        if (committeeId) {
            API.get(`reportmodels?committees=${committeeId}`, true)
                .then(res => {
                    if (res.success) {
                        setReportModels(sortByName(res.reportmodels, 'name'));
                    } else {
                        setReportModels([]);
                    }
                })
                .catch(err => console.error(err));
        }
    }, [committeeId])

    useEffect(() => {
        if (selectedSeason) {
            API.get(`teams?season=${selectedSeason}`)
                .then(res => {
                    if (res.success) {
                        setTeams(res.teams);
                    }
                })
                .catch(err => console.error(err));
        }
    }, [selectedSeason])

    return (
        <div className="flex flex-col gap-3">
            {alert?.type && <GenericAlert type={alert.type}>{alert.label}</GenericAlert>}
            <SelectSeason
                seasons={seasons}
                selectedSeason={selectedSeason}
                setSelectedSeason={setSelectedSeason}
            />
            <MainTable
                championships={championships}
                loading={loading}
                selectedSeason={selectedSeason}
                setAlert={setAlert}
                setAction={setAction}
                setChampionship={setChampionship}
                setChampionships={setChampionships}
                setLoading={setLoading}
                setOpenModal={setOpenModal}
                teams={teams}
            />
            <div className="w-fit">
                <Button size="sm" onClick={openCommModal}>
                    Nuovo Campionato
                </Button>
            </div>
            <MainModal
                action={action}
                championship={championship}
                openModal={openModal}
                reportModels={reportModels}
                seasons={seasons}
                selectedSeason={selectedSeason}
                setAlert={setAlert}
                setChampionship={setChampionship}
                setChampionships={setChampionships}
                setOpenModal={setOpenModal}
                setSeasons={setSeasons}
            />
        </div>
    )
}

export default Championships;