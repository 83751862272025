import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { serviceProvider as API } from "../../../../API/api";
import { abbrText, mapsUrl, sortByName } from "../../../../lib/utils";

// Components
import { Table } from "flowbite-react";
import GenericAlert from "../../../elements/GenericAlert";
import LoadingRow from "../../../elements/LoadingRow";


const MainRow = ({ field }) => {

    return (
        <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
            <Table.Cell className="hidden md:table-cell whitespace-nowrap font-medium text-gray-900 dark:text-white">
                {field.province}
            </Table.Cell>
            <Table.Cell>
                {field.city}
            </Table.Cell>
            <Table.Cell>
                {field.name}
            </Table.Cell>
            <Table.Cell>
                <a 
                    className="hover:font-semibold"
                    target="_blank" 
                    href={mapsUrl(`${field.city} ${field.name} ${field.address}`)}
                    rel="noreferrer"
                >
                    <span className="hidden lg:block">{field.address}</span>
                    <span className="lg:hidden">{abbrText(field.address, 20)}</span>
                </a>
            </Table.Cell>
        </Table.Row>
    )
}

function FieldsMain() {
    const { _id: committeeId } = useSelector(state => state.committee);
    const [alert, setAlert] = useState({ type: '', label: '' });
    const [fields, setFields] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (committeeId) {
            API.get(`fields?committee=${committeeId}`)
                .then(res => {
                    if (res.success) {
                        setFields(sortByName(sortByName(sortByName(res.fields, 'name'), 'city'), 'province'));
                    } else {
                        setAlert({ type: 'failure', label: res.msg || 'Qualcosa è andato storto, riprova più tardi' });
                    }
                    setLoading(false);
                })
                .catch(err => console.error(err));
        }
    }, [committeeId])

    return (
        <div className="flex flex-col gap-2">
            {alert.type && <GenericAlert type={alert.type}>{alert.label}</GenericAlert>}
            <Table hoverable={true}>
                <Table.Head>
                    <Table.HeadCell className="hidden md:table-cell">
                        Provincia
                    </Table.HeadCell>
                    <Table.HeadCell>
                        Comune
                    </Table.HeadCell>
                    <Table.HeadCell>
                        Nome
                    </Table.HeadCell>
                    <Table.HeadCell>
                        Indirizzo
                    </Table.HeadCell>
                </Table.Head>
                <Table.Body className="divide-y">
                    {
                        loading &&
                        <LoadingRow colspan={4} />
                    }
                    {
                        !loading && fields.length < 1 &&
                        <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                            <Table.Cell
                                colSpan={4}
                                className="whitespace-nowrap font-medium text-gray-900 dark:text-white text-center"
                            >
                                Nessun campo trovato
                            </Table.Cell>
                        </Table.Row>
                    }
                    {
                        fields.map(field =>
                            <MainRow
                                key={field._id}
                                field={field}
                            />
                        )
                    }
                </Table.Body>
            </Table>
        </div>
    )
}

export default FieldsMain;