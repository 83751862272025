import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { serviceProvider as API } from "../../../API/api";
import { sortByName } from "../../../lib/utils";

// Components
import { Button, Select, Table, TextInput } from "flowbite-react";
import DeleteButton from "../../buttons/DeleteButton";
import EditButton from "../../buttons/EditButton";
import FormLabel from "../../elements/FormLabel";
import GenericAlert from "../../elements/GenericAlert";
import GenericModal from "../../elements/GenericModal";
import LoadingRow from "../../elements/LoadingRow";
import NoResultsRow from "../../elements/NoResultsRow";
import SubmitButton from "../../buttons/SubmitButton";


const TableRow = ({ committeeId, field, setAction, setField, setOpenModal }) => {
    const { _id, name, province, city, address } = field;

    const rowField = {
        _id: _id,
        name: name || '',
        province: province || '',
        city: city || '',
        address: address || 0,
        committee: committeeId || ''
    }

    const handleEdit = () => {
        setAction('edit');
        setField(rowField);
        setOpenModal(true);
    }

    const handleDelete = () => {
        setAction('delete');
        setField(rowField);
        setOpenModal(true);
    }

    return (
        <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
            <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                {province}
            </Table.Cell>
            <Table.Cell>
                {city}
            </Table.Cell>
            <Table.Cell>
                {name}
            </Table.Cell>
            <Table.Cell>
                <EditButton onClick={handleEdit} />
                <DeleteButton onClick={handleDelete} />
            </Table.Cell>
        </Table.Row>
    )
}

const FieldsTable = ({ committeeId, fields, setAlert, setAction, setField, setFields, setOpenModal }) => {
    const [loading, setLoading] = useState(true);
    const [noResults, setNoResults] = useState(false);

    useEffect(() => {
        API.get(`fields?committee=${committeeId}`)
            .then(res => {
                if (res.success) {
                    setFields(sortByName(sortByName(sortByName(res.fields, 'name'), 'city'), 'province'));
                    setAlert({ type: '', label: '' });
                    setNoResults(false);
                    setLoading(false);
                } else {
                    setFields([]);
                    setAlert({ type: 'failure', label: res.msg });
                    setLoading(false);
                }
            })
            .catch(err => console.error(err))
    }, [committeeId, setFields, setAlert])

    useEffect(() => {
        if (fields.length) {
            setNoResults(false);
            setLoading(false);
        } else {
            setNoResults(true);
            setLoading(false);
        }
    }, [fields])

    return (
        <Table hoverable={true}>
            <Table.Head>
                <Table.HeadCell>
                    Provincia
                </Table.HeadCell>
                <Table.HeadCell>
                    Comune
                </Table.HeadCell>
                <Table.HeadCell>
                    Nome
                </Table.HeadCell>
                <Table.HeadCell>
                    <span className="sr-only">
                        Edit
                    </span>
                </Table.HeadCell>
            </Table.Head>
            <Table.Body className="divide-y">
                {loading && <LoadingRow colspan={4} />}
                {(!loading && noResults) && <NoResultsRow colspan={4} />}
                {
                    fields.map((field, i) =>
                        <TableRow
                            key={i}
                            committeeId={committeeId}
                            field={field}
                            setAction={setAction}
                            setField={setField}
                            setOpenModal={setOpenModal}
                        />
                    )
                }
            </Table.Body>
        </Table>
    )
}

const FieldsForm = ({ action, field, locations, setAlert, setField, setFields, setModalAlert, setOpenModal }) => {
    const [cities, setCities] = useState([]);

    const handleInput = (e) => {
        const { id, value } = e.currentTarget;
        setField(prevState => ({
            ...prevState,
            [id]: value
        }));
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        switch (action) {
            case 'new':
                API.insert('fields', JSON.stringify(field), true)
                    .then(res => {
                        if (res.success) {
                            setFields(prevState => sortByName(sortByName(sortByName([...prevState, res.field], 'name'), 'city'), 'province'));
                            setAlert({ type: 'success', label: 'Il campo è stato inserito' });
                            setOpenModal(false);
                        } else {
                            setModalAlert({ type: 'failure', label: res.msg || 'Qualcosa è andato storto...' });
                        }
                    })
                    .catch(err => console.error(err));
                break;
            case 'edit':
                API.update(`fields/${field._id}`, JSON.stringify(field), true)
                    .then(res => {
                        if (res.success) {
                            setFields(prevState => {
                                const index = prevState.findIndex(el => el._id === field._id);
                                prevState[index] = field;
                                return sortByName(sortByName(sortByName(prevState, 'name'), 'city'), 'province')
                            });
                            setAlert({ type: 'success', label: 'Il campo è stato modificato correttamente' });
                            setOpenModal(false);
                        } else {
                            setModalAlert({ type: 'failure', label: res.msg || 'Qualcosa è andato storto...' });
                        }
                    })
                    .catch(err => console.error(err));
                break;
            case 'delete':
                API.delete(`fields/${field._id}`, true)
                    .then(res => {
                        if (res.success) {
                            setFields(prevState => prevState.filter(el => el._id !== field._id));
                            setAlert({ type: 'success', label: 'Il campo è stato eliminato' });
                            setOpenModal(false);
                        } else {
                            setModalAlert({ type: 'failure', label: res.msg || 'Qualcosa è andato storto...' });
                        }
                    })
                    .catch(err => console.error(err));
                break;
            default:
        }
    }

    useEffect(() => {
        // Ogni volta che viene impostata una provincia, aggiorna l'array dei comuni
        if (field.province) {
            const loc = locations.find(loc => loc.provincia === field.province);
            setCities(loc.comuni);
        } else {
            setCities([]);
        }
    }, [locations, field.province])

    return (
        <form className="flex flex-col gap-4" onSubmit={handleSubmit}>
            <div>
                <div className="mb-2 block">
                    <FormLabel htmlFor="name" label="Nome" />
                </div>
                <TextInput
                    id="name"
                    type="text"
                    required={true}
                    value={field.name}
                    onChange={handleInput}
                    disabled={action === 'delete' ? true : false}
                />
            </div>
            <div className="flex flex-row gap-4 items-center">
                <div className="basis-1/2">
                    <div className="mb-2 block">
                        <FormLabel htmlFor="province" label="Provincia" />
                    </div>
                    <Select
                        id="province"
                        required={true}
                        onChange={handleInput}
                        value={field.province}
                    >
                        <option>-</option>
                        {
                            locations.map(location =>
                                <option
                                    key={location._id}
                                    value={location.provincia}
                                >
                                    {location.provincia}
                                </option>
                            )
                        }
                    </Select>
                </div>
                <div>
                    <div className="mb-2 block">
                        <FormLabel htmlFor="city" label="Comune" />
                    </div>
                    <Select
                        id="city"
                        required={true}
                        onChange={handleInput}
                        value={field.city}
                    >
                        <option>-</option>
                        {
                            cities.map((city, i) =>
                                <option
                                    key={`city_${i}`}
                                    value={city}
                                >
                                    {city}
                                </option>
                            )
                        }
                    </Select>
                </div>
            </div>
            <div>
                <div className="mb-2 block">
                    <FormLabel htmlFor="address" label="Indirizzo" />
                </div>
                <TextInput
                    id="address"
                    type="text"
                    value={field.address}
                    onChange={handleInput}
                    disabled={action === 'delete' ? true : false}
                />
            </div>
            {
                action === 'delete' &&
                <GenericAlert type="warning" divclass="text-lg mx-auto" hideicon="true">Desideri eliminare questo campo?</GenericAlert>
            }
            <div className="text-center">
                <SubmitButton />
            </div>
        </form>
    )
}

const FieldsModal = ({ action, field, locations, openModal, setAlert, setField, setFields, setOpenModal }) => {
    const [modalAlert, setModalAlert] = useState({ type: '', label: '' });

    // Azzera l'alert del modal ogni volta che viene aperto
    useEffect(() => {
        openModal === true && setModalAlert({ type: '', label: '' });
    }, [openModal]);

    return (
        <GenericModal openModal={openModal} setOpenModal={setOpenModal} title={`${action === 'new' ? 'Nuovo' : action === 'edit' ? 'Modifica' : 'Elimina'} Campo`}>
            {modalAlert?.type && <GenericAlert type={modalAlert.type}>{modalAlert.label}</GenericAlert>}
            <FieldsForm action={action} field={field} locations={locations} setAlert={setAlert} setField={setField} setFields={setFields} setModalAlert={setModalAlert} setOpenModal={setOpenModal} />
        </GenericModal>
    )
}

function Fields() {
    const { _id: committeeId } = useSelector(state => state.committee);
    const [action, setAction] = useState('new');
    const [alert, setAlert] = useState({ type: '', label: '' });
    const [fields, setFields] = useState([]);
    const [field, setField] = useState({ name: '', province: '', city: '', address: '', committee: committeeId });
    const [locations, setLocations] = useState([]);
    const [openModal, setOpenModal] = useState(false);

    const openCommModal = () => {
        setField({ name: '', province: '', city: '', address: '', committee: committeeId });
        setAction('new');
        setOpenModal(true);
    }

    useEffect(() => {
        API.get('locations')
            .then(res => {
                if (res.success) {
                    setLocations(sortByName(res.locations, 'provincia'));
                } else {
                    setLocations([]);
                    setAlert({ type: 'failure', label: res.msg });
                }
            })
            .catch(err => console.error(err))
    }, [setLocations, setAlert])

    return (
        <div className="flex flex-col gap-3">
            {alert?.type && <GenericAlert type={alert.type}>{alert.label}</GenericAlert>}
            <FieldsTable
                committeeId={committeeId}
                fields={fields}
                setAlert={setAlert}
                setAction={setAction}
                setField={setField}
                setFields={setFields}
                setOpenModal={setOpenModal}
            />
            <div className="w-fit">
                <Button size="sm" onClick={openCommModal}>
                    Nuovo Campo
                </Button>
            </div>
            <FieldsModal
                action={action}
                field={field}
                locations={locations}
                openModal={openModal}
                setAlert={setAlert}
                setField={setField}
                setFields={setFields}
                setOpenModal={setOpenModal}
            />
        </div>
    )
}

export default Fields;