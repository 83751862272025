import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { serviceProvider as API } from "../../../API/api";
import { sortByName } from "../../../lib/utils";

// Components
import { Table, TextInput, Tooltip } from "flowbite-react";
import EditButton from "../../buttons/EditButton";
import FormLabel from "../../elements/FormLabel";
import GenericAlert from "../../elements/GenericAlert";
import GenericModal from "../../elements/GenericModal";
import LoadingRow from "../../elements/LoadingRow";
import NoResultsRow from "../../elements/NoResultsRow";
import SubmitButton from "../../buttons/SubmitButton";

// Icons
const MoneyIcon = ({ colorClass }) =>
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={`w-6 h-6 ${colorClass}`}>
        <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 18.75a60.07 60.07 0 0115.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 013 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 00-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 01-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 003 15h-.75M15 10.5a3 3 0 11-6 0 3 3 0 016 0zm3 0h.008v.008H18V10.5zm-12 0h.008v.008H6V10.5z" />
    </svg>



const TableRow = ({ championship, roles, setAllowances, setChampionship, setOpenModal }) => {
    const allSet = useCallback(() => championship.allowances.length === roles.filter(r => r.sport === championship.sport).length, [championship, roles])

    const handleEdit = () => {
        const rowAllowances = roles
            .filter(r => r.sport === championship.sport)
            .map(r => ({
                role: r.title,
                amount: championship.allowances.find(a => a.role === r.title)?.amount || 0
            }));
        setChampionship(championship);
        setAllowances(rowAllowances);
        setOpenModal(true);
    }

    return (
        <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
            <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                {championship.name}
            </Table.Cell>
            <Table.Cell>
                <Tooltip content={allSet() ? 'Le diarie risultano impostate' : 'Alcune diarie non risultano impostate'}>
                    <MoneyIcon colorClass={allSet() ? 'text-green-500' : 'text-yellow-500'} />
                </Tooltip>
            </Table.Cell>
            <Table.Cell>
                <EditButton onClick={handleEdit} />
            </Table.Cell>
        </Table.Row>
    )
}

const MainTable = ({ championships, loading, roles, setAllowances, setChampionship, setOpenModal }) => {

    return (
        <Table hoverable={true}>
            <Table.Head>
                <Table.HeadCell>
                    Campionato
                </Table.HeadCell>
                <Table.HeadCell>
                    Diarie
                </Table.HeadCell>
                <Table.HeadCell>
                    <span className="sr-only">
                        Edit
                    </span>
                </Table.HeadCell>
            </Table.Head>
            <Table.Body className="divide-y">
                {loading && <LoadingRow colspan={3} />}
                {!loading && championships.length < 1 && <NoResultsRow colspan={3} />}
                {
                    championships.map(c =>
                        <TableRow
                            key={c._id}
                            championship={c}
                            roles={roles}
                            setAllowances={setAllowances}
                            setChampionship={setChampionship}
                            setOpenModal={setOpenModal}
                        />
                    )
                }
            </Table.Body>
        </Table>
    )
}

const MainForm = ({ allowances, championship, setAlert, setAllowances, setChampionships, setModalAlert, setOpenModal }) => {

    const handleInput = (e) => {
        const { id, value } = e.currentTarget;
        setAllowances(prevState =>
            prevState
                .map(a => ({
                    role: a.role,
                    amount: id === a.role
                        ? Number(value)
                        : a.amount
                }))
        )
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        API.update(`championships/${championship._id}`, JSON.stringify({allowances: allowances}), true)
            .then(res => {
                if (res.success) {
                    setChampionships(prevState => sortByName([
                        ...prevState.filter(c => c._id !== championship._id),
                        res.championship
                    ], 'name'));
                    setAlert({ type: 'success', label: 'Diarie impostate' });
                    setOpenModal(false);
                } else {
                    setModalAlert({ type: 'failure', label: res.msg || 'Qualcosa è andato storto...' });
                }
            })
            .catch(err => console.error(err));
    }

    return (
        <form className="flex flex-col gap-4" onSubmit={handleSubmit}>
            {
                allowances
                    .map((a, i) =>
                    <div key={`all_${i}`}>
                        <div className="mb-2 block">
                            <FormLabel htmlFor={a.role} label={a.role} />
                        </div>
                        <TextInput
                            id={a.role}
                            type="number"
                            min="0"
                            step="0.5"
                            required={true}
                            value={a.amount}
                            onChange={handleInput}
                        />
                    </div>
                )
            }
            <div className="text-center">
                <SubmitButton />
            </div>
        </form>
    )
}

const MainModal = ({ allowances, championship, openModal, setAlert, setAllowances, setChampionships, setOpenModal }) => {
    const [modalAlert, setModalAlert] = useState({ type: '', label: '' });

    // Azzera l'alert del modal ogni volta che viene aperto
    useEffect(() => {
        openModal === true && setModalAlert({ type: '', label: '' });
    }, [openModal]);

    return (
        <GenericModal openModal={openModal} setOpenModal={setOpenModal} title="Modifica diarie">
            {modalAlert?.type && <GenericAlert type={modalAlert.type}>{modalAlert.label}</GenericAlert>}
            <MainForm
                allowances={allowances}
                championship={championship}
                setAlert={setAlert}
                setAllowances={setAllowances}
                setChampionships={setChampionships}
                setModalAlert={setModalAlert}
                setOpenModal={setOpenModal}
            />
        </GenericModal>
    )
}

function Refunds() {
    const { _id: committeeId } = useSelector(state => state.committee);
    const { _id: seasonId } = useSelector(state => state.season);
    const [alert, setAlert] = useState({ type: '', label: '' });
    const [allowances, setAllowances] = useState([]);
    const [championship, setChampionship] = useState({});
    const [championships, setChampionships] = useState([]);
    const [loading, setLoading] = useState(true);
    const [roles, setRoles] = useState([]);
    const [openModal, setOpenModal] = useState(false);

    useEffect(() => {
        if (committeeId && seasonId) {
            API.get(`championships?committee=${committeeId}&season=${seasonId}`)
                .then(res => {
                    if (res.success) {
                        setChampionships(sortByName(res.championships, 'name'));
                    } else {
                        setAlert({ type: 'failure', label: res.msg || 'Qualcosa è andato storto...' });
                    }
                    setLoading(false);
                })
                .catch(err => console.error(err));
        }
    }, [committeeId, seasonId])

    useEffect(() => {
        if (committeeId) {
            API.get(`refroles?committee=${committeeId}`)
                .then(res => {
                    if (res.success) {
                        setRoles(sortByName(res.refroles, 'title'));
                    } else {
                        setAlert({ type: 'failure', label: res.msg || 'Qualcosa è andato storto...' });
                    }
                })
                .catch(err => console.error(err));
        }
    }, [committeeId])

    return (
        <div className="flex flex-col gap-3">
            {alert?.type && <GenericAlert type={alert.type}>{alert.label}</GenericAlert>}
            <MainTable
                championships={championships}
                loading={loading}
                roles={roles}
                setAllowances={setAllowances}
                setChampionship={setChampionship}
                setOpenModal={setOpenModal}
            />
            <MainModal
                allowances={allowances}
                championship={championship}
                openModal={openModal}
                roles={roles}
                setAlert={setAlert}
                setAllowances={setAllowances}
                setChampionship={setChampionship}
                setChampionships={setChampionships}
                setOpenModal={setOpenModal}
            />
        </div>
    )
}

export default Refunds;