import { Outlet } from "react-router-dom";
import DashSidebar from "../elements/DashSidebar";

function UserHome() {
    return (
        <div className="flex flex-col md:flex-row gap-5">
            <div className="w-fit">
                <DashSidebar />
            </div>
            <div className="w-full lg:w-3/4">
                <Outlet />
            </div>
        </div>
    )
}

export default UserHome;