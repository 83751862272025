import { useEffect, useMemo, useState } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { authProvider as AUTH } from './API/api';
import { serviceProvider as API } from './API/api';
import { assign as assignCommittee } from './features/committee/committeeSlice';
import { assign as assignSeason } from './features/season/seasonSlice';
import { assign as assignUser } from './features/user/userSlice';
import { sortByDate, sortByName } from './lib/utils';

// Components
import MainFooter from './components/public/index/MainFooter';
import GenericAlert from './components/elements/GenericAlert';
import LoadingSpinner from './components/elements/LoadingSpinner';
import LoginModal from './components/elements/LoginModal';
import Nav from './components/elements/Nav';
import NotFound from './components/elements/NotFound';
//import { Spinner } from 'flowbite-react';

function App() {
  const [alert] = useState({ type: '', label: '' });
  const [bulletins, setBulletins] = useState([]);
  const [championships, setChampionships] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingMatches, setLoadingMatches] = useState(true);
  //const [matches, setMatches] = useState([]);
  const [openLogin, setOpenLogin] = useState(false);
  const [seasons, setSeasons] = useState([]);
  const { committeeUrlCode } = useParams();
  const { _id: committeeId } = useSelector(state => state.committee);
  const { _id: seasonId } = useSelector(state => state.season);
  const season = useMemo(() => JSON.parse(localStorage.getItem('season')), []);
  const user = useSelector(state => state.user);
  const dispatch = useDispatch();

  // Verifico il comitato e lo memorizzo
  useEffect(() => {
    API.get(`committees/${committeeUrlCode}`, false)
      .then(res => {
        dispatch(assignCommittee(res.committee));
        setLoading(false);
      })
      .catch(err => console.error(err))
  }, [committeeUrlCode, dispatch])

  // Verifico la stagione attuale
  useEffect(() => {
    if (season?._id) {
      // ho una season nel localstorage
      dispatch(assignSeason(season));
    } else {
      // non ho una season nel localstorage, devo cercarla
      API.get(`seasons?date=${new Date().toISOString()}`)
        .then(res => {
          if (res.success) {
            localStorage.setItem('season', JSON.stringify(res.seasons[0]));
            dispatch(assignSeason(res.seasons[0]));
          }
        })
        .catch(err => console.error(err));
    }
  }, [dispatch, season])


  // Verifico l'utente
  useEffect(() => {
    if (!user._id) {
      AUTH.verifyLogin()
        .then(res => {
          if (res?.success) dispatch(assignUser(res.user));
        })
        .catch(err => console.error(err));
    }
  }, [user._id, dispatch]);

  // Championships & Matches
  /*   useEffect(() => {
      if (committeeId && seasonId) {
        setLoadingMatches(true);
        API.get(`championships?committee=${committeeId}&season=${seasonId}&isPublic=true`)
          .then(res => {
            if (res.success) {
              setChampionships(sortByName(res.championships, 'name'));
              // Creo un array con tutte le gare dei campionati
              const matches = [];
              res.championships.forEach(c =>
                c.calendar.forEach(g =>
                  g.rounds.forEach(r =>
                    r.matches.forEach(m => {
                      matches.push(m)
                    })
                  )
                )
              )
              setMatches(sortByDate(matches.filter(m => !m.postponed), 'date'));
            }
            setLoadingMatches(false);
          })
          .catch(err => console.error(err));
      }
    }, [committeeId, seasonId]) */

  // Championships & Matches
  useEffect(() => {
    if (committeeId && seasonId) {
      setLoadingMatches(true);
      API.get(`championships?committee=${committeeId}&season=${seasonId}&isPublic=true&page=nav`)
        .then(res => {
          if (res.success) {
            setChampionships(sortByName(res.championships, 'name'));
            setLoadingMatches(false);
          }
        })
        .catch(err => console.error(err));
    }
  }, [committeeId, seasonId])

  useEffect(() => {
    API.get(`seasons?date=${new Date().toISOString()}`)
      .then(res => {
        if (res.success) {
          dispatch(assignSeason(res.seasons[0]));
        }
      })
      .catch(err => console.error(err));
  }, [dispatch])

  // Bulletins
  useEffect(() => {
    if (committeeId && seasonId) {
      API.get(`documents?type=bulletin&committee=${committeeId}&season=${seasonId}`)
        .then(res => {
          if (res.success) {
            setBulletins(sortByDate(res.documents, 'inserted_at', 'desc'));
          }
        })
        .catch(err => console.error(err));
    }
  }, [committeeId, seasonId])

  // Verifico le stagioni
  useEffect(() => {
    API.get('seasons')
      .then(res => {
        if (res.success) {
          setSeasons(res.seasons);
        }
      })
      .catch(err => console.error(err));
  }, [dispatch])


  return (
    <div className="text-center bg-gradient-to-bl from-sky-200 to-sky-100">
      {loading && <LoadingSpinner />}
      {
        !committeeId ?
          <NotFound />
          :
          <>
            <header>
              <Nav championships={championships} setOpenLogin={setOpenLogin} />
              {/* <Nav
                championships={championships}
                seasons={seasons}
                setOpenLogin={setOpenLogin}
              /> */}
            </header>
            <div className='min-h-screen mt-5 mx-5 pb-10 flex flex-col gap-4'>
              {alert?.type && <GenericAlert type={alert.type}>{alert.label}</GenericAlert>}
              {/* loadingMatches &&
                <div className='bg-sky-100 p-10 rounded mx-36'>
                  <Spinner size="lg" />
                </div> */
              }
              {/* !loadingMatches && <Outlet context={[bulletins, matches]} /> */}
              <Outlet context={[bulletins]} />
              <LoginModal open={openLogin} setOpen={setOpenLogin} />
            </div>
            <MainFooter />
          </>
      }
    </div>
  );
}

export default App;
