import { useEffect, useState } from "react";
import { serviceProvider as API } from "../../../API/api";
import { sortByName } from "../../../lib/utils";

// Components
import { Select, Table, TextInput } from "flowbite-react";
import GenericAlert from "../../elements/GenericAlert";
import FormLabel from "../../elements/FormLabel";

const ProvinceOption = ({ province }) => {
    return (
        <option value={province}>
            {province}
        </option>
    )
}

const CityOption = ({ city }) => {
    return (
        <option value={city}>
            {city}
        </option>
    )
}

const LocationsForm = ({ distance, setAlert, setDistance, setDistances, setEndCities }) => {
    const [startCities, setStartCities] = useState([])
    const [locations, setLocations] = useState([]);

    const handleProvince = (e) => {
        const { id, value } = e.currentTarget;
        if (id === 'province1') {
            const loc = locations.find(el => el.provincia === value);
            setStartCities(loc.comuni);
        }
        setDistance(prevState => {
            prevState.provinces[id === 'province1' ? 0 : 1] = value;
            return { ...prevState };
        });
    }

    const handleFirstCity = (e) => {
        const { value } = e.currentTarget;
        setDistance(prevState => {
            prevState.locations[0] = value;
            return { ...prevState };
        });
    }

    useEffect(() => {
        API.get('locations')
            .then(res => {
                if (res.success) {
                    setLocations(sortByName(res.locations, 'provincia'));
                } else {
                    setLocations([]);
                    setAlert({ type: 'failure', label: res.msg });
                }
            })
            .catch(err => console.error(err))
    }, [setLocations, setAlert])

    useEffect(() => {
        const { locations: locs, provinces } = distance
        if (provinces.length > 1 && locs.length > 0 && locs.length < 2) {
            const loc = locations.find(el => el.provincia === distance.provinces[1]);
            setEndCities(loc.comuni);
            const url = `distances?provinces=${distance.provinces[0]}&provinces=${distance.provinces[1]}&locations=${distance.locations[0]}`;
            API.get(url)
                .then(res => {
                    if (res.success) {
                        setAlert({ type: '', label: '' });
                        setDistances(res.distances);
                    } else {
                        setAlert({ type: 'warning', label: res.msg });
                        setDistances([]);
                    }
                })
                .catch(err => console.error(err))
        }
    }, [distance, locations, setAlert, setDistances, setEndCities])

    return (
        <div className="flex flex-col lg:flex-row gap-6">
            <div className="w-48">
                <div className="mb-2 block">
                    <FormLabel htmlFor="province1" label="Prov. di partenza" />
                </div>
                <Select
                    id="province1"
                    required={true}
                    onChange={handleProvince}
                >
                    <option>
                        -
                    </option>
                    {
                        locations.map(loc => <ProvinceOption key={loc._id} province={loc.provincia} />)
                    }
                </Select>
            </div>
            <div className="w-48">
                <div className="mb-2 block">
                    <FormLabel htmlFor="city1" label="Comune di partenza" />
                </div>
                <Select
                    id="city1"
                    required={true}
                    onChange={handleFirstCity}
                >
                    {
                        !startCities.length &&
                        <option>
                            Scegli prima la provincia
                        </option>
                    }
                    {
                        startCities.length &&
                        <option>
                            -
                        </option>
                    }
                    {
                        startCities.map((city, i) => <CityOption key={`dep_${i}`} city={city} />)
                    }
                </Select>
            </div>
            <div className="w-48">
                <div className="mb-2 block">
                    <FormLabel htmlFor="province2" label="Provincia di arrivo" />
                </div>
                <Select
                    id="province2"
                    required={true}
                    onChange={handleProvince}
                >
                    <option>
                        -
                    </option>
                    {
                        locations.map(loc => <ProvinceOption key={loc._id} province={loc.provincia} />)
                    }
                </Select>
            </div>
        </div>
    )
}

const TableRow = ({ distance, distances, endCity }) => {
    const { locations, provinces } = distance;
    const startCity = locations[0];
    const [foundDistance, setFoundDistance] = useState({ provinces: [], locations: [], distance: 0 });
    const [statusClass, setStatusClass] = useState('');

    const handleInput = (e) => {
        const { value } = e.currentTarget;
        // Previene lo 0 iniziale e il campo vuoto
        setFoundDistance(prevState => ({
            ...prevState,
            distance: !value.length ? 0
                : value.length > 1 && value[0] === '0'
                    ? value.slice(1)
                    : value
        }));
    };

    const handleSubmit = (e) => {
        const { value } = e.currentTarget;
        const bodyObject = {
            provinces: provinces,
            locations: [startCity, endCity],
            distance: value
        };
        API.insert('distances', JSON.stringify(bodyObject), true)
            .then(res => {
                if (res.success) {
                    setFoundDistance(bodyObject);
                    setStatusClass('outline outline-1 outline-green-700');
                } else {
                    setStatusClass('outline outline-1 outline-red-700');
                }
            })
            .catch(err => console.error(err));
    }

    const handleDelete = (e) => {
        const bodyObject = {
            provinces: provinces,
            locations: [startCity, endCity]
        };
        API.delete('distances', true, JSON.stringify(bodyObject))
            .then(res => {
                if (res.success) {
                    setFoundDistance({ provinces: [], locations: [], distance: 0 });
                    setStatusClass('outline outline-1 outline-green-700');
                } else {
                    setStatusClass('outline outline-1 outline-red-700');
                }
            })
            .catch(err => console.error(err))
    }

    // Aggiorna gli stati ogni volta che distances viene modificato
    useEffect(() => {
        const found_distance = distances.find(distance => distance.locations.includes(startCity) && distance.locations.includes(endCity));
        setFoundDistance(found_distance ? found_distance : { provinces: [], locations: [], distance: 0 });
        setStatusClass('');
    }, [distances, endCity, startCity])

    return (
        <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
            <Table.Cell>
                {startCity}
            </Table.Cell>
            <Table.Cell>
                {endCity}
            </Table.Cell>
            <Table.Cell>
                <div className="flex flex-row gap-1">
                    <TextInput
                        type="number"
                        className={`w-16 lg:w-48 ${statusClass}`}
                        onBlur={handleSubmit}
                        onChange={handleInput}
                        step="1"
                        value={foundDistance.distance}
                    />
                    <div>
                        {foundDistance.provinces.length > 0 &&
                            <button
                                type="button"
                                className="focus:outline-none text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-2.5 py-2.5 mr-2 mb-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900"
                                onClick={handleDelete}
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className='w-5 h-5'>
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                                </svg>
                            </button>
                        }
                    </div>
                </div>
            </Table.Cell>
        </Table.Row>
    )
}

const DistancesTable = ({ distance, distances, endCities }) => {
    return (
        <Table hoverable={true}>
            <Table.Head>
                <Table.HeadCell>
                    Partenza
                </Table.HeadCell>
                <Table.HeadCell>
                    Arrivo
                </Table.HeadCell>
                <Table.HeadCell>
                    <span className="sr-only">
                        Edit
                    </span>
                </Table.HeadCell>
            </Table.Head>
            <Table.Body className="divide-y">
                {
                    !endCities.length &&
                    <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800 text-center">
                        <Table.Cell colSpan={3}>
                            Seleziona prima il comune di partenza e quello di arrivo
                        </Table.Cell>
                    </Table.Row>
                }
                {
                    endCities.map((city, i) => {
                        if (distance.locations[0] !== city) {
                            return <TableRow key={`end_${i}`} distance={distance} distances={distances} endCity={city} />
                        }
                        return '';
                    })
                }
            </Table.Body>
        </Table>
    )
}

function Distances() {
    const [alert, setAlert] = useState({ type: '', label: '' });
    const [distances, setDistances] = useState([]);
    const [distance, setDistance] = useState({ provinces: [], locations: [], distance: 0 });
    const [endCities, setEndCities] = useState([]);

    return (
        <div className="flex flex-col gap-3">
            {alert?.type && <GenericAlert type={alert.type}>{alert.label}</GenericAlert>}
            <LocationsForm distance={distance} setAlert={setAlert} setDistance={setDistance} setDistances={setDistances} setEndCities={setEndCities} />
            <DistancesTable distance={distance} distances={distances} endCities={endCities} />
        </div>
    )
}

export default Distances;