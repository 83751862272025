import { useCallback, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { abbrText, readablePartials } from "../../../lib/utils";

// Components
import { Table, Tooltip } from "flowbite-react";
import { localeOpts } from "../../../lib/configs";
import { Spinner } from "flowbite-react";

// Icon
import { ReactComponent as WhistleIcon } from '../../../images/icons/whistle.svg';

const TableRow = ({ match, showReferees }) => {
    const { urlcode: committeeUrlcode } = useSelector(state => state.committee);
    
    const referees = useCallback(() =>
        match.assignments
            .filter(a => a.status > 2)
            .map(a => `${a.role}: ${a.referee.lastname} ${a.referee.firstname}`)
        , [match]
    )

    return (
        <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
            <Table.Cell className="hidden md:table-cell whitespace-nowrap font-medium text-gray-900 dark:text-white">
                {match.num}
            </Table.Cell>
            <Table.Cell className="hidden sm:table-cell">
                <Link to={`/${committeeUrlcode}/campionati/${match.championship.sport?.toLowerCase()}/${match.championship.urlcode}`}>
                    <span className="hidden md:block">{match.championship.name}</span>
                    <span className="md:hidden text-xs">{abbrText(match.championship.name, 16)}</span>
                </Link>
            </Table.Cell>
            <Table.Cell>
                <span className="hidden md:block">{match.teamA.name}</span>
                <div className="md:hidden text-xs">
                    <Tooltip content={match.championship.name}>
                        {abbrText(match.teamA.name, 12)} - {abbrText(match.teamB.name, 16)}
                    </Tooltip>
                </div>
            </Table.Cell>
            <Table.Cell className="hidden md:table-cell">
                {match.teamB.name}
            </Table.Cell>
            <Table.Cell>
                <span className="hidden md:block">{new Date(match.date).toLocaleString('it-IT', localeOpts)}</span>
                <span className="md:hidden text-xs">{new Date(match.date).toLocaleString('it-IT', localeOpts)}</span>
            </Table.Cell>
            <Table.Cell>
                {(match.result.a + match.result.b) > 0 ?
                    <Tooltip content={readablePartials(match.partials)}>
                        <span className="hidden md:block">{`${match.result.a} - ${match.result.b}`}</span>
                        <span className="md:hidden text-xs">{`${match.result.a} - ${match.result.b}`}</span>
                    </Tooltip>
                    :
                    <>
                        <span className="hidden md:block">0 - 0</span>
                        <span className="md:hidden text-xs">0 - 0</span>
                    </>
                }
            </Table.Cell>
            <Table.Cell className={`hidden ${showReferees && 'md:table-cell'}`}>
                {referees().length > 0 &&
                    <Tooltip content={referees().map((a, i) => <p key={`prev_${i}`}>{a}</p>)}>
                        <div className="bg-gray-100 hover:bg-gray-200 p-1.5 rounded border border-gray-300">
                            <WhistleIcon className="w-6 h-6" />
                        </div>
                    </Tooltip>
                }
            </Table.Cell>
        </Table.Row>
    )
}

function PreviousMatches({ previousMatches }) {
    const { showReferees } = useSelector(state => state.committee);
    const [showResults, setShowResults] = useState(10);
    const showMatches = useCallback(() => previousMatches?.slice(0, showResults), [previousMatches, showResults]);

    return (
        <Table hoverable={true}>
            <Table.Head>
                <Table.HeadCell className="hidden md:table-cell">
                    #
                </Table.HeadCell>
                <Table.HeadCell className="hidden sm:table-cell">
                    Campionato
                </Table.HeadCell>
                <Table.HeadCell>
                    <span className="hidden md:block">Squadra 1</span>
                    <span className="md:hidden">Squadre</span>
                </Table.HeadCell>
                <Table.HeadCell className="hidden md:table-cell">
                    Squadra 2
                </Table.HeadCell>
                <Table.HeadCell>
                    Data
                </Table.HeadCell>
                <Table.HeadCell>
                    Risultato
                </Table.HeadCell>
                <Table.HeadCell className={`hidden ${showReferees && 'md:table-cell'}`}>
                    UDG
                </Table.HeadCell>
            </Table.Head>
            <Table.Body className="divide-y">
                {showMatches().length < 1 ?
                    <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                        <Table.Cell colSpan={7} className="text-center">
                            <Spinner />
                        </Table.Cell>
                    </Table.Row>
                    :
                    showMatches().map(match => <TableRow key={match._id} match={match} showReferees={showReferees} />)
                }
                {showMatches().length < previousMatches.length &&
                    <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                        <Table.Cell colSpan={7} className="text-center uppercase italic font-bold">
                            <span
                                className="cursor-pointer hover:underline"
                                onClick={() => setShowResults(prevState => prevState += 10)}
                            >
                                Carica altri risultati
                            </span>
                        </Table.Cell>
                    </Table.Row>
                }
            </Table.Body>
        </Table>
    )
}

export default PreviousMatches;